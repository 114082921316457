import { Component, Input, OnInit ,ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AwsSupplierManagementService } from 'src/app/services/main/home/supplier-management/aws-supplier-management.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-info-supplier',
  templateUrl: './info-supplier.component.html',
  styleUrls: ['./info-supplier.component.scss'],
  providers: [ConfirmationService],
})
export class InfoSupplierComponent implements OnInit {

  @Input() supplier = "";
  @Input() idCompany = "";

  displayInfoSupplier = true;
  loadComplete = true;
  companyTypes = [];
  //card left
  profile = null;
  typeCompany = "";
  years = "";
  files = [];

  informationColor = "";
  informationNum = 0;
  informationScore = 0;
  reputationColor = "";
  reputationNum = 0;
  reputationScore = 0;
  trustColor = "";
  trustNum = 0;
  trustScore = 0;

  //card resumen
  tabSidebar_info = 'resumen';
  tabDetail = false;

  categories = [];
  categoriesUnspsc = [];
  accountBanks = [];
  catalogues = [];
  clients = [];
  infraestructures = [];
  transports = [];
  drivers = [];
  delivery = [];
  payment = [];
  warranty = [];
  receipt = [];
  qualityManagement = null;
  securityHealthWork = null;
  responsabilitySocialEnvironmental = null;
  accountingInformation = null;
  reportsFiles = [];
  financialReference = [];
  chart: any;

  yearNow = new Date().getFullYear();
  annualSaleYear1 = null;
  annualSaleAmount1USD = null;
  annualSaleYear2 = null;
  annualSaleAmount2USD = null;
  annualSaleYear3 = null;
  annualSaleAmount3USD = null;
  
  certificateISO9001 = false;
  certificateGMP = false;
  certificateBCR = false;
  certificateHACCP = false;
  certificateBASC = false;
  certificateISO14001 = false;
  certificateISO18001 = false;

  //card contactos
  executives = [];
  // card estado
  clasification = "";
  status = "";

  quantityLineBusiness = 0;
  quantityExecutive = 0;
  quantityAccountBank = 0;

  quantityLineProduct = 0;
  quantityExperienceClients = 0;
  quantityInfraestructure = 0;
  quantityConditionTerms = 0;
  quantityQualityManagement = 0;
  quantityEnviromental = 0;
  quantitySecurity = 0;
  quantityAccountingInformation = 0;
  quantityFinancialReference = 0;
  quantitySafety = 0;
  list_activity_proccess = [];

  centerMap: google.maps.LatLngLiteral;
  zoomMap = 12;
  positionMarker: google.maps.LatLngLiteral;
  options = {
    draggable: true
  };
  listMap = false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  //MAP INFRAESTRUCTURA
  center: google.maps.LatLngLiteral;
  zoom = 12;
  temporalInfraestructure = null;

  listTaxConditions = [];
  taxConditions = [];
  
  formStatusObserved :UntypedFormGroup;
  formStatusSuspended :UntypedFormGroup;
  formStatusBaned :UntypedFormGroup;
  formStatusSuitable :UntypedFormGroup;
  tab = '';
  loadForm = false;
  loadClassification = false;
  submitted = false;
  showObserved = false;
  showSuspended = false;
  showBanned = false;
  selectedContact = [];
  desktop = false;
  dataGr = []
  loadPolicy = false;
  dataPolicies = [];
  tempDataOp = null;
  lang = "";
  dataPoliciesGeneral = [];
  dataPoliciesGood = [];
  dataPoliciesServices = [];
  dataPoliciesOther = [];
  loadRqList = false;
  listRequestSupplier = [];
  tempData = null;
  loadRqListApproval = false;
  displayModalObs= false;
  message = "";
  loadRqListObs = false;
  tempId = "";
  loadUpdateAll = false;
  constructor(
    private globalService: GlobalService,
    private aws: AwsSupplierManagementService,
    public trans: TranslateService,
    private fb: UntypedFormBuilder,
    private confirmationService: ConfirmationService
  ) {
    this.desktop = globalService.desktop;
    this.formStatusSuitable = this.fb.group({
      reason:[''],
      emails:[null],
      references:[null]
    });

    this.formStatusObserved = this.fb.group({
      reason:[ '', Validators.compose([Validators.required])],
      emails:[null],
      references:[null]
    });

    this.formStatusSuspended = this.fb.group({
      reason:[ '', Validators.compose([Validators.required])],
      date:[null , Validators.compose([Validators.required])],
      emails:[null],
      references:[null]
    });

    this.formStatusBaned = this.fb.group({
      reason:[ '', Validators.compose([Validators.required])],
      emails:[null],
      references:[null]
    });
  }

  get valueSuitable(){
    return this.formStatusSuitable.value
  }

  get controlSuitable(){
    return this.formStatusSuitable.controls
  }

  get valueObserved(){
    return this.formStatusObserved.value
  }

  get controlObserved(){
    return this.formStatusObserved.controls
  }

  get valueSuspended(){
    return this.formStatusSuspended.value
  }

  get controlSuspended(){
    return this.formStatusSuspended.controls
  }

  get valueBaned(){
    return this.formStatusBaned.value
  }

  get controlBaned(){
    return this.formStatusBaned.controls
  }

  ngOnInit(): void {
    this.getCompanyData();
    this.listPolicies();
    this.formatsSupplierList();
    this.lang = `${this.globalService.lang.charAt(0).toUpperCase()}` + `${this.globalService.lang.slice(1)}`;
    this.companyTypes = [
      {
        value: 'BOTH',
        label: `${this.globalService.lang === 'es' ? 'SERVICIOS, BIENES Y EQUIPOS' : 'SERVICES, GOODS AND EQUIPMENT'}`,
      },
      {
        value: 'GOOD',
        label: `${
          this.globalService.lang === 'es' ? 'BIENES Y EQUIPOS' : 'GOODS AND EQUIPMENT' }`,
      },
      {
        value: 'SERVICE',
        label: `${this.globalService.lang === 'es' ? 'SERVICIOS' : 'SERVICES'}`,
      },
    ];
    //centrar mapa
    navigator.geolocation.getCurrentPosition((position) => {
      this.centerMap = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }

  async updateAll(){
    this.getCompanyData();
    this.listPolicies();
    this.formatsSupplierList();
  }

  async getCompanyData(){
    this.loadComplete = true;
    let result = await firstValueFrom(this.aws.getCompanySupplier({ idSupplier: this.supplier, idCompany: this.idCompany, language: this.globalService.lang }));
    this.loadComplete = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.profile = result['data']['profile'];
      this.years = `${new Date().getFullYear() - new Date(this.profile['startCommercialActivity'] * 1000).getFullYear()} años(s)` ;
      this.typeCompany = this.companyTypes.find(c => c['value'] === this.profile['typeCompany']) ? this.companyTypes.find(c => c['value'] === this.profile['typeCompany'])['label'] : '';
      this.changeValues(this.profile);

      this.categories = result['data'] ? result['data']['categories'] : [];
      this.categoriesUnspsc = result['data'] ? result['data']['categoriesUnspsc'] : [];
      this.accountBanks = result['data'] ? result['data']['accountBanks'] : [];
      this.catalogues = result['data'] ? result['data']['catalogues'] : [];
      this.clients = result['data'] ? result['data']['clients'] : [];
      this.infraestructures = result['data'] ? result['data']['infraestructures'] : [];
      this.transports = result['data'] ? result['data']['transports'] : [];
      this.drivers = result['data'] ? result['data']['drivers'] : [];
      this.delivery = result['data'] ? result['data']['delivery'] : [];
      this.payment = result['data'] ? result['data']['payment'] : [];
      this.warranty = result['data'] ? result['data']['warranty'] : [];
      this.receipt = result['data'] ? result['data']['receipts'] : [];
      this.qualityManagement = result['data'] ? result['data']['qualityManagement'] : null;
      this.securityHealthWork = result['data'] ? result['data']['safetyCal'] : null;
      this.responsabilitySocialEnvironmental = result['data'] ? result['data']['enviromentCal'] : null;
      this.accountingInformation = result['data'] ? result['data']['accountingInformation'] : null;
      this.reportsFiles = result['data'] ? result['data']['reportsFiles'] : [];
      this.financialReference = result['data'] ? result['data']['financialReferenceList'] : [];
      this.executives = result['data'] ? result['data']['executivesAndSalesRepresentatives'] : [];
      if(this.accountingInformation){
        this.annualSaleYear1 = this.accountingInformation.annualSaleYear1;
        this.annualSaleAmount1USD = this.accountingInformation.annualSaleAmount1USD;
        this.annualSaleYear2 = this.accountingInformation.annualSaleYear2;
        this.annualSaleAmount2USD = this.accountingInformation.annualSaleAmount2USD;
        this.annualSaleYear3 = this.accountingInformation.annualSaleYear3;
        this.annualSaleAmount3USD = this.accountingInformation.annualSaleAmount3USD;
      }

      this.certificateISO9001 = result['data']['qualityManagement']['ISO9001Certificate'].length > 0 ? true : false;
      this.certificateGMP = result['data']['qualityManagement']['GMPCertificate'].length > 0 ? true : false;
      this.certificateBCR = result['data']['qualityManagement']['BRCCertificate'].length > 0 ? true : false;
      this.certificateHACCP = result['data']['qualityManagement']['HACCPCertificate'].length > 0 ? true : false;
      this.certificateBASC = result['data']['qualityManagement']['BASCCertificate'].length > 0 ? true : false;
      this.certificateISO14001 = result['data']['enviromentCal']['ISO14001Certificate'].length > 0 ? true : false;
      this.certificateISO18001 = result['data']['safetyCal']['OSHA18001Certificate'].length > 0 ? true : false;

      //card estado
      this.clasification = result['data']['relation']['clasification'];
      this.status = result['data']['relation']['statusByCompany'];



      this.quantityLineBusiness = result['data']['categoriesCal']['calification'];
      this.quantityExecutive = result['data']['executiveCal']['calification'];
      this.quantityAccountBank = result['data']['accountBankCal']['calification'];

      this.quantityLineProduct = result['data']['lineProductAndServiceCal']['calification'];
      this.quantityExperienceClients = result['data']['experienceCal']['calification'];
      this.quantityInfraestructure = result['data']['infraestructureCal']['calification'];
      this.quantityConditionTerms = result['data']['conditionCommercialCal']['calification'];
      this.quantityQualityManagement = result['data']['qualityManagement']['calification'];
      this.quantityEnviromental = result['data']['enviromentCal']['calification'];
      this.quantitySecurity = result['data']['safetyCal']['calification'];
      this.quantityAccountingInformation = result['data']['accountingInformation']['calification'];
      this.quantityFinancialReference = result['data']['financialReference']['calification'];
      this.quantitySafety = result['data']['safetyCal']['calification'];
      this.list_activity_proccess = result['data']['relation']['listStatus'];

      

      this.listTaxConditions = [
        { code: 'RETAINING_AGENT', name: 'RETAINING AGENT', language_name: `${ this.globalService.lang === 'es' ? 'AGENTE RETENEDOR' : 'RETAINING AGENT' }` },
        { code: 'PERCEPTION_AGENT', name: 'PERCEPTION AGENT', language_name: `${ this.globalService.lang === 'es' ? 'AGENTE DE PERCEPCIÓN' : 'PERCEPTION AGENT' }` },
        { code: 'WAIVER_OF_IGV_EXEMPTION', name: 'WAIVER OF IGV EXEMPTION', language_name: `${ this.globalService.lang === 'es' ? 'RENUNCIA A LA EXONERACION DE IGV' : 'WAIVER OF IGV EXEMPTION' }` },
        { code: 'GOOD_TAXPAYER', name: 'GOOD TAXPAYER', language_name: `${ this.globalService.lang === 'es' ? 'BUEN CONTRIBUYENTE' : 'GOOD TAXPAYER' }` },
        { code: 'AFFECT_TO_DETRACTION', name: 'AFFECT TO DETRACTION', language_name: `${ this.globalService.lang === 'es' ? 'AFECTO A DETRACCION' : 'AFFECT TO DETRACTION' }` },
        { code: 'AFFECT_TO_INCOME_OF_4TH_CATEGORY', name: 'AFFECT TO INCOME OF 4TH CATEGORY', language_name: `${ this.globalService.lang === 'es' ? 'AFECTO A RENTA DE 4TA CATEGORIA' : 'AFFECT TO INCOME OF 4TH CATEGORY' }` },
        { code: 'AFFECT_TO_IGV_WITHHOLDING', name: 'AFFECT TO IGV WITHHOLDING', language_name: `${ this.globalService.lang === 'es' ? 'AFECTO A RETENCION DE IGV' : 'AFFECT TO IGV WITHHOLDING' }` },
        { code: 'WITHHOLDING_OF_INCOME_NOT_DOMICILED', name: 'WITHHOLDING OF INCOME NOT DOMICILED', language_name: `${ this.globalService.lang === 'es' ? 'RETENCION DE RENTA NO DOMICILIADO' : 'WITHHOLDING OF INCOME NOT DOMICILED' }` },
        { code: 'NO_4TH_CATEGORY_INCOME_AFFECT', name: 'NO 4TH CATEGORY INCOME AFFECT', language_name: `${ this.globalService.lang === 'es' ? 'NO AFECTO A RENTA DE 4TA CATEGORIA' : 'NO 4TH CATEGORY INCOME AFFECT' }` }
      ];
      let tempArr = [];
      if(this.profile['taxConditions']){
        this.profile['taxConditions'].forEach(element => {
          if(element){
            tempArr =  this.listTaxConditions.filter( c => c['code'].includes(element));
            if(tempArr[0]){
              this.taxConditions.push(tempArr[0])
            }
          }
          
        });
      }
      /* 
      //card 3
      this.executives = result['data']['executivesAndSalesRepresentatives'];

      for (const executive of this.executives) {
        if(['chiefSalesOfficer', 'chiefLogisticsOfficer', 'chiefadministrationOfficer', 'chiefOperationsOfficer', 'chiefExecutiveOfficer', 'projectManager'].includes(executive['typeExecutive'])){
          this.chief.push(executive);
        }else if(['salesRepresentative'].includes(executive.typeExecutive)){
          this.sales.push(executive);
        }else if(['logisticsManager','operationsManager','chiefExecutiveProject','salesManager'].includes(executive.typeExecutive)){
          this.manager.push(executive);
        }else{
          this.additional.push(executive);
        }
      }

      
 */
      this.loadComplete = false;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  async listPolicies(){
    this.loadPolicy = true;
    let result = await firstValueFrom(this.aws.listPoliciesCompanySupplier({ idSupplier: this.supplier, idCompany: this.idCompany}));
    this.loadPolicy = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.dataPolicies = result['data'];
      this.dataPoliciesGeneral = result['data'].filter(c=> c.policyType === 'GENERAL');
      this.dataPoliciesGood = result['data'].filter(c=> c.policyType === 'OTHER' && c.companyType === 'GOOD');
      this.dataPoliciesServices = result['data'].filter(c=> c.policyType === 'OTHER' && c.companyType === 'SERVICE');
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  showDetail(){
    this.tabDetail = true;
    setTimeout(() => {
      this.createGraphic();
    }, 1000);
  }

  closeModal() {
    this.globalService.sendRequest({ type: 'CLOSEMODALSUPPLIERINFO' });
  }

  changeValues(data) {
    this.informationScore = data['informationLevel'];
    this.informationNum = parseInt(data['informationLevel']);
    this.informationColor = this.getColorScale(this.informationNum);

    this.reputationScore = data['reputationLevel'];
    this.reputationNum = parseInt(data['reputationLevel']);
    this.reputationColor = this.getColorScale(this.reputationNum);

    this.trustScore = data['trustLevel'];
    this.trustNum = parseInt(data['trustLevel']);
    this.trustColor = this.getColorScale(this.trustNum);
  }

  getColorScale(number) {
    if (number >= 0 && number <= 45) {
      return 'red';
    } else if (number > 45 && number <= 55) {
      return 'orange';
    } else if (number > 55 && number <= 65) {
      return 'yellow';
    } else if (number > 65 && number <= 85) {
      return 'lemon';
    } else if (number > 85 && number <= 100) {
      return 'green';
    } else {
      return 'red';
    }
  }

  get formAmount() {
    let amount1 = this.annualSaleAmount1USD ? this.annualSaleAmount1USD : 0;
    let amount2 = this.annualSaleAmount2USD ? this.annualSaleAmount2USD : 0;
    let amount3 = this.annualSaleAmount3USD ? this.annualSaleAmount3USD : 0;
    let contador = 0;
    if (amount1 > 0) contador = contador + 1;
    if (amount2 > 0) contador = contador + 1;
    if (amount3 > 0) contador = contador + 1;
    let suma = amount1 + amount2 + amount3;
    if (suma === 0) {
      return 0;
    } else {
      let promedio = suma / contador;
      return promedio;
    }
  }

  get rangeSales() {
    if (this.formAmount >= 1 && this.formAmount <= 200000) {
      return `Menos de $200,000`;
    } else if (this.formAmount > 200000 && this.formAmount <= 500000) {
      return 'USD 200,001 - 500,000';
    } else if (this.formAmount > 500000 && this.formAmount <= 1000000) {
      return 'USD 500,001 - 1,000,000';
    } else if (this.formAmount > 1000000 && this.formAmount <= 3000000) {
      return 'USD 1,000,001 - 3,000,000';
    } else if (this.formAmount > 3000000 && this.formAmount <= 5000000) {
      return 'USD 3,000,001 - 5,000,000';
    } else if (this.formAmount > 5000000 && this.formAmount <= 10000000) {
      return 'USD 5,000,001 - 10,000,000';
    } else if (this.formAmount > 10000000 && this.formAmount <= 25000000) {
      return 'USD 10,000,001 - 25,000,000';
    } else if (this.formAmount > 25000000 && this.formAmount <= 50000000) {
      return 'USD 25,000,001 - 50,000,000';
    } else if (this.formAmount > 50000000 && this.formAmount <= 75000000) {
      return 'USD 50,000,001 - 75,000,000';
    } else if (this.formAmount > 75000000 && this.formAmount <= 100000000) {
      return 'USD 75,000,001 - 100,000,000';
    } else if (this.formAmount > 100000000) {
      return `Más de $100,000,001`;
    } else {
      return '-';
    }
  }

  createGraphic(){
    this.dataGr =  this.profile.valuesList;
  }
  
  getRango(promedio) {
    if (promedio >= 1 && promedio <= 1000) {
      return `HASTA US$ 1,000`;
    } else if (promedio > 1000 && promedio <= 5000) {
      return 'US$ 1,001 - US$ 5,000';
    } else if (promedio > 5000 && promedio <= 10000) {
      return 'US$ 5,001 - US$ 10,000';
    } else if (promedio > 10000 && promedio <= 20000) {
      return 'US$ 10,001 - US$ 20,000';
    } else if (promedio > 20000 && promedio <= 50000) {
      return 'US$ 20,001 - US$ 50,000';
    } else if (promedio > 50000 && promedio <= 100000) {
      return 'US$ 50,001 - US$ 100,000';
    } else if (promedio > 100000 && promedio <= 250000) {
      return 'US$ 100,001 - US$ 250,000';
    } else if (promedio > 250000 && promedio <= 500000) {
      return 'US$ 250,001 - US$ 500,000';
    } else if (promedio > 500000 && promedio <= 1000000) {
      return 'US$ 500,001 - US$ 1M';
    } else if (promedio > 1000000 && promedio <= 2500000) {
      return 'US$ 1M - US$ 2.5M';
    } else if (promedio > 2500000 && promedio <= 5000000) {
      return 'US$ 2.5M - US$ 5M';
    } else if (promedio > 5000000 && promedio <= 10000000) {
      return 'US$ 5M - US$ 10M';
    } else if (promedio > 10000000 && promedio <= 20000000) {
      return 'US$ 10M - US$ 20M';
    } else if (promedio > 20000000 && promedio <= 50000000) {
      return 'US$ 20M - US$ 50M';
    } else if (promedio > 50000000) {
      return `MÁS DE US$ 50M`;
    }else{
      return '-'
    }
  }

  closeListMap(){
    if (this.listMap = true) {
       document.querySelector('.container-list-map').classList.remove('animate__animated' , 'animate__slideInRight');
       document.querySelector('.container-list-map').classList.add('animate__animated', 'animate__slideOutRight');
       setTimeout(() => {
          this.listMap = false;
       }, 1000);
      
    }
  }

  openLocation(item){
      this.centerMap = {
        lat: item.lat,
        lng: item.lng,
      };
      this.center = {
        lat: item.lat,
        lng: item.lng,
      };
      this.closeListMap();
  }

  openInfo(marker: MapMarker, content) {
    this.temporalInfraestructure = content;
    this.infoWindow.open(marker)
  }

  openCopyMail() {
    this.globalService.sendRequest({
      severity: 'success',
      summary: 'Mensaje',
      detail: 'MAIL_COPIED_TO_CLIPBOARD',
      type: 'TOAST',
    });
  }
  openCopyPhone() {
    this.globalService.sendRequest({
      severity: 'success',
      summary: 'Mensaje',
      detail: 'PHONE_COPIED_TO_CLIPBOARD',
      type: 'TOAST',
    });
  }

  async saveClassification(selected){
    let body = { 
      idSupplier:this.supplier, 
      idCompany:this.idCompany, 
      clasification:selected
    }
    if(selected != this.clasification){
      this.loadClassification = true;
      let result = await firstValueFrom(this.aws.updateClasificationCompany(body));
      this.loadClassification = false;
      if(result['status'] === false){
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      }else if(result['status'] === true){
        this.clasification = result['data']['clasification'];
        this.globalService.sendRequest({ severity:'success', summary: 'Success', detail: result['message'], type : 'TOAST'});
        this.globalService.sendRequest({ type : 'UPDATELOGINFOSUPPLIER'});
        this.globalService.sendRequest({ type : 'UPDATETABLEINFOSUPPLIER'});
      }else{
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
      }
    }
  }

  async saveStatus(selected, form){
    let body = null;
    
      body = { 
        idSupplier:this.supplier, 
        idCompany:this.idCompany, 
        statusByCompany:selected,
        reason: form['value']['reason'],
        emails: form['value']['emails'] ? form['value']['emails'] : [],
        startDate: form['value']['date'] ? (form['value']['date'][0] ? form['value']['date'][0].getTime() / 1000 : null) : null,
        endDate: form['value']['date'] ? (form['value']['date'][1] ? form['value']['date'][1].getTime() / 1000 : null) : null,
        references: form['value']['references'] ? form['value']['references'] : []
      }

      if(form.valid && selected != this.status){
        if(this.tab === 'SUSPENDED'){
          if(form['value']['date'] && form['value']['date'][1]){
            this.submitted = false;
            this.loadForm = true;
            let result = await firstValueFrom(this.aws.updateStatusByCompanyCompany(body));
            this.loadForm = false;
            if(result['status'] === false){
              this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
            }else if(result['status'] === true){
              this.status = result['data']['statusByCompany'];
              this.globalService.sendRequest({ severity:'success', summary: 'Success', detail: result['message'], type : 'TOAST'});
              this.globalService.sendRequest({ type : 'UPDATELOGINFOSUPPLIER'});
              this.globalService.sendRequest({ type : 'UPDATETABLEINFOSUPPLIER'});
              this.resetform();
            }else{
              this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
            }
          }else{
            this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'DATE_RANGE_REQUIRED', type : 'TOAST'});
          }
        }else{
          this.submitted = false;
          this.loadForm = true;
          let result = await firstValueFrom(this.aws.updateStatusByCompanyCompany(body));
          this.loadForm = false;
          if(result['status'] === false){
            this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
          }else if(result['status'] === true){
            this.status = result['data']['statusByCompany'];
            this.globalService.sendRequest({ severity:'success', summary: 'Success', detail: result['message'], type : 'TOAST'});
            this.globalService.sendRequest({ type : 'UPDATELOGINFOSUPPLIER'});
            this.globalService.sendRequest({ type : 'UPDATETABLEINFOSUPPLIER'});
            this.resetform();
          }else{
            this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
          }
        }
      }else{
        this.submitted = true;
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'COMPLETE_REQUIRED_FIELDS', type: 'TOAST', });
      }
    
  }

  validateEmailsList(tab) {
    let emailss = [];
    if(tab === 'SUITABLE'){
      emailss = this.formStatusSuitable.value.emails;
    }
    if(tab === 'OBSERVED'){
      emailss = this.formStatusObserved.value.emails;
    }
    if(tab === 'SUSPENDED'){
      emailss = this.formStatusSuspended.value.emails;
    }
    if(tab === 'BANNED'){
      emailss = this.formStatusBaned.value.emails;
    }
    if (emailss.length > 0) {
      if(tab === 'SUITABLE'){
        this.formStatusSuitable.patchValue({
          emails: emailss.filter((c) => this.validateEmail(c)).map((c) => c.toLowerCase().trim()),
        });
      }
      if(tab === 'OBSERVED'){
        this.formStatusObserved.patchValue({
          emails: emailss.filter((c) => this.validateEmail(c)).map((c) => c.toLowerCase().trim()),
        });
      }
      if(tab === 'SUSPENDED'){
        this.formStatusSuspended.patchValue({
          emails: emailss.filter((c) => this.validateEmail(c)).map((c) => c.toLowerCase().trim()),
        });
      }
      if(tab === 'BANNED'){
        this.formStatusBaned.patchValue({
          emails: emailss.filter((c) => this.validateEmail(c)).map((c) => c.toLowerCase().trim()),
        });
      }
    } else {
      this.formStatusSuitable.patchValue({ emails: null });
      this.formStatusObserved.patchValue({ emails: null });
      this.formStatusSuspended.patchValue({ emails: null });
      this.formStatusBaned.patchValue({ emails: null });
    }
  }

  validateEmail(val) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(val.toLowerCase());
  }

  resetform(){
    this.formStatusBaned.reset();
    this.formStatusObserved.reset();
    this.formStatusSuspended.reset();
  }

  cancelShow(){
    this.showBanned = false;
    this.showObserved = false;
    this.showSuspended = false;
    this.tab = '';
  }

  async copyAll(){
    if(this.selectedContact.length > 0){
      let mails = [];
      this.selectedContact.map(c=> {
        mails.push(c['email'])
      })
      let text ;
      text = mails.toString();
      try {
        await navigator.clipboard.writeText(text.split(",").join(";"));
      } catch (e) {
      }
      this.globalService.sendRequest({
        severity: 'success',
        summary: 'Mensaje',
        detail: 'MAILS_COPIED_TO_CLIPBOARD',
        type: 'TOAST',
      });
    }
    
  }

  scroll(el: HTMLElement) {
      el.scrollIntoView({behavior: 'smooth'});
  }


  async formatsSupplierList(){
    this.loadRqList = true;
    try {
      let body = {
        "idCompany": this.idCompany,
        "idSupplier": this.supplier
      }  
      let result = await firstValueFrom(this.aws.requiredFormatsSupplierList(body));
      if(!result['status']){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      }else{
        this.listRequestSupplier = result['data'].sort((a,b) => a.createdDate < b.createdDate ? 1 : -1);
      }
    } catch (error) {
      if (error.status === 401) {
        this.globalService.sendRequest({severity: 'error', summary: 'Error', detail: error.error['message'], type: 'TOAST'});
      }else if (error.status === 400){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'Error de sistema.', type: 'TOAST' });
      }
    }
    this.loadRqList = false;
  }

  async approvedRequest(id){
    this.loadRqListApproval = true;
    try {
      let body = {
        id: id,
        "idCompany": this.idCompany,
        "idSupplier": this.supplier,
        "language": this.globalService.lang
      }  
      let result = await firstValueFrom(this.aws.documentApprovalRequestApproved(body));
      if(!result['status']){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      }else{
        this.globalService.sendRequest({ severity: 'success', summary: 'Error', detail: result['message'], type: 'TOAST' });
        this.formatsSupplierList();
      }
    } catch (error) {
      if (error.status === 401) {
        this.globalService.sendRequest({severity: 'error', summary: 'Error', detail: error.error['message'], type: 'TOAST'});
      }else if (error.status === 400){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'Error de sistema.', type: 'TOAST' });
      }
    }
    this.loadRqListApproval = false;
  }

  async saveObserve(){
    this.loadRqListObs = true;
    try {
      let body = {
        id: this.tempId,
        "idCompany": this.idCompany,
        "idSupplier": this.supplier,
        motive: this.message,
        "language": this.globalService.lang
      }  
      let result = await firstValueFrom(this.aws.documentApprovalRequestObserve(body));
      if(!result['status']){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      }else{
        this.globalService.sendRequest({ severity: 'success', summary: 'Error', detail: result['message'], type: 'TOAST' });
        this.formatsSupplierList();
        this.tempId = null;
        this.message = "";
        this.displayModalObs = false;
      }
    } catch (error) {
      if (error.status === 401) {
        this.globalService.sendRequest({severity: 'error', summary: 'Error', detail: error.error['message'], type: 'TOAST'});
      }else if (error.status === 400){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'Error de sistema.', type: 'TOAST' });
      }
    }
    this.loadRqListObs = false;
  }

  confirmApprove(event: Event, id) {
    this.confirmationService.confirm({
        target: event.target,
        message: this.globalService.lang === 'es' ? '¿Está seguro de aprobar este documento?' : "Are you sure you approve this document?",
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.approvedRequest(id);
        },
        reject: () => {
            //reject action
        }
    });
}
}
