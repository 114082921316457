import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SessionIn } from 'src/app/interfaces/session-in';
import { GlobalService } from 'src/app/services/global.service';
import { HubService } from 'src/app/services/hub.service';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-new-feed',
  templateUrl: './new-feed.component.html',
  styleUrls: ['./new-feed.component.scss']
})
export class NewFeedComponent implements OnInit, OnChanges, OnDestroy {

  emojii18n = null;
  sessionIn: SessionIn;
  @Input() listNewFeed = null;
  @Output() deletePost = new EventEmitter<Object>();
  @Output() updatePostEvent = new EventEmitter<Object>();

  listComment = [];
  loadingComment = false;
  comments: string = "";
  loading = false;
  ExclusiveStartKeyComment = null;
  
  comment:boolean = false;

  reaction = null;
  loadingReaction = false;
  showReaction = false;
  displayModalUpdate = false
  items = [{
    label: 'Opciones',
    items: [{
      label: 'Actualizar',
      icon: 'pi pi-refresh',
      command: () => {
          this.updatePost();
      }
    },
    {
      label: 'Borrar',
      icon: 'pi pi-times',
      command: () => {
          this.deletedPost();
      }
    }]
  }];
  themeSelect = '';
  displayModalPublish = false;
  nameCompany = '';
  id = "";

  contentHtml = "";
  subscriber: Subscription;
  constructor(
    private sessionService: SessionService,
    private aws: AwsHubHomeService,
    private hubService: HubService,
    private globalService: GlobalService,
    public trans: TranslateService,
    private router: Router
  ) {
    this.themeSelect = this.globalService.theme
    this.sessionIn = this.sessionService.sessionIn;
    this.subscriber = this.globalService.request.subscribe((res) => {
      if(res){
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateServiceEmoji();
            break;
          case 'UPDATETHEME':
            this.themeSelect = this.globalService.theme
            break;
            default:
            break;
        }
      }
    });
   }

  ngOnInit(): void {
    this.reaction = this.listNewFeed['reaction'];
    this.id = this.listNewFeed['post']['id'];
    if(this.listNewFeed['post']['format'] === "GENERAL"){
      if(this.listNewFeed['post']['whoPublished'] === "COMPANY"){
        this.nameCompany = this.listNewFeed['company']['businessName'];
      }
    }
    this.translateServiceEmoji();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    
    if(this.listNewFeed){
      let comment = this.replaceURLWithHTMLLinks(this.listNewFeed['post']['formatInformation']['comment']);
      this.contentHtml = comment.split("\n").join("<br>");
    }
  }

  replaceURLWithHTMLLinks(e) {
    return e.replace(
      /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/gi,
      function (e, r, n) {
        var t = '';
        r = r || '';
        for (var a = /\(/g; a.exec(r); ) {
          var l;
          (l = /(.*)(\.\).*)/.exec(n) || /(.*)(\).*)/.exec(n)) &&
            ((n = l[1]), (t = l[2] + t));
        }
        return (
          r +
          "<a href='" +
          n +
          "' target='_blank' rel='nofollow noopener'>" +
          n +
          '</a>' +
          t
        );
      }
    );
  }

  async translateServiceEmoji(){
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;
  }

  //reaciones
  async reactionPost(){
    this.loadingReaction = true;
    let result = await firstValueFrom(this.aws.createReaction({ idPost: this.listNewFeed['post']['id'], type: 'INTERESTED', whoReaction: 'COMPANY', idCompany: this.hubService.idCompany }));
    this.loadingReaction = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.listNewFeed['post']['amountOfInterest'] = this.listNewFeed['post']['amountOfInterest'] + 1;
      this.listNewFeed['reaction'] = result['data'];
      this.reaction = result['data'];
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  async deletedReaction(){
    this.loadingReaction = true;
    let result = await firstValueFrom(this.aws.deleteReaction({ idPost: this.listNewFeed['post']['id'] }));
    this.loadingReaction = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.listNewFeed['post']['amountOfInterest'] = this.listNewFeed['post']['amountOfInterest'] - 1;
      this.listNewFeed['reaction'] = null;
      this.reaction = null;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  //abrir post
  openPreview(){
    if(this.listNewFeed['post']['format'] === "GENERAL"){
      if(this.listNewFeed['post']['type'] != "GENERAL"){
        if(this.listNewFeed['post']['whoPublishedGroup'] === "HUB"){
          this.globalService.sendRequest({ data: this.listNewFeed, type : 'SHOW_POST', index: 0});
          this.router.navigate([`/home/hub/${this.hubService.idHub}/${this.hubService.idCompany}/post/${this.listNewFeed['post']['id']}`]);
        }
      }
    }
    // else{
    //   if(this.listNewFeed['post']['type'] === "GENERAL"){
    //     if(this.listNewFeed['post']['whoPublishedGroup'] === "NOT_GROUP"){
    //       this.globalService.sendRequest({ data: this.listNewFeed, type : 'SHOW_POST', index: 0});
    //       this.router.navigate([`/home/post/${this.listNewFeed['post']['id']}`]);
    //     }
    //   }
    // }
  }

  openPreviewIndex(index){
    if(this.listNewFeed['post']['format'] === "GENERAL"){
      if(this.listNewFeed['post']['type'] != "GENERAL"){
        if(this.listNewFeed['post']['whoPublishedGroup'] === "HUB"){
          this.globalService.sendRequest({ data: this.listNewFeed, type : 'SHOW_POST', index: index});
          this.router.navigate([`/home/hub/${this.hubService.idHub}/${this.hubService.idCompany}/post/${this.listNewFeed['post']['id']}`]);
        }
      }
    }
  }

  updatePost(){
    this.displayModalUpdate = true;
  }

  async deletedPost(){
    let result = await firstValueFrom(this.aws.deletePost({ id: this.listNewFeed['post']['id'], createdDate: this.listNewFeed['post']['createdDate'] }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.deletePost.emit(this.listNewFeed)
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  updatePostEventEmit(e){
    this.updatePostEvent.emit(e);
  }

  addEmoji(event) {
    const text = `${this.comments}${event.emoji.native}`;
    this.comments = text;
  }

}
