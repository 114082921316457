<p-dialog [(visible)]="modalCompletProfile" [modal]="true" [breakpoints]="{'640px': '100vw'}" [style]="{'width': '70%'}" [showHeader]="false" [closeOnEscape]="false" styleClass="modal-create-profile" appendTo="body">
    <div class="grid m-0">
        <form [formGroup]="initialCompletedProfile" method="GET" style="width: 100%;">
            <ng-container *ngIf="loadDependency">
                <div class="col-12 flex align-items-center justify-content-center flex-column pt-4">
                    <div class="step border-700 border-circle border-1 flex align-items-center justify-content-center mb-3">
                        1
                    </div>
                    <span class="font-bold" translate>profileData</span>
                </div>
                <div class="col-12 text-center">
                    <p class="m-0 text-base" translate>fillprofileData <i class="fa fa-cogs fas ml-2"></i></p>
                </div>
                <div class="col-12 mb-3" style="border-bottom: 1px solid var(--surface-d);">
                    <h3 class="m-0" translate>profileU</h3>
                </div>
                <div class="col-12">
                    <div class="grid">
                        <div class="col-12 md:col-5 col-one md:pr-5">
                            <div class="field grid">
                                <label for="firstname" class="col-12 mb-2 md:col-4 md:mb-0" translate>name</label>
                                <div class="col-12 md:col-8">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="lastname" class="col-12 mb-2 md:col-4 md:mb-0" translate>lastName</label>
                                <div class="col-12 md:col-8">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="email" class="col-12 mb-2 md:col-4 md:mb-0" translate>email</label>
                                <div class="col-12 md:col-8">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="date" class="col-12 mb-2 md:col-4 md:mb-0" translate>dateBirth</label>
                                <div class="col-12 md:col-8">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
    
                        </div>
                        <div class="col-12 md:col-5">
                            <div class="field grid">
                                <label for="cellphone" class="col-12 mb-2 md:col-3 md:mb-0" translate>cellphone</label>
                                <div class="col-12 md:col-4">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                                <div class="col-12 md:col-5">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="phone" class="col-12 mb-2 md:col-3 md:mb-0" translate>telephone</label>
                                <div class="col-12 md:col-4">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                                <div class="col-12 md:col-5">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
    
                            <div class="field grid">
                                <label for="phone" class="col-12 mb-2 md:col-3 md:mb-0" translate>sex</label>
                                <div class="col-12 md:col-7">
                                    <p-skeleton borderRadius="6px" height="37px"></p-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-2 col-three">
                            <div class="grid">
                                <div class="col-12">
                                    <label class="col-12 font-bold" translate>profilePicture</label>
                                </div>
                                <div class="col-12 flex justify-content-center">
                                    <div class="profile-photo">
                                        <p-avatar  image="{{profilePhoto}}?e={{logo_ramdon}}" styleClass="avatarxxlarge p-mr-2" size="xlarge"
                                            shape="circle"></p-avatar>
                                        <button type="button" pButton icon="fas fa-camera"
                                            class="p-button-secondary p-button-rounded" tooltipPosition="top"
                                            (click)="displayImagencrop = true"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!loadDependency">
                <div class="col-12 flex align-items-center justify-content-center flex-column pt-4">
                    <div class="step border-700 border-circle border-1 flex align-items-center justify-content-center mb-3">
                        1
                    </div>
                    <span class="font-bold" translate>profileData</span>
                </div>
                <div class="col-12 text-center">
                    <p class="m-0 text-base" translate>fillprofileData <i class="fa fa-cogs fas ml-2"></i></p>
                </div>
                <div class="col-12 mb-3" style="border-bottom: 1px solid var(--surface-d);">
                    <h3 class="m-0" translate>profileU</h3>
                </div>
                <div class="col-12">
                    <div class="grid">
                        <div class="col-12 md:col-5 col-one md:pr-5">
                            <div class="field grid">
                                <label for="firstname" class="col-12 mb-2 md:col-4 md:mb-0" translate>name</label>
                                <div class="col-12 md:col-8">
                                    <input id="firstname" type="text" class="inputfield w-full uppercase"
                                        formControlName="name" pInputText>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="lastname" class="col-12 mb-2 md:col-4 md:mb-0" translate>lastName</label>
                                <div class="col-12 md:col-8">
                                    <input id="lastname" type="text" class="inputfield w-full uppercase"
                                        formControlName="lastName" pInputText>
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="email" class="col-12 mb-2 md:col-4 md:mb-0" translate>email</label>
                                <div class="col-12 md:col-8">
                                    <input id="email" type="text" class="inputfield w-full" formControlName="email"
                                        pInputText style="padding: 12px 10px; height: 36.4px;">
                                </div>
                            </div>
                            <div class="field grid mb-0 md:mb-3">
                                <label for="date" class="col-12 mb-2 md:col-4 md:mb-0" translate>dateBirth</label>
                                <div class="col-12 md:col-8">
                                    <p-calendar [showIcon]="true" inputId="icon" [style]="{width: '100%'}"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['dateBirth'].errors }"
                                        formControlName="dateBirth" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" appendTo="body" [maxDate]="maxDate">
                                    </p-calendar>
                                </div>
                            </div>
    
                        </div>
                        <div class="col-12 md:col-5">
                            <div class="field grid">
                                <label for="cellphone" class="col-12 mb-2 md:col-3 md:mb-0" translate>cellphone</label>
                                <div class="col-12 mb-2 md:col-4 md:mb-0">
                                    <p-dropdown [options]="codePhone" placeholder="{{'countryCode' | translate}}"
                                        [style]="{width: '100%'}" [filter]="true" filterBy="label"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['prefixCellphone'].errors }"
                                        formControlName="prefixCellphone" appendTo="body">
                                    </p-dropdown>
                                </div>
                                <div class="col-12 md:col-5">
                                    <input id="cellphone" type="text" class="inputfield w-full" pInputText
                                        style="height: 39.6px;"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['cellphone'].errors }"
                                        formControlName="cellphone" pKeyFilter="num">
                                </div>
                            </div>
                            <div class="field grid">
                                <label for="phone" class="col-12 mb-2 md:col-3 md:mb-0" translate>telephone</label>
                                <div class="col-12 mb-2 md:col-4 md:mb-0">
                                    <p-dropdown [options]="codePhone" placeholder="{{'countryCode' | translate}}"
                                        [style]="{width: '100%'}" [filter]="true" filterBy="label"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['prefixCodePhone'].errors }"
                                        formControlName="prefixCodePhone" appendTo="body"></p-dropdown>
                                </div>
                                <div class="col-12 md:col-5">
                                    <input id="phone" type="text" class="inputfield w-full" pInputText
                                        style="height: 39.6px;"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['phone'].errors }"
                                        formControlName="phone" pKeyFilter="num">
                                </div>
                            </div>
    
                            <div class="field grid">
                                <label for="phone" class="col-12 mb-2 md:col-3 md:mb-0" translate>sex</label>
                                <div class="col-12 md:col-7">
                                    <p-selectButton [options]="gender" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f['sex'].errors }" formControlName="sex">
                                        <ng-template let-item pTemplate>
                                            <span translate>{{item.label}}</span>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-2 col-three">
                            <div class="grid">
                                <div class="col-12">
                                    <label class="col-12 font-bold" translate>profilePicture</label>
                                </div>
                                <div class="col-12 flex justify-content-center">
                                    <div class="profile-photo">
                                        <p-avatar  image="{{profilePhoto}}?e={{logo_ramdon}}" styleClass="avatarxxlarge p-mr-2" size="xlarge"
                                            shape="circle"></p-avatar>
                                        <button type="button" pButton icon="fas fa-camera"
                                            class="p-button-secondary p-button-rounded" tooltipPosition="top"
                                            (click)="displayImagencrop = true"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{'closeU' | translate}}"
            class="p-button-rounded p-button-danger mr-2 w-button" (click)="closeModal()"></button>
        <button pButton pRipple type="button" label="{{'saveU' | translate}}"
            class="p-button-rounded p-button-success w-button" [loading]="loadSubmit"
            (click)="onSubmit(initialCompletedProfile.value)"></button>
    </ng-template>
    <p-dialog [closeOnEscape]="false" header="{{'attachImageU' | translate}}" [(visible)]="displayImagencrop" [modal]="true" [closable]="false" [style]="{'width': '80%'}" styleClass="dialog-crop-img" appendTo="body" [breakpoints]="{'640px': '100vw'}">
    <div class="ui-g">
        <div class="ui-g-12 pd-0">
            <app-crop-image-global [type_crop]="type_crop" (close)="displayImagencrop = false"
                (sendCrop)="saveFile($event)"></app-crop-image-global>
        </div>
    </div>
</p-dialog>
</p-dialog>