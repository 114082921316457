import { Component, EventEmitter, Input, OnInit, Output, ViewChild ,ElementRef, OnDestroy } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { firstValueFrom } from 'rxjs';
import { SessionIn } from 'src/app/interfaces/session-in';
import { GlobalService } from 'src/app/services/global.service';
import { HubService } from 'src/app/services/hub.service';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy {

  sessionIn: SessionIn;
  @Input() comment = null;
  @Input() post = null;
  @Output() deleteComment = new EventEmitter<Object>();
  @Output() new = new EventEmitter<Object>();
  emojii18n = null;
  listComment = [];
  loadingComment = false;
  comments: string = "";
  loading = false;
  ExclusiveStartKeyComment = null;

  show = false;
  selectedComment = null;
    items = [{
        label: 'Opciones',
        items: [{
            label: 'Actualizar',
            icon: 'pi pi-refresh',
            command: () => {
              this.openEdit();
            }
        },
        {
            label: 'Borrar',
            icon: 'pi pi-times',
            command: () => {
                this.deletedComment();
            }
        }
      ]}
    ];
    itemsCancel = [{
      label: 'Opciones',
      items: [
      {
          label: 'Cancelar',
          icon: 'pi pi-times',
          command: () => {
              this.cancelEditComment();
          }
      }
    ]}
    ];
updateComment = false;
commentsUpdater = "";
temporalCommentAffterCancel = '';
statusEdit = false;
loadingCommentUpdate = false;
textPost: string = '';
textPostSecond: string = '';
themeSelect = '';
@ViewChild("input") input: ElementRef;
@ViewChild("inputSecond") inputSecond: ElementRef;
subscriber: Subscription;
  constructor(
    private sessionService: SessionService,
    private aws: AwsHubHomeService,
    private hubService: HubService,
    private globalService: GlobalService,
    public trans: TranslateService
  ) {
    this.sessionIn = this.sessionService.sessionIn;
    this.themeSelect = this.globalService.theme;
    this.subscriber = this.globalService.request.subscribe((res) => {
      if(res){
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateServiceEmoji();
            break;
          case 'UPDATETHEME':
            this.themeSelect = this.globalService.theme
            break;
            default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.translateServiceEmoji();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  async translateServiceEmoji(){
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;
  }
  async showAnswer(){
    if(!this.show){
      this.show = true;
      if(this.comment['comment']['amountOfComment'] > 0){
        this.listComment = [];
        this.loadingComment = true;
        let result = await firstValueFrom(this.aws.getCommentForComment({ 
          idPost: this.post['post']['id'],idCommentPrincipal: this.comment['comment']['id'], ExclusiveStartKey: this.ExclusiveStartKeyComment
        }));
  
        this.loadingComment = false;
        if(result['status'] === false){
          this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
        }else if(result['status'] === true){
          this.ExclusiveStartKeyComment = result['data']['ExclusiveStartKey'] ? result['data']['ExclusiveStartKey'] : null
          result['data']['comments'].forEach(c => {
            this.listComment.push(c)
          });
        }else{
          this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
        }
      }
    }else{
      this.show = false;
    }
  }

  async newComment(){
    this.loading = true;
    let result = await firstValueFrom(this.aws.createPostsComment({ 
      idPost: this.post['post']['id'], type: 'ANSWER', whoComment: 'COMPANY', idCompany: this.hubService.idCompany,
      idCommentPrincipal: this.comment['comment']['id'], comment: this.comments.trim()
    }));
    this.loading = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.comments = "";
      this.listComment.unshift(result['data']);
      this.comment['comment']['amountOfComment'] = this.comment['comment']['amountOfComment'] + 1;
      this.new.emit({});
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  showOptions(op: OverlayPanel, event, comment, type){
    op.show(event);
    comment['selected']=true;
    this.selectedComment = comment;
    this.temporalCommentAffterCancel = comment.comment;
  }

  async deletedComment(){
    let result = await firstValueFrom(this.aws.deleteComment({ id: this.selectedComment['id'] }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.deleteComment.emit(this.selectedComment);
      if(this.selectedComment['type'] === "ANSWER"){
        this.comment['comment']['amountOfComment'] = this.comment['comment']['amountOfComment'] - 1;
        this.listComment = this.listComment.filter(c => c['comment']['id'] != this.selectedComment['id']);
      }
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }


  async editComment(comment){
    this.loadingCommentUpdate = true;
    let body = {
      id:comment['id'],
      comment: comment['comment'],
      createdDate : comment['createdDate']
    }

    let result = await firstValueFrom(this.aws.updatePostComment(body));
       if(result['status'] === false){
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      }else if(result['status'] === true){
         this.statusEdit = false;
      }else{
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
      }
      this.loadingCommentUpdate = false;
  }

  deleteResponse(comment){
    this.deleteComment.emit(comment);
    if(comment['type'] === "ANSWER"){
      this.listComment = this.listComment.filter(c => c['comment']['id'] != comment['id']);
    }
  }

  cancelEditComment(){
    this.comment['comment']['comment'] = this.temporalCommentAffterCancel;
    this.statusEdit = false;
  }

  openEdit(){
    this.statusEdit = true;

  }

  addEmoji(selected) {
    const emoji: string = (selected.emoji as any).native;
    const input = this.input.nativeElement;
    input.focus();

    if (document.execCommand){ 
      var event = new Event('input');
      document.execCommand('insertText', false, emoji);
      return; 
    }
       // insert emoji on carrot position
    const [start, end] = [input.selectionStart, input.selectionEnd]; 
    input.setRangeText(emoji, start, end, 'end');
  }

  addEmojiSecond(selected){
    const emoji: string = (selected.emoji as any).native;
    const input = this.inputSecond.nativeElement;
    input.focus();

    if (document.execCommand){ 
      var event = new Event('input');
      document.execCommand('insertText', false, emoji);
      return; 
    }
       // insert emoji on carrot position
    const [start, end] = [input.selectionStart, input.selectionEnd]; 
    input.setRangeText(emoji, start, end, 'end');
  }
}
