import { Injectable } from '@angular/core';
import { SessionIn } from '../interfaces/session-in';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public sessionIn: SessionIn = null;
  public token: string = "";
  public revokeToken: string = "";

  constructor(private router: Router) {
    window.addEventListener('storage', this.handleStorageEvent.bind(this));
  }

  setSessionValues(body: SessionIn, token: string, revokeToken: string){
    this.sessionIn = body;
    this.token = token;
    this.revokeToken = revokeToken;
    localStorage.setItem('VE9LRU4=', token);
    localStorage.setItem('UkVWT0tFVE9LRU4=', revokeToken);
    localStorage.setItem('U0VTU0lPTg==', JSON.stringify(body));
  }

  deletedSession(){
    this.sessionIn = null;
    this.token = "";
    this.revokeToken = "";
    localStorage.removeItem("U0VTU0lPTg==");
    localStorage.removeItem("VE9LRU4=");
    localStorage.removeItem("UkVWT0tFVE9LRU4=");
  }

  setSessionValuesStorage(){
    let sessionTemp = localStorage.getItem("U0VTU0lPTg==") ? JSON.parse(localStorage.getItem("U0VTU0lPTg==")) : null;
    let tokenTemp = localStorage.getItem("VE9LRU4=") ? localStorage.getItem("VE9LRU4=") : '';
    let revokeTokenTemp = localStorage.getItem("UkVWT0tFVE9LRU4=") ? localStorage.getItem("UkVWT0tFVE9LRU4=") : '';
    if(sessionTemp && typeof(sessionTemp) === 'object'){ this.sessionIn = sessionTemp }
    if(tokenTemp && tokenTemp.length > 10){ this.token = tokenTemp }
    if(revokeTokenTemp && revokeTokenTemp.length > 10){ this.revokeToken = revokeTokenTemp }
  }

  handleStorageEvent(event: StorageEvent) {
    if (event.key === 'VE9LRU4=' && event.newValue === null) {
      window.location = window.location;
    }
  }

  get statusSession(){
    this.setSessionValuesStorage();
    return this.sessionIn && this.token.length > 0 && this.revokeToken.length > 0 ? true : false;
  }
}
