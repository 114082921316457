import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { AwsSupplierManagementService } from 'src/app/services/main/home/supplier-management/aws-supplier-management.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-log-kraljic',
  templateUrl: './log-kraljic.component.html',
  styleUrls: ['./log-kraljic.component.scss']
})
export class LogKraljicComponent implements OnInit, OnDestroy {
  loadingData = false;
  activities = [];
  @Input() supplier = "";
  @Input() idCompany = "";
  ExclusiveStartKey = null;
  subscriber: Subscription;
  constructor(private globalService: GlobalService,  private aws: AwsSupplierManagementService) { 
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELOGINFOSUPPLIER':
            this.getData();
            break;
            default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }


  async getData(){
    let body = { 
        idSupplier:this.supplier, 
        idCompany:this.idCompany
      }
      this.loadingData = true;
        let result = await firstValueFrom(this.aws.listStatusCompanySupplier(body));
        this.loadingData = false;
        if(result['status'] === false){
          this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
        }else if(result['status'] === true){
            let data = result['data'];
            
              this.activities = data;
        }else{
          this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
        }
      
  }
}
