import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AwsHubHomeService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      Auth: this.sessionService.token,
    }),
  };

  getCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/company`, body,  this.httpOptions);
  }

  getHubId(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getHubId`, body,  this.httpOptions);
  }

  getPosts(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getPosts`, body,  this.httpOptions);
  }

  listStoreCompaniesHub(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listStoreCompaniesHub`, body, this.httpOptions);
  }

  createHubsCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createHubsCompany`, body, this.httpOptions);
  }

  getListPlans(body){
    return this.http.post(`${environment.baseUrlAPI}/listPlans`, body,  this.httpOptions);
  }

  updatePlan(body){
    return this.http.post(`${environment.baseUrlAPI}/user/updatePlan`, body, this.httpOptions);
  }

  createPermissionsPostHubForUserAndCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createPermissionsPostHubForUserAndCompany`, body,  this.httpOptions);
  }

  getCommentForComment(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getCommentForComment`, body,  this.httpOptions);
  }

  createPostsComment(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createPostsComment`, body,  this.httpOptions);
  }

  deleteComment(body){
    let httpOptionsDelete = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/user/deleteComment`, httpOptionsDelete);
  }

  updatePostComment(body){
    return this.http.post(`${environment.baseUrlAPI}/user/updatePostComment`, body,  this.httpOptions);
  }

  createPosts(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createPosts`, body,  this.httpOptions);
  }

  updatePost(body){
    return this.http.post(`${environment.baseUrlAPI}/user/updatePost`, body,  this.httpOptions);
  }

  createReaction(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createReaction`, body,  this.httpOptions);
  }

  deleteReaction(body){
    let httpOptionsDelete = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/user/deleteReaction`, httpOptionsDelete);
  }

  deletePost(body){
    let httpOptionsDelete = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/user/deletePost`, httpOptionsDelete);
  }

  getPost(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getPost`, body,  this.httpOptions);
  }

  getPostComment(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getPostComment`, body,  this.httpOptions);
  }

  getReaction(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getReaction`, body,  this.httpOptions);
  }

  getStatusCompanyHub(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/validateCompanyHub`,body, this.httpOptions);
  }
}
