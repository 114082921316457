<ng-container *ngIf="item">

  <div class="relative card-catalogue shadow-5" style="border-radius: 24px;" *ngIf="optionCatalogue != 'OVERLAPPED'">
      <swiper [navigation]="false" class="mySwiperCatalogue" [autoplay]="{ delay: 2500, disableOnInteraction: false}">
          <ng-container *ngIf="item.files.length > 0">
              <ng-template swiperSlide *ngFor="let itemSecond of item.files">
                  <img *ngIf="itemSecond.type === 'PHOTO'" [src]="itemSecond.url" alt="" srcset="">
                  <img *ngIf="itemSecond.type === 'VIDEO'" [src]="itemSecond.urlImg" alt="" srcset="">
              </ng-template>
          </ng-container>
          <ng-container *ngIf="item.files.length === 0">
              <ng-template swiperSlide>
                  <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
              </ng-template>
          </ng-container>
      </swiper>

  
          <div class="text-catalogue flex align-items-start justify-content-between">
              <h3>{{item.catalogueName}}</h3>
              <div class="speedDial-btn">
                <button pButton pRipple type="button" icon="fas fa-ellipsis-v"  class="p-button-secondary p-button-rounded" (click)="options.toggle($event);"></button>
                  <p-menu #options [model]="menu" [popup]="true" appendTo="body"></p-menu>
              </div>
          </div>
          <div class="title-catalogue">

          </div>
  </div>

  <div class="relative card-catalogue-hover shadow-5" style="border-radius: 24px;" *ngIf="optionCatalogue === 'OVERLAPPED'" >
    <swiper [navigation]="false" class="mySwiperCatalogue" [autoplay]="{ delay: 2500, disableOnInteraction: false}">
        <ng-container *ngIf="item.files.length > 0">
            <ng-template swiperSlide *ngFor="let itemSecond of item.files">
                <img *ngIf="itemSecond.type === 'PHOTO'" [src]="itemSecond.url" alt="" srcset="">
                <img *ngIf="itemSecond.type === 'VIDEO'" [src]="itemSecond.urlImg" alt="" srcset="">
            </ng-template>
        </ng-container>
        <ng-container *ngIf="item.files.length === 0">
            <ng-template swiperSlide>
                <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
            </ng-template>
        </ng-container>
    </swiper>
    <div class="title-catalogue-hover">

    </div>
      <div class="text-catalogue-hover flex align-items-start justify-content-between">
          <h3>{{item.catalogueName}}</h3>
          <div class="speedDial-btn">
            <p-speedDial *ngIf="type !== 'SUMMARY'" [model]="items" radius="20" direction="down" appendTo="body"
            showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-text p-button-white">
            </p-speedDial>
            <p-speedDial *ngIf="type === 'SUMMARY'" [model]="itemsSummary" radius="20" direction="down" appendTo="body"
            showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-text p-button-white">
            </p-speedDial>
          </div>
          
      </div>
</div>

  <app-preview-photo-video [listPreview]="item['files']" (closeComponent)="previewSidebarImage = false" *ngIf="previewSidebarImage"></app-preview-photo-video>
</ng-container>