<p-sidebar [(visible)]="sidebarPermisionToPost" position="right"  (onHide)="cerrar()" [dismissible]="!loadingFormPermission" styleClass="sidebar-permission-to-post">
    <div class="grid" *ngIf="permission.length > 0">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center"style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                <div class="col-12">
                    <h2 class="m-0" translate>HUB_PERMISSION.TITLE</h2>
                </div>
            </div>
        </div>
        <div class="col-12 pb-3" style="height: 122px; overflow-y: auto;">
            <p translate>HUB_PERMISSION.DESCRIPTION</p>
            <p class="mb-0" translate>HUB_PERMISSION.DESCRIPTION_TWO</p>
        </div>
        <div class="col-12 pt-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 297px)'}">
                <div class="grid m-0">
                    <div class="col-12 pt-0">
                        <ng-container *ngFor="let item of listFormularyPermissions; index as i">
                            <div class="grid mb-3">
                                <div class="col-12 font-bold" [style.color]="colorHub" translate>
                                    <span>{{i+1}} .</span> {{'HUB_PERMISSION.QUESTIONS.'+item.question}}
                                </div>
                                <div class="col-12">
                                    <ng-container *ngIf="item.typeQuestion === 'TEXT'">
                                        <input type="text" pInputText class="w-full" [(ngModel)]="item.value" />
                                    </ng-container>
                                    <ng-container *ngIf="item.typeQuestion === 'TEXT_AREA'">
                                        <textarea rows="5" cols="30" pInputTextarea autoResize="autoResize"
                                            class="w-full" [(ngModel)]="item.value"></textarea>
                                    </ng-container>
                                    <ng-container *ngIf="item.typeQuestion === 'RADIO_BUTTON'">

                                        <ng-container *ngFor="let itemRadio of item.optionsQuestion">
                                            <div class="field-radiobutton">
                                                <p-radioButton name="{{itemRadio.label}}" value="{{itemRadio.value}}" [(ngModel)]="item.value"> </p-radioButton>
                                                <label translate>{{'HUB_PERMISSION.VALUES.'+itemRadio.label}}</label>
                                            </div>
                                        </ng-container>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-12 pt-0">
                        <h3 class="mt-0" translate>HUB_PERMISSION.TITLE_RULES</h3>
                    </div>
                    <div class="col-12 py-0">
                        <ng-container *ngFor="let item of listFormularyRules; index as i">
                            <div class="grid">
                                <div class="col-12 font-bold" [style.color]="colorHub" translate>
                                    <span>{{i + 1}} .</span> {{'HUB_PERMISSION.RULES.'+item.question}}
                                </div>
                                <div class="col-12 ml-3" translate>
                                    {{'HUB_PERMISSION.RULES.'+item.summary}}
                                     <p-divider></p-divider>
                                </div>
                            </div>
                           
                        </ng-container>
                    </div>
                    <div class="col-12 pt-0 flex align-items-center justify-content-between">
                        <h3 class="inline-block m-0" translate>HUB_PERMISSION.ACCEPT_RULES</h3> <p-checkbox [(ngModel)]="acceptHubRules" [binary]="true" ></p-checkbox>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 pb-0">
            <button pButton pRipple type="button" label="{{'requestU' | translate}}" class=" p-button-rounded w-button p-button-success mr-2"
                [loading]="loadingFormPermission" (click)="request()"></button>
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" [disabled]="loadingFormPermission"
                class=" p-button-rounded w-button p-button-danger" (click)="cerrar()"></button>
        </div>
    </div>
</p-sidebar>