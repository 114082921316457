@if(users){
    @if (users.length > 0) {
        @if(!group){
            <div class="flex align-items-center w-full justify-content-center">
                @for (item of users; track i; let i = $index) {
                    @if(i + 1 <= maximumNumberUsersDisplay){
                        <img *ngIf="users[i]['symbol']" [style.border-color]="users[i]['symbol']" class="mr-1" style="border-radius: 50%; width: 2rem;height: 2rem; border-style: solid;" [src]="users[i]['avatar'] ? users[i]['avatar'] : 'assets/images/user.svg'" alt="" srcset="" tooltipPosition="left" pTooltip="{{users[i]['name']}} {{users[i]['lastName']}}">

                        <img *ngIf="!users[i]['symbol']" class="mr-1" style="border-radius: 50%; width: 2rem;height: 2rem;" [src]="users[i]['avatar'] ? users[i]['avatar'] : 'assets/images/user.svg'" alt="" srcset="" tooltipPosition="left" pTooltip="{{users[i]['name']}} {{users[i]['lastName']}}">
                    }
                    @if (i === maximumNumberUsersDisplay){
                        <button pButton pRipple type="button" icon="pi pi-plus" style="background: black; width: 2rem; height: 2rem;" class="p-button-rounded p-button-secondary" (click)="op.toggle($event)"></button>
                        
                    }
                }
            </div>
        }@else {
            <div class="flex align-items-center w-full justify-content-center">
                <p-avatarGroup>
                    @for (item of users; track i; let i = $index) {
                        @if(i + 1 <= maximumNumberUsersDisplay){
                            <p-avatar [style]="{'width':'32px' , 'height':'32px' , 'cursor' : 'pointer' , 'display' : 'flex'}" [image]="users[i]['avatar'] ? users[i]['avatar'] : 'assets/images/user.svg'" shape="circle" tooltipPosition="left" pTooltip="{{users[i]['name']}}"></p-avatar>
                        }
                        @if(i === maximumNumberUsersDisplay){
                            <p-avatar label="+" shape="circle" size="large" [style]="{'background-color':'#000000', 'color': '#ffffff', 'width':'32px' , 'height':'32px' , 'cursor' : 'pointer' , 'display' : 'flex'}" (click)="op.toggle($event)"></p-avatar>
                        }
                    }
                </p-avatarGroup>
            </div>
        }
    }@else{
        -
    }
}@else {
    -
}


<p-overlayPanel #op [style]="{width: '250px'}" appendTo="body">
    <ng-template pTemplate>
       <div class="grid">
            <p-scrollPanel [style]="{width: '100%', height: '150px'}">
                @for (item of users; track i; let i = $index) {
                    
                    @if(users[i + maximumNumberUsersDisplay]){
                        <div class="col-12 flex align-items-center">
                            <div>
                                <img class="mr-2" style="border-radius: 50%; width: 2rem;height: 2rem;" [src]="users[i + maximumNumberUsersDisplay]['avatar'] ? users[i + maximumNumberUsersDisplay]['avatar'] : 'assets/images/user.svg'" alt="" srcset="" tooltipPosition="left" pTooltip="{{users[i + maximumNumberUsersDisplay]['name']}} {{users[i + maximumNumberUsersDisplay]['lastName']}}">
                            </div>
                            <div>
                                <span><strong>{{users[i + maximumNumberUsersDisplay]['name']}} {{users[i + maximumNumberUsersDisplay]['lastName']}}</strong></span>
                            </div>
                        </div> 
                    }
                
                }
            </p-scrollPanel>
       </div>
    </ng-template>
</p-overlayPanel>