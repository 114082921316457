<div id="chatPlaceholder">
    <div class="card text-center shadow-5">
        <img style="height: 100px; width: 100px; object-fit: contain;" src="assets/icons/network.png" />
        <h2 translate>WELCOME_CHAT</h2>
        <p translate>TEXT_IMPORTANT_CHAT</p>
        <div class="inline-block font-bold" style="width: 300px; text-align: left;">
            <ol>
                <li translate>RULE_I_CHAT</li>
                <li translate>RULE_II_CHAT</li>
                <li translate>RULE_III_CHAT</li>
            </ol>
        </div>
        <p translate>TEXT_IMPORTANT_CHAT2</p>
        <div class="inline-block font-bold" style="width: 300px; text-align: left;">
            <ol>
                <li translate>RULE_IV_CHAT</li>
                <li translate>RULE_V_CHAT</li>
                <li translate>RULE_VI_CHAT</li>
            </ol>
        </div>
        <!-- <div class="block">
            <div>
                <p><b style="color: var(--pink-500);" translate>important</b> <span translate>TEXT_IMPORTANT_CHAT_SECOND</span></p>
            </div>
        </div> -->
    </div>
</div>