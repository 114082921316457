<ng-container *ngIf="comment">
   <div class="card-container mb-1 relative">
      <div class="image-profil">
           <p-avatar [image]="comment['user']['avatar']" shape="circle"></p-avatar>
      </div>
      <div class="card shadow-1 comment-container p-3" style="    margin: 0px 0px 0px 5px;">
         <div class="name-b w-full">
            <span class="name">
                <strong>{{comment['user']['name']}} {{comment['user']['lastName']}}</strong>
                <ng-container *ngIf="comment['comment']['whoComment'] === 'COMPANY'">
                    <i class="ml-4 fas fa-building" pTooltip="{{comment['company']['businessName']}}"></i>
                </ng-container>
                <i *ngIf="comment.edit && !statusEdit" class="fas fa-ellipsis-h cursor-pointer" (click)="showOptions(op, $event, comment['comment'], 'PRINCIPAL')"></i>
            </span>
            <ng-container *ngIf="!statusEdit">
                <p class="m-0 mt-1" [innerHtml]="comment['comment']['comment']"></p>
            </ng-container>
            <ng-container *ngIf="statusEdit">
                <div class="flex">
                    <div class="text-area-comment">
                        <textarea #input [rows]="1" [cols]="30" [(ngModel)]="comment['comment']['comment']" pInputTextarea autoResize="autoResize" class="text-area"
                            placeholder="{{ 'comment_here' | translate}}"></textarea>
                    </div>
                    <div class="button-comment" style="width: 100px;">
                        <button pButton pRipple type="button" icon="fas fa-grin" class="mr-2 p-button-rounded p-button-text p-button-plain hidden md:inline-flex"  (click)="opEmoji.toggle($event)"></button>
                        <button pButton pRipple type="button" icon="fas fa-sync"
                        [loading]="loadingCommentUpdate" (click)="editComment(comment['comment'])" class="p-button-rounded p-button-secondary" [disabled]="comment['comment']['comment'].trim().length === 0"></button>
                    </div>
                </div>
            </ng-container>
            
        </div>
      </div>
   </div>
   <div class="w-full" style="padding-left: 33px;">
      <div class="btn-answer flex align-items-center justify-content-between px-1 md:px-3">   
        <span (click)="showAnswer()" ><span translate>respond</span></span>
        <span class="ml-4 bussiness" style="font-size: 11px;">
            <ng-container>{{comment['comment']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}</ng-container>
        </span>
      </div>
      <div class="btn-answer flex align-items-center justify-content-between px-1 md:px-3" *ngIf="comment['comment']['amountOfComment']">   
        <span (click)="showAnswer()" ><i style="transform: rotate(90deg);" class="fas fa-level-up-alt"></i> ({{comment['comment']['amountOfComment']}}) <span translate>respuestas</span></span>
      </div>
      <ng-container *ngIf="show">
         <div class="col-12 p-0">
             <div class="grid m-0">
                 <div class="col-12 flex align-items-center mb-2 p-0">
                     <div class="profile-photo-comment">
                         <p-avatar [image]="sessionIn['avatar']" shape="circle"></p-avatar>
                     </div>
                     <div class="card shadow-1 comment-container  p-2 m-0 w-full flex align-items-center justify-content-between" style="    margin: 0px 0px 0px 5px!important;">
                         <div class="text-area-comment">
                             <textarea #inputSecond [rows]="1" [cols]="30" [(ngModel)]="comments" pInputTextarea autoResize="autoResize" class="text-area"
                                 placeholder="{{ 'comment_here' | translate}}"></textarea>
                         </div>
                         <div class="button-comment" style="width: 100px;">
                            <button pButton pRipple type="button" icon="fas fa-grin" class="mr-2 p-button-rounded p-button-text p-button-plain hidden md:inline-flex"  (click)="opEmojiSecond.toggle($event)"></button>
                             <button pButton pRipple type="button" (click)="newComment()" icon="fas fa-paper-plane"
                             [loading]="loading" class="p-button-rounded p-button-secondary" [disabled]="comments.trim().length === 0"></button>
                         </div>
                     </div>
                 </div>
                 <div class="col-12 p-0">
                    <div *ngFor="let itemList of listComment" class="card-container mb-1 relative flex-column">
                        <app-comment-response [comment]="comment" [item]="itemList" (deleteComment)="deleteResponse($event)"></app-comment-response>
                    </div>
                 </div>
             </div>
         </div>
         <div *ngIf="loadingComment" class="col-12 text-center">
             <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
         </div>
     </ng-container>
   </div>
   <div class="w-full md:hidden">
     <p-divider></p-divider>
   </div>
</ng-container>


<p-menu #op [popup]="true" [model]="items" appendTo="body"></p-menu>

<p-menu #opCancel [popup]="true" [model]="itemsCancel" appendTo="body"></p-menu>

<p-overlayPanel #opEmoji [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
      <emoji-mart   [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false" (emojiSelect)="addEmoji($event)"  [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart> 
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opEmojiSecond [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
      <emoji-mart  [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false" (emojiSelect)="addEmojiSecond($event)"  [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart> 
    </ng-template>
</p-overlayPanel>