<p-sidebar [(visible)]="previewSidebarImage" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebarPreview">
    <div class="grid m-0 list-feed-preview relative overflow-hidden" *ngIf="listNewFeed">
        <button pButton pRipple type="button" icon="pi pi-times" class="close-preview p-button-rounded p-button-secondary" (click)="closePreview()"></button>
        <div class="col-12 md:col-7 md:pl-3 p-0 relative" [ngClass]="{'hidden': images.length === 0 }">
            <button pButton pRipple type="button" icon="fas fa-search-plus" class="p-button-rounded p-button-secondary zoom-in" (click)="zoomIn()"></button>
            <button pButton pRipple type="button" icon="fas fa-search-minus" class="p-button-rounded p-button-secondary zoom-out" (click)="zoomOut()"></button>
            <ng-container *ngIf="listNewFeed">
                <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
                    <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length > 0">
                        <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)">
                            <ng-template swiperSlide *ngFor="let item of images">
                                <div class="w-full h-full relative">
                                    <div class="swiper-zoom-container relative">
                                        <img src="{{item.url}}" />
                                    </div>
                                </div>
                            </ng-template>
                        </swiper>
                    </ng-container>   
                </ng-container>
                <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
                    <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length > 0">
                        <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)">
                            <ng-template swiperSlide *ngFor="let item of images">
                                <div class="w-full h-full relative">
                                    <div class="swiper-zoom-container relative">
                                        <img src="{{item.url}}" />
                                    </div>
                                </div>
                            </ng-template>
                        </swiper>
                    </ng-container>   
                </ng-container>
            </ng-container>
            <div class="responsive-post-info block md:hidden" *ngIf="listNewFeed">
                <div class="col-12 pb-0 text-right" style="font-size: 20px; color: white;">
                    <i class="fas fa-chevron-up"  *ngIf="!InforMobile" (click)="InforMobile = true;"></i>
                    <i class="fas fa-chevron-down" *ngIf="InforMobile" (click)="InforMobile = false;"></i>
                </div>
                <ng-container *ngIf="InforMobile">
                    <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
                        <div class="col-12 pb-0">
                            <div class="feed">
                                <img class="img-profile" [src]="listNewFeed['user']['avatar']" alt="">
                                <div class="name-b">
                                    <span class="name">{{listNewFeed['user']['name']}} {{listNewFeed['user']['lastName']}}</span>
                                    
                                    <br>
                                    <span class="bussiness" style="font-size: 12px;">
                                        <ng-container *ngIf="listNewFeed['post']['whoPublished'] === 'COMPANY'">
                                            {{listNewFeed['company']['businessName']}}
                                        </ng-container>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'PUBLIC'" class="fas fa-globe" pTooltip="{{'public'| translate}}"
                                        tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USER_CREATOR'" class="fas fa-lock"
                                            pTooltip="{{'just_me'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY'" class="fas fa-building"
                                            pTooltip="{{'company_users'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS'"
                                            class="fas fa-user-friends" pTooltip="{{'company_clients'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_SUPPLIERS'"
                                            class="fas fa-user-friends" pTooltip="{{'company_suppliers'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS_AND_SUPPLIERS'"
                                            class="fas fa-users" pTooltip="{{'company_clients_suppliers'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_ASSOCIATED_HUB'"
                                            class="fas fa-user-friends" pTooltip="{{'hub_associates'| translate}}" tooltipPosition="top"></i>
                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_MEMBER_HUB'"
                                            class="fas fa-user-friends" pTooltip="{{'hub_members'| translate}}" tooltipPosition="top"></i>
                                    </span>
                                    <br>
                                    <span class="bussiness" style="font-size: 12px;">
                                        <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                                    </span>
                                    <br>
                                </div>
                                <div *ngIf="listNewFeed.edit" class="options-feed" (click)="op.toggle($event)">
                                    <i class="fas fa-ellipsis-h"></i>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
                        <div class="col-12">
                            <div class="feed">
                                <img class="img-profile" src="assets/icons/CBN.png" alt="">
                                <div class="name-b">
                                    <span class="name mr-1">CBN TECHNOLOGIES</span> 
                                    <span class="bussiness" style="font-size: 12px;">
                                        <i class="fas fa-globe" pTooltip="{{'public'| translate}}" tooltipPosition="top"></i>
                                    </span>
                                    <br>
                                    <span class="bussiness" style="font-size: 12px;">
                                        <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                                    </span>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12 pb-0">
                        <p class="m-0" style="color: white;
                        max-height: 100px;
                        overflow-x: auto;" [innerHtml]="contentHtml"></p>
                    </div>
                    <div class="col-12 pb-3 pt-0">
                        <p-divider></p-divider>
                        <div class="flex align-items-center justify-content-between">
                            <div class="flex align-items-center">
                                <ng-container *ngIf="loadingReaction">
                                    <div *ngIf="!reaction" class=" ml-3 flex align-items-center" >
                                        <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                    <div *ngIf="reaction" class="text-primary ml-3 flex align-items-center" >
                                        <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loadingReaction">
                                    <div *ngIf="!reaction" class=" ml-3 flex align-items-center cursor-pointer" (click)="reactionPost()">
                                        <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                    <div *ngIf="reaction" class=" text-primary ml-3 flex align-items-center cursor-pointer" (click)="deletedReaction()">
                                        <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="listNewFeed" class="flex align-items-center">
                                <div class="flex align-items-center mr-4 icon-star cursor-pointer" *ngIf="!listNewFeed['post']['amountOfInterest']">
                                    <i class="fas fa-star text-lg mr-2"></i>
                                    <span >{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                </div>
                                <div *ngIf="listNewFeed['post']['amountOfInterest']" class="flex align-items-center mr-4 icon-star cursor-pointer" (click)="opReaction.toggle($event)">
                                    <i class="fas fa-star text-lg mr-2"></i>
                                    <span >{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                </div>
                                <div class="flex align-items-center mr-3 icon-cooment" (click)="sidebarMobileComent=true">
                                    <i class="fas fa-comment text-lg mr-2"></i>
                                    <span >{{listNewFeed ? listNewFeed['post']['amountOfComment'] : 0}}</span>
                                </div>
                            </div>
                        </div>
                    </div>   
                </ng-container>
            </div>
        </div>
        <div class="hidden md:block col-5 m-auto">
            <div class="grid m-3">
                <div class="col-12 p-3 col-post">
                    <div class="grid m-0">
                        <div class="col-12 p-0">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 130px)'}" styleClass="custom">
                                <div *ngIf="listNewFeed" class="grid m-0">
                                    <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
                                        <div class="col-12">
                                            <div class="feed">
                                                <img class="img-profile" [src]="listNewFeed['user']['avatar']" alt="">
                                                <div class="name-b">
                                                    <span class="name">{{listNewFeed['user']['name']}}
                                                        {{listNewFeed['user']['lastName']}}</span><br>
                                                    <span class="bussiness" style="font-size: 12px;">
                                                        <ng-container
                                                            *ngIf="listNewFeed['post']['whoPublished'] === 'COMPANY'">
                                                            {{listNewFeed['company']['businessName']}} - </ng-container>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'PUBLIC'"
                                                            class="fas fa-globe" pTooltip="{{'public'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USER_CREATOR'"
                                                            class="fas fa-lock" pTooltip="{{'just_me'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY'"
                                                            class="fas fa-building" pTooltip="{{'company_users'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS'"
                                                            class="fas fa-user-friends" pTooltip="{{'company_clients'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_SUPPLIERS'"
                                                            class="fas fa-user-friends" pTooltip="{{'company_suppliers'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS_AND_SUPPLIERS'"
                                                            class="fas fa-users"
                                                            pTooltip="{{'company_clients_suppliers'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_ASSOCIATED_HUB'"
                                                            class="fas fa-user-friends" pTooltip="{{'hub_associates'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_MEMBER_HUB'"
                                                            class="fas fa-user-friends" pTooltip="{{'hub_members'| translate}}"
                                                            tooltipPosition="top"></i>
                                                        <ng-container>&nbsp;&nbsp;{{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}</ng-container>
                                                    </span>
                                                    <br>
                                                </div>
                                                <div *ngIf="listNewFeed.edit" class="options-feed"
                                                    (click)="op.toggle($event)">
                                                    <i class="fas fa-ellipsis-h"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
                                        <div class="col-12">
                                            <div class="feed">
                                                <img class="img-profile" src="assets/icons/CBN.png" alt="">
                                                <div class="name-b">
                                                    <span class="name mr-1">CBN TECHNOLOGIES</span> 
                                                    <span class="bussiness" style="font-size: 12px;">
                                                        <i class="fas fa-globe" pTooltip="{{'public'| translate}}" tooltipPosition="top"></i>
                                                    </span>
                                                    <br>
                                                    <span class="bussiness" style="font-size: 12px;">
                                                        <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                                                    </span>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-12">
                                        <p class="m-0" [innerHtml]="contentHtml"></p>
                                    </div>
                                    <div class="col-12 py-0">
                                        <p-divider></p-divider>
                                    </div>
                                    <div class="col-12">
                                        <div class="grid">
                                            <div class="col-12 flex align-items-center">
                                                <div class="profile-photo-comment">
                                                    <p-avatar [image]="sessionIn['avatar']" shape="circle"></p-avatar>
                                                </div>
                                                <div class="card shadow-1 comment-container  p-2 m-0 w-full flex" style="margin: 0px 0px 0px 5px!important;">
                                                    <div class="text-area-comment">
                                                        <textarea #input [rows]="1" [cols]="30" [(ngModel)]="comments"
                                                            pInputTextarea autoResize="autoResize"
                                                            class="text-area scrollStyle"
                                                            placeholder="{{ 'comment_here' | translate }}"></textarea>
                                                    </div>
                                                    <div class="button-comment" style="width: 100px;">
                                                        <button pButton pRipple type="button" icon="fas fa-grin"
                                                            class="mr-2 p-button-rounded p-button-text p-button-plain"
                                                            (click)="opEmoji.toggle($event)"></button>
                                                        <button pButton pRipple type="button" (click)="newComment()"
                                                            icon="fas fa-paper-plane" [loading]="loading"
                                                            class="p-button-rounded p-button-secondary"
                                                            [disabled]="comments.trim().length === 0"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ng-container *ngFor="let item of listComment">
                                                    <app-comment [comment]="item" [post]="listNewFeed"
                                                        (deleteComment)="deleteComment($event)"
                                                        (new)="newForComment()">
                                                    </app-comment>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="loadingComment" class="col-12 text-center">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </p-scrollPanel>
                        </div>
                        <div class="col-12 p-0 ">
                            <p-divider></p-divider>
                            <div class="flex align-items-center justify-content-between">
                                <div class="flex align-items-center">
                                    <ng-container *ngIf="loadingReaction">
                                        <div *ngIf="!reaction" class=" ml-3 flex align-items-center" >
                                            <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                        </div>
                                        <div *ngIf="reaction" class="text-primary ml-3 flex align-items-center" >
                                            <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!loadingReaction">
                                        <div *ngIf="!reaction" class=" ml-3 flex align-items-center cursor-pointer" (click)="reactionPost()">
                                            <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                        </div>
                                        <div *ngIf="reaction" class=" text-primary ml-3 flex align-items-center cursor-pointer" (click)="deletedReaction()">
                                            <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="listNewFeed" class="flex align-items-center">
                                    <div class="flex align-items-center mr-4 icon-star cursor-pointer" *ngIf="!listNewFeed['post']['amountOfInterest']">
                                        <i class="fas fa-star text-lg mr-2"></i>
                                        <span>{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                    </div>
                                    <div *ngIf="listNewFeed['post']['amountOfInterest']" class="flex align-items-center mr-4 icon-star cursor-pointer" (click)="opReaction.toggle($event)">
                                        <i class="fas fa-star text-lg mr-2"></i>
                                        <span>{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                    </div>
                                    <div class="flex align-items-center mr-3 icon-cooment">
                                        <i class="fas fa-comment text-lg mr-2"></i>
                                        <span>{{listNewFeed ? listNewFeed['post']['amountOfComment'] : 0}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block md:hidden col-12 md:col-7 md:pl-3 p-0 relative">
            <div class="mx-3 mt-8 mb-3 p-3" style="background: var(--surface-a); border-radius: 24px;" *ngIf="listNewFeed">
                <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
                    <div class="col-12 pb-0">
                        <div class="feed">
                            <img class="img-profile" [src]="listNewFeed['user']['avatar']" alt="">
                            <div class="name-b">
                                <span class="name">{{listNewFeed['user']['name']}} {{listNewFeed['user']['lastName']}}</span>
                                
                                <br>
                                <span class="bussiness" style="font-size: 12px;">
                                    <ng-container *ngIf="listNewFeed['post']['whoPublished'] === 'COMPANY'">
                                        {{listNewFeed['company']['businessName']}}
                                    </ng-container>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'PUBLIC'" class="fas fa-globe" pTooltip="{{'public'| translate}}"
                                    tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USER_CREATOR'" class="fas fa-lock"
                                        pTooltip="{{'just_me'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY'" class="fas fa-building"
                                        pTooltip="{{'company_users'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS'"
                                        class="fas fa-user-friends" pTooltip="{{'company_clients'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_SUPPLIERS'"
                                        class="fas fa-user-friends" pTooltip="{{'company_suppliers'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS_AND_SUPPLIERS'"
                                        class="fas fa-users" pTooltip="{{'company_clients_suppliers'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_ASSOCIATED_HUB'"
                                        class="fas fa-user-friends" pTooltip="{{'hub_associates'| translate}}" tooltipPosition="top"></i>
                                    <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_MEMBER_HUB'"
                                        class="fas fa-user-friends" pTooltip="{{'hub_members'| translate}}" tooltipPosition="top"></i>
                                </span>
                                <br>
                                <span class="bussiness" style="font-size: 12px;">
                                    <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                                </span>
                                <br>
                            </div>
                            <div *ngIf="listNewFeed.edit" class="options-feed" (click)="op.toggle($event)">
                                <i class="fas fa-ellipsis-h"></i>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
                    <div class="col-12">
                        <div class="feed">
                            <img class="img-profile" src="assets/icons/CBN.png" alt="">
                            <div class="name-b">
                                <span class="name mr-1">CBN TECHNOLOGIES</span> 
                                <span class="bussiness" style="font-size: 12px;">
                                    <i class="fas fa-globe" pTooltip="{{'public'| translate}}" tooltipPosition="top"></i>
                                </span>
                                <br>
                                <span class="bussiness" style="font-size: 12px;">
                                    <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                                </span>
                                <br>
                            </div>
                        </div>
                    </div>
                </ng-container>
                    <div class="col-12 pb-0">
                        <p class="m-0" style="color: white;
                        max-height: 100px;
                        overflow-x: auto;" [innerHtml]="contentHtml"></p>
                    </div>
                    <div class="col-12 p-0">
                        <p-divider></p-divider>
                      </div>
                    <div class="col-12 px-0 flex">
                        <div class="profile-photo-comment inline-block">
                            <p-avatar [image]="sessionIn['avatar']" shape="circle"></p-avatar>
                        </div>
                        <div class="inline-flex" style="margin: 0px 0px 0px 5px!important;  width: calc(100% - 38px);">
                            <div class="text-area-comment mr-2" style="width: calc(100% - 46px);">
                                <textarea #input [rows]="1" [cols]="30" [(ngModel)]="comments"
                                    pInputTextarea autoResize="autoResize"
                                    class="text-area scrollStyle"
                                    placeholder="{{ 'comment_here' | translate }}"></textarea>
                            </div>
                            <div class="button-comment" style="width: 38px;">
                                <button pButton pRipple type="button" (click)="newComment()"
                                    icon="fas fa-paper-plane" [loading]="loading"
                                    class="p-button-rounded p-button-secondary"
                                    [disabled]="comments.trim().length === 0"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 354px)'}" styleClass="custom">
                            <div class="grid m-0">
                                <div class="col-12 pl-0 pr-3 mt-2">
                                    <ng-container *ngFor="let item of listComment">
                                        <app-comment [comment]="item" [post]="listNewFeed"
                                            (deleteComment)="deleteComment($event)"
                                            (new)="newForComment()">
                                        </app-comment>
                                    </ng-container>
                                </div>
                            </div>
                        </p-scrollPanel>
                    </div>
                    <div class="col-12 py-0" style="color:var(--text-color);">
                        <p-divider></p-divider>
                        <div class="flex align-items-center justify-content-between">
                            <div class="flex align-items-center">
                                <ng-container *ngIf="loadingReaction">
                                    <div *ngIf="!reaction" class=" flex align-items-center" >
                                        <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                    <div *ngIf="reaction" class="text-primary flex align-items-center" >
                                        <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loadingReaction">
                                    <div *ngIf="!reaction" class=" flex align-items-center cursor-pointer" (click)="reactionPost()">
                                        <i class="far fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                    <div *ngIf="reaction" class=" text-primary flex align-items-center cursor-pointer" (click)="deletedReaction()">
                                        <i class="fas fa-star text-lg mr-2"></i> <span translate>INTERESTING</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="listNewFeed" class="flex align-items-center">
                                <div class="flex align-items-center mr-4 icon-star cursor-pointer" *ngIf="!listNewFeed['post']['amountOfInterest']">
                                    <i class="fas fa-star text-lg mr-2"></i>
                                    <span>{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                </div>
                                <div *ngIf="listNewFeed['post']['amountOfInterest']" class="flex align-items-center mr-4 icon-star cursor-pointer" (click)="opReaction.toggle($event)">
                                    <i class="fas fa-star text-lg mr-2"></i>
                                    <span>{{listNewFeed ? listNewFeed['post']['amountOfInterest'] : 0}}</span>
                                </div>
                                <div class="flex align-items-center icon-cooment">
                                    <i class="fas fa-comment text-lg mr-2"></i>
                                    <span>{{listNewFeed ? listNewFeed['post']['amountOfComment'] : 0}}</span>
                                </div>
                            </div>
                        </div>
                    </div>   
            </div>
        </div>
    </div>
</p-sidebar>

<p-sidebar [(visible)]="sidebarMobileComent" position="right" [showCloseIcon]="true" [style]="{width:'95vw'}" *ngIf="sidebarMobileComent">
    <div class="grid">
        <div class="col-12 p-0 mt-4">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 105px)'}" styleClass="custom">
                <div class="grid m-0">
                    <div class="col-12 px-0 flex">
                        <div class="profile-photo-comment inline-block">
                            <p-avatar [image]="sessionIn['avatar']" shape="circle"></p-avatar>
                        </div>
                        <div class="inline-flex" style="margin: 0px 0px 0px 5px!important;  width: calc(100% - 38px);">
                            <div class="text-area-comment mr-2" style="width: calc(100% - 46px);">
                                <textarea #input [rows]="1" [cols]="30" [(ngModel)]="comments"
                                    pInputTextarea autoResize="autoResize"
                                    class="text-area scrollStyle"
                                    placeholder="{{ 'comment_here' | translate }}"></textarea>
                            </div>
                            <div class="button-comment" style="width: 38px;">
                                <button pButton pRipple type="button" (click)="newComment()"
                                    icon="fas fa-paper-plane" [loading]="loading"
                                    class="p-button-rounded p-button-secondary"
                                    [disabled]="comments.trim().length === 0"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0 mt-2">
                        <ng-container *ngFor="let item of listComment">
                            <app-comment [comment]="item" [post]="listNewFeed"
                                (deleteComment)="deleteComment($event)"
                                (new)="newForComment()">
                            </app-comment>
                        </ng-container>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 pb-0 px-0">
            <button (click)="sidebarMobileComent = false" pButton pRipple type="button" label="{{'closeU' | translate}}"
            class="p-button-rounded p-button-danger w-button"></button>
        </div>
    </div>
</p-sidebar>

<p-menu #op [popup]="true" [model]="items" appendTo="body"></p-menu>

<app-create-post *ngIf="displayModalUpdate" (update)="updatePostEventEmit($event)" [itemUpdate]="listNewFeed"
    (close)="displayModalUpdate = false" [nameCompany]="nameCompany" [type]="'EDIT'"></app-create-post>

<p-overlayPanel #opEmoji [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
        <emoji-mart [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false"
            (emojiSelect)="addEmoji($event)" [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opReaction [showCloseIcon]="true" (onHide)="showReaction = false" (onShow)="showReaction = true" appendTo="body" [style]="{width: '320px', height: '350px'}">
    <ng-template pTemplate>
        <app-post-reactions *ngIf="showReaction" [idPost]="id"></app-post-reactions>
    </ng-template>
</p-overlayPanel>

