<div class="grid list-feed" *ngIf="listNewFeed">
    <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
        <div class="col-12">
            <div class="feed">
                <img class="img-profile" src="assets/icons/CBN.png" alt="">
                <div class="name-b">
                    <span class="name mr-1">CBN TECHNOLOGIES</span> 
                    <span class="bussiness" style="font-size: 12px;">
                        <i class="fas fa-globe" pTooltip="{{'public'| translate}}" tooltipPosition="top"></i>
                    </span>
                    <br>
                    <span class="bussiness" style="font-size: 12px;">
                        <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                    </span>
                    <br>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
        <div class="col-12">
            <div class="feed">
                <img class="img-profile" [src]="listNewFeed['user']['avatar']" alt="">
                <div class="name-b">
                    <span class="name">{{listNewFeed['user']['name']}} {{listNewFeed['user']['lastName']}}</span>
                    
                    <br>
                    <span class="bussiness" style="font-size: 12px;">
                        <ng-container *ngIf="listNewFeed['post']['whoPublished'] === 'COMPANY'">
                            {{listNewFeed['company']['businessName']}}
                        </ng-container>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'PUBLIC'" class="fas fa-globe" pTooltip="{{'public'| translate}}"
                        tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USER_CREATOR'" class="fas fa-lock"
                            pTooltip="{{'just_me'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY'" class="fas fa-building"
                            pTooltip="{{'company_users'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS'"
                            class="fas fa-user-friends" pTooltip="{{'company_clients'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_SUPPLIERS'"
                            class="fas fa-user-friends" pTooltip="{{'company_suppliers'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_CLIENTS_AND_SUPPLIERS'"
                            class="fas fa-users" pTooltip="{{'company_clients_suppliers'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_ASSOCIATED_HUB'"
                            class="fas fa-user-friends" pTooltip="{{'hub_associates'| translate}}" tooltipPosition="top"></i>
                        <i *ngIf="listNewFeed['post']['publishedFor'] === 'USERS_COMPANY_AND_MEMBER_HUB'"
                            class="fas fa-user-friends" pTooltip="{{'hub_members'| translate}}" tooltipPosition="top"></i>
                    </span>
                    <br>
                    <span class="bussiness" style="font-size: 12px;">
                        <i class="fas fa-clock"></i> {{listNewFeed['post']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}
                    </span>
                    <br>
                </div>
                <div *ngIf="listNewFeed.edit" class="options-feed" (click)="op.toggle($event)">
                    <i class="fas fa-ellipsis-h"></i>
                </div>
            </div>
        </div>
    </ng-container>
    
    <div class="col-12">
        <p class="m-0 html-content-feed" [innerHtml]="contentHtml"></p>
    </div>
    <ng-container *ngIf="listNewFeed['post']['format'] === 'NEW_COMPANY'">
        <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length > 0">
            <div class="col-12 cursor-pointer">
                <div class="image-grid-1 w-full h-full">
                    <img (click)="openPreview()" src="{{listNewFeed['post']['formatInformation']['images'][0].url}}" alt="" srcset="" style="height: 120px;object-fit: contain;">
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="listNewFeed['post']['format'] === 'GENERAL'">
        <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length > 0">
            <div class="col-12 cursor-pointer">
                <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length === 1">
                    <div class="image-grid-1 w-full h-full">
                        
                        <img (click)="openPreview()" src="{{listNewFeed['post']['formatInformation']['images'][0].url}}"
                            alt="" srcset="">
                    </div>
                </ng-container>
                <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length === 2">
                    <div class="image-grid-2">
                        
                        <img (click)="openPreviewIndex(0)"
                            src="{{listNewFeed['post']['formatInformation']['images'][0].url}}" alt="" srcset="">
                        <img (click)="openPreviewIndex(1)"
                            src="{{listNewFeed['post']['formatInformation']['images'][1].url}}" alt="architecture">
                    </div>
                </ng-container>
                <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length >= 3">
                    <div class="image-grid-3">
                        
                        <img (click)="openPreviewIndex(0)" class="image-grid-col-2 image-grid-row-2"
                            src="{{listNewFeed['post']['formatInformation']['images'][0].url}}" alt="" srcset="">
                        <img (click)="openPreviewIndex(1)"
                            src="{{listNewFeed['post']['formatInformation']['images'][1].url}}" alt="architecture">
                        <span class="relative" (click)="openPreviewIndex(2)">
                            <ng-container *ngIf="listNewFeed['post']['formatInformation']['images'].length > 3">
                                <span class="absolute w-full h-full flex align-items-center justify-content-center"
                                    style="background: rgba(0, 0, 0, 0.5);">
                                    <span class="text-2xl" style="color: white;">+
                                        {{listNewFeed['post']['formatInformation']['images'].length - 3}}</span>
                                </span>
                            </ng-container>
    
                            <img src="{{listNewFeed['post']['formatInformation']['images'][2].url}}" alt="architecture">
                        </span>
    
    
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <div class="col-12 buttons-feedd p-0">
        <div class="grid w-full m-0">
            <div class="col-4 border-button-like">
                <span *ngIf="!listNewFeed['post']['amountOfInterest']" class="p-0 icon-star cursor-pointer">
                    <i class="fas fa-star"></i> <span>{{listNewFeed['post']['amountOfInterest']}}</span>
                </span>
                <span *ngIf="listNewFeed['post']['amountOfInterest']" class="p-0 icon-star cursor-pointer" (click)="opReaction.toggle($event)">
                    <i class="fas fa-star"></i> <span class="select-hover">{{listNewFeed['post']['amountOfInterest']}}</span>
                </span>
            </div>
            <div class="col-4 icon-cooment" (click)="openPreview()">
                <i class="fas fa-comment" ></i> <span>{{listNewFeed['post']['amountOfComment']}}</span>
            </div>
            <div
                class="col-4 border-button-shared opacity-50 icon-shared flex align-items-center justify-content-center">
                <i class="fas fa-share-square"></i>
            </div>
        </div>
    </div>
    <div class="col-12 buttons-feed p-0" style=" border-top: 1px solid #ced0d4;">
        <div class="grid w-full m-0">
            <ng-container *ngIf="loadingReaction">
                <fieldset class="col-4 pb-0 border-button-like hoover-btn m-0" style="border: 0; min-inline-size: auto;" *ngIf="!reaction">
                    <i class="far fa-star"></i> <span translate>INTERESTING</span>
                </fieldset>
                <fieldset class="col-4 pb-0 border-button-like hoover-btn text-primary m-0" style="border: 0; min-inline-size: auto;" *ngIf="reaction" >
                    <i class="far fa-star"></i> <span translate>INTERESTING</span>
                </fieldset>
            </ng-container>
            <ng-container *ngIf="!loadingReaction">
                <fieldset class="col-4 pb-0 border-button-like hoover-btn m-0" style="border: 0; min-inline-size: auto;" 
                    *ngIf="!reaction" (click)="reactionPost()">
                    <i class="far fa-star"></i> <span translate>INTERESTING</span>
                </fieldset>
                <fieldset class="col-4 pb-0 border-button-like hoover-btn text-primary m-0" style="border: 0; min-inline-size: auto;" 
                    *ngIf="reaction" (click)="deletedReaction()">
                    <i class="far fa-star"></i> <span translate>INTERESTING</span>
                </fieldset>
            </ng-container>
            <div class="col-4 pb-0 hoover-btn" (click)="openPreview()">
                <i class="far fa-comment"></i> <span translate>COMMENT</span>
            </div>
            <div class="col-4 pb-0 border-button-shared opacity-50">
                <i class="far fa-share-square"></i> <span translate>SHARE</span>
            </div>
        </div>
    </div>
</div>


<p-menu #op [popup]="true" [model]="items" appendTo="body"></p-menu>

<app-create-post *ngIf="displayModalUpdate" (update)="updatePostEventEmit($event)" [itemUpdate]="listNewFeed"
    (close)="displayModalUpdate = false" [nameCompany]="nameCompany" [type]="'EDIT'"></app-create-post>


<p-overlayPanel #opEmoji [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
        <emoji-mart [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false"
            (emojiSelect)="addEmoji($event)" [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opReaction [showCloseIcon]="true" (onHide)="showReaction = false" (onShow)="showReaction = true" appendTo="body" [style]="{width: '320px', height: '350px'}">
    <ng-template pTemplate>
        <app-post-reactions *ngIf="showReaction" [idPost]="id"></app-post-reactions>
    </ng-template>
</p-overlayPanel>