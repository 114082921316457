import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-post-reactions',
  templateUrl: './post-reactions.component.html',
  styleUrls: ['./post-reactions.component.scss']
})
export class PostReactionsComponent implements OnInit {

  @Input() idPost = "";

  listUserReaction = [];
  loading = false;

  constructor(
    private aws: AwsHubHomeService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getReactions();
  }

  async getReactions(){
    this.loading = true;
    let result = await firstValueFrom(this.aws.getReaction({ idPost: this.idPost }));
    this.loading = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.listUserReaction = result['data'];
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

}
