<div class="notifications ">
	<ng-container *ngFor="let notification of notifications">
		<div [id]="notification.id" class="notification shadow-6 animate__animated animate__bounceInLeft">
			<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
		</div>
	</ng-container>
</div>

<ng-template #notificationTpl let-notification="notification">
	<div class="container-notify cursor-pointer">
		<div class="image">
			<i [class]="notification.icon" [ngClass]="{'color-red': notification.message === 'LOST_CONNECTION' , 'color-green': notification.message === 'CONNECTION_RESTORED'}"></i>
		</div>
		<div class="container-message">
			<div class="title" translate>{{notification.message}}</div>
		</div>
		<i class="fas fa-times cursor-pointer" (click)="close(notification)"></i>
	</div>
</ng-template>


