<div class="card w-full relative card-princ"  *ngIf="result" [ngClass]="{'black': result['plan'] === 'BLACK' , 'premium': result['plan'] === 'PREMIUM'}">
    <div class="md:flex">
        <div class="card-logo md:flex">
            <div class="image-logo">
                <img *ngIf="result.logo.length > 0" src="{{result.logo}}"
                    alt="">
                <p-avatar *ngIf="result.logo.length === 0" label="{{result.business_name.charAt(0)}}" styleClass="mr-2" [style]="{'width': '80px','height': '80px','font-size': '30px','background-color': '#83888f', 'color': '#ffffff'}" shape="circle"></p-avatar>
                <div class="border-left-1 card-info block md:hidden" style="margin-left: 10px;">
                    <div class="flex align-items-center justify-content-center flex-column">
                        <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic">
                            <span>{{informationScore}}</span>
                            <div class="slice">
                                <div class="bar"></div>
                                <div class="fill"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="w-full md:flex">
                <div class="desc">
                    <h3 style="color:var(--bold-text);">{{result.business_name}}</h3>
                    <span class="trade">{{result.trade_name}}</span>
                    <span class="trade" style="color: #6ec5ff;">{{result.country}} - {{result.state}} - {{result.city}}</span>
                    <div class="flex align-items-center justify-content-center md:justify-content-start">
                        <span *ngIf="resultDATE > 0" class="date">{{resultDATE}} <span translate>years</span></span>&nbsp;&nbsp;
                        <span *ngIf="resultDATE === 0" class="date" translate>current_year</span>&nbsp;&nbsp;
                        <span style="font-size: 12px;  color: var(--text-color);">{{result.code_tributary}}</span>
                        <img class="pl-2" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{result.country | lowercase}}.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="border-left-1 card-info hidden md:block">
            <div class="flex align-items-center justify-content-center flex-column">
                <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic">
                    <span>{{informationScore}}</span>
                    <div class="slice">
                        <div class="bar"></div>
                        <div class="fill"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="md:flex">
        <div class="buttons">
            <div class="flex align-items-center justify-content-center verification-container mt-1 md:mt-0">
                <div>
                  <img *ngIf="result.verification_cbn" pTooltip="{{ 'CBN_verification' | translate }}" style="height: 20px;" src="assets/icons/check3.png" tooltipposition="bottom">
                    <img *ngIf="result.homologation" class="pl-2"  pTooltip="{{ 'third_party_verification' | translate }}" style="height: 20px;" src="assets/icons/check1.png" tooltipposition="bottom">
                    <img *ngIf="result.trade_assurance" class="pl-2"  pTooltip="{{ 'safe_trade' | translate }}" style="height: 20px;" src="assets/icons/check2.png" tooltipposition="bottom">  
                </div>
                

                <div class="inline-block md:hidden">

                    <img *ngIf="result.iso9001" class="pl-2"  pTooltip="{{ 'ISO_9001' | translate }}" style="height: 20px;" src="assets/certificacion/1. CALIDAD.svg" tooltipposition="bottom">

                    <img *ngIf="result.gmp" class="pl-2"  pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" style="height: 20px;" src="assets/certificacion/2. GMP.svg" tooltipposition="bottom">
                    
                    <img *ngIf="result.brc" class="pl-2"  pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" style="height: 20px;" src="assets/certificacion/3. BRC.svg" tooltipposition="bottom">
                    
                    <img *ngIf="result.basc" class="pl-2"  pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" style="height: 20px;" src="assets/certificacion/4. BASC.svg" tooltipposition="bottom">

                    <img *ngIf="result.haccp" class="pl-2"  pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" style="height: 20px;" src="assets/certificacion/4. HACCP.svg" tooltipposition="bottom">

                    <img *ngIf="result.osha18001" class="pl-2"  pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" style="height: 20px;" src="assets/certificacion/6. SEGURIDAD.svg" tooltipposition="bottom">

                    <img *ngIf="result.iso14001" class="pl-2"  pTooltip="{{ 'ISO_14001' | translate }}" style="height: 20px;" src="assets/certificacion/7. AMBIENTE.svg" tooltipposition="bottom">

                </div>
            </div>
            <div class="pl-2 certificates-avatar-container hidden md:inline-block">
                <p-avatarGroup styleClass="certificates-avatar">
                    <p-avatar tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" *ngIf="result.iso9001" shape="circle" image="assets/certificacion/1. CALIDAD.svg"></p-avatar>

                    <p-avatar tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" *ngIf="result.gmp" shape="circle" image="assets/certificacion/2. GMP.svg"></p-avatar>
                    
                    <p-avatar tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" *ngIf="result.brc" shape="circle" image="assets/certificacion/3. BRC.svg"></p-avatar>

                    <p-avatar tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" *ngIf="result.basc" shape="circle" image="assets/certificacion/4. BASC.svg"></p-avatar>

                    <p-avatar tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" *ngIf="result.haccp" shape="circle" image="assets/certificacion/4. HACCP.svg"></p-avatar>

                    <p-avatar tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" *ngIf="result.osha18001" shape="circle" image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>

                    <p-avatar tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" *ngIf="result.iso14001" shape="circle" image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                </p-avatarGroup>
            </div>
            <div class="icons-list-result flex align-items-center justify-content-center md:justify-content-end mt-1 md:mt-0">
                <ng-container *ngIf="client_id !== idCompany && typeCompany === 'ASSOCIATED'">
                    <i class="fa-star-half-alt fas pl-3 cursor-pointer" (click)="resetThemes() ;sidebarAdd = true;" pTooltip="{{ 'EVALUATE' | translate }}"></i>
                </ng-container>
                <i (click)="setId()" class="fas fa-address-book pl-3 cursor-pointer" style="float: left;" pTooltip="{{ 'SUMMARY' | translate }}"></i> 
                <!-- <i (click)="displayContac = true" class="fas fa-comments pl-3"></i> -->
                
                <ng-container *ngIf="companiesCompare.length < 2">
                    <i *ngIf="!validateCompare" class="fas fa-compress-alt pl-3 pr-3 cursor-pointer" (click)="changeCompare('ADD')" pTooltip="{{ 'COMPARISON' | translate }}"></i>
                    <i *ngIf="validateCompare" class="fas fa-compress-alt pl-3 pr-3 cursor-pointer text-green-500" (click)="changeCompare('REMOVE')" pTooltip="{{ 'COMPARISON' | translate }}"></i>
                </ng-container>
                
                <ng-container *ngIf="companiesCompare.length === 2">
                    <i class="fas fa-compress-alt pl-3 pr-3 cursor-pointer"  pTooltip="{{ 'COMPARISON' | translate }}"></i>
                </ng-container>
                
                <ng-container *ngIf="result['store_status'] === 'YES'">
                    <!-- <i class="fas fa-id-badge pr-3 cursor-pointer"></i> -->
                    <a [href]="urlCompany" target="_blank" class="text-color"><i class="fas fa-store pr-3" pTooltip="{{'go_to_store' | translate}}"></i></a>
                </ng-container>

                <div class="flex align-items-center md:hidden">
                    <i *ngIf="clientStatus" class="fas fa-globe mr-2" style="color:var(--bold-text);font-size: 20px;" pTooltip="{{'in_my_network' | translate }}" tooltipposition="bottom"></i>
                    <p-tag severity="success" value="75.00" styleClass="cursor-pointer"></p-tag>
                </div>
            </div>
            
        </div>
        <div class="buttons-seconds text-center border-left-1 justify-content-center hidden md:flex">
            <i *ngIf="clientStatus" class="fas fa-globe mr-2" style="font-size: 20px;" pTooltip="{{'in_my_network' | translate }}" tooltipposition="bottom"></i>
            <p-tag severity="success" value="75.00" styleClass="cursor-pointer">
            </p-tag>
        </div>
    </div>
</div>

<app-info-supplier-search *ngIf="showInfo" [idCompany]="idCompany" [typeShow]="typeView" [client_id]="client_id" [plan]="plan_id"></app-info-supplier-search> 

<p-overlayPanel #opFiles>
    <ng-template pTemplate>
        <app-list-files [files]="filesEv" type="EDIT" (update)="files = $event"></app-list-files>
    </ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="sidebarAdd" position="right" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" styleClass="sidebar-evaluate-company" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center"
                style="height: 48.96px; border-bottom: 1px solid var(--surface-d);    ">
                <div class="col-12">
                    <h2 class="m-0 flex align-items-center justify-content-between">
                        <span translate>EVALUATE_COMPANY</span>
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-12">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 190px)'}">
                <div class="formgrid grid m-0">
                        <div class="field col-12">
                            <label class="font-bold" translate>motive</label><span class="required">*</span>
                            <input type="text" class="w-full uppercase" pInputText [(ngModel)]="reason" [ngClass]="{ 'ng-invalid ng-dirty': submitted && reason.trim().length === 0 }" />
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="font-bold" translate>show_author</label>
                            <div class="flex align-items-center">
                                <p-inputSwitch [(ngModel)]="showAutor"></p-inputSwitch> <span *ngIf="!showAutor" class="ml-2 font-bold" translate>NO</span><span *ngIf="showAutor" class="ml-2 font-bold" translate>yes</span>
                            </div>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="font-bold" translate>evaluation_type</label>
                            <div class="flex align-items-center mt-2">
                                <p-radioButton name="typeEv" value="GENERAL" [(ngModel)]="typeEv"></p-radioButton>
                                <label translate class="mx-2">GENERAL</label>
                                <p-radioButton name="typeEv" value="CRITERION" [(ngModel)]="typeEv"></p-radioButton>
                                <label translate class="mx-2">CRITERION</label>
                            </div>
                        </div>
                        <div class="field col-12">
                            <label class="font-bold" translate>references</label>
                            <p-chips [(ngModel)]="references" styleClass="w-full" [addOnBlur]="true"></p-chips>
                        </div>
                        <ng-container *ngIf="typeEv === 'GENERAL'">
                            <div class="field col-12 m-0">
                                <label class="font-bold" translate>score</label><span class="required">*</span>
                            </div>
                            <div class="field col-12 hidden md:flex align-items-center">
                                <div style="width: calc(100% - 463px);">
                                    <p-slider [min]="0" [max]="100" [(ngModel)]="score" styleClass="mx-2"> </p-slider>
                                </div>
                                <div style="width: 63px;">
                                    <span [style.background]="getColorScaleEv(score / 10)" class="information-container mx-2" [ngClass]="{'text-black': getColorScaleEv(score / 10) === 'yellow'}">
                                        {{score / 10 | number:'1.0-1'}}
                                    </span>
                                </div>
                                <div style="width: 400px;">
                                    <div class="flex align-items-center">
                                        <span class="icono-sol" style="float: right;">
                                            <i class="fas fa-circle" style="color: #ff0000;"></i>
                                            <span class=" ml-1" translate>DEFICIENT</span>
                                        </span>
                                        <span class="icono-sol" style="float: right;">
                                            <i class="fas fa-circle" style="color: #f0a82c;"></i>
                                            <span class=" ml-1" translate>REGULAR</span>
                                        </span>
                                        <span class="icono-sol" style="float: right;">
                                            <i class="fas fa-circle" style="color: #ffff00;"></i>
                                            <span class=" ml-1" translate>evaluations_score.GOOD</span>
                                        </span>
                                        <span class="icono-sol" style="float: right;">
                                            <i class="fas fa-circle" style="color: #74e73b;"></i>
                                            <span class=" ml-1" translate>VERY_GOOD</span>
                                        </span>
                                        <span class="icono-sol" style="float: right;">
                                            <i class="fas fa-circle" style="color: #008000;"></i>
                                            <span class=" ml-1" translate>EXCELLENT</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12 md:hidden align-items-center">
                                <div class="flex align-items-center">
                                    <div style="width: calc(100% - 63px);">
                                        <p-slider [min]="0" [max]="100" [(ngModel)]="score" styleClass="mx-2"> </p-slider>
                                    </div>
                                    <div style="width: 63px;">
                                        <span [style.background]="getColorScale(score / 10)" class="information-container mx-2" [ngClass]="{'text-black': getColorScale(score / 10) === 'yellow'}">
                                            {{score / 10 | number:'1.0-1'}}
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="mt-2">
                                    <div class="text-center">
                                        <span class="icono-sol inline-flex" >
                                            <i class="fas fa-circle" style="color: #ff0000;"></i>
                                            <span class=" ml-1" translate>DEFICIENT</span>
                                        </span>
                                        <span class="icono-sol inline-flex" >
                                            <i class="fas fa-circle" style="color: #f0a82c;"></i>
                                            <span class=" ml-1" translate>REGULAR</span>
                                        </span>
                                        <span class="icono-sol inline-flex" >
                                            <i class="fas fa-circle" style="color: #ffff00;"></i>
                                            <span class=" ml-1" translate>evaluations_score.GOOD</span>
                                        </span>
                                        <span class="icono-sol inline-flex">
                                            <i class="fas fa-circle" style="color: #74e73b;"></i>
                                            <span class=" ml-1" translate>VERY_GOOD</span>
                                        </span>
                                        <span class="icono-sol inline-flex">
                                            <i class="fas fa-circle" style="color: #008000;"></i>
                                            <span class=" ml-1" translate>EXCELLENT</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="typeEv === 'CRITERION'">
                            <div class="field col-12 flex align-items-center justify-content-between">
                                <label class="font-bold" translate>evaluate_by_criteria</label>
                                <span  [style.background]="getColorScaleEv(scoreCriterion / 10)" class="information-container mx-2" [ngClass]="{'text-black': getColorScaleEv(scoreCriterion / 10) === 'yellow'}">
                                    {{scoreCriterion / 10 | number:'1.0-1'}}
                                </span>
                            </div>
                            <div class="field col-12">
                                <p-accordion>
                                    <ng-container *ngFor="let criterionItem of themeListFormulary; let i = index">
                                        <p-accordionTab>
                                            <ng-template pTemplate="header">
                                                <div class="grid m-0 w-full">
                                                    <div class="col-12  flex align-items-center justify-content-between">
                                                        <span translate>{{criterionItem.criterion}}</span>
                                                        <span> {{criterionItem.evaluate}}/{{criterionItem.themes.length}}
                                                            <span [style.background]="getColorScaleEv(criterionItem.score / 10)" *ngIf="criterionItem.evaluate > 0" class="information-container mx-2" [ngClass]="{'text-black': getColorScaleEv(criterionItem.score / 10) === 'yellow'}">
                                                                {{criterionItem.score / 10  | number:'1.0-1'}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <div class="grid m-0 w-full hidden md:block">
                                                    <div class="col-12 flex align-items-center mb-2">
                                                        <div style="width: 300px;" class="font-bold" translate>
                                                            theme
                                                        </div>
                                                        <div style="width: 60px;" class="font-bold" translate>
                                                            evaluate
                                                        </div>
                                                        <div style="width: calc(100% - (60px + 70px + 300px));" class="font-bold" translate>
                                                            score
                                                        </div>
                                                        <div style="width: 70px;" class="font-bold" translate>
                                                        </div>
                                                    </div>
                                                    <ng-container *ngFor="let item of criterionItem.themes">
                                                        <div class="col-12 flex align-items-center">
                                                            <div style="width: 300px;" translate>
                                                                {{item.theme}}
                                                            </div>
                                                            <div style="width: 60px;">
                                                                <p-inputSwitch (onChange)="item.score = 0;calculateThemeScore(i)" [(ngModel)]="item.evaluate"></p-inputSwitch>
                                                            </div>
                                                            <div style="width: calc(100% - (60px + 70px + 300px))">
                                                                <p-slider (onSlideEnd)="calculateThemeScore(i)" [disabled]="!item.evaluate" [min]="0" [max]="100" [(ngModel)]="item.score" styleClass="mx-2"></p-slider>
                                                                <!-- <p-slider *ngIf="!item.evaluate" [disabled]="!item.evaluate" [min]="0" [max]="10" styleClass="mx-2"></p-slider> -->
                                                            </div>
                                                            <div style="width: 70px;">
                                                                <span *ngIf="item.evaluate" [style.background]="getColorScaleEv(item.score / 10)" class="information-container mx-2" [ngClass]="{'text-black': getColorScaleEv(item.score / 10) === 'yellow'}">
                                                                    {{item.score / 10 | number:'1.0-1'}}
                                                                </span>
                                                                <span *ngIf="!item.evaluate" class="red-span information-container mx-2"> 0 </span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="grid m-0 w-full block md:hidden">
                                                    <ng-container *ngFor="let item of criterionItem.themes">
                                                        <div class="col-12">
                                                            <div class="font-bold" translate>
                                                                theme
                                                            </div>
                                                            <div translate class="mb-2">
                                                                {{item.theme}}
                                                            </div>
                                                            <div class="font-bold" translate>
                                                                evaluate
                                                            </div>
                                                            <div class="mb-1">
                                                                <p-inputSwitch (onChange)="item.score = 0;calculateThemeScore(i)" [(ngModel)]="item.evaluate"></p-inputSwitch>
                                                            </div>
                                                            <div class="font-bold" translate>
                                                                score
                                                            </div>
                                                            <div class="mb-2 flex align-items-center">
                                                                <div class="w-full">
                                                                 <p-slider (onSlideEnd)="calculateThemeScore(i)" [disabled]="!item.evaluate" [min]="0" [max]="100" [(ngModel)]="item.score" styleClass="mt-2"></p-slider>   
                                                                </div>
                                                                <span *ngIf="item.evaluate" [style.background]="getColorScaleEv(item.score / 10)" class="information-container ml-2" [ngClass]="{'text-black': getColorScaleEv(item.score / 10) === 'yellow'}">
                                                                    {{item.score / 10 | number:'1.0-1'}}
                                                                </span>
                                                                <span *ngIf="!item.evaluate" class="red-span information-container mx-2"> 0 </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0">
                                                            <p-divider></p-divider>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                    </ng-container>
                                </p-accordion>
                            </div>
                        </ng-container>
                        <div class="field col-12">
                            <label class="font-bold" translate>comments</label>
                            <p-editor [style]="{'height':'320px'}" [(ngModel)]="commentary">
                                <ng-template pTemplate="header">
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                                    </span>
                                </ng-template>
                            </p-editor>
                        </div>
                        <div class="field col-12 block md:hidden">
                            <label class="font-bold" translate>attached_files</label>
                            <div>
                                <button pButton pRipple type="button" icon="pi pi-paperclip" label="{{filesEv.length}}" (click)="opFiles.toggle($event)" [disabled]="filesEv.length === 0" class="p-button-rounded p-button-secondary p-button-outlined mr-2"></button>
                                <button pButton pRipple type="button" label="{{ 'attach' | translate }}" (click)="uploadFiles = true" class="w-button p-button-rounded p-button-secondary buttonAtt"></button>
                            </div>
                        </div>
                </div>
            </p-scrollPanel>
        </div>
        
            <div class="col-12 pb-0 flex align-items-center justify-content-between">
                <div>
                    <button pButton pRipple type="button" label="{{'saveU' | translate}}" class="p-button-rounded w-button p-button-success mr-3" (click)="evaluateCompany()" [disabled]="loadCreate" [loading]="loadCreate"></button>
                    <button pButton pRipple type="button" label="{{'closeU' | translate}}" [disabled]="loadCreate" class="p-button-rounded w-button p-button-danger" (click)="sidebarAdd = false"></button>
                </div>
                <div class="hidden md:block">
                    <button pButton pRipple type="button" icon="pi pi-paperclip" label="{{filesEv.length}}" (click)="opFiles.toggle($event)" [disabled]="filesEv.length === 0" class="p-button-rounded p-button-secondary p-button-outlined mr-2"></button>
                    <button pButton pRipple type="button" label="{{ 'attach' | translate }}" (click)="uploadFiles = true" class="w-button p-button-rounded p-button-secondary buttonAtt"></button>
                </div>
            </div>
    </div>
    <app-upload-files *ngIf="uploadFiles" type='COMPANY' [typeValue]="client_id" path="evaluation"
    (nextFiles)="nextFilesEv($event)">
    </app-upload-files>
</p-sidebar>