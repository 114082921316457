<div class="col-12">
    <strong style="text-decoration: underline;" translate>file_list</strong>
</div>
<div class="col-12">
    <p-scrollPanel [style]="{width: '16rem', height: '162px'}">
        <div class="w-100">
            <div *ngFor="let file of files;let i = index;" class="col-12 flex justify-content-between">
                <div class="w-2rem flex align-items-center justify-content-center mr-2">
                    <i class="far {{getIcon(file.name)}} text-2xl" [style.color]="getColor(file.name)"></i>
                </div>
                <div class="flex align-items-center overflow-hidden" style="width: 100%;">
                    <span class="w-full overflow-hidden text-left text-overflow-ellipsis white-space-nowrap block">
                        <a [href]="file.url" target="_blank" class="text-900 no-underline">{{file.name}}</a>
                    </span>
                </div>
                <div *ngIf="type === 'EDIT'" class="flex align-items-center justify-content-center ml-2"
                    style="width: 2.5rem;">
                    <button pButton pRipple type="button" icon="far fa-trash-alt"
                        class="p-button-rounded p-button-danger p-button-text" (click)="deleteFile(file, i)"></button>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>
<div class="col-12 text-center" *ngIf="files.length > 0">
    <button pButton pRipple type="button" label="{{ 'DOWNLOAD_ALL' | translate }}" class="p-button-rounded p-button-success" (click)="downloadAll(files)" [loading]="loadDownloadFiles"></button>
</div>