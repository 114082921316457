import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent implements OnInit, OnDestroy {

  @Input() data:any = null;
  @Input() type = "LOAD";
  @Input() filter = "";
  @Input() idSelected = null;
  @Output() createChatUser = new EventEmitter<Object>();
  @Output() goChatUser = new EventEmitter<Object>();
  idChat = "";
  countMessage = 0;
  tempIdChat = "";

  constructor(
  ) {
  }

  ngOnInit(): void {
    /* console.log(this.data); */
  }

  ngOnDestroy(): void {
  }
  
  createChat(){
    this.createChatUser.emit(this.data);
  }

  async goChat(){
    this.data['messagesUnread'] = 0;
    this.goChatUser.emit(this.data);
  }
}
