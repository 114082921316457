import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AwsEvaluationsHubService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      Auth: this.sessionService.token,
    }),
  };

  dependencyCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/hub/dependencyCompanyHub`, body, this.httpOptions);
  }

  listEvaluationsHubPanelPagination(body){
    return this.http.post(`${environment.baseUrlAPI}/hub/listEvaluationsHubPanelPagination`, body, this.httpOptions);
  }

  listEvaluationsHubPanelGraphic(body){
    return this.http.post(`${environment.baseUrlAPI}/hub/listEvaluationsHubPanelGraphic`, body, this.httpOptions);
  }

  evaluateCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/hub/evaluateCompanyHub`, body , this.httpOptions);
  }
}
