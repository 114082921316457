import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AwsChatCompanyService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      Auth: this.sessionService.token,
    }),
  };

  // obtener todos los usuarios de la empresa
  usersCompanyNotChat(body){
    return this.http.post(`${environment.baseUrlAPI}/user/usersCompanyNotChat`, body,  this.httpOptions);
  }

  // crear chat directo con usuario de la empresa
  createChatUserDirectCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createChatUserDirectCompany`, body,  this.httpOptions);
  }

  // lista chat directo con usuario de la empresa
  listChatUserDirectCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listChatUserDirectCompany`, body,  this.httpOptions);
  }

  // lista chat grupal de usuarios de la empresa
  listChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listChatCompany`, body,  this.httpOptions);
  }

  // lista chat grupal de usuarios de la empresa
  listChatUserGroupCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listChatUserGroupCompany`, body,  this.httpOptions);
  }

  initialDetailChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/initialDetailChatCompany`, body,  this.httpOptions);
  }

  updateMessageUnreadChatParticipant(body){
    return this.http.post(`${environment.baseUrlAPI}/user/updateMessageUnreadChatParticipant`, body,  this.httpOptions);
  }

  sendMessagePrincipalChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/sendMessagePrincipalChatCompany`, body,  this.httpOptions);
  }

  messagesPrincipalChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/messagesPrincipalChatCompany`, body,  this.httpOptions);
  }

  initialDetailThreadChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/initialDetailThreadChatCompany`, body,  this.httpOptions);
  }

  messagesAsnwerChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/messagesAsnwerChatCompany`, body,  this.httpOptions);
  }

  sendMessageAnswerChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/sendMessageAnswerChatCompany`, body,  this.httpOptions);
  }

  informationChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/informationChatCompany`, body,  this.httpOptions);
  }

  participantsChatCompanyByNotifyEmail(body){
    return this.http.post(`${environment.baseUrlAPI}/user/participantsChatCompanyByNotifyEmail`, body,  this.httpOptions);
  }

  notificationChatCompanyByEmail(body){
    return this.http.post(`${environment.baseUrlAPI}/user/notificationChatCompanyByEmail`, body,  this.httpOptions);
  }
}
