<p-sidebar [(visible)]="previewSidebarImage" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebarPreview">
    <div class="grid m-0 list-preview-photo-video relative overflow-hidden">
        <div class="col-12 p-0 relative" style="z-index: 2;">
            <div class="w-full container-option-preview-global">
                <div style="width: calc(100% - 175px);" class="name-avatar" *ngIf="section === 'CHAT' && dataUser">
                    <span class="border-avatar" [style.border-color]="dataUser.colorAvatar">
                        <p-avatar [image]="dataUser.avatar" styleClass="avatarChat" shape="circle"></p-avatar>
                    </span>
                    <div class="container-message ml-2">
                        <span [style.color]="dataUser.colorName" class="name-participant">{{dataUser.name}}</span> <span class="ml-2 name-participant" *ngIf="dataUser.company">{{dataUser.company}}</span><span class="time"><i class="mr-2 far fa-clock"></i>{{ (dataUser.time * 1000) | date:'h:mm a' }} </span> 
                     </div>
                     <div class="container-message-responsive ml-2">
                        <span [style.color]="dataUser.colorName" class="name-participant">{{dataUser.name}}</span> <span class="name-participant" *ngIf="dataUser.company">{{dataUser.company}}</span><span class="time"><i class="mr-2 far fa-clock"></i>{{ (dataUser.time * 1000) | date:'h:mm a' }} </span> 
                     </div>
                </div>
                <div style="width: 175px" class="text-right">
                    <button pButton pRipple type="button" icon="fas fa-download" class="p-button-rounded p-button-secondary mr-2" (click)="download(tempSlider)"></button>
                    <ng-container *ngIf="typeFile === 'PHOTO'">
                        <button pButton pRipple type="button" icon="fas fa-search-plus" class="p-button-rounded p-button-secondary mr-2"  (click)="zoomIn()"></button>
                        <button pButton pRipple type="button" icon="fas fa-search-minus" class="p-button-rounded p-button-secondary mr-2" (click)="zoomOut()"></button>
                    </ng-container>
                    <button pButton pRipple type="button" icon="pi pi-times" class="close-preview p-button-rounded p-button-secondary" (click)="closePreview()"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 relative" [ngClass]="{'hidden': listPreview.length === 0 }">
            <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)" (slideChange)="onSlideChange($event)">
                <ng-template swiperSlide *ngFor="let item of listPreview">
                    <ng-container *ngIf="item.type === 'PHOTO'">
                        <div class="w-full h-full relative">
                            <div class="swiper-zoom-container relative">
                                <img src="{{item.url}}" />
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.type === 'VIDEO'">
                        <div class="w-full h-full relative">
                            <div class="swiper-zoom-container relative">
                                <video id="background-video" loop controls controlsList="nodownload">
                                    <source [src]="item.url">
                                </video>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </swiper>
        </div>
    </div>
</p-sidebar>
