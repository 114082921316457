import { Component, OnInit, OnDestroy } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Notification } from 'src/app/interfaces/notification';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications = true;
  notificationList: Notification[] = [ ];

  statusLoad = "";
  loading = false;
  subscriber: Subscription;
  constructor(
    private globalService: GlobalService,
    private awsShared: AwsSharedService
  ) {
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATENOTIFICATIONS':
            this.getNotifications();
            break;
            default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }
  
  async getNotifications(){
    this.loading = true;
    let result = await firstValueFrom(this.awsShared.getListNotifications());
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.notificationList = result['data'];
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
    this.statusLoad = "FINISH";
    this.loading = false;
  }

  closeModal() {
    this.globalService.sendRequest({ type: 'CLOSESIDEBARNOTIFICATIONS' });
  }

}
