import { Component, EventEmitter, Input, OnInit, Output, ViewChild ,ElementRef } from '@angular/core';
import SwiperCore, {Zoom, Navigation } from "swiper";
SwiperCore.use([Zoom, Navigation]);
@Component({
  selector: 'app-preview-photo-video',
  templateUrl: './preview-photo-video.component.html',
  styleUrls: ['./preview-photo-video.component.scss']
})
export class PreviewPhotoVideoComponent implements OnInit  {
  @Input() listPreview = [];
  @Input() section = "PREVIEW";
  @Input() dataUser = null;
  @Input() index = 0;
  @Output() closeComponent = new EventEmitter<Object>();
  previewSidebarImage = false;
  swiper = null;
  indexSwiper = 0;
  typeFile = null;
  tempSlider = null;
  constructor() { }

  ngOnInit(): void {

  }

  closePreview(){
    this.closeComponent.emit();
  }

  zoomIn(){
    this.swiper.zoom.in()
  }

  zoomOut(){
    this.swiper.zoom.out()
  }
  onSwiper(swiper){
    this.typeFile = this.listPreview[0].type;
    this.tempSlider = this.listPreview[0].url;
    this.swiper = swiper;
    this.swiper.slideTo(this.index);
    this.previewSidebarImage = true;
  }

  download(url){
    window.open(url, "_blank");
  }

  onSlideChange(swiper){
    this.typeFile = this.listPreview[swiper.activeIndex].type;
    this.tempSlider = this.listPreview[swiper.activeIndex].url;
  }
}
