<p-dialog [closeOnEscape]="false" header="Crear publicacion" [(visible)]="displayModalPublish" [modal]="true"
    [style]="{width: '680px'}" [draggable]="false" [resizable]="false" [closable]="false"
    styleClass="dialog-create-post" appendTo="body">
    <ng-template pTemplate="header">
        <div class="grid m-0 w-full">
            <div class="col-12 p-0 flex">
                <div class="flex align-items-center" style="width:calc(100% - 37.7px);">
                    <p-avatar [image]="sessionIn['avatar']" size="large" styleClass="mr-3 my-2" shape="circle">
                    </p-avatar>
                    <div class="flex flex-column">
                        <h3 class="m-0 mb-1">{{sessionIn['name']}} {{sessionIn['lastName']}}</h3>
                        <h4 class="m-0">{{nameCompany}}</h4>
                    </div>
                </div>
                <div class="flex" style="width: 37.7px;">
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-secondary p-button-text" (click)="cerrar()"></button>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="grid m-0">
        <div class="col-12 px-0 pb-3">
            <div class="flex">
                <textarea #input placeholder="{{ 'what_do_you_want_to_tell' | translate}}" [ngClass]="{ 'ng-invalid ng-dirty': submitted && this.textPost.length === 0}"
                    [(ngModel)]="textPost" rows="2" cols="30" pInputTextarea autoResize="autoResize"
                    class="w-full text-area"></textarea>
                <!-- <button pButton pRipple type="button" icon="fas fa-grin" class="ml-2 p-button-rounded p-button-text p-button-plain"  (click)="op.toggle($event)"></button> -->
            </div>



        </div>
        <div class="col-12 thumbs pb-0 mb-4" *ngIf="containsPost.length > 0">
            <swiper [spaceBetween]="10" [navigation]="true" [zoom]="true" [pagination]="{clickable: true}"
                class="mySwiper2">
                <ng-template swiperSlide *ngFor="let item of containsPost; index as i">
                    <div class="relative w-full h-full">
                        <div class="swiper-zoom-container relative">
                            <img src="{{item.url}}" />
                        </div>
                        <button pButton pRipple type="button" style="    right: 15px;
                            top: 15px;" icon="pi pi-times" class="p-button-rounded p-button-secondary absolute"
                            (click)="deletePhotoArr(i)"></button>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="grid m-0">
            <div class="col-12 p-0 md:flex align-items-center justify-content-between">
                <div class="icons-footer text-center md:text-left mb-2 md:mb-0">
                    <i class="far fa-image mr-4 cursor-pointer" (click)="uploadFile = true"
                        pTooltip="{{ 'add_photos' | translate }}"></i>

                    <i class="fab fa-youtube opacity-50 mr-4"></i>

                    <i class="fas fa-file opacity-50 mr-4"></i>

                    <i class="fas fa-briefcase opacity-50 mr-4"></i>

                    <i class="fas fa-chart-bar opacity-50 mr-4"></i>

                    <i class="fas fa-grin cursor-pointer" pTooltip="Emojis" (click)="op.toggle($event)"></i>
                </div>
                <div class="flex align-items-center justify-content-between">
                    <p-dropdown [options]="publishedFor" styleClass="text-left" [style]="{width: '100%'}"
                        [(ngModel)]="publishedForModel"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && publishedForModel.length === 0 }">
                    </p-dropdown>
                    <button *ngIf="type === 'CREATE'" pButton pRipple type="button" label="{{ 'PUBLISH' | translate}}"
                        class="p-button-rounded w-button ml-3" [loading]="loading" (click)="submitPost()"></button>
                    <button *ngIf="type === 'EDIT'" pButton pRipple type="button" label="{{ 'updateU' | translate}}"
                        class="p-button-rounded w-button ml-3" [loading]="loading"
                        (click)="submitPostUpdate()"></button>
                </div>
            </div>
        </div>
    </ng-template>
    <app-upload-files *ngIf="uploadFile" type='COMPANY' [typeValue]="idCompany" path="hub,post" (nextFiles)="nextFiles($event)" extensions="jpg,jpeg,gif,png,webp">
    </app-upload-files>

    <p-overlayPanel #op [showCloseIcon]="true" styleClass="overlay-emoji" appendTo="body">
        <ng-template pTemplate>
            <emoji-mart  [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false"
                (emojiSelect)="addEmoji($event)" [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart>
        </ng-template>
    </p-overlayPanel>
</p-dialog>
