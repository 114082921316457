<div class="grid">
    <div class="col-12">
        <div class="content-get-file pb-0">
            <ng-container *ngIf="noImage">
                <label for="imageReader" class="btn-file-up" translate>attachImageU</label>
                <input type="file" #imageReader name="{{'attachImageU' | translate}}" id="imageReader" (change)="uploadListener($event)" accept=".png, .jpg, .jpeg" style="display: none;" />
                <div *ngIf="!errorImage.status_general" class="errors-image">
                    <p *ngIf="!errorImage.status_size" translate>sizeErr</p>
                    <p *ngIf="!errorImage.status_file" translate>ErrFormat</p>
                </div>
            </ng-container>
            <ng-container *ngIf="!noImage && urlImg.length > 0">
                <img [src]="urlImg" alt="" id="image-crop" style="max-width: 100%;max-height: 100%;">
            </ng-container>
        </div>
    </div>
    <div class="col-12 pt-0 mobile">
        <div class="grid m-0">
            <div class="col-12 px-0">
                <button pButton icon="fas fa-search-plus" pTooltip="{{ 'zoomIn' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="zoom(0.1)"></button>
                <button pButton icon="fas fa-search-minus" pTooltip="{{ 'wardOff' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="zoom(-0.1)"></button>
                <button pButton icon="fas fa-arrow-left" pTooltip="{{ 'left' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="move(-10,0)"></button>
                <button pButton icon="fas fa-arrow-right" pTooltip="{{ 'right' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="move(10,0)"></button>
                <button pButton icon="fas fa-arrow-up" pTooltip="{{ 'top' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="move(0,-10)"></button>
                <button pButton icon="fas fa-arrow-down" pTooltip="{{ 'bottom' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="move(0,10)"></button>
                <button pButton icon="fas fa-redo" pTooltip="{{ 'rotate' | translate }} (45°)" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="rotate(-45)"></button>
                <button pButton icon="fas fa-undo" pTooltip="{{ 'rotate' | translate }} (-45°)" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="rotate(45)"></button>
                <button pButton icon="fas fa-arrows-alt-h" pTooltip="{{ 'horizontal' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="scaleX()"></button>
                <button pButton icon="fas fa-arrows-alt-v" pTooltip="{{ 'vertical' | translate }}" class="p-button-secondary p-button-rounded mr-1 mb-1" [disabled]="noImage" tooltipPosition="top" (click)="scaleY()"></button>
                <button pButton icon="fas fa-sync-alt" class="p-button-outlined p-button-rounded p-button-secondary mb-1" [disabled]="noImage" (click)="clear()"></button>
            </div>
            <div class="col-12 px-0 text-left">
                <button pButton label="{{ 'saveU' | translate }}" class="p-button-success p-button-rounded w-button mr-1" [disabled]="noImage" (click)="saveCrop()"></button>
                <button pButton label="{{ 'cancelU' | translate }}" class="p-button-danger p-button-rounded w-button" (click)="cancel()"></button>
            </div>
        </div>
    </div>
    <div class="col-12 pt-0 desktop">
        <div class="grid m-0">
            <div class="col-6 pl-0">
                <button pButton icon="fas fa-search-plus" pTooltip="{{ 'zoomIn' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="zoom(0.1)"></button>&nbsp;
                <button pButton icon="fas fa-search-minus" pTooltip="{{ 'wardOff' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="zoom(-0.1)"></button>&nbsp;&nbsp;
                <button pButton icon="fas fa-arrow-left" pTooltip="{{ 'left' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="move(-10,0)"></button>&nbsp;
                <button pButton icon="fas fa-arrow-right" pTooltip="{{ 'right' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="move(10,0)"></button>&nbsp;
                <button pButton icon="fas fa-arrow-up" pTooltip="{{ 'top' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="move(0,-10)"></button>&nbsp;
                <button pButton icon="fas fa-arrow-down" pTooltip="{{ 'bottom' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="move(0,10)"></button>&nbsp;&nbsp;
                <button pButton icon="fas fa-redo" pTooltip="{{ 'rotate' | translate }} (45°)" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="rotate(-45)"></button>&nbsp;
                <button pButton icon="fas fa-undo" pTooltip="{{ 'rotate' | translate }} (-45°)" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="rotate(45)"></button>&nbsp;&nbsp;
                <button pButton icon="fas fa-arrows-alt-h" pTooltip="{{ 'horizontal' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="scaleX()"></button>&nbsp;
                <button pButton icon="fas fa-arrows-alt-v" pTooltip="{{ 'vertical' | translate }}" class="p-button-secondary p-button-rounded" [disabled]="noImage" tooltipPosition="top" (click)="scaleY()"></button>
            </div>
            <div class="col-6 pr-0 text-right">
                <button pButton label="{{ 'cleanU' | translate }}" class="p-button-outlined p-button-rounded p-button-secondary w-button" [disabled]="noImage" (click)="clear()"></button>&nbsp;
                <button pButton label="{{ 'cancelU' | translate }}" class="p-button-danger p-button-rounded w-button" (click)="cancel()"></button>&nbsp;
                <button pButton label="{{ 'saveU' | translate }}" class="p-button-success p-button-rounded w-button" [disabled]="noImage" (click)="saveCrop()"></button>
            </div>
        </div>
    </div>
</div>