<ng-container *ngIf="type === 'LOAD'">
    <div class="conversation">
        <div class="picture">
            <p-skeleton styleClass="avatar-chat-square" shape="square" size="3rem"></p-skeleton>
        </div>
        <div class="content">
            <p-skeleton styleClass="mt-1" borderRadius="16px"></p-skeleton>
            <p-skeleton styleClass="mt-2" borderRadius="16px"></p-skeleton>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'CHAT' && data">
    <ng-container *ngIf="!data.idChat">
        <div class="conversation" (click)="createChat()">
            <div class="picture">
                <p-avatar styleClass="avatar-chat" size="large" [image]="data.avatar && data.avatar.length > 0 ? data.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
            </div>
            <div class="content">
                <div class="conversationHeader">
                    <div class="name">{{ data.name }}</div>
                    <!-- <div class="time"></div>  -->
                </div>
                <div class="message">
                    <!-- {{selectedCompanies['name']}} -->
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="data.idChat">
        <div class="conversation" (click)="goChat()" [ngClass]="{'selectedChat': data['idChat'] === idSelected}">
            <div class="picture">
                <ng-container *ngIf="filter === 'GROUP' || filter === 'ALL'">
                    <p-avatar styleClass="avatar-chat-square" size="large" [image]="data.avatar && data.avatar.length > 0 ? data.avatar : 'assets/images/user.svg'" shape="square"></p-avatar>
                </ng-container>
                <ng-container *ngIf="filter === 'DIRECT'">
                    <p-avatar styleClass="avatar-chat" size="large" [image]="data.avatar && data.avatar.length > 0 ? data.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
                </ng-container>
            </div>
            <div class="content">
                <div class="conversationHeader">
                    <div class="name">{{ data.name }}</div>
                    <ng-container *ngIf="data['idChat'] != idSelected">
                        <div class="time" *ngIf="data.messagesUnread > 0"> <p-badge [value]="data.messagesUnread"></p-badge></div>
                    </ng-container>
                </div>
                <div class="message">
                    <ng-container *ngIf="data.typeMessageToRead === 'TEXT'">
                        {{data.newMessageToRead}}
                    </ng-container>
                    <ng-container *ngIf="data.typeMessageToRead === 'IMAGE'">
                        <i class="fas fa-images"></i>
                    </ng-container>
                    <ng-container *ngIf="data.typeMessageToRead === 'VIDEO'">
                        <i class="fas fa-film"></i>
                    </ng-container>
                    <ng-container *ngIf="data.typeMessageToRead === 'FILE'">
                        <i class="fas fa-file"></i>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>