import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, numberAttribute, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChild, ContentChildren, ViewChild, NgModule } from '@angular/core';
import * as i2 from 'primeng/api';
import { Header, Footer, PrimeTemplate, SharedModule } from 'primeng/api';
import * as i3 from 'primeng/scroller';
import { ScrollerModule } from 'primeng/scroller';

/**
 * VirtualScroller is a performant approach to handle huge data efficiently.
 * @group Components
 */
const _c0 = ["scroller"];
function VirtualScroller_div_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function VirtualScroller_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, VirtualScroller_div_1_ng_container_2_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.headerTemplate);
  }
}
function VirtualScroller_ng_template_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c1 = a0 => ({
  height: a0
});
const _c2 = (a0, a1) => ({
  $implicit: a0,
  options: a1
});
function VirtualScroller_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtemplate(1, VirtualScroller_ng_template_6_ng_container_1_Template, 1, 0, "ng-container", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    const scrollerOptions_r7 = ctx.options;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c1, ctx_r3.itemSize + "px"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", item_r6 ? ctx_r3.itemTemplate : ctx_r3.loadingItemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction2(5, _c2, item_r6, scrollerOptions_r7));
  }
}
function VirtualScroller_div_7_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function VirtualScroller_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵtemplate(2, VirtualScroller_div_7_ng_container_2_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-pc-section", "footer");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.footerTemplate);
  }
}
const _c3 = [[["p-header"]], [["p-footer"]]];
const _c4 = ["p-header", "p-footer"];
class VirtualScroller {
  el;
  cd;
  /**
   * An array of objects to display.
   * @group Props
   */
  value;
  /**
   * Height of an item in the list.
   * @group Props
   */
  itemSize;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Max height of the content area in inline mode.
   * @group Props
   */
  scrollHeight;
  /**
   * Defines if data is loaded and interacted with in lazy manner.
   * @group Props
   */
  lazy;
  /**
   * Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   * @group Props
   */
  options;
  /**
   * Threshold in milliseconds to delay lazy loading during scrolling.
   * @group Props
   */
  delay = 250;
  /**
   * Callback to invoke in lazy mode to load new data.
   * @param {VirtualScrollerLazyLoadEvent} event - custom lazy load event.
   * @group Emits
   */
  onLazyLoad = new EventEmitter();
  header;
  footer;
  templates;
  scroller;
  itemTemplate;
  headerTemplate;
  footerTemplate;
  loadingItemTemplate;
  virtualScrollTimeout;
  constructor(el, cd) {
    this.el = el;
    this.cd = cd;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'loadingItem':
          this.loadingItemTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }
  onLazyItemLoad(event) {
    if (this.virtualScrollTimeout) {
      clearTimeout(this.virtualScrollTimeout);
    }
    this.virtualScrollTimeout = setTimeout(() => {
      this.onLazyLoad.emit({
        ...event,
        rows: event.last - event.first,
        forceUpdate: () => this.cd.detectChanges()
      });
    }, this.delay);
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  scrollToIndex(index, mode) {
    this.scroller?.scrollToIndex(index, mode);
  }
  static ɵfac = function VirtualScroller_Factory(t) {
    return new (t || VirtualScroller)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: VirtualScroller,
    selectors: [["p-virtualScroller"]],
    contentQueries: function VirtualScroller_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, Header, 5);
        i0.ɵɵcontentQuery(dirIndex, Footer, 5);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footer = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function VirtualScroller_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scroller = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      value: "value",
      itemSize: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "itemSize", "itemSize", numberAttribute],
      style: "style",
      styleClass: "styleClass",
      scrollHeight: "scrollHeight",
      lazy: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "lazy", "lazy", booleanAttribute],
      options: "options",
      delay: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "delay", "delay", numberAttribute]
    },
    outputs: {
      onLazyLoad: "onLazyLoad"
    },
    features: [i0.ɵɵInputTransformsFeature],
    ngContentSelectors: _c4,
    decls: 8,
    vars: 17,
    consts: [[3, "ngClass", "ngStyle"], ["class", "p-virtualscroller-header", 4, "ngIf"], [1, "p-virtualscroller-content"], ["content", ""], ["styleClass", "p-virtualscroller-list", 3, "items", "itemSize", "lazy", "options", "onLazyLoad"], ["scroller", ""], ["pTemplate", "item"], ["class", "p-virtualscroller-footer", 4, "ngIf"], [1, "p-virtualscroller-header"], [4, "ngTemplateOutlet"], [1, "p-virtualscroller-item", 3, "ngStyle"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "p-virtualscroller-footer"]],
    template: function VirtualScroller_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef(_c3);
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, VirtualScroller_div_1_Template, 3, 1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2, 3)(4, "p-scroller", 4, 5);
        i0.ɵɵlistener("onLazyLoad", function VirtualScroller_Template_p_scroller_onLazyLoad_4_listener($event) {
          return ctx.onLazyItemLoad($event);
        });
        i0.ɵɵtemplate(6, VirtualScroller_ng_template_6_Template, 2, 8, "ng-template", 6);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(7, VirtualScroller_div_7_Template, 3, 2, "div", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", "p-virtualscroller p-component")("ngStyle", ctx.style);
        i0.ɵɵattribute("data-pc-name", "virtualscroller")("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.header || ctx.headerTemplate);
        i0.ɵɵadvance();
        i0.ɵɵattribute("data-pc-section", "content");
        i0.ɵɵadvance(2);
        i0.ɵɵstyleMap(i0.ɵɵpureFunction1(15, _c1, ctx.scrollHeight));
        i0.ɵɵproperty("items", ctx.value)("itemSize", ctx.itemSize)("lazy", ctx.lazy)("options", ctx.options);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.footer || ctx.footerTemplate);
      }
    },
    dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.PrimeTemplate, i3.Scroller],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VirtualScroller, [{
    type: Component,
    args: [{
      selector: 'p-virtualScroller',
      template: `
        <div [ngClass]="'p-virtualscroller p-component'" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'virtualscroller'" [attr.data-pc-section]="'root'">
            <div class="p-virtualscroller-header" *ngIf="header || headerTemplate">
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </div>
            <div #content class="p-virtualscroller-content" [attr.data-pc-section]="'content'">
                <p-scroller #scroller [items]="value" styleClass="p-virtualscroller-list" [style]="{ height: scrollHeight }" [itemSize]="itemSize" [lazy]="lazy" (onLazyLoad)="onLazyItemLoad($event)" [options]="options">
                    <ng-template pTemplate="item" let-item let-scrollerOptions="options">
                        <div [ngStyle]="{ height: itemSize + 'px' }" class="p-virtualscroller-item">
                            <ng-container *ngTemplateOutlet="item ? itemTemplate : loadingItemTemplate; context: { $implicit: item, options: scrollerOptions }"></ng-container>
                        </div>
                    </ng-template>
                </p-scroller>
            </div>
            <div class="p-virtualscroller-footer" *ngIf="footer || footerTemplate" [attr.data-pc-section]="'footer'">
                <ng-content select="p-footer"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    value: [{
      type: Input
    }],
    itemSize: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    scrollHeight: [{
      type: Input
    }],
    lazy: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    options: [{
      type: Input
    }],
    delay: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    onLazyLoad: [{
      type: Output
    }],
    header: [{
      type: ContentChild,
      args: [Header]
    }],
    footer: [{
      type: ContentChild,
      args: [Footer]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    scroller: [{
      type: ViewChild,
      args: ['scroller']
    }]
  });
})();
class VirtualScrollerModule {
  static ɵfac = function VirtualScrollerModule_Factory(t) {
    return new (t || VirtualScrollerModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: VirtualScrollerModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, ScrollerModule, SharedModule, ScrollerModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VirtualScrollerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, ScrollerModule],
      exports: [VirtualScroller, SharedModule, ScrollerModule],
      declarations: [VirtualScroller]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { VirtualScroller, VirtualScrollerModule };
