import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss']
})
export class AvatarGroupComponent implements OnInit {
  @Input() users = [];
  @Input() maximumNumberUsersDisplay = 0;
  @Input() group = false;
  constructor() { }

  ngOnInit(): void {
  }

}
