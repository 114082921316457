<div class="w-full" style="min-height: 77px;">
    <ng-container *ngIf="videos.length > 0 || files.length > 0 || images.length > 0">
        <div class="w-full overflow-hidden files-container-show" *ngIf="showFiles">
            <i class="ml-2 fas fa-chevron-down cursor-pointer animate__animated animate__rotateInDownLeft animate__faster"
                (click)="showFiles = false"></i>
            <div class="preview-files scrollStyle animate__animated animate__fadeInUp animate__faster">
                <ng-container *ngFor="let item of images;  let i = index">
                    <div>
                        <img [src]="item.url" alt="" srcset="">
                        <ul class="options-images">
                            <li><i class="far fa-trash-alt" (click)="deleteImages(i)"></i></li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of videos; let i = index">
                    <div>
                        <i class="far {{getIcon(item.name)}} text-2xl" [style.color]="getColor(item.name)"></i>
                        <span
                            class="inline-block white-space-nowrap overflow-hidden text-overflow-ellipsis">{{item.name}}</span>
                        <ul class="options-images">
                            <li><i class="far fa-trash-alt" (click)="deleteVideos(i)"></i></li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of files; let i = index">
                    <div>
                        <i class="far {{getIcon(item.name)}} text-2xl" [style.color]="getColor(item.name)"></i>
                        <span
                            class="inline-block white-space-nowrap overflow-hidden text-overflow-ellipsis">{{item.name}}</span>
                        <ul class="options-images">
                            <li><i class="far fa-trash-alt" (click)="deleteFiles(i)"></i></li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="videos.length > 0 || files.length > 0 || images.length > 0">
        <div class="w-full overflow-hidden files-container-hidden" *ngIf="!showFiles" (click)="showFiles = true">
            <div class="preview-files-min animate__animated animate__fadeInDown animate__faster">
                <span>{{videos.length + files.length + images.length}} <span translate>files_to_send</span></span>
                <i
                    class="ml-2 fas fa-chevron-right cursor-pointer animate__animated animate__rotateInUpLeft animate__faster"></i>
            </div>
        </div>
    </ng-container>
    <form [formGroup]="formQuery" method="GET" class="w-full flex">
        <div id="smileyIcon">
            <div class="speedDial-button relative">
                <p-speedDial type="button" showIcon="pi pi-paperclip" hideIcon="pi pi-paperclip"
                    [model]="itemsSpeedDial" radius="120" direction="up"
                    buttonClassName="p-button-secondary p-button-text"></p-speedDial>
            </div>
            <button pButton pRipple type="button" icon="far fa-smile"
                class="p-button-rounded p-button-secondary p-button-text" (click)="opEmoji.toggle($event)"></button>
            <!-- <img style="height: 26px; cursor: pointer;     padding: 0px 8px;" [src]="themeSelect === 'light' ? 'assets/icons/gif_.png' : 'assets/icons/gif.png'" alt="" srcset="" (click)="opgif.toggle($event)"> -->
        </div>
        <div id="textarea" class="relative">
            <!-- <div *ngIf="videos.length > 0 || files.length > 0 || images.length > 0" class="w-full absolute btn-files-top">
                <button *ngIf="videos.length > 0" pButton pRipple type="button" icon="far fa-file-video" class="p-button-rounded p-button-secondary" [disabled]="videos.length === 0" (click)="opVideos.toggle($event)"></button>
                <button *ngIf="files.length > 0" pButton pRipple type="button" icon="fas fa-file" class="p-button-rounded p-button-secondary" [disabled]="files.length === 0" (click)="opFiles.toggle($event)"></button>
                <button *ngIf="images.length > 0" pButton pRipple type="button" icon="far fa-file-image" class="p-button-rounded p-button-secondary" [disabled]="images.length === 0" (click)="opImages.toggle($event)"></button>
            </div> -->
            <textarea #input [rows]="1" [cols]="20" pInputTextarea autoResize="autoResize" class="scrollStyleTextArea"
                pInputTextarea formControlName="query" (keyup.enter)="sendTextArea($event, input)"></textarea>
        </div>
        <div id="micIcon">
            <button pButton pRipple type="button" icon="fas fa-paper-plane" (click)="onSubmit()"
                class="p-button-rounded p-button-success" [disabled]="!validateSendQuery"></button>
        </div>
    </form>
</div>

<!-- emoji chat -->
<p-overlayPanel #opEmoji [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
        <emoji-mart [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false"
            (emojiSelect)="addEmoji($event)" [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart>
    </ng-template>
</p-overlayPanel>

<!-- gif -->


        <!-- <p-overlayPanel #opgif [showCloseIcon]="true">
            <ng-template pTemplate>
                <app-search-gif (send)="sendGif($event); opgif.hide($event) "></app-search-gif>
            </ng-template>
        </p-overlayPanel> -->


<p-overlayPanel #opFiles>
    <ng-template pTemplate>
       
            <app-list-files [files]="files" type="EDIT" (update)="files = $event"></app-list-files>
       
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opVideos>
    <ng-template pTemplate>
        
            <app-list-files [files]="videos" type="EDIT" (update)="videos = $event"></app-list-files>
       
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opImages>
    <ng-template pTemplate>
        
            <app-list-files [files]="images" type="EDIT" (update)="images = $event"></app-list-files>
       
    </ng-template>
</p-overlayPanel>

<p-dialog [closeOnEscape]="false" header="{{'attachImageU' | translate}}" [(visible)]="displayImagencrop" [modal]="true"
    [closable]="false" [style]="{'width': '80%'}" styleClass="dialog-crop-img" [autoZIndex]="true">
    <div class="ui-g">
        <div class="ui-g-12 pd-0">
            <app-crop-image-global (close)="displayImagencrop = false" (sendCrop)="saveFile($event)">
            </app-crop-image-global>
        </div>
    </div>
</p-dialog>

<app-upload-files *ngIf="uploadFiles" [type]="typeSession" [typeValue]="idCompany" path="messages"
    (nextFiles)="nextFiles($event)"></app-upload-files>

<app-upload-video *ngIf="uploadVideo" [type]="typeSession" [typeValue]="idCompany" path="messages"
    (nextFiles)="nextVideo($event)" (closeDialogVideo)="uploadVideo=false"></app-upload-video>