import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AnimatedCheckComponent } from 'src/app/shared/animated-check/animated-check.component';
import { SwiperModule } from 'swiper/angular';
import { CropImageGlobalComponent } from 'src/app/shared/crop-image-global/crop-image-global.component';
import { PrimengModule } from '../primeng/primeng.module';
import { UploadFilesComponent } from 'src/app/shared/upload-files/upload-files.component';
import { DndDirective } from 'src/app/shared/upload-files/dnd.directive';
import { ListFilesComponent } from 'src/app/shared/list-files/list-files.component';
import { SidebarCreateBusinessComponent } from 'src/app/components/home/sidebar-create-business/sidebar-create-business.component';
import { ListBusinessComponent } from 'src/app/components/home/sidebar-create-business/list-business/list-business.component';
import { NotificationsComponent } from 'src/app/shared/notifications/notifications.component';
import { ListNotificationComponent } from 'src/app/shared/notifications/list-notification/list-notification.component';
import { ModalCompleteProfileComponent } from 'src/app/components/home/red/modal-complete-profile/modal-complete-profile.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ClipboardModule } from 'ngx-clipboard';
import { ValidationBusinessInformationComponent } from 'src/app/shared/validation-business-information/validation-business-information.component';
import { AnimatedWarningComponent } from 'src/app/shared/animated-warning/animated-warning.component';
import { AnimatedErrorComponent } from 'src/app/shared/animated-error/animated-error.component';
import { InfoSupplierComponent } from 'src/app/shared/info-supplier/info-supplier.component';
import { ListActivitiesProccessComponent } from 'src/app/shared/list-activities-proccess/list-activities-proccess.component';
import { ListPropertiesComponent } from 'src/app/components/home/company/commercial-solidity/list-properties/list-properties.component';
import { ViewProductComponent } from 'src/app/components/home/company/commercial-solidity/view-product/view-product.component';
import { NewFeedComponent } from 'src/app/shared/new-feed/new-feed.component';
import { CreatePostComponent } from 'src/app/shared/create-post/create-post.component';
import { CommentComponent } from 'src/app/shared/comment/comment.component';
import { CommentResponseComponent } from 'src/app/shared/comment-response/comment-response.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PermissionToPostComponent } from 'src/app/shared/permission-to-post/permission-to-post.component';
import { PostDetailComponent } from 'src/app/shared/post-detail/post-detail.component';
import { PostReactionsComponent } from 'src/app/shared/post-reactions/post-reactions.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DndDirectiveVideo } from 'src/app/shared/upload-video/dnd-video.directive';
import { UploadVideoComponent } from 'src/app/shared/upload-video/upload-video.component';
import { PreviewPhotoVideoComponent } from 'src/app/shared/preview-photo-video/preview-photo-video.component';
import { CountdownComponent } from 'src/app/shared/countdown/countdown.component';
import { CompanyShopComponent } from 'src/app/pages/home/hub/hub-home/company-shop/company-shop.component';
import { ElectronicInvoiceExternalComponent } from 'src/app/shared/type-documents-invoice/electronic-invoice-external/electronic-invoice-external.component';
import { InvoiceReceiptComponent } from 'src/app/shared/type-documents-invoice/invoice-receipt/invoice-receipt.component';
import { ReceiptTicketBallotComponent } from 'src/app/shared/type-documents-invoice/receipt-ticket-ballot/receipt-ticket-ballot.component';
import { PhysicalInvoicesComponent } from 'src/app/shared/type-documents-invoice/physical-invoices/physical-invoices.component';
import { InternationalInvoiceComponent } from 'src/app/shared/type-documents-invoice/international-invoice/international-invoice.component';
import { ElectronicInvoiceSunatComponent } from 'src/app/shared/type-documents-invoice/electronic-invoice-sunat/electronic-invoice-sunat.component';
import { MaintenanceSectionComponent } from 'src/app/shared/maintenance-section/maintenance-section.component';
import { AvatarGroupComponent } from 'src/app/shared/avatar-group/avatar-group.component';
import { ChatItemComponent } from 'src/app/shared/chat/chat-item/chat-item.component';
import { ChatNotSelectedComponent } from 'src/app/shared/chat/chat-not-selected/chat-not-selected.component';
import { ChatMessageUserComponent } from 'src/app/shared/chat/chat-message-user/chat-message-user.component';
import { ChatInputFooterComponent } from 'src/app/shared/chat/chat-input-footer/chat-input-footer.component';
// import { SearchGifComponent } from 'src/app/shared/search-gif/search-gif.component';
import { RatingComponent } from 'src/app/shared/rating/rating.component';
import { InfoSupplierSearchComponent } from 'src/app/shared/info-supplier-search/info-supplier-search.component';
import { SidebarFeedbackComponent } from 'src/app/shared/sidebar-feedback/sidebar-feedback.component';
import { LogKraljicComponent } from 'src/app/shared/info-supplier/log-kraljic/log-kraljic.component';
import { ListResultSearchComponent } from 'src/app/components/home/search/list-result-search/list-result-search.component';
import { CompareProviderComponent } from 'src/app/components/home/search/compare-provider/compare-provider.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { PreviewImageOpportunitiesComponent } from 'src/app/shared/preview-image-opportunities/preview-image-opportunities.component';
import { NotificationsToastComponent } from 'src/app/shared/notifications-toast/notifications-toast.component';
import { NotificationsSystemToastComponent } from 'src/app/shared/notifications-system-toast/notifications-system-toast.component';
import { NewListFilesComponent } from 'src/app/shared/new-list-files/new-list-files.component';
import { DownloadAllComponent } from 'src/app/shared/download-all/download-all.component';
import { ListFileTemplateComponent } from 'src/app/shared/list-file-template/list-file-template.component';
import { BarRatingComponent } from 'src/app/shared/bar-rating/bar-rating.component';
import { UploadSingleFilePerTypeComponent } from 'src/app/shared/upload-single-file-per-type/upload-single-file-per-type.component';
import { FullCalendarModule } from '@fullcalendar/angular';
@NgModule({
  declarations: [
    AnimatedCheckComponent,
    CropImageGlobalComponent,
    UploadFilesComponent,
    DndDirective,
    UploadVideoComponent,
    DndDirectiveVideo,
    ListFilesComponent,
    SidebarCreateBusinessComponent,
    ListBusinessComponent,
    NotificationsComponent,
    ListNotificationComponent,
    ModalCompleteProfileComponent,
    ValidationBusinessInformationComponent,
    AnimatedWarningComponent,
    AnimatedErrorComponent,
    InfoSupplierComponent,
    ListActivitiesProccessComponent,
    ListPropertiesComponent,
    ViewProductComponent,
    NewFeedComponent,
    CreatePostComponent,
    CommentComponent,
    CommentResponseComponent,
    PermissionToPostComponent,
    PostDetailComponent,
    PostReactionsComponent,
    PreviewPhotoVideoComponent,
    CountdownComponent,
    CompanyShopComponent,
    ElectronicInvoiceExternalComponent,
    InvoiceReceiptComponent,
    ReceiptTicketBallotComponent,
    PhysicalInvoicesComponent,
    InternationalInvoiceComponent,
    ElectronicInvoiceSunatComponent,
    MaintenanceSectionComponent,
    AvatarGroupComponent,
    PreviewImageOpportunitiesComponent,
    //chat
    ChatItemComponent,
    ChatNotSelectedComponent,
    ChatMessageUserComponent,
    ChatInputFooterComponent,
    // SearchGifComponent,
    RatingComponent,
    InfoSupplierSearchComponent,
    SidebarFeedbackComponent,
    LogKraljicComponent,
    ListResultSearchComponent,
    CompareProviderComponent,
    NotificationsToastComponent,
    NotificationsSystemToastComponent,
    NewListFilesComponent,
    DownloadAllComponent,
    ListFileTemplateComponent,
    BarRatingComponent,
    UploadSingleFilePerTypeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    PrimengModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ClipboardModule,
    PickerModule,
    ColorPickerModule,
    NgApexchartsModule,
    FullCalendarModule
  ],
  exports: [
    TranslateModule,
    AnimatedCheckComponent,
    SwiperModule,
    CropImageGlobalComponent,
    UploadFilesComponent,
    UploadVideoComponent,
    ListFilesComponent,
    SidebarCreateBusinessComponent,
    ListBusinessComponent,
    NotificationsComponent,
    ListNotificationComponent,
    ModalCompleteProfileComponent,
    GoogleMapsModule,
    ClipboardModule,
    ValidationBusinessInformationComponent,
    AnimatedWarningComponent,
    AnimatedErrorComponent,
    InfoSupplierComponent,
    ListActivitiesProccessComponent,
    ListPropertiesComponent,
    ViewProductComponent,
    NewFeedComponent,
    CreatePostComponent,
    CommentComponent,
    CommentResponseComponent,
    PickerModule,
    PermissionToPostComponent,
    PostDetailComponent,
    PostReactionsComponent,
    ColorPickerModule,
    PreviewPhotoVideoComponent,
    CountdownComponent,
    CompanyShopComponent,
    ElectronicInvoiceExternalComponent,
    InvoiceReceiptComponent,
    ReceiptTicketBallotComponent,
    PhysicalInvoicesComponent,
    InternationalInvoiceComponent,
    ElectronicInvoiceSunatComponent,
    MaintenanceSectionComponent,
    AvatarGroupComponent,
    LogKraljicComponent,
    PreviewImageOpportunitiesComponent,
    NotificationsToastComponent,
    NotificationsSystemToastComponent,
    //chat
    ChatItemComponent,
    ChatNotSelectedComponent,
    ChatMessageUserComponent,
    ChatInputFooterComponent,
    // SearchGifComponent,
    RatingComponent,
    InfoSupplierSearchComponent,
    SidebarFeedbackComponent,
    ListResultSearchComponent,
    CompareProviderComponent,
    NgApexchartsModule,
    NewListFilesComponent,
    DownloadAllComponent,
    ListFileTemplateComponent,
    BarRatingComponent,
    UploadSingleFilePerTypeComponent,
    FullCalendarModule
  ],
})
export class GlobalModule {}
