import { Component, EventEmitter, Input, OnInit, Output, ViewChild ,ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SessionIn } from 'src/app/interfaces/session-in';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { GlobalService } from 'src/app/services/global.service';
import { HubService } from 'src/app/services/hub.service';
import { SessionService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit, OnDestroy {

  @Input() typePost = "GENERAL";
  @Output() closePost = new EventEmitter<Object>();
  @Output() deletePost = new EventEmitter<Object>();

  //data
  id = "";
  nameCompany = "";
  listNewFeed = null;
  getData = "";
  indexSwiper = 0;

  sessionIn: SessionIn;
  themeSelect = "";
  //carousel de imagenes
  swiper = null;
  images = [];
  //reaction
  reaction = null;
  loadingReaction = false;

  showReaction = false;
  //comments
  emojii18n = null;
  listComment = [];
  loadingComment = false;
  comments: string = "";
  loading = false;
  ExclusiveStartKeyComment = null;

  //sidebar
  previewSidebarImage = false;
  items = [{
    label: 'Opciones',
    items: [{
        label: 'Actualizar',
        icon: 'pi pi-refresh',
        command: () => {
            this.updatePost();
        }
      },
      {
        label: 'Borrar',
        icon: 'pi pi-times',
        command: () => {
            this.deletedPost();
        }
      }
    ]}
  ];
  displayModalUpdate = false;
  @ViewChild("input") input: ElementRef;

  contentHtml = "";
  InforMobile = false;
  sidebarMobileComent = false;
  subscriber: Subscription;
  constructor(
    private sessionService: SessionService,
    private aws: AwsHubHomeService,
    private hubService: HubService,
    private globalService: GlobalService,
    public trans: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.themeSelect = this.globalService.theme;
    this.sessionIn = this.sessionService.sessionIn;
    this.subscriber = this.globalService.request.subscribe((res) => {
      if(res){
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateServiceEmoji();
            break;
          case 'UPDATETHEME':
            this.themeSelect = this.globalService.theme
            break;
          case 'SHOW_POST':
            this.listNewFeed = res['data'];
            this.indexSwiper = res['index'];
            this.getData = "SERVICE";
            break;
            default:
            break;
        }
      }
    });
    if(!this.globalService.desktop){
      this.InforMobile = true;
    }
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['post'];
    if(this.getData != "SERVICE"){
      this.getDataPost();
    }else{
      this.setValueInfo();
    }
    this.translateServiceEmoji();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  setValueInfo(){
    this.reaction = this.listNewFeed['reaction'];
    if(this.typePost !== 'GENERAL'){
      this.nameCompany = this.listNewFeed['company']['businessName'];
    }
    this.images = this.listNewFeed['post']['formatInformation']['images'];
    let comment = this.replaceURLWithHTMLLinks(this.listNewFeed['post']['formatInformation']['comment']);
    this.contentHtml = comment.split("\n").join("<br>");
    this.showComment();
    this.previewSidebarImage = true;
  }

  async getDataPost(){
    let result = await firstValueFrom(this.aws.getPost({ id: this.id }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      this.closePost.emit(null);
    }else if(result['status'] === true){
      this.listNewFeed = result['data'];
      // this.indexSwiper = 0;
      this.setValueInfo();
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  
  replaceURLWithHTMLLinks(e) {
    return e.replace(
      /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/gi,
      function (e, r, n) {
        var t = '';
        r = r || '';
        for (var a = /\(/g; a.exec(r); ) {
          var l;
          (l = /(.*)(\.\).*)/.exec(n) || /(.*)(\).*)/.exec(n)) &&
            ((n = l[1]), (t = l[2] + t));
        }
        return (
          r +
          "<a href='" +
          n +
          "' target='_blank' rel='nofollow noopener'>" +
          n +
          '</a>' +
          t
        );
      }
    );
  }

  async translateServiceEmoji(){
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;
  }

  closePreview(){
    this.closePost.emit(this.listNewFeed);
  }

  zoomIn(){
    this.swiper.zoom.in()
  }

  zoomOut(){
    this.swiper.zoom.out()
  }

  onSwiper(swiper){
    this.swiper = swiper;
    if(this.images.length > 0){
      this.swiper.slideTo(this.indexSwiper);
    }
    this.previewSidebarImage = true;
  }

  //comentarios
  async showComment(){
    if(this.listNewFeed['post']['amountOfComment'] > 0){
      this.loadingComment = true;
      this.listComment = [];
      let result = await firstValueFrom(this.aws.getPostComment({ idPost: this.listNewFeed['post']['id'], ExclusiveStartKey: this.ExclusiveStartKeyComment }));
      this.loadingComment = false;
      if(result['status'] === false){
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      }else if(result['status'] === true){
        this.ExclusiveStartKeyComment = result['data']['ExclusiveStartKey'] ? result['data']['ExclusiveStartKey'] : null
        result['data']['comments'].forEach(c => {
          this.listComment.push(c)
        });
      }else{
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
      }
    }
  }

  async newComment(){
    this.loading = true;
    let result = await firstValueFrom(this.aws.createPostsComment({ 
      idPost: this.listNewFeed['post']['id'], 
      type: 'PRINCIPAL', 
      whoComment: this.typePost === "GENERAL" ? 'USER' : 'COMPANY', 
      idCompany: this.typePost != "GENERAL" ? this.hubService.idCompany : '',
      idCommentPrincipal: '', comment: this.comments.trim()
    }));
    this.loading = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.comments = "";
      this.listComment.unshift(result['data']);
      this.listNewFeed['post']['amountOfComment'] = this.listNewFeed['post']['amountOfComment'] + 1;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  newForComment(){
    this.listNewFeed['post']['amountOfComment'] = this.listNewFeed['post']['amountOfComment'] + 1
  }

  deleteComment(comment){
    if(comment['type'] === "PRINCIPAL"){
      this.listNewFeed['post']['amountOfComment'] = this.listNewFeed['post']['amountOfComment'] - (comment['amountOfComment'] + 1);
      this.listComment = this.listComment.filter(c => c['comment']['id'] != comment['id']);
    }else{
      this.listNewFeed['post']['amountOfComment'] = this.listNewFeed['post']['amountOfComment'] - 1;
    }
  }

  //reacciones
  async reactionPost(){
    this.loadingReaction = true;
    let result = await firstValueFrom(this.aws.createReaction({ 
      idPost: this.listNewFeed['post']['id'], 
      type: 'INTERESTED', 
      whoReaction: this.typePost === "GENERAL" ? 'USER' : 'COMPANY', 
      idCompany: this.typePost != "GENERAL" ? this.hubService.idCompany : ''
    }));
    this.loadingReaction = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.listNewFeed['post']['amountOfInterest'] = this.listNewFeed['post']['amountOfInterest'] + 1;
      this.listNewFeed['reaction'] = result['data'];
      this.reaction = result['data'];
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  async deletedReaction(){
    this.loadingReaction = true;
    let result = await firstValueFrom(this.aws.deleteReaction({ idPost: this.listNewFeed['post']['id'] }));
    this.loadingReaction = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.listNewFeed['post']['amountOfInterest'] = this.listNewFeed['post']['amountOfInterest'] - 1;
      this.listNewFeed['reaction'] = null;
      this.reaction = null;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  addEmoji(selected) {
    const emoji: string = (selected.emoji as any).native;
    const input = this.input.nativeElement;
    input.focus();

    if (document.execCommand){ 
      var event = new Event('input');
      document.execCommand('insertText', false, emoji);
      return; 
    }
       // insert emoji on carrot position
    const [start, end] = [input.selectionStart, input.selectionEnd]; 
    input.setRangeText(emoji, start, end, 'end');
  }

  //actualizar
  updatePost(){
    this.displayModalUpdate = true;
  }

  updatePostEventEmit(e){
    this.listNewFeed = e;
    this.reaction = this.listNewFeed['reaction'];
    this.nameCompany = this.listNewFeed['company']['businessName'];
    this.images = this.listNewFeed['post']['formatInformation']['images'];
  }

  //eliminar
  async deletedPost(){
    let result = await firstValueFrom(this.aws.deletePost({ id: this.listNewFeed['post']['id'], createdDate: this.listNewFeed['post']['createdDate'] }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.deletePost.emit(this.listNewFeed);
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

}
