<p-sidebar [(visible)]="displayInfoSupplier" position="right" [baseZIndex]="10000" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" [blockScroll]="true" styleClass="sidebar-100vw">
    <div class="grid hidden md:flex">
        <ng-container *ngIf="loadComplete">
            <div class="col-12 text-center w-full h-full flex align-items-center justify-content-center"
                style="height: calc(100vh - 48px)!important;">
                <p-progressSpinner [style]="{width: '150px', height: '250px'}"></p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadComplete">
            <div class="col-4 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12">
                                <h2 class="m-0" translate>SUMMARY</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                            <ng-container *ngIf="profile">
                                <div class="grid m-0">
                                    <div class="col-12 text-center">
                                        <div class="container mx-2 shadow-5 mb-3" style="border-radius: 24px;">
                                            <div class="card-profile" style="padding-top: 20px;">
                                                <img *ngIf="profile.logo.length > 0" class="w-full px-3"
                                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                                    [src]="profile.logo" alt="">
                                                <img *ngIf="profile.logo.length === 0" class="w-full px-3"
                                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                                    src="assets/images/no_logo.jpeg" alt="">
                                                <div class="card-profile_user-stats relative">
                                                    <div class="grid m-0">
                                                        <div class="col-12" style="margin-top:10px">
                                                            <div class="stats-holder">
                                                                <div class="user-stats">
                                                                    <div class="user-infos">
                                                                        <span
                                                                            class="infos_name">{{profile.businessName}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="stats-holder flex align-items-center justify-content-center">
                                                                    <img class="mr-3"
                                                                        src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{profile.country | lowercase}}.png"
                                                                        alt="" srcset="">
                                                                    <p-tag [value]="profile.tradeName"
                                                                        class="tag-info"></p-tag>
                                                                    <span class="inline-block ml-3"
                                                                        style="height: 24px;"
                                                                        *ngIf="profile.homologation === 'YES'">
                                                                        <app-animated-check>
                                                                        </app-animated-check>
                                                                    </span>
                                                                </div>
                                                                <div class="grid mt-2">
                                                                    <div class="col-12">
                                                                        <div
                                                                            class="user-stats flex align-items-center justify-content-between">
                                                                            <span>
                                                                                <p-tag styleClass="ml-6 mr-2"
                                                                                    severity="info"
                                                                                    [value]="years"></p-tag>
                                                                            </span>
                                                                            <span>{{profile.codeTributary}}</span>
                                                                            <span>
                                                                                <button [disabled]="profile.files.length === 0" pButton pRipple type="button"
                                                                                    icon="pi pi-paperclip"
                                                                                    label="{{profile.files.length}}"
                                                                                    class="p-button-rounded p-button-secondary mr-6 p-button-outlined"
                                                                                    (click)="files = profile.files; opFiles.toggle($event)"></button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <span>{{profile.direction}} <br>
                                                                                {{profile.countryName}} -
                                                                                {{profile.state}} - {{profile.city}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <strong
                                                                                translate>contact_</strong>
                                                                            <span>
                                                                                <p class="m-0">
                                                                                    <span class="mr-2 inline-block ">
                                                                                        <i
                                                                                            class="fas fa-mobile-alt mr-2"></i>
                                                                                        <span
                                                                                            class="">{{profile.prefixPhone}}
                                                                                            {{profile.phone}}</span>
                                                                                    </span>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <strong
                                                                                translate>comercial_activity</strong>
                                                                            <span class="text-center">
                                                                                <p-tag
                                                                                    *ngFor="let item of profile.comercialActivityList"
                                                                                    styleClass="m-2" severity="success"
                                                                                    [value]="item"></p-tag>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12"
                                                                        *ngIf="profile.country === 'PE'">
                                                                        <div class="user-stats">
                                                                            <strong translate>taxConditions</strong>
                                                                            <span class="text-center">
                                                                                <p-tag
                                                                                    *ngFor="let item of taxConditions"
                                                                                    styleClass="m-2" severity="info"
                                                                                    [value]="item.language_name"></p-tag>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong translate>type_company</strong>
                                                                            <span>{{typeCompany}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong
                                                                                translate>commercial_section</strong>
                                                                            <span>{{profile.commercialSectionList}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <div class="social">
                                                                                <a *ngIf="profile.web.length > 0"
                                                                                    target="_blank" [href]="profile.web"
                                                                                    class="mr-4 ">
                                                                                    <span
                                                                                        class="fa fa-globe text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="profile.linkedin.length > 0"
                                                                                    target="_blank"
                                                                                    [href]="profile.linkedin"
                                                                                    class="mr-4">
                                                                                    <span
                                                                                        class="fab fa-linkedin text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="profile.facebook.length > 0"
                                                                                    target="_blank"
                                                                                    [href]="profile.facebook" class="">
                                                                                    <span
                                                                                        class="fab fa-facebook text-lg"></span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12"
                                                                        *ngIf="profile.brochure.length > 0 || profile.presentationLetter.length > 0">
                                                                        <div class="grid"
                                                                            style="border-top: 1px solid var(--surface-d); font-size: 11px;">
                                                                            <div [ngClass]="{'border-set': profile.brochure.length > 0 && profile.presentationLetter.length > 0}"
                                                                                *ngIf="profile.brochure.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="     margin-top: 13px;"
                                                                                (click)="files = profile.brochure;opFiles.toggle($event)">
                                                                                <span class="uppercase"
                                                                                    translate>brochure</span>
                                                                                <span
                                                                                    class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                            <div *ngIf="profile.presentationLetter.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="    margin-top: 13px;"
                                                                                (click)="files = profile.presentationLetter;opFiles.toggle($event)">
                                                                                <span class="uppercase"
                                                                                    translate>presentation</span><span
                                                                                    class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12 flex align-items-center justify-content-between" *ngIf="tabSidebar_info === 'resumen'">
                                <ng-container *ngIf="!tabDetail">
                                    <h2 class="m-0" style="width: 70%;display: inline;" translate>SUMMARY</h2>
                                    <span class=" cursor-pointer" (click)="showDetail()"><i
                                            class="mr-3 fas fa-arrow-right"></i> <span translate>DETAIL</span></span>
                                </ng-container>
                                <ng-container *ngIf="tabDetail">
                                    <h2 class="m-0" style="width: 80%;display: inline;" translate>DETAIL</h2>
                                    <span class="cursor-pointer" (click)="tabDetail = false;"><i
                                            class="mr-3 fas fa-arrow-right"></i> <span translate>SUMMARY</span> </span>
                                </ng-container>
                            </div>
                            <div class="col-12 flex align-items-center justify-content-between"
                                *ngIf="tabSidebar_info === 'contacto'">
                                <h2 class="m-0" style="width: 70%;display: inline;" translate>CONTACT</h2>
                            </div>
                            <div class="col-12 flex align-items-center justify-content-between"
                                *ngIf="tabSidebar_info === 'estado'">
                                <h2 class="m-0" style="width: 70%;display: inline;" translate>STATUS</h2>
                            </div>
                            <div class="col-12 flex align-items-center justify-content-between"
                                *ngIf="tabSidebar_info === 'policies'">
                                <h2 class="m-0 uppercase" style="width: 70%;display: inline;" translate>Policies</h2>
                            </div>
                            <div class="col-12 flex align-items-center justify-content-between"
                                *ngIf="tabSidebar_info === 'request'">
                                <h2 class="m-0 uppercase" style="width: 70%;display: inline;" translate>FORMATOS DISPONIBLES</h2>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <ng-container *ngIf="tabSidebar_info === 'resumen'">
                            <ng-container *ngIf="!tabDetail">
                                <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                                    <div class="grid m-0">
                                        <ng-container *ngIf="profile">
                                            <div class="col-12">
                                                <h3 class="m-0" translate>company_summary</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.summary.length === 0" translate>
                                                    no_data_available</p>
                                                <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}
                                                </p>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="accountingInformation">
                                            <div class="col-12"
                                                *ngIf="accountingInformation.accountingInformation.length > 0">
                                                <h3 class="m-0" translate>financial_information</h3>
                                            </div>
                                            <div *ngIf="accountingInformation.accountingInformation.length > 0" class="col-12">
                                                <div class="grid m-0">
                                                    <ng-container *ngFor="let item of accountingInformation.accountingInformation">
                                                        <div class="col-12 mb-3 border-none">
                                                            <div class="card shadow-5 m-0 flex align-items-center">
                                                                <div class="grid m-0 w-full">
                                                                    <div class="col-12">
                                                                        <div class="grid">
                                                                            <div class="col-12 p-0">
                                                                                <div class="grid m-0">
                                                                                    <div
                                                                                        class="col-4 flex align-items-center ">
                                                                                        <p class="m-0"><i
                                                                                                pTooltip="{{ 'accounting_information_period' | translate }}"
                                                                                                style="font-size: 18px;"
                                                                                                class="cursor-pointer fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 flex align-items-center">
                                                                                        <p class="m-0"><i
                                                                                                pTooltip="{{ 'inventories_stocks' | translate }}"
                                                                                                style="font-size: 18px;"
                                                                                                class="cursor-pointer fas fa-boxes mr-3"></i>USD
                                                                                            {{item.accountingStockInventoryUSD
                                                                                            | number:'1.2-2'}}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="col-4 text-center">

                                                                                        <button
                                                                                            (click)="files=item.accountingBalanceSheet; opFiles.toggle($event)"
                                                                                            [disabled]="item.accountingBalanceSheet.length === 0"
                                                                                            pButton pRipple
                                                                                            type="button"
                                                                                            label="{{item.accountingBalanceSheet.length}} - {{ 'BALANCE'| translate}}"
                                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                                            icon="fas fa-file-invoice-dollar"></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 p-0">
                                                                                <div class="grid m-0">
                                                                                    <div
                                                                                        class="col-4 pt-0 pb-0 flex align-items-center ">
                                                                                        <p class="m-0"><i
                                                                                                pTooltip="{{ 'share_capital' | translate }}"
                                                                                                style="font-size: 18px;"
                                                                                                class="cursor-pointer fas fa-university mr-3"></i>USD
                                                                                            {{item.accountingCapitalStockUSD
                                                                                            | number:'1.2-2'}} </p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 pt-0 pb-0 flex align-items-center">
                                                                                        <p class="m-0"><i
                                                                                                style="font-size: 18px;"
                                                                                                pTooltip="{{ 'machinery_equipment' | translate }}"
                                                                                                class="cursor-pointer fas fa-cog mr-3"></i>USD
                                                                                            {{item.accountingAssetsUSD |
                                                                                            number:'1.2-2'}} </p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 pt-0 pb-0 text-center">
                                                                                        <button
                                                                                            (click)="item.accountingProfitLossStatement; opFiles.toggle($event)"
                                                                                            [disabled]="item.accountingProfitLossStatement.length === 0"
                                                                                            pButton pRipple
                                                                                            type="button"
                                                                                            label="{{item.accountingProfitLossStatement.length}} - {{ 'STATE'| translate}}"
                                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                                            icon="fas fa-file-invoice-dollar"></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card shadow-5 m-0">
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <h3 class="m-0" translate>annual_sales</h3>
                                                        </div>
                                                        <div
                                                            class="col-7 total-top flex align-items-center justify-content-center">
                                                            <div class="detail-total">
                                                                <div class="row-range">
                                                                    <span class="title-row" translate>sales_range</span>
                                                                    <span *ngIf="formAmount > 0"
                                                                        class="range-title">{{rangeSales}}</span>
                                                                    <span *ngIf="formAmount <= 0" class="range-title"
                                                                        translate>NO_SALES_RECORD</span>
                                                                </div>
                                                                <div class="row-range">
                                                                    <span class="title-row"
                                                                        translate>average_income</span>
                                                                    <span *ngIf="formAmount > 0"
                                                                        class="promedio-title">USD {{formAmount |
                                                                        number:'1.2-2'}}</span>
                                                                    <span *ngIf="formAmount <= 0"
                                                                        class="promedio-title">USD -</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div class="grid">
                                                                <div class="col-12 p-0">
                                                                    <div class="grid m-0">
                                                                        <div class="col-5"><span
                                                                                class="title-row2">{{yearNow}}</span>
                                                                        </div>
                                                                        <div class="col-7"><span class="title-row2"
                                                                                translate>sales</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid">
                                                                        <div class="col-5">
                                                                            <div class="center-text-flex">
                                                                                {{annualSaleYear1}}</div>
                                                                        </div>
                                                                        <div class="col-7">
                                                                            <div class="center-text-flex">USD
                                                                                {{annualSaleAmount1USD |
                                                                                number:'1.2-2'}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid">
                                                                        <div class="col-5">
                                                                            <div class="center-text-flex">
                                                                                {{annualSaleYear2}}</div>
                                                                        </div>
                                                                        <div class="col-7">
                                                                            <div class="center-text-flex">USD
                                                                                {{annualSaleAmount2USD |
                                                                                number:'1.2-2'}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid">
                                                                        <div class="col-5">
                                                                            <div class="center-text-flex">
                                                                                {{annualSaleYear3}}</div>
                                                                        </div>
                                                                        <div class="col-7">
                                                                            <div class="center-text-flex">USD
                                                                                {{annualSaleAmount3USD |
                                                                                number:'1.2-2'}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>infrastructure</h3>
                                        </div>
                                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                            <ng-container>
                                                <div class="list-button-float" *ngIf="!listMap">
                                                    <div class="circle-off"></div>
                                                    <button style="height:40px; width:40px;  background: black;" pButton
                                                        pRipple type="button" icon="fas fa-list-ul"
                                                        class="p-button-rounded p-button-secondary "
                                                        (click)="listMap = true"></button>
                                                </div>
                                                <div class="container-list-map animate__animated animate__slideInRight"
                                                    *ngIf="listMap">
                                                    <ng-container *ngIf="infraestructures.length === 0">
                                                        <div class="grid m-0 w-full h-full justify-content-end">
                                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                                style="width: 50%;">
                                                                <span
                                                                    style="color: white; font-size: 18px; text-align: center;" translate>not_registered_infrastructures</span>
                                                                <button pButton pRipple type="button"
                                                                    label="{{'closeU' | translate}}"
                                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                                    (click)="closeListMap()"></button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="infraestructures.length > 0">
                                                        <div class="grid m-0">
                                                            <div class="col-12 text-right tex">
                                                                <div class="circle"></div>
                                                                <button
                                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                    pButton pRipple type="button" icon="fas fa-times"
                                                                    class="p-button-rounded p-button-danger"
                                                                    (click)="closeListMap()">
                                                                </button>
                                                            </div>
                                                            <div class="col-12">
                                                                <p-scrollPanel
                                                                    [style]="{width: '100%', height: '339px'}">
                                                                    <ng-container *ngFor="let item of infraestructures">
                                                                        <app-list-properties [type]="'SUMMARY'"
                                                                            [item]="item"
                                                                            (location)="openLocation(item)">
                                                                        </app-list-properties>
                                                                    </ng-container>
                                                                </p-scrollPanel>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                                <map-marker #somemarker="mapMarker"
                                                    *ngFor="let item of infraestructures" [position]="item.position"
                                                    (mapClick)="openInfo(somemarker, item)">
                                                </map-marker>
                                                <map-info-window>
                                                    <ng-container *ngIf="temporalInfraestructure">
                                                        <div style="width: 150px;     flex-direction: column;"
                                                            class="flex align-items-center flex-wrap text-center ">
                                                            <p class="m-0 text-blue-500 text-center">
                                                                <strong
                                                                    class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                            </p>
                                                            <p class="m-0  text-center">
                                                                {{temporalInfraestructure.nameType}} -
                                                                {{temporalInfraestructure.area}}m2</p>
                                                            <p class="m-0 text-center">
                                                                <strong>{{temporalInfraestructure.address}}</strong>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                </map-info-window>
                                            </google-map>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>certifications</h3>
                                        </div>
                                        <div class="col-12">
                                            <div class="row service-items">
                                                <div class="col col-d-12 col-t-12 col-m-12 border-line-h p-0"
                                                    style="display: flex;">
                                                    <div *ngIf="certificateISO9001" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'ISO_9001' | translate }}" shape="circle"
                                                                image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateGMP" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"
                                                                shape="circle"
                                                                image="assets/certificacion/2. GMP.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateBCR" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}"
                                                                shape="circle"
                                                                image="assets/certificacion/3. BRC.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateHACCP" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}"
                                                                shape="circle"
                                                                image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateBASC" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}"
                                                                shape="circle"
                                                                image="assets/certificacion/4. BASC.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateISO14001" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'ISO_14001' | translate }}" shape="circle"
                                                                image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateISO18001" style="padding: 5px;">
                                                        <div
                                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                                pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}"
                                                                shape="circle"
                                                                image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>experience_main_clients</h3>
                                        </div>
                                        <div class="col-12">
                                            <div class="grid m-0">
                                                <ng-container *ngFor="let item of clients">
                                                    <div class="col-12 mb-3 border-none">
                                                        <div class="card shadow-5 m-0 flex align-items-center"
                                                            style="padding: 20px 12px;">
                                                            <div class="grid m-0 w-full">
                                                                <div class="col-12 flex align-items-center">
                                                                    <h3 class="font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full m-0"
                                                                        style="color: var(--primary-color);">
                                                                        <span pTooltip="{{item.businessName}}"
                                                                            tooltipPosition="top">{{item.businessName}}</span>
                                                                    </h3>
                                                                    <div class="flex align-items-center">
                                                                        <img class="mr-3"
                                                                            src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png"
                                                                            alt="" srcset="">
                                                                        <button style="float: right;"
                                                                            [disabled]="item.files.length === 0" pButton
                                                                            pRipple type="button"
                                                                            icon="fas fa-paperclip"
                                                                            label="{{item.files.length}}"
                                                                            class="p-button-rounded p-button-secondary p-button-outlined"
                                                                            (click)="files = item.files; opFiles.toggle($event)"></button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid m-0 w-full">
                                                                        <div class="col-4 flex align-items-center ">
                                                                            <p class="m-0" style="color: #689F38;">
                                                                                {{item.codeTributary}}</p>
                                                                        </div>
                                                                        <div class="col-5 flex align-items-center">
                                                                            <p class="m-0"><i style="font-size: 18px;"
                                                                                    pTooltip="{{ 'average_sales_last_year' | translate }}"
                                                                                    class="cursor-pointer fas fa-dollar-sign mr-3"></i>
                                                                                {{getRango(item.averageSalesLastYear)}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-3 text-center">
                                                                            <button style="width: 125.95px;"
                                                                                icon="fas fa-user-friends" pButton
                                                                                pRipple type="button"
                                                                                label="{{ 'CONTACT' | translate }}"
                                                                                (click)="opContact.toggle($event)"
                                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                                [disabled]="item.contacts.length === 0"></button>
                                                                            <p-overlayPanel #opContact>
                                                                                <ng-template pTemplate>
                                                                                    <p-scrollPanel [style]="{width: '300px', height: '350px'}">
                                                                                        <div class="grid align-items-center m-0">
                                                                                            <div class="col-12 p-0" *ngFor="let item of item.contacts">
                                                                                                <div class="card m-2 shadow-5">
                                                                                                    <div class="grid">
                                                                                                        <div class="col-12 text-center">
                                                                                                            <p-avatar
                                                                                                                *ngIf="item.gender === 'M'"
                                                                                                                image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                                                size="xlarge"
                                                                                                                shape="circle"></p-avatar>
                                                                                                            <p-avatar
                                                                                                                *ngIf="item.gender === 'F'"
                                                                                                                image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                                                size="xlarge"
                                                                                                                shape="circle"></p-avatar>
                                                                                                        </div>
                                                                                                        <div class="col-12 text-center">
                                                                                                            <p>{{item.name}}
                                                                                                            </p>
                                                                                                            <p class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{item.email}}</p>
                                                                                                            <p *ngIf="item.phone">
                                                                                                                <span class="mr-2 inline-block ">
                                                                                                                    <i class="fas fa-phone mr-2"></i>
                                                                                                                    <span>{{item.phone}}</span>
                                                                                                                </span>
                                                                                                                -
                                                                                                                <span>#{{item.extensionPhone}}</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-scrollPanel>
                                                                                </ng-template>
                                                                            </p-overlayPanel>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid m-0">
                                                                        <div
                                                                            class="col-4 pt-0 pb-0 flex align-items-center ">
                                                                            <p class="m-0"
                                                                                style="color: var(--bold-text);">
                                                                                {{item.sectorName}}</p>
                                                                        </div>
                                                                        <div
                                                                            class="col-5 pt-0 pb-0 flex align-items-center">
                                                                            <p class="m-0"><i style="font-size: 18px;"
                                                                                    pTooltip="{{ 'year_business_relationship' | translate }}"
                                                                                    class="cursor-pointer fas fa-history mr-3"></i>{{item.YearCommercialRel}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-3 pt-0 pb-0 text-center">
                                                                            <button style="width: 125.95px;" pButton
                                                                                pRipple type="button"
                                                                                icon="pi pi-info-circle"
                                                                                label="{{ 'DETAIL' | translate }}"
                                                                                (click)="opDetail.toggle($event)"
                                                                                class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                                                [disabled]="item.detail.length === 0"></button>
                                                                            <p-overlayPanel #opDetail>
                                                                                <ng-template pTemplate>
                                                                                    <div class="grid align-items-center text-center"
                                                                                        style="width: 300px;">
                                                                                        <div class="col-12"
                                                                                            style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                                            <div
                                                                                                [innerHTML]="item.detail">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-overlayPanel>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>line_products_services</h3>
                                        </div>
                                        <div class="col-12">
                                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                                no_data_available</p>
                                            <ng-container *ngIf="catalogues.length > 0">
                                                <div class="grid-system">
                                                    <div class="item" *ngFor="let items of catalogues">
                                                        <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-6">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <h3 class="m-0" translate>credit_report</h3>
                                                </div>
                                                <div class="col-12">
                                                    <div class="grid m-0">
                                                        <ng-container *ngFor="let item of reportsFiles">
                                                            <div class="col-12 border-none">
                                                                <div
                                                                    class="card shadow-5 m-0 p-3 flex align-items-center flex-wrap">
                                                                    <div class="grid w-full align-items-center m-0">
                                                                        <div
                                                                            class="w-full flex align-items-center justify-content-between">
                                                                            <h3 class="w-full ml-2"
                                                                                style="color: var(--primary-color);">
                                                                                {{item.financialEntity}}</h3> <button
                                                                                style="margin-right: 0.35rem;"
                                                                                [disabled]="item.financialReferenceReportFile.length === 0"
                                                                                pButton pRipple type="button"
                                                                                icon="pi pi-paperclip"
                                                                                label="{{item.financialReferenceReportFile.length}}"
                                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                                (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>

                                                                        </div>
                                                                        <div class="col-6"><i style="font-size: 18px;"
                                                                                pTooltip="{{'credit_level_obtained' | translate}}"
                                                                                class="cursor-pointer fas fa-star-half-alt"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;
                                                                            <span>{{item.financialReferenceCreditRating
                                                                                | number:'1.2-2'}}</span></div>
                                                                        <div class="col-6"><i
                                                                                pTooltip="Fecha de reporte"
                                                                                style="font-size: 18px;"
                                                                                class="cursor-pointer fas fa-calendar-check"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;
                                                                            <span>{{(item.financialReferenceReportDate *
                                                                                1000) | date:"dd/MM/yyyy"}}</span></div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <h3 class="m-0" translate>credit_risk</h3>
                                                </div>
                                                <div class="col-12">
                                                    <div class="grid m-0">
                                                        <ng-container *ngFor="let item of financialReference">
                                                            <div class="col-12 border-none">
                                                                <div class="card shadow-5 m-0 mb-3 ">
                                                                    <div class="flex align-items-center">
                                                                        <div class="card-img"
                                                                            style="margin-right: 5px;">
                                                                            <p-avatar [image]="item.image"
                                                                                shape="circle" size="large"
                                                                                [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                                                        </div>
                                                                        <div class="card-etc" style="width: 100%;">
                                                                            <h3 style="color: var(--primary-color);"
                                                                                class="mb-1 font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full">
                                                                                <span pTooltip="{{item.bank}}"
                                                                                    tooltipPosition="top"> {{item.bank}}
                                                                                </span> </h3>

                                                                            <p class="mb-0 mt-1"><b
                                                                                    style="color:#8cc785">{{item.name}}
                                                                                </b></p>
                                                                            <p class="mb-0 mt-1"><b
                                                                                    style="color:#8cc785">{{item.email}}</b>
                                                                            </p>
                                                                            <p class="mb-0 mt-1"
                                                                                style="font-size: 11px;">
                                                                                <span>{{'approvedLine' | translate }}
                                                                                </span> ({{item.currency}}):
                                                                                {{item.approvedAmount | number:'1.2-2'}}
                                                                            </p>

                                                                        </div>
                                                                    </div>

                                                                    <div style="width: 100%;">
                                                                        <p class="mt-1"
                                                                            style="font-size: 11px;     margin-left: 3.5rem; margin-bottom: 0;">
                                                                            <span class="mr-2 inline-block">
                                                                                <i class="fas fa-mobile-alt mr-2"></i>
                                                                                <span>({{item.prefixCellphone}}) </span>
                                                                                <span>{{item.cellphone}}</span>

                                                                            </span>
                                                                            <span class="mr-2 inline-block">
                                                                                <i class="fas fa-phone mr-2"></i>
                                                                                <span>({{item.prefixPhone}})</span>
                                                                                <span>{{item.phone}}</span>
                                                                            </span>
                                                                            <span>#{{item.extension}}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-scrollPanel>
                            </ng-container>
                            <ng-container *ngIf="tabDetail">
                                <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                                    <div class="grid m-0">
                                        <ng-container *ngIf="profile">
                                            <div class="col-12">
                                                <h3 class="m-0" translate>company_summary</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.summary.length === 0" translate>
                                                    no_data_available</p>
                                                <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}
                                                </p>
                                            </div>
                                            <div class="col-12">
                                                <h3 class="m-0" translate>panelMission</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.mission.length === 0" translate>
                                                    no_data_available</p>
                                                <p class="m-0" *ngIf="profile.mission.length > 0">{{profile.mission}}
                                                </p>
                                            </div>
                                            <div class="col-12">
                                                <h3 class="m-0" translate>panelVision</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.vision.length === 0" translate>
                                                    no_data_available</p>
                                                <p class="m-0" *ngIf="profile.vision.length > 0">{{profile.vision}}</p>
                                            </div>
                                        </ng-container>

                                        <div class="col-12">
                                            <h3 class="m-0" translate>values</h3>
                                        </div>
                                        <div class="col-12">
                                            <div id="chartdivprofilev1" style="text-align: center;      display: flex;   justify-content: center;
                                                flex-flow: wrap;
                                                align-content: center;">
                                                <ng-container *ngFor="let item of dataGr">
                                                    <p-tag styleClass="mr-2 mb-2" value="{{item}}"></p-tag>
                                                </ng-container>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>business_lines</h3>
                                        </div>
                                        <div class="col-12">
                                            <p-table [value]="categories" [paginator]="true" [rows]="5" [scrollable]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 50px; width: 50px;"></th>
                                                        <th style="min-width: 150px;" translate>segment</th>
                                                        <th style="min-width: 150px;" translate>group</th>
                                                        <th style="min-width: 150px;" translate>subgroup</th>
                                                        <th style="min-width: 150px;" translate>category</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data>
                                                    <tr>
                                                        <td>
                                                            <span class="icon-back" [style.background]="data['color']">
                                                                <i class="{{data['icon']}}" aria-hidden="true"></i>
                                                            </span>
                                                        </td>
                                                        <td>{{data.segment}}</td>
                                                        <td>{{data.group}}</td>
                                                        <td>{{data.subGroup}}</td>
                                                        <td>{{data.category}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>UNSPSC</h3>
                                        </div>
                                        <div class="col-12">
                                            <p-table [value]="categoriesUnspsc" [scrollable]="true" [paginator]="true" [rows]="5">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 150px;" translate>segment</th>
                                                        <th style="min-width: 150px;" translate>group</th>
                                                        <th style="min-width: 150px;" translate>subgroup</th>
                                                        <th style="min-width: 150px;" translate>category</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data>
                                                    <tr>
                                                        <td>{{data.segment}}</td>
                                                        <td>{{data.family}}</td>
                                                        <td>{{data.group}}</td>
                                                        <td>{{data.category}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>bank_information_s</h3>
                                        </div>
                                        <div class="col-12">
                                            <div class="grid m-0">
                                                <ng-container *ngFor="let item of accountBanks">
                                                    <div class="col-12 mb-3 border-none">
                                                        <div class="card shadow-5 m-0">
                                                            <div class="grid m-0 align-items-center">
                                                                <div class="card-img">
                                                                    <p-avatar [image]="item.image" shape="circle"
                                                                        size="large"
                                                                        [style]="{'height':'6rem', 'width': '6rem'}"></p-avatar>
                                                                </div>
                                                                <div class="flex align-content-start col">
                                                                    <div class="card-etc ">
                                                                        <h3 class="m-0 mb-2 font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full flex align-items-center justify-content-between"
                                                                            style="color: var(--primary-color);">
                                                                            <span><span pTooltip="{{item.bank}}"
                                                                                    tooltipPosition="top">{{item.bank}}
                                                                                    - {{item.countryName}} -
                                                                                    {{item.country}}</span></span>
                                                                        </h3>
                                                                        <span><span class="mr-2"
                                                                                style="color: #689F38;"> <b>N°:</b>
                                                                                {{item.nBankAccount}}</span> <span
                                                                                style="color: #689F38;"
                                                                                *ngIf="item.nInterbankAccount.length > 0">
                                                                                <b>CCI:</b>
                                                                                {{item.nInterbankAccount}}</span></span>

                                                                        <span class="block mt-2"> <span class="mr-2"><b
                                                                                    style="color: var(--bold-text);"
                                                                                    translate>type</b>: {{item.type}}
                                                                            </span> <span class="mr-2"><b
                                                                                    style="color: var(--bold-text);"
                                                                                    translate>coin</b>:
                                                                                {{item.currencyName}}
                                                                                ({{item.currency}})</span> <span
                                                                                class="mb-0"><b
                                                                                    style="color: var(--bold-text);">Swift/BIC:</b>
                                                                                {{item.SwiftCode}}</span> </span>

                                                                    </div>
                                                                </div>
                                                                <div class="button-container"
                                                                    style="display: flex;align-items: flex-end;flex-direction: column;">
                                                                    <div class="flex align-items-center">
                                                                        <img class="mr-3"
                                                                            src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png"
                                                                            alt="" srcset="">
                                                                        <button [disabled]="item.files.length === 0"
                                                                            pButton pRipple type="button"
                                                                            icon="pi pi-paperclip"
                                                                            class="p-button-rounded p-button-secondary p-button-outlined"
                                                                            style="float: right; margin-bottom: 8px;"
                                                                            (click)="files = item.files; opFiles.toggle($event)"
                                                                            label="{{item.files.length}}"></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>line_products_services</h3>
                                        </div>
                                        <div class="col-12">
                                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                                no_data_available</p>
                                                <ng-container *ngIf="catalogues.length > 0">
                                                    <div class="grid-system">
                                                        <div class="item" *ngFor="let items of catalogues">
                                                            <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>commercial_references</h3>
                                        </div>
                                        <div class="col-12">
                                            <div class="grid m-0">
                                                <ng-container *ngFor="let item of clients">
                                                    <div class="col-12 mb-3 border-none">
                                                        <div class="card shadow-5 m-0 flex align-items-center"
                                                            style="padding: 20px 12px;">
                                                            <div class="grid m-0 w-full">
                                                                <div class="col-12 flex align-items-center">
                                                                    <h3 class="font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full m-0"
                                                                        style="color: var(--primary-color);">
                                                                        <span pTooltip="{{item.businessName}}"
                                                                            tooltipPosition="top">{{item.businessName}}</span>
                                                                    </h3>
                                                                    <div class="flex align-items-center">
                                                                        <img class="mr-3"
                                                                            src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png"
                                                                            alt="" srcset="">
                                                                        <button style="float: right;"
                                                                            [disabled]="item.files.length === 0" pButton
                                                                            pRipple type="button"
                                                                            icon="fas fa-paperclip"
                                                                            label="{{item.files.length}}"
                                                                            class="p-button-rounded p-button-secondary p-button-outlined"
                                                                            (click)="files = item.files; opFiles.toggle($event)"></button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid m-0 w-full">
                                                                        <div class="col-4 flex align-items-center ">
                                                                            <p class="m-0" style="color: #689F38;">
                                                                                {{item.codeTributary}}</p>
                                                                        </div>
                                                                        <div class="col-5 flex align-items-center">
                                                                            <p class="m-0"><i style="font-size: 18px;"
                                                                                    pTooltip="Ventas promedio último año (USD)"
                                                                                    class="cursor-pointer fas fa-dollar-sign mr-3"></i>
                                                                                {{getRango(item.averageSalesLastYear)}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <button style="width:125.95px ;"
                                                                                icon="fas fa-user-friends" pButton
                                                                                pRipple type="button" label="CONTACTO"
                                                                                (click)="opContact.toggle($event)"
                                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                                [disabled]="item.contacts.length === 0"></button>
                                                                                <p-overlayPanel #opContact>
                                                                                    <ng-template pTemplate>
                                                                                        <p-scrollPanel [style]="{width: '300px', height: '350px'}">
                                                                                            <div class="grid align-items-center m-0">
                                                                                                <div class="col-12 p-0" *ngFor="let item of item.contacts">
                                                                                                    <div class="card m-2 shadow-5">
                                                                                                        <div class="grid">
                                                                                                            <div class="col-12 text-center">
                                                                                                                <p-avatar
                                                                                                                    *ngIf="item.gender === 'M'"
                                                                                                                    image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                                                    size="xlarge"
                                                                                                                    shape="circle"></p-avatar>
                                                                                                                <p-avatar
                                                                                                                    *ngIf="item.gender === 'F'"
                                                                                                                    image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                                                    size="xlarge"
                                                                                                                    shape="circle"></p-avatar>
                                                                                                            </div>
                                                                                                            <div class="col-12 text-center">
                                                                                                                <p>{{item.name}}
                                                                                                                </p>
                                                                                                                <p>{{item.email}}
                                                                                                                </p>
                                                                                                                <p *ngIf="item.phone">
                                                                                                                    <span
                                                                                                                        class="mr-2 inline-block ">
                                                                                                                        <i
                                                                                                                            class="fas fa-phone mr-2"></i>
                                                                                                                        <span>{{item.phone}}</span>
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        -
                                                                                                                        #{{item.extensionPhone}}
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-scrollPanel>
                                                                                    </ng-template>
                                                                                </p-overlayPanel>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0">
                                                                    <div class="grid m-0">
                                                                        <div
                                                                            class="col-4 pt-0 pb-0 flex align-items-center ">
                                                                            <p class="m-0"
                                                                                style="color: var(--bold-text);">
                                                                                {{item.sectorName}}</p>
                                                                        </div>
                                                                        <div
                                                                            class="col-5 pt-0 pb-0 flex align-items-center">
                                                                            <p class="m-0"><i style="font-size: 18px;"
                                                                                    pTooltip="Año de inicio de relación comercial"
                                                                                    class="cursor-pointer fas fa-history mr-3"></i>{{item.YearCommercialRel}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-3 pt-0 pb-0">

                                                                            <button style="width:125.95px ;"
                                                                                icon="pi pi-info-circle" pButton pRipple
                                                                                type="button" label="DETALLE"
                                                                                (click)="opDetail.toggle($event)"
                                                                                class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                                                [disabled]="item.detail.length === 0"></button>
                                                                            <p-overlayPanel #opDetail>
                                                                                <ng-template pTemplate>
                                                                                    <div class="grid align-items-center text-center"
                                                                                        style="width: 300px;">
                                                                                        <div class="col-12"
                                                                                            style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                                            <div
                                                                                                [innerHTML]="item.detail">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-overlayPanel>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>infrastructure</h3>
                                        </div>
                                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                            <ng-container>
                                                <div class="list-button-float" *ngIf="!listMap">
                                                    <div class="circle-off"></div>
                                                    <button style="height:40px; width:40px;  background: black;" pButton
                                                        pRipple type="button" icon="fas fa-list-ul"
                                                        class="p-button-rounded p-button-secondary "
                                                        (click)="listMap = true"></button>
                                                </div>
                                                <div class="container-list-map animate__animated animate__slideInRight"
                                                    *ngIf="listMap">
                                                    <ng-container *ngIf="infraestructures.length === 0">
                                                        <div class="grid m-0 w-full h-full justify-content-end">
                                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                                style="width: 50%;">
                                                                <span
                                                                    style="color: white; font-size: 18px; text-align: center;"
                                                                    translate>not_registered_infrastructures</span>
                                                                <button pButton pRipple type="button"
                                                                    label="{{ 'closeU' | translate }}"
                                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                                    (click)="closeListMap()"></button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="infraestructures.length > 0">
                                                        <div class="grid m-0">
                                                            <div class="col-12 text-right tex">
                                                                <div class="circle"></div>
                                                                <button
                                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                    pButton pRipple type="button" icon="fas fa-times"
                                                                    class="p-button-rounded p-button-danger"
                                                                    (click)="closeListMap()">
                                                                </button>
                                                            </div>
                                                            <div class="col-12">
                                                                <p-scrollPanel
                                                                    [style]="{width: '100%', height: '339px'}">
                                                                    <ng-container *ngFor="let item of infraestructures">
                                                                        <app-list-properties [type]="'SUMMARY'"
                                                                            [item]="item"
                                                                            (location)="openLocation(item)">
                                                                        </app-list-properties>
                                                                    </ng-container>
                                                                </p-scrollPanel>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                                <map-marker #somemarker="mapMarker"
                                                    *ngFor="let item of infraestructures" [position]="item.position"
                                                    (mapClick)="openInfo(somemarker, item)">
                                                </map-marker>
                                                <map-info-window>
                                                    <ng-container *ngIf="temporalInfraestructure">
                                                        <div style="width: 150px;     flex-direction: column;"
                                                            class="flex align-items-center flex-wrap text-center ">
                                                            <p class="m-0 text-blue-500 text-center">
                                                                <strong
                                                                    class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                            </p>
                                                            <p class="m-0  text-center">
                                                                {{temporalInfraestructure.nameType}} -
                                                                {{temporalInfraestructure.area}}m2</p>
                                                            <p class="m-0 text-center">
                                                                <strong>{{temporalInfraestructure.address}}</strong>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                </map-info-window>
                                            </google-map>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>registered_vehicles</h3>
                                        </div>
                                        <div class="col-12">
                                            <p-table [value]="transports" [scrollable]="true" [paginator]="true" [rows]="5">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 50px; width: 50px;"></th>
                                                        <th style="min-width: 150px;" translate>type</th>
                                                        <th style="min-width: 150px;" translate>vehicle_group</th>
                                                        <th style="min-width: 150px;" translate>vehicle_category</th>
                                                        <th style="min-width: 150px;" translate>vehicle_owner</th>
                                                        <th style="min-width: 150px;" translate>license_plate</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data>
                                                    <tr>
                                                        <td>
                                                            <img [src]="data.image" alt="" height="32">
                                                        </td>
                                                        <td>{{data.type}}</td>
                                                        <td>{{data.group}}</td>
                                                        <td>{{data.category}}</td>
                                                        <td>{{data.typeOwner}}</td>
                                                        <td>{{data.plate}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>detail_drivers</h3>
                                        </div>
                                        <div *ngFor="let item of drivers" class="col-4">
                                            <div class="card shadow-5 m-0 flex align-items-center flex-column">
                                                <div class="card-img " style="width: 3.5rem;">
                                                    <p-avatar
                                                        image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                        shape="circle" size="large"
                                                        [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                                </div>
                                                <div class="card-etc text-center">
                                                    <h3 style="color: #3bb1ff;"
                                                        class="mb-1 font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full">
                                                        <span pTooltip="{{item.name}}" tooltipPosition="top">
                                                            {{item.name}}</span> </h3>

                                                    <p class="mb-0 mt-1"><b style="color: var(--bold-text);"
                                                            translate>driver_license </b>{{item.license}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>commercial_terms</h3>
                                        </div>
                                        <div class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 text-center"
                                                        style="border-bottom: 1px solid var(--surface-d)">
                                                        <p class="m-0" translate>way_to_pay</p>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                                            <div *ngFor="let item of payment"
                                                                class="col-12 flex align-items-center">
                                                                <div class="mr-3">
                                                                    <p-avatar [image]="item.img" shape="circle"
                                                                        size="large"
                                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                                </div>
                                                                <div>
                                                                    {{item.name}}
                                                                </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 text-center"
                                                        style="border-bottom: 1px solid var(--surface-d)">
                                                        <p class="m-0" translate>warranty</p>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                                            <div *ngFor="let item of warranty"
                                                                class="col-12 flex align-items-center">
                                                                <div class="mr-3">
                                                                    <p-avatar [image]="item.img" shape="circle"
                                                                        size="large"
                                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                                </div>
                                                                <div>{{item.name}} </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 text-center"
                                                        style="border-bottom: 1px solid var(--surface-d)">
                                                        <p class="m-0" translate>conditionTypeOfReceiptlabel</p>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                                            <div *ngFor="let item of receipt"
                                                                class="col-12 flex align-items-center">
                                                                <div class="mr-3">
                                                                    <p-avatar [image]="item.img" shape="circle"
                                                                        size="large"
                                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                                </div>
                                                                <div>{{item.name}} </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 text-center"
                                                        style="border-bottom: 1px solid var(--surface-d)">
                                                        <p class="m-0" translate>place_of_delivery</p>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                                            <div *ngFor="let item of delivery   "
                                                                class="col-12 flex align-items-center">
                                                                <div class="mr-3">
                                                                    <p-avatar [image]="item.img" shape="circle"
                                                                        size="large"
                                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                                </div>
                                                                <div>{{item.name}} </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <h3 class="m-0" translate>SIDEBAR.QUALITY</h3>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b
                                                                    translate>one_quality_management_policies</b> <i
                                                                    class="pl-2 fas fa-info-circle"
                                                                    pTooltip="{{ 'control_improvement_TT' | translate}}"></i>
                                                            </p>


                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="qualityManagement.qualityManagementPolicies.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.qualityManagementPolicies.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.qualityManagementPolicies; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>ISO9001_certificate</b> <p-avatar
                                                                    tooltipPosition="left"
                                                                    pTooltip="{{ 'ISO_9001' | translate }}"
                                                                    [style]="{height:'20px' , width: '20px'}" shape="circle"
                                                                    image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div style="width: 320.05px; text-align: right;">
                                                            <button
                                                                [disabled]="qualityManagement.ISO9001Certificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.ISO9001Certificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.ISO9001Certificate;opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div class="w-full">
                                                            <div class="formgrid grid">
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>registration_number</label>
                                                                        <div class="col">
                                                                            {{qualityManagement.ISO9001RegistrationNo}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>due_date</label>
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="qualityManagement.ISO9001RegistrationDueDate">{{(qualityManagement.ISO9001RegistrationDueDate
                                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>other_quality_certificates</h3>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>GMP_certified</b> <p-avatar
                                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                                    pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"
                                                                    shape="circle"
                                                                    image="assets/certificacion/2. GMP.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="qualityManagement.GMPCertificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.GMPCertificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.GMPCertificate; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>BRC_certificate</b> <p-avatar
                                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                                    pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}"
                                                                    shape="circle"
                                                                    image="assets/certificacion/3. BRC.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div style="width: 320.05px; text-align: right;">
                                                            <button
                                                                [disabled]="qualityManagement.BRCCertificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.BRCCertificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.BRCCertificate;opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>HACCP_Certificate</b> <p-avatar
                                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                                    pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}"
                                                                    shape="circle"
                                                                    image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div style="width: 320.05px; text-align: right;">
                                                            <button
                                                                [disabled]="qualityManagement.HACCPCertificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.HACCPCertificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.HACCPCertificate;opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>BASC_certificate</b> <p-avatar
                                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                                    pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}"
                                                                    shape="circle"
                                                                    image="assets/certificacion/4. BASC.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div style="width: 320.05px; text-align: right;">
                                                            <button
                                                                [disabled]="qualityManagement.BASCCertificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{qualityManagement.BASCCertificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=qualityManagement.BASCCertificate;opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div class="w-full">
                                                            <div class="formgrid grid">
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>registration_number</label>
                                                                        <div class="col">
                                                                            {{qualityManagement.BASCRegistrationNo}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>due_date</label>
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="qualityManagement.BASCDueDate">{{(qualityManagement.BASCDueDate
                                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <h3 class="m-0" translate>approvals</h3>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <div class="grid m-0">
                                                <ng-container *ngFor="let item of qualityManagement.homologation">
                                                    <div class="col-12 mb-3 border-none">
                                                        <div class="grid card-hom card shadow-5 m-0">
                                                            <div
                                                                class="col-12 flex align-items-center justify-content-between w-full px-0">
                                                                <h3 style="color: var(--primary-color);" class="m-0">
                                                                    {{item.approvalCompany}}</h3>

                                                                <button pButton pRipple type="button"
                                                                    icon="pi pi-paperclip" label="{{item.files.length}}"
                                                                    class="p-button-rounded p-button-secondary p-button-outlined"
                                                                    (click)="files = item.files;opFiles.toggle($event)"></button>
                                                            </div>
                                                            <div class="col-5 p-0">

                                                                <p style="color: #689F38;">{{item.requestingCompany}}
                                                                </p>
                                                                <p style="color: var(--bold-text);" class="m-0">
                                                                    {{item.codeTributary}}</p>
                                                            </div>
                                                            <div class="col-7 p-0">
                                                                <p class="m-0"> <i style="font-size: 18px;"
                                                                        class="fas fa-star-half-alt mr-3"></i>
                                                                    {{item.scoreObtained}} </p>
                                                                <p> <i style="font-size: 18px;"
                                                                        class="fas fa-calendar-check mr-3"></i>
                                                                    {{item.expirationDate *
                                                                    1000 | date:'dd/MM/yyyy'}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">

                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>QUALITY_MANAGEMENT_Q1</b>
                                                            </p>
                                                        </div>
                                                        <div style="padding-right: 2.5%;" class="text-right">
                                                            <span>{{qualityManagement.deliveryCertifiedQualityWarranty ?
                                                                'SI' : 'NO'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>QUALITY_MANAGEMENT_Q2</b></p>
                                                        </div>
                                                        <div style="padding-right: 2.5%;" class="text-right">
                                                            <span>{{qualityManagement.manufacturerDeliverQualityDossier
                                                                ? 'SI' : 'NO'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>QUALITY_MANAGEMENT_Q3</b></p>
                                                        </div>
                                                        <div style="padding-right: 2.5%;" class="text-right">
                                                            <span>{{qualityManagement.outsourceProcessesActivities ?
                                                                'SI' : 'NO'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="qualityManagement" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>outsourced_processes_activities</h3>
                                                    </div>
                                                    <div class="col-12">
                                                        <p-scrollPanel [style]="{width: '100%', height: '162px'}">
                                                            <ng-container
                                                                *ngFor="let item of qualityManagement.processActivity; let i = index">
                                                                <div
                                                                    class="mx-1 mb-2 px-3 py-2 card flex align-items-center justify-content-between shadow-5">
                                                                    <p>{{item}}</p>
                                                                </div>
                                                            </ng-container>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="securityHealthWork" class="col-12">
                                            <h3 class="m-0" translate>occupational_policies</h3>
                                        </div>
                                        <div *ngIf="securityHealthWork" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>occupational_health_safety</b></p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="securityHealthWork.occupationalHealthSafetyPolicies.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{securityHealthWork.occupationalHealthSafetyPolicies.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=securityHealthWork.occupationalHealthSafetyPolicies; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>civil_defense_certificate</b></p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="securityHealthWork.civilDefenseCertificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{securityHealthWork.civilDefenseCertificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=securityHealthWork.civilDefenseCertificate; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>occupational_committee</b></p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="securityHealthWork.occupationalHealthSafetyCommittee.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{securityHealthWork.occupationalHealthSafetyCommittee.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=securityHealthWork.occupationalHealthSafetyCommittee; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div class="w-full">
                                                            <div class="formgrid grid">
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>name_supervisor</label>
                                                                        <div class="col">
                                                                            {{securityHealthWork.supervisorName}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>OSHA_certified</b>
                                                                <p-avatar [style]="{height:'20px' , width: '20px'}"
                                                                    styleClass="cursor-pointer" tooltipPosition="left"
                                                                    pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}"
                                                                    shape="circle"
                                                                    image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="securityHealthWork.OSHA18001Certificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{securityHealthWork.OSHA18001Certificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=securityHealthWork.OSHA18001Certificate; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div class="w-full">
                                                            <div class="formgrid grid">
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>registration_number</label>
                                                                        <div class="col">
                                                                            {{securityHealthWork.OSHA18001RegistrationNo}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>due_date</label>
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="securityHealthWork.OSHA18001DueDate">{{(securityHealthWork.OSHA18001DueDate
                                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="securityHealthWork" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>detailed_security_statistics</h3>
                                                    </div>
                                                    <div class="col-12 ">
                                                        <p-scrollPanel [style]="{width: '100%', height: '303px'}">
                                                            <div class="grid">
                                                                <div class="col-12 text-center">
                                                                    <div class="col-12 p-0 pb-2">
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12">
                                                                                <i class="fas fa-pen-square"
                                                                                    style="font-size: 32px;"></i>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <span translate>register_SECURITY_AND_HEALTH_AT_WORK_MANAGEMENT</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-calendar-alt"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>year</span></div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-stopwatch-20"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>nacc</span></div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-user-clock"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>IR</span></div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-user-injured"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>SR</span></div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-hospital-user"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>LTCR</span></div>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <div class="col-12"><i
                                                                                    class="fas fa-user-graduate"
                                                                                    style="font-size: 32px;"></i></div>
                                                                            <div class="col-12"><span
                                                                                    translate>STR</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngFor="let item of securityHealthWork.securityRecords; let i = index"
                                                                        class="item-register col-12 p-0 pb-2 mb-2 flex align-items-center justify-content-center">
                                                                        <div class="width-list-s-r"><span>{{i+1}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securityYearRegistry}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securityStatisticsHealthRegistry}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securityFrequentIndexIF}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securitySeverityIndexIS}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securityAccidentRateAI}}</span>
                                                                        </div>
                                                                        <div class="width-list-s-r">
                                                                            <span>{{item.securityTrainingIndexIC}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                                            <h3 class="m-0" translate>social_responsibility</h3>
                                        </div>
                                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>environmental_management_policies</b></p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="responsabilitySocialEnvironmental.environmentalManagementPolicies.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{responsabilitySocialEnvironmental.environmentalManagementPolicies.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=responsabilitySocialEnvironmental.environmentalManagementPolicies; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p><b translate>solid_waste_management_plan</b></p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="responsabilitySocialEnvironmental.solidWasteManagementPlan.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{responsabilitySocialEnvironmental.solidWasteManagementPlan.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=responsabilitySocialEnvironmental.solidWasteManagementPlan; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div>
                                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                                    translate>ISO45001_certificate</b> <p-avatar
                                                                    tooltipPosition="left"
                                                                    pTooltip="{{ 'ISO_14001' | translate }}"
                                                                    shape="circle" [style]="{height:'20px' , width: '20px'}"
                                                                    image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                [disabled]="responsabilitySocialEnvironmental.ISO14001Certificate.length === 0"
                                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                                label="{{responsabilitySocialEnvironmental.ISO14001Certificate.length}}"
                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                (click)="files=responsabilitySocialEnvironmental.ISO14001Certificate; opFiles.toggle($event)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 flex align-items-center justify-content-between">
                                                        <div class="w-full">
                                                            <div class="formgrid grid">
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>registration_number</label>
                                                                        <div class="col">
                                                                            {{responsabilitySocialEnvironmental.ISO14001RegistrationNo}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="field col m-0">
                                                                    <div class="field grid m-0">
                                                                        <label class="col-fixed mb-0"
                                                                            translate>due_date</label>
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="responsabilitySocialEnvironmental.ISO14001DueDate">{{(responsabilitySocialEnvironmental.ISO14001DueDate
                                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="responsabilitySocialEnvironmental" class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>ethics_governance</h3>
                                                    </div>
                                                    <div class="col-12">
                                                        <p-scrollPanel [style]="{width: '100%', height: '457px'}">
                                                            <div class="grid m-0">
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.washed"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2"
                                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q2</span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.washed">
                                                                    <p>{{responsabilitySocialEnvironmental.washedText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.conduct"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2"
                                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q3</span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.conduct">
                                                                    <p>{{responsabilitySocialEnvironmental.conductText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.security"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q4
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.security">
                                                                    <p>{{responsabilitySocialEnvironmental.securityText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.confidentiality"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q5
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.confidentiality">
                                                                    <p>{{responsabilitySocialEnvironmental.confidentialityText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.standards"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q6
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.standards">
                                                                    <p>{{responsabilitySocialEnvironmental.standardsText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.complaint"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q7
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.complaint">
                                                                    <p>{{responsabilitySocialEnvironmental.complaintText}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="responsabilitySocialEnvironmental" class="col-6">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>labor_standards</h3>
                                                    </div>
                                                    <div class="col-12">
                                                        <p-scrollPanel [style]="{width: '100%', height: '457px'}">
                                                            <div class="grid m-0">
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.personal"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2"
                                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q8
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.personal">
                                                                    <p>{{responsabilitySocialEnvironmental.personalText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.payment"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2"
                                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q9
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.payment">
                                                                    <p>{{responsabilitySocialEnvironmental.paymentText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.regulation"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q10
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.regulation">
                                                                    <p>{{responsabilitySocialEnvironmental.regulationText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.laborUnion"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2"
                                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q11
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.laborUnion">
                                                                    <p>{{responsabilitySocialEnvironmental.laborUnionText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.judgment"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q12
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.judgment">
                                                                    <p>{{responsabilitySocialEnvironmental.judgmentText}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p class="m-0 flex align-items-center">
                                                                        <i *ngIf="responsabilitySocialEnvironmental.minors"
                                                                            class="pi pi-check-circle"
                                                                            style="color: #37e32b;"></i>
                                                                        <span class="ml-2" translate>
                                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q13
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="responsabilitySocialEnvironmental.minors">
                                                                    <p>{{responsabilitySocialEnvironmental.minorsText}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </p-scrollPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="accountingInformation" class="col-12">
                                            <h3 class="m-0" translate>accounting_information_details</h3>
                                        </div>
                                        <div *ngIf="accountingInformation" class="col-12">
                                            <div class="grid m-0">
                                                <ng-container *ngFor="let item of accountingInformation.accountingInformation">
                                                    <div class="col-12 mb-3 border-none">
                                                        <div class="card shadow-5 m-0 flex align-items-center">
                                                            <div class="grid m-0 w-full">
                                                                <div class="col-12">
                                                                    <div class="grid">
                                                                        <div class="col-12 p-0">
                                                                            <div class="grid m-0">
                                                                                <div
                                                                                    class="col-4 flex align-items-center ">
                                                                                    <p class="m-0"><i
                                                                                            pTooltip="{{ 'accounting_information_period'| translate}}"
                                                                                            style="font-size: 18px;"
                                                                                            class="cursor-pointer fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 flex align-items-center">
                                                                                    <p class="m-0"><i
                                                                                            pTooltip="{{ 'inventories_stocks'| translate}}"
                                                                                            style="font-size: 18px;"
                                                                                            class="cursor-pointer fas fa-boxes mr-3"></i>USD
                                                                                        {{item.accountingStockInventoryUSD
                                                                                        | number:'1.2-2'}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="col-4 ">
                                                                                    <p
                                                                                        class="m-0 flex align-items-center">
                                                                                        <p-avatar tooltipPosition="left"
                                                                                            pTooltip="{{ 'BALANCE_SHEET' | translate }}"
                                                                                            shape="circle"
                                                                                            [style]="{'height':'20px','margin-right': '0.35rem' , 'width': '20px'}"
                                                                                            image="assets/certificacion/9. BALANCE.svg"></p-avatar>
                                                                                        <button
                                                                                            icon="fas fa-file-invoice-dollar"
                                                                                            (click)="files=item.accountingBalanceSheet; opFiles.toggle($event)"
                                                                                            [disabled]="item.accountingBalanceSheet.length === 0"
                                                                                            pButton pRipple
                                                                                            type="button"
                                                                                            label="{{item.accountingBalanceSheet.length}} - BALANCE"
                                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "></button>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 p-0">
                                                                            <div class="grid m-0">
                                                                                <div
                                                                                    class="col-4 pt-0 pb-0 flex align-items-center ">
                                                                                    <p class="m-0"><i
                                                                                            pTooltip="{{'share_capital' | translate}}"
                                                                                            style="font-size: 18px;"
                                                                                            class="cursor-pointer fas fa-university mr-3"></i>USD
                                                                                        {{item.accountingCapitalStockUSD
                                                                                        | number:'1.2-2'}} </p>
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 pt-0 pb-0 flex align-items-center">
                                                                                    <p class="m-0"><i
                                                                                            style="font-size: 18px;"
                                                                                            pTooltip="{{ 'machinery_equipment' | translate}}"
                                                                                            class="cursor-pointer fas fa-cog mr-3"></i>USD
                                                                                        {{item.accountingAssetsUSD |
                                                                                        number:'1.2-2'}} </p>
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 pt-0 pb-0 flex align-items-center">
                                                                                    <p
                                                                                        class="m-0 flex align-items-center">
                                                                                        <p-avatar tooltipPosition="left"
                                                                                            pTooltip="{{ 'PROFIT_AND_LOSS_STATEMENTS' | translate }}"
                                                                                            shape="circle"
                                                                                            [style]="{'height':'20px','margin-right': '0.35rem' , 'width': '20px'}"
                                                                                            image="assets/certificacion/8. GYP.svg"></p-avatar>
                                                                                        <button
                                                                                            icon="fas fa-file-invoice-dollar"
                                                                                            (click)="item.accountingProfitLossStatement; opFiles.toggle($event)"
                                                                                            [disabled]="item.accountingProfitLossStatement.length === 0"
                                                                                            pButton pRipple
                                                                                            type="button"
                                                                                            label="{{item.accountingProfitLossStatement.length}} - ESTADOS"
                                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "></button>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="accountingInformation" class="col-12">
                                            <div class="card shadow-5 m-0">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <h3 class="m-0" translate>annual_sales</h3>
                                                    </div>
                                                    <div
                                                        class="col-7 total-top flex align-items-center justify-content-center">
                                                        <div class="detail-total">
                                                            <div class="row-range">
                                                                <span class="title-row" translate>sales_range</span>
                                                                <span *ngIf="formAmount > 0"
                                                                    class="range-title">{{rangeSales}}</span>
                                                                <span *ngIf="formAmount <= 0" class="range-title"
                                                                    translate>NO_SALES_RECORD</span>
                                                            </div>
                                                            <div class="row-range">
                                                                <span class="title-row" translate>average_income</span>
                                                                <span *ngIf="formAmount > 0" class="promedio-title">USD
                                                                    {{formAmount | number:'1.2-2'}}</span>
                                                                <span *ngIf="formAmount <= 0" class="promedio-title">USD
                                                                    -</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="grid">
                                                            <div class="col-12 p-0">
                                                                <div class="grid m-0">
                                                                    <div class="col-5"><span
                                                                            class="title-row2">{{yearNow}}</span></div>
                                                                    <div class="col-7"><span class="title-row2"
                                                                            translate>sales</span></div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 p-0">
                                                                <div class="grid">
                                                                    <div class="col-5">
                                                                        <div class="center-text-flex">
                                                                            {{annualSaleYear1}}</div>
                                                                    </div>
                                                                    <div class="col-7">
                                                                        <div class="center-text-flex">USD
                                                                            {{annualSaleAmount1USD | number:'1.2-2'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 p-0">
                                                                <div class="grid">
                                                                    <div class="col-5">
                                                                        <div class="center-text-flex">
                                                                            {{annualSaleYear2}}</div>
                                                                    </div>
                                                                    <div class="col-7">
                                                                        <div class="center-text-flex">USD
                                                                            {{annualSaleAmount2USD | number:'1.2-2'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 p-0">
                                                                <div class="grid">
                                                                    <div class="col-5">
                                                                        <div class="center-text-flex">
                                                                            {{annualSaleYear3}}</div>
                                                                    </div>
                                                                    <div class="col-7">
                                                                        <div class="center-text-flex">USD
                                                                            {{annualSaleAmount3USD | number:'1.2-2'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <h3 class="m-0 flex align-items-center" translate>credit_report
                                                        <p-avatar tooltipPosition="left"
                                                            pTooltip="{{ 'CREDIT_REPORT' | translate }}" shape="circle"
                                                            [style]="{height:'20px' , width:'20px'}"
                                                            image="assets/certificacion/10. REPORT FIN.svg"></p-avatar>
                                                    </h3>
                                                </div>
                                                <div class="col-12">
                                                    <div class="grid m-0">
                                                        <ng-container *ngFor="let item of reportsFiles">
                                                            <div class="col-12 mb-3 border-none">
                                                                <div
                                                                    class="card shadow-5 m-0 p-3 flex align-items-center flex-wrap">
                                                                    <div class="grid w-full align-items-center m-0">
                                                                        <div
                                                                            class="w-full flex align-items-center justify-content-between">
                                                                            <h3 class="w-full ml-2"
                                                                                style="color: var(--primary-color);">
                                                                                {{item.financialEntity}}</h3> <button
                                                                                style="margin-right: 0.35rem;"
                                                                                [disabled]="item.financialReferenceReportFile.length === 0"
                                                                                pButton pRipple type="button"
                                                                                icon="pi pi-paperclip"
                                                                                label="{{item.financialReferenceReportFile.length}}"
                                                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                                                (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>

                                                                        </div>
                                                                        <div class="col-6"><i style="font-size: 18px;"
                                                                                pTooltip="{{'credit_level_obtained' | translate }}"
                                                                                class="cursor-pointer fas fa-star-half-alt"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;
                                                                            <span>{{item.financialReferenceCreditRating
                                                                                | number:'1.2-2'}}</span></div>
                                                                        <div class="col-6"><i
                                                                                pTooltip="{{ 'report_date' | translate}}"
                                                                                style="font-size: 18px;"
                                                                                class="cursor-pointer fas fa-calendar-check"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;
                                                                            <span>{{(item.financialReferenceReportDate *
                                                                                1000) | date:"dd/MM/yyyy"}}</span></div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <h3 class="m-0" translate>credit_risk </h3>
                                                </div>
                                                <div class="col-12">
                                                    <div class="grid m-0">
                                                        <ng-container *ngFor="let item of financialReference">
                                                            <div class="col-12 mb-3 border-none">
                                                                <div class="card shadow-5 m-0 mb-3 ">
                                                                    <div class="flex align-items-center">
                                                                        <div class="card-img"
                                                                            style="margin-right: 5px;">
                                                                            <p-avatar [image]="item.image"
                                                                                shape="circle" size="large"
                                                                                [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                                                        </div>
                                                                        <div class="card-etc" style="width: 100%;">
                                                                            <h3 style="color: var(--primary-color);"
                                                                                class="mb-1 font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full">
                                                                                <span pTooltip="{{item.bank}}"
                                                                                    tooltipPosition="top"> {{item.bank}}
                                                                                </span> </h3>

                                                                            <p class="mb-0 mt-1"><b
                                                                                    style="color:#8cc785">{{item.name}}
                                                                                </b></p>
                                                                            <p class="mb-0 mt-1"><b
                                                                                    style="color:#8cc785">{{item.email}}</b>
                                                                            </p>
                                                                            <p class="mb-0 mt-1"
                                                                                style="font-size: 11px;">
                                                                                <span>{{'approvedLine' | translate }}
                                                                                </span> ({{item.currency}}):
                                                                                {{item.approvedAmount | number:'1.2-2'}}
                                                                            </p>

                                                                        </div>
                                                                    </div>

                                                                    <div style="width: 100%;">
                                                                        <p class="mt-1"
                                                                            style="font-size: 11px;     margin-left: 3.5rem; margin-bottom: 0;">
                                                                            <span class="mr-2 inline-block">
                                                                                <i class="fas fa-mobile-alt mr-2"></i>
                                                                                <span>({{item.prefixCellphone}}) </span>
                                                                                <span>{{item.cellphone}}</span>

                                                                            </span>
                                                                            <span class="mr-2 inline-block">
                                                                                <i class="fas fa-phone mr-2"></i>
                                                                                <span>({{item.prefixPhone}})</span>
                                                                                <span>{{item.phone}}</span>
                                                                            </span>
                                                                            <span>#{{item.extension}}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-scrollPanel>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="tabSidebar_info === 'contacto'">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                                <div class="grid m-0">
                                    <div class="col-12 flex align-items-center justify-content-between">
                                        <h3 class="m-0" translate>executives_available </h3>
                                        <button pButton pRipple type="button" icon="fas fa-copy"
                                            pTooltip="{{ 'copy_emails' | translate }}"
                                            class="p-button-rounded p-button-secondary"
                                            [disabled]="selectedContact.length === 0" (click)="copyAll()"></button>
                                    </div>
                                    <div class="col-12">
                                        <p-table [value]="executives" [scrollable]="true" [paginator]="true" [rows]="20" scrollHeight="calc(100vh - 308px)" [(selection)]="selectedContact">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="min-width: 50px; width: 50px;">
                                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                                    </th>
                                                    <th style="min-width: 50px; width: 50px;"></th>
                                                    <th style="min-width: 150px; width: 150px;" translate>executives</th>
                                                    <th style="min-width: 150px; width: 150px;" translate>position_create</th>
                                                    <th style="min-width: 150px; width: 150px;" translate>Última actualización</th>
                                                    <th style="min-width: 80px; width: 80px;"></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-data>
                                                <tr>
                                                    <td>
                                                        <p-tableCheckbox [value]="data"></p-tableCheckbox>
                                                    </td>
                                                    <td>
                                                        <p-avatar
                                                            [image]="data.avatar ? data.avatar : 'assets/images/user.svg'"
                                                            shape="circle" size="large"
                                                            [style]="{'height':'25px', 'width': '25px'}"></p-avatar>
                                                    </td>
                                                    <td>{{data.name}} {{data.lastName}}</td>
                                                    <td translate>{{data.typeExecutive}}</td>
                                                    <td>{{(data.updateDate * 1000) | date: 'dd/MM/yyyy'}}</td>
                                                    <td>
                                                        <a *ngIf="data.linkedin.length > 0" [href]="data.linkedin"
                                                            style="color: rgb(81, 184, 224);text-decoration: none;"><i
                                                                class="fab fa-linkedin mr-1"></i></a>
                                                        <span *ngIf="data.email.length > 0" pTooltip="{{data.email}}"
                                                            ngxClipboard [cbContent]="data.email"
                                                            class="mr-2 cursor-pointer" style="margin-left: 5px;"
                                                            (click)="openCopyMail()"><i
                                                                class="fas fa-envelope"></i></span>
                                                        <span *ngIf="data.cellPhone.length > 0"
                                                            pTooltip="{{data.cellPhonePrefix}} {{data.cellPhone}}"
                                                            ngxClipboard
                                                            cbContent="{{data.cellPhonePrefix}} {{data.cellPhone}}"
                                                            (click)="openCopyPhone()" class="cursor-pointer"
                                                            style="margin-left: 5px;"><i
                                                                class="fas fa-mobile"></i></span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>

                                    </div>
                                </div>
                            </p-scrollPanel>
                        </ng-container>
                        <ng-container *ngIf="tabSidebar_info === 'estado'">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                                <div class="grid m-2">
                                    <div class="col-12 p-0 flex-center-v">
                                        <h3 style="margin: 5px 0px;" translate>SHARED_INFORMATION</h3>
                                        <div class="title-flex">
                                            <span
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                                <span translate>FILLED</span>

                                            </span>
                                            <span>
                                                <i class="fas fa-minus"></i>
                                                <span translate>PARTIAL</span>
                                            </span>
                                            <span>
                                                <i class="pi pi-times"></i>
                                                <span translate>N_O</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-blue"> <span translate>BASIC_INFORMATION_U</span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {{ (10.5 + quantityLineBusiness + quantityExecutive +
                                                    quantityAccountBank) | number:'1.2-2'}}/35</span>
                                            </div>
                                            <div class="col-6 p-0">
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        GENERAL_DATA
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>10.5/10.50</span>
                                                        <span
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        BUSINESS_LINES
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityLineBusiness}}/10.50</span>
                                                        <span *ngIf="quantityLineBusiness === 10.5"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityLineBusiness < 10.5 && quantityLineBusiness > 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                        <span *ngIf="quantityLineBusiness === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 p-0 border-left">
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        COMPANY_EXECUTIVES
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityExecutive}}/8.75</span>
                                                        <span *ngIf="quantityExecutive === 8.75"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityExecutive < 8.75 && quantityExecutive > 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                        <span *ngIf="quantityExecutive === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        BANK_ACCOUNTS
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityAccountBank}}/5.25</span>
                                                        <span *ngIf="quantityAccountBank === 5.25"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityAccountBank < 5.25 && quantityAccountBank > 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                        <span *ngIf="quantityAccountBank === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-yellow"> <span translate
                                                        class="uppercase">SIDEBAR.COMMERCIAL_SOLIDITY</span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {{ (quantityLineProduct + quantityExperienceClients +
                                                    quantityInfraestructure +
                                                    quantityConditionTerms) | number:'1.2-2'}}/60</span>
                                            </div>
                                            <div class="col-6 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            PRODUCT_SERVICE_LINES
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityLineProduct}}/21</span>
                                                            <span *ngIf="quantityLineProduct === 21"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityLineProduct < 21 && quantityLineProduct > 0"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                            <span *ngIf="quantityLineProduct === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle uppercase">
                                                            {{ 'SIDEBAR.EXPERIENCE_CLIENTS' | translate}}
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityExperienceClients}}/21</span>
                                                            <span *ngIf="quantityExperienceClients === 21"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityExperienceClients < 21 && quantityExperienceClients > 0"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                            <span *ngIf="quantityExperienceClients === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-6 p-0 border-left">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            INFRASTRUCTURE
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityInfraestructure}}/15</span>
                                                            <span *ngIf="quantityInfraestructure === 15"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityInfraestructure < 15 && quantityInfraestructure > 0"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                            <span *ngIf="quantityInfraestructure === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            COMMERCIAL_TERMS
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityConditionTerms}}/3</span>
                                                            <span *ngIf="quantityConditionTerms === 3"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityConditionTerms < 3 && quantityConditionTerms > 0"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                            <span *ngIf="quantityConditionTerms === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 p-0" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-green"> <span translate
                                                        class="uppercase">SIDEBAR.BUSINESS_SOLIDITY</span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {{quantityQualityManagement | number:'1.2-2'}}/5</span>
                                            </div>
                                            <div class="col-6 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            QUALITY_MANAGEMENT
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityQualityManagement}}/5</span>
                                                            <span *ngIf="quantityQualityManagement === 5"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityQualityManagement < 5 && quantityQualityManagement > 0"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                            <span *ngIf="quantityQualityManagement === 0"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="pi pi-times"></i>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle uppercase" translate>
                                                            SIDEBAR.SAFETY
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            FINANCIAL_REFERENCES
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 p-0 border-left">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            ACCOUNTING_INFORMATION
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <h3 style="margin: 5px 0px;" translate>CLASSIFICATION_Kraljic</h3>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0 justify-content-center">
                                            <div class="col-12 box-principal">
                                                <div class="grid m-0">
                                                    <span class="float-span-kraljic-princ"> <span
                                                            translate>IMPACT</span> <br> <span
                                                            translate>FINANCIAL</span></span>
                                                    <span class="float-span-kraljic-botom"
                                                        translate>COMPLEXITY_SUPPLY</span>
                                                    <div class="col-12" style="padding-bottom: 0;">
                                                        <div class="grid m-0">
                                                            <span class="float-span-kraljic-top" translate>HIGH</span>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <div class="col-12 box-kraljic" translate
                                                                        [ngClass]="{'LEVERAGED': clasification === 'LEVERAGED'}"
                                                                        (click)="saveClassification('LEVERAGED')"
                                                                        *ngIf="!loadClassification">
                                                                        LEVERAGED
                                                                    </div>
                                                                    <div class="col-12 box-kraljic"
                                                                        style="cursor: initial;" translate
                                                                        [ngClass]="{'LEVERAGED': clasification === 'LEVERAGED'}"
                                                                        *ngIf="loadClassification">
                                                                        LEVERAGED
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <div class="col-12 box-kraljic" translate
                                                                        [ngClass]="{'STRATEGIC': clasification === 'STRATEGIC'}"
                                                                        (click)="saveClassification('STRATEGIC')"
                                                                        *ngIf="!loadClassification">
                                                                        STRATEGIC
                                                                    </div>
                                                                    <div class="col-12 box-kraljic"
                                                                        style="cursor: initial;" translate
                                                                        [ngClass]="{'STRATEGIC': clasification === 'STRATEGIC'}"
                                                                        *ngIf="loadClassification">
                                                                        STRATEGIC
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12" style="padding-top: 0;">
                                                        <div class="grid m-0">
                                                            <span class="float-span-kraljic-bottom"
                                                                translate>UNDER</span>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <span class="float-span-kraljic-botom-l"
                                                                        translate>UNDER</span>
                                                                    <div class="col-12 box-kraljic" translate
                                                                        [ngClass]="{'UNCRITICAL': clasification === 'UNCRITICAL'}"
                                                                        (click)="saveClassification('UNCRITICAL')"
                                                                        *ngIf="!loadClassification">
                                                                        UNCRITICAL
                                                                    </div>
                                                                    <div class="col-12 box-kraljic"
                                                                        style="cursor: initial;" translate
                                                                        [ngClass]="{'UNCRITICAL': clasification === 'UNCRITICAL'}"
                                                                        *ngIf="loadClassification">
                                                                        UNCRITICAL
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <span class="float-span-kraljic-botom-r"
                                                                        translate>HIGH</span>
                                                                    <div class="col-12 box-kraljic flex-wrap"
                                                                        [ngClass]="{'BOTTLENECK': clasification === 'BOTTLENECK'}"
                                                                        (click)="saveClassification('BOTTLENECK')"
                                                                        *ngIf="!loadClassification">
                                                                        <span translate>NECKS</span> <br> <span
                                                                            translate>BOTTLE</span>
                                                                    </div>
                                                                    <div class="col-12 box-kraljic flex-wrap"
                                                                        style="cursor: initial;"
                                                                        [ngClass]="{'BOTTLENECK': clasification === 'BOTTLENECK'}"
                                                                        *ngIf="loadClassification">
                                                                        <span translate>NECKS</span> <br> <span
                                                                            translate>BOTTLE</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0" style="margin-top: 64px;">
                                        <h3 style="margin: 5px 0px;" translate>STATUS</h3>
                                    </div>
                                    <div class="col-12 p-0 text-center">
                                        <div class="btn-apto" translate
                                            (click)="tab = 'SUITABLE'; resetform();"
                                            [ngClass]="{'SUITABLE': status === 'SUITABLE' , 'SELECTEDSUITABLE' : tab === 'SUITABLE'}">
                                            SUITABLE
                                        </div>

                                        <div class="btn-observado" translate
                                            (click)="tab = 'OBSERVED';resetform();"
                                            [ngClass]="{'OBSERVED': status === 'OBSERVED' , 'SELECTEDOBSERVED' : tab === 'OBSERVED'}">
                                            OBSERVED
                                        </div>

                                        <div class="btn-suspendido" translate
                                            (click)="tab = 'SUSPENDED';resetform();"
                                            [ngClass]="{'SUSPENDED': status === 'SUSPENDED' , 'SELECTEDSUSPENDED' : tab === 'SUSPENDED'}">
                                            SUSPENDED
                                        </div>

                                        <div class="btn-vetado" translate
                                            (click)="tab = 'BANNED';resetform();"
                                            [ngClass]="{'BANNED': status === 'BANNED' , 'SELECTEDBANNED' : tab === 'BANNED'}">
                                            BANNED
                                        </div>
                                    </div>
                                    <ng-container *ngIf="tab === 'SUITABLE' && tab != status">
                                        <form [formGroup]="formStatusSuitable" method="GET" class="w-full grid">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label translate>Comentario</label>:
                                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                                        formControlName="reason"
                                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuitable['reason'].errors }"></textarea>
                                                </div>
                                                <div class="field">
                                                    <label translate>references</label>:
                                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                                </div>
                                                <div class="field">
                                                    <label translate>notify</label>:
                                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0 text-right">
                                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                                    class="p-button-rounded w-button p-button-danger mr-2"
                                                    (click)="resetform(); cancelShow()"></button>
                                                <button [loading]="loadForm" pButton pRipple type="button"
                                                    label="{{'saveU' | translate}}"
                                                    class="p-button-rounded w-button p-button-success"
                                                    (click)="saveStatus('SUITABLE' , formStatusSuitable)"></button>
                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="tab === 'OBSERVED' && tab != status">
                                        <form [formGroup]="formStatusObserved" method="GET" class="w-full grid">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label translate>motive</label>: <span class="required">*</span>
                                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                                        formControlName="reason"
                                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlObserved['reason'].errors }"></textarea>
                                                </div>
                                                <div class="field">
                                                    <label translate>references</label>:
                                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                                </div>
                                                <div class="field">
                                                    <label translate>notify</label>:
                                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0 text-right">
                                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                                    class="p-button-rounded w-button p-button-danger mr-2"
                                                    (click)="resetform(); cancelShow()"></button>
                                                <button [loading]="loadForm" pButton pRipple type="button"
                                                    label="{{'saveU' | translate}}"
                                                    class="p-button-rounded w-button p-button-success"
                                                    (click)="saveStatus('OBSERVED' , formStatusObserved)"></button>
                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="tab === 'SUSPENDED' && tab != status">
                                        <form [formGroup]="formStatusSuspended" method="GET" class="w-full grid">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label translate>motive</label>: <span class="required">*</span>
                                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                                        formControlName="reason"
                                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuspended['reason'].errors }"></textarea>
                                                </div>
                                                <div class="field">
                                                    <label translate>references</label>:
                                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                                </div>
                                                <div class="field">
                                                    <label translate>notify</label>:
                                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                                </div>
                                                <div class="field">
                                                    <label translate>suspensio_duration</label> <span
                                                        class="required">*</span><br>
                                                    <p-calendar appendTo="body" [showIcon]="true" selectionMode="range"
                                                        formControlName="date" dateFormat="dd/mm/yy"
                                                        placeholder="dd/mm/yyyy"
                                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuspended['date'].errors }"></p-calendar>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0 text-right">
                                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                                    class="p-button-rounded w-button p-button-danger mr-2"
                                                    (click)="resetform();cancelShow()"></button>
                                                <button [loading]="loadForm" pButton pRipple type="button"
                                                    label="{{'saveU' | translate}}"
                                                    class="p-button-rounded w-button p-button-success"
                                                    (click)="saveStatus('SUSPENDED' , formStatusSuspended)"></button>
                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="tab === 'BANNED' && tab != status">
                                        <form [formGroup]="formStatusBaned" method="GET" class="w-full grid">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label translate>motive</label>: <span class="required">*</span>
                                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                                        formControlName="reason"
                                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlBaned['reason'].errors }"></textarea>
                                                </div>
                                                <div class="field">
                                                    <label translate>references</label>:
                                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                                </div>
                                                <div class="field">
                                                    <label translate>notify</label>:
                                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                                </div>
                                            </div>

                                            <div class="col-12 p-0 text-right">
                                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                                    class="p-button-rounded w-button p-button-danger mr-2"
                                                    (click)="resetform();cancelShow()"></button>
                                                <button [loading]="loadForm" pButton pRipple type="button"
                                                    label="{{'saveU' | translate}}"
                                                    class="p-button-rounded w-button p-button-success"
                                                    (click)="saveStatus('BANNED' , formStatusBaned)"></button>
                                            </div>
                                        </form>
                                    </ng-container>
                                    <div class="col-12 p-0">
                                        <app-log-kraljic [supplier]="supplier"
                                            [idCompany]="idCompany"></app-log-kraljic>
                                    </div>
                                </div>
                            </p-scrollPanel>
                        </ng-container>
                        <ng-container *ngIf="tabSidebar_info === 'policies'">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                                <div class="grid m-0">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>LIST_OF_AVAILABLE_POLICIES</h3>
                                    </div>
                                    <div class="col-12">
                                        <label class="block mb-2 font-bold" translate>policies-dashboard.GENERAL</label>
                                        <p-table [value]="dataPoliciesGeneral" [scrollable]="true" [loading]="loadPolicy">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                       policy
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                        company
                                                     </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        file
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                       comercial_activity
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        type
                                                    </th>
                                                    <th style="min-width: 50px; width: 50px;" translate>
                                                        acceptor
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        acceptance_date
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        creation_date
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-item>
                                                <tr>
                                                    <td>
                                                        
                                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                        
                                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>
                                                        {{item.policyName}}
                                                    </td>
                                                    <td>
                                                        {{item.business}}
                                                    </td>
                                                    <td>
                                                        
                                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                                            <p-overlayPanel #opFiles>
                                                                <ng-template pTemplate>
                                                                   <app-list-files [files]="item.files"></app-list-files>
                                                                </ng-template>
                                                            </p-overlayPanel>
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                                {{item['comercialActivity'][0]['name'+lang]}}
                                                            </ng-container>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                                -
                                                            </ng-container>
                                                            <ng-container *ngIf="!item['comercialActivity']">
                                                                -
                                                            </ng-container>   
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                        
                                                    </td>
                                                    <td translate>
                                                        {{item.policyType}}
                                                    </td>
                                                    <td>
                                                        @if(item['user']){
                                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                                        }@else{
                                                            -
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.acceptedDate">
                                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                                            </ng-container>
                                                            <ng-container *ngIf="!item.acceptedDate">
                                                                -
                                                             </ng-container>
                                                        
                                                    </td>
                                                    <td>
                                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="col-12">
                                        <label class="block mb-2 font-bold" translate>policies-dashboard.GOODS</label>
                                        <p-table [value]="dataPoliciesGood" [scrollable]="true" [loading]="loadPolicy">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                       policy
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                        company
                                                     </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        file
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                       comercial_activity
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        type
                                                    </th>
                                                    <th style="min-width: 50px; width: 50px;" translate>
                                                        acceptor
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        acceptance_date
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        creation_date
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-item>
                                                <tr>
                                                    <td>
                                                        
                                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                        
                                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>
                                                        {{item.policyName}}
                                                    </td>
                                                    <td>
                                                        {{item.business}}
                                                    </td>
                                                    <td>
                                                        
                                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                                            <p-overlayPanel #opFiles>
                                                                <ng-template pTemplate>
                                                                   <app-list-files [files]="item.files"></app-list-files>
                                                                </ng-template>
                                                            </p-overlayPanel>
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                                {{item['comercialActivity'][0]['name'+lang]}}
                                                            </ng-container>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                                -
                                                            </ng-container>
                                                            <ng-container *ngIf="!item['comercialActivity']">
                                                                -
                                                            </ng-container>   
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                        
                                                    </td>
                                                    <td translate>
                                                        {{item.policyType}}
                                                    </td>
                                                    <td>
                                                        @if(item['user']){
                                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                                        }@else{
                                                            -
                                                        }
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.acceptedDate">
                                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                                            </ng-container>
                                                            <ng-container *ngIf="!item.acceptedDate">
                                                                -
                                                             </ng-container>
                                                        
                                                    </td>
                                                    <td>
                                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="col-12">
                                        <label class="block mb-2 font-bold" translate>policies-dashboard.SERVICES</label>
                                        <p-table [value]="dataPoliciesServices" [scrollable]="true" [loading]="loadPolicy">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                       policy
                                                    </th>
                                                    <th style="min-width: 200px;" translate>
                                                        company
                                                     </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        file
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                       comercial_activity
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        status
                                                    </th>
                                                    <th style="min-width: 100px;" translate>
                                                        type
                                                    </th>
                                                    <th style="min-width: 50px; width: 50px;" translate>
                                                        acceptor
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        acceptance_date
                                                    </th>
                                                    <th style="min-width: 70px; width: 70px;" translate>
                                                        creation_date
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-item>
                                                <tr>
                                                    <td>
                                                        
                                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                        
                                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                                <i class="pi pi-check"></i>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>
                                                        {{item.policyName}}
                                                    </td>
                                                    <td>
                                                        {{item.business}}
                                                    </td>
                                                    <td>
                                                        
                                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                                            <p-overlayPanel #opFiles>
                                                                <ng-template pTemplate>
                                                                   <app-list-files [files]="item.files"></app-list-files>
                                                                </ng-template>
                                                            </p-overlayPanel>
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                                {{item['comercialActivity'][0]['name'+lang]}}
                                                            </ng-container>
                                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                                -
                                                            </ng-container>
                                                            <ng-container *ngIf="!item['comercialActivity']">
                                                                -
                                                            </ng-container>   
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                                            </ng-container>
                                                        
                                                    </td>
                                                    <td translate>
                                                        {{item.policyType}}
                                                    </td>
                                                    <td>
                                                        @if(item['user']){
                                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                                        }@else{
                                                            -
                                                        }
                                                    </td>
                                                    <td>
                                                        
                                                            <ng-container *ngIf="item.acceptedDate">
                                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                                            </ng-container>
                                                            <ng-container *ngIf="!item.acceptedDate">
                                                                -
                                                             </ng-container>
                                                        
                                                    </td>
                                                    <td>
                                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </p-scrollPanel>
                        </ng-container>
                        <ng-container *ngIf="tabSidebar_info === 'request'">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                                <div class="grid m-0">
                                    <div class="col-12">
                                        <label class="block mb-2 font-bold" translate>Formatos requeridos</label>

                                        @for (data of listRequestSupplier; track $index) {
                                            <div class="card shadow-5 mb-0 mx-0" >
                                                <div class="grid">
                                                    <div class="col-12 mb-2 py-0 flex align-items-center justify-content-between">
                                                        <b style="color: var(--indigo-400);">{{data.nameRequest}}</b>
                                                        <div class="flex align-items-center">
                                                            
                                                            
                                                            @if(data.files.length > 1){
                                                                <div class="flex align-items-center justify-content-center">
                                                                    <app-new-list-files [files]="data.files" [quantity]="0"></app-new-list-files>
                                                                </div>
                                                            }@else if(data.files.length === 1){
                                                                <div class="flex align-items-center justify-content-center">
                                                                    <app-new-list-files [files]="data.files" [quantity]="1"></app-new-list-files>
                                                                </div>
                                                            }@else{
                                                                
                                                            }
                                                        
                                                            <!-- } -->
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12 py-0">
                                                        <b>Documento</b>
                                                    </div> -->
                                                    <div class="col-12 py-0 flex align-items-center mb-2">
                                                        <b>Creado por:</b> 
                                                        <div class="ml-2 flex align-items-center">
                                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.dataCreator['avatar'] ? data.dataCreator['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.dataCreator['email']}}"/>
                                                            <span class="ml-2">{{data.dataCreator['name']}} {{data.dataCreator['lastName']}}</span>
                                                        </div>
                                                        
                                                    </div>
                                                    @if(data.status === 'OBSERVED' && data.observedBy){
                                                        <div class="col-12 mb-2 py-0 flex align-items-center mb-2">
                                                            <b>Observado por:</b> 
                                                            <div class="ml-2 flex align-items-center">
                                                                <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.observedBy['avatar'] ? data.observedBy['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.observedBy['email']}}"/>
                                                                <span class="ml-2">{{data.observedBy.name}} {{data.observedBy.lastName}}</span>
                                                            </div>
                                                            
                                                        </div>
                                                    }

                                                    @if(data.status === 'APPROVED' && data.approvedBy){
                                                        <div class="col-12 mb-2 py-0 flex align-items-center mb-2">
                                                            <b>Aprobado por:</b> 
                                                            <div class="ml-2 flex align-items-center">
                                                                <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.approvedBy['avatar'] ? data.approvedBy['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.approvedBy['email']}}"/>
                                                                <span class="ml-2">{{data.approvedBy.name}} {{data.approvedBy.lastName}}</span>
                                                            </div>
                                                            
                                                        </div>
                                                    }
                                                    
                                                    <div class="col-12 py-0 mb-2">
                                                        
                                                            <div class="mb-2">
                                                                <b class="mr-1">Fecha de creación:</b>{{data.createdDate * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}
                                                            </div>
                                                           <div class="mb-2">
                                                            @if(data.status){
                                                                <b class="mr-1">Estado:</b>
                                                                @if(data.status === 'SENT'){
                                                                    <b style="color: var(--yellow-500);">ENVIADO</b>
                                                                }
                                                                @if(data.status === 'PENDING'){
                                                                    <b style="color: var(--pink-500);">PENDIENTE</b>
                                                                }
                                                                @if(data.status === 'OBSERVED'){
                                                                    <b style="color: var(--bluegray-300);">OBSERVADO</b>
                                                                }
                                                                @if(data.status === 'APPROVED'){
                                                                    <b style="color: var(--green-500);">APROBADO</b>
                                                                }
                                                            }
                                                           </div>
                                                           @if(data.statusFlowDigital === 'CONFIRMED'){
                                                            <div class="mb-2">
                                                                <b class="mr-1">Visto bueno:</b><a class="mr-1" style="color: var(--primary-color);" href="mailto:{{data.emailInvitedFlowDigital}}">{{data.emailInvitedFlowDigital}}</a> - <span>{{data.confirmedDateFlowDigital * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}</span>
                                                              </div>
                                                           }
                                                           
                                                        
                                                        
                                                    </div>
                                                    <div class="col-12 text-right py-0">
                                                        <!-- <button pButton type="button" [ngClass]="{'p-button-outlined': selectedData}" class="w-button p-button-rounded p-button-success mr-2" label="EMPRESAS" (click)="selectedData = data.companies; sidebarCompanies = true;"></button>  -->
                                    
                                                        @if((data.observedReason && data.observedReason.length > 0) && data.status === 'OBSERVED'){
                                                            <button pButton (click)="op.toggle($event); tempData = data.observedReason" type="button" class="w-button p-button-rounded p-button-secondary mr-2 p-button-outlined" label="MOTIVO"></button> 
                                                        }
                                                        
                                                        <button pButton (click)="op.toggle($event); tempData = data.instructionsRequest" type="button" class="w-button p-button-rounded p-button-secondary p-button-outlined" label="INSTRUCCIONES"></button> 

                                                        @if(data.status === 'SENT'){
                                                            <button pButton type="button"  class="w-button p-button-rounded p-button-secondary ml-2" label="OBSERVAR" (click)="displayModalObs = true; tempId = data.id;"></button>

                                                            <button pButton type="button"  class="w-button p-button-rounded p-button-success ml-2" label="APROBAR" (click)="confirmApprove($event, data.id)" [loading]="loadRqListApproval"></button>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </p-scrollPanel>  
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-2 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12 text-right">
                                <button pButton pRipple type="button" [loading]="loadComplete" icon="fas fa-sync"style="float:right;" (click)="updateAll()" class="p-button-rounded p-button-secondary p-button-outlined ml-2" pTooltip="{{'updateU' | translate }}"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 165px)'}">
                            <div class="grid m-0">
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">
                                    <div class="text-center">
                                        <p class="mt-0 font-bold" translate>INFORMATION</p>
                                        <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{informationScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">

                                    <div class="text-center">
                                        <p class=" font-bold" translate>REPUTATION</p>
                                        <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{reputationScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">

                                    <div class="text-center">
                                        <p class=" font-bold" translate>TRUST</p>
                                        <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{trustScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-12 px-0 pb-0 flex align-items-center justify-content-between">
                <div>
                        <button (click)="tabDetail = false;tabSidebar_info = 'resumen';"
                            [ngClass]="{button_tab_selected: tabSidebar_info === 'resumen'}" pButton pRipple
                            type="button" label="{{ 'SUMMARY' | translate}}" [loading]="loadComplete"
                            class="w-button p-button-rounded p-button-secondary mr-2 p-button-outlined"></button>

                        <button (click)="tabSidebar_info = 'contacto'"
                            [ngClass]="{button_tab_selected: tabSidebar_info === 'contacto'}" pButton pRipple
                            type="button" label="{{ 'CONTACT' | translate}}" [loading]="loadComplete"
                            class="w-button p-button-rounded p-button-secondary mr-2 p-button-outlined"></button>

                        <button (click)="tabSidebar_info = 'estado'" [loading]="loadComplete"
                            [ngClass]="{button_tab_selected: tabSidebar_info === 'estado'}" pButton pRipple
                            type="button" label="{{ 'STATUS' | translate }}"
                            class="w-button p-button-rounded p-button-secondary p-button-outlined mr-2"></button>
                            @if(dataPolicies.length > 0){
                                <button (click)="tabSidebar_info = 'policies'" [loading]="loadComplete"
                                [ngClass]="{button_tab_selected: tabSidebar_info === 'policies'}" pButton pRipple
                                type="button" label="{{ 'SIDEBAR.POLICIES' | translate }}"
                                class="w-button p-button-rounded p-button-secondary p-button-outlined uppercase mr-2"></button>
                            }
                        
                            
                            @if(listRequestSupplier.length > 0){
                                <button (click)="tabSidebar_info = 'request'" [loading]="loadComplete"
                                [ngClass]="{button_tab_selected: tabSidebar_info === 'request'}" pButton pRipple
                                type="button" label="{{ 'FORMATOS' | translate }}"
                                class="w-button p-button-rounded p-button-secondary p-button-outlined uppercase"></button>
                            }
                </div>
                <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loadComplete"  class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
            </div>
        </ng-container>
    </div>
    <div class="grid flex md:hidden">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center"
                style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                <div class="col-12 flex align-items-cenyer justify-content-between">
                    <h2 class="m-0" translate>SUMMARY</h2>

                    <div *ngIf="tabSidebar_info === 'resumen'">
                        <ng-container *ngIf="!tabDetail">
                            <span class=" cursor-pointer" (click)="showDetail(); scroll(summary)"><i
                                    class="mr-3 fas fa-arrow-right"></i> <span translate>DETAIL</span></span>
                        </ng-container>
                        <ng-container *ngIf="tabDetail">
                            <span class="cursor-pointer" (click)="tabDetail = false; scroll(summary)"><i
                                    class="mr-3 fas fa-arrow-right"></i> <span translate>SUMMARY</span> </span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 164px)'}">
                <div  class="grid m-0">
                    <div class="col-12 text-center" *ngIf="profile">
                        <div class="container mx-2 shadow-5 mb-3" style="border-radius: 24px;">
                            <div class="card-profile" style="padding-top: 20px;">
                                <img *ngIf="profile.logo.length > 0" class="w-full px-3"
                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                    [src]="profile.logo" alt="">
                                <img *ngIf="profile.logo.length === 0" class="w-full px-3"
                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                    src="assets/images/no_logo.jpeg" alt="">
                                <div class="card-profile_user-stats relative">
                                    <div class="grid m-0">
                                        <div class="col-12" style="margin-top:10px">
                                            <div class="stats-holder">
                                                <div class="user-stats">
                                                    <div class="user-infos">
                                                        <span
                                                            class="infos_name">{{profile.businessName}}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="stats-holder flex align-items-center justify-content-center">
                                                    <img class="mr-3"
                                                        src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{profile.country | lowercase}}.png"
                                                        alt="" srcset="">
                                                    <p-tag [value]="profile.tradeName"
                                                        class="tag-info"></p-tag>
                                                    <span class="inline-block ml-3"
                                                        style="height: 24px;"
                                                        *ngIf="profile.homologation === 'YES'">
                                                        <app-animated-check>
                                                        </app-animated-check>
                                                    </span>
                                                </div>
                                                <div class="grid mt-2">
                                                    <div class="col-12">
                                                        <div
                                                            class="user-stats flex align-items-center justify-content-between">
                                                            <span>
                                                                <p-tag styleClass="ml-2 mr-2"
                                                                    severity="info"
                                                                    [value]="years"></p-tag>
                                                            </span>
                                                            <span>{{profile.codeTributary}}</span>
                                                            <span>
                                                                <button pButton pRipple type="button"
                                                                    icon="pi pi-paperclip"
                                                                    label="{{profile.files.length}}"
                                                                    class="p-button-rounded p-button-secondary mr-2 p-button-outlined"
                                                                    (click)="files = profile.files; opFiles.toggle($event)"></button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="user-stats">
                                                            <span>{{profile.direction}} <br>
                                                                {{profile.countryName}} -
                                                                {{profile.state}} - {{profile.city}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="user-stats">
                                                            <strong class="lowercase"
                                                                translate>CONTACT</strong>
                                                            <span>
                                                                <p class="m-0">
                                                                    <span class="mr-2 inline-block ">
                                                                        <i
                                                                            class="fas fa-mobile-alt mr-2"></i>
                                                                        <span
                                                                            class="">{{profile.prefixPhone}}
                                                                            {{profile.phone}}</span>
                                                                    </span>
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="user-stats">
                                                            <strong
                                                                translate>comercial_activity</strong>
                                                            <span class="text-center">
                                                                <p-tag
                                                                    *ngFor="let item of profile.comercialActivityList"
                                                                    styleClass="m-2" severity="success"
                                                                    [value]="item"></p-tag>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12"
                                                        *ngIf="profile.country === 'PE'">
                                                        <div class="user-stats">
                                                            <strong translate>taxConditions</strong>
                                                            <span class="text-center">
                                                                <p-tag
                                                                    *ngFor="let item of taxConditions"
                                                                    styleClass="m-2" severity="info"
                                                                    [value]="item.language_name"></p-tag>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="user-stats">
                                                            <strong translate>type_company</strong>
                                                            <span>{{typeCompany}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="user-stats">
                                                            <strong
                                                                translate>commercial_section</strong>
                                                            <span>{{profile.commercialSectionList}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="user-stats">
                                                            <div class="social">
                                                                <a *ngIf="profile.web.length > 0"
                                                                    target="_blank" [href]="profile.web"
                                                                    class="mr-4 ">
                                                                    <span
                                                                        class="fa fa-globe text-lg"></span>
                                                                </a>
                                                                <a *ngIf="profile.linkedin.length > 0"
                                                                    target="_blank"
                                                                    [href]="profile.linkedin"
                                                                    class="mr-4">
                                                                    <span
                                                                        class="fab fa-linkedin text-lg"></span>
                                                                </a>
                                                                <a *ngIf="profile.facebook.length > 0"
                                                                    target="_blank"
                                                                    [href]="profile.facebook" class="">
                                                                    <span
                                                                        class="fab fa-facebook text-lg"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12"
                                                        *ngIf="profile.brochure.length > 0 || profile.presentationLetter.length > 0">
                                                        <div class="grid"
                                                            style="border-top: 1px solid var(--surface-d); font-size: 11px;">
                                                            <div [ngClass]="{'border-set': profile.brochure.length > 0 && profile.presentationLetter.length > 0}"
                                                                *ngIf="profile.brochure.length > 0"
                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                style="     margin-top: 13px;"
                                                                (click)="files = profile.brochure;opFiles.toggle($event)">
                                                                <span class="uppercase"
                                                                    translate>brochure</span>
                                                                <span
                                                                    class="ml-2 pi pi-download"></span>
                                                            </div>
                                                            <div *ngIf="profile.presentationLetter.length > 0"
                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                style="    margin-top: 13px;"
                                                                (click)="files = profile.presentationLetter;opFiles.toggle($event)">
                                                                <span class="uppercase"
                                                                    translate>presentation</span><span
                                                                    class="ml-2 pi pi-download"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <div class="grid align-items-start m-0">
                            <div class="mb-4 col-4 flex align-items-center justify-content-center py-0">
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <p class="font-bold" translate>INFORMATION</p>
                                    <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic"
                                        style="font-size: 75px;">
                                        <span>{{informationScore}}</span>
                                        <div class="slice flex align-items-center justify-content-center">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 col-4 flex align-items-center justify-content-center py-0">
        
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <p class=" font-bold" translate>REPUTATION</p>
                                    <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic"
                                        style="font-size: 75px;">
                                        <span>{{reputationScore}}</span>
                                        <div class="slice flex align-items-center justify-content-center">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 col-4 flex align-items-center justify-content-center py-0">
        
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <p class=" font-bold" translate>TRUST</p>
                                    <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic"
                                        style="font-size: 75px;">
                                        <span>{{trustScore}}</span>
                                        <div class="slice flex align-items-center justify-content-center">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span #summary></span>
                    <ng-container *ngIf="!tabDetail">
                        <ng-container *ngIf="profile">
                            <div  class="col-12">
                                <h3 class="m-0" translate>company_summary</h3>
                            </div>
                            <div class="col-12">
                                <p class="m-0" *ngIf="profile.summary.length === 0" translate>
                                    no_data_available</p>
                                <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="accountingInformation">
                            <div class="col-12"
                                *ngIf="accountingInformation.accountingInformation.length > 0">
                                <h3 class="m-0" translate>financial_information</h3>
                            </div>
                            <div *ngIf="accountingInformation.accountingInformation.length > 0"
                                class="col-12">
                                <div *ngFor="let item of accountingInformation.accountingInformation; let i = index;" class="card shadow-5 m-0 flex align-items-center mb-3">
                                    <div class="grid m-0 w-full">
                                        <div class="col-12 md:col-11">
                                            <div class="grid">
                                                <div class="col-12 p-0">
                                                    <div class="grid m-0">
                                                        <div class="col-12 md:col-4 flex align-items-center ">
                                                            <p class="m-0"><i pTooltip="{{ 'accounting_information_period' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}} </p>
                                                        </div>
                                                        <div class="col-12 md:col-5 flex align-items-center">
                                                            <p class="m-0"><i pTooltip="{{ 'inventories_stocks' | translate }}"  style="font-size: 18px;" class="cursor-pointer fas fa-boxes mr-3"></i>USD {{item.accountingStockInventoryUSD | number:'1.2-2'}}
                                                            </p>
                                                        </div>
                                                        <div class="col-12 md:col-3">
                                                            <p class="m-0 flex align-items-center"><i pTooltip="{{ 'balance_sheet' | translate }}" style="font-size: 18px;"
                                                                    class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAb.toggle($event)" [disabled]="item.accountingBalanceSheet.length === 0" pButton pRipple type="button"
                                                                    label="{{item.accountingBalanceSheet.length}} - {{ 'BALANCE' | translate}}"
                                                                    class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                    <p-overlayPanel #opFilesAb>
                                                                        <ng-template pTemplate>
                                                                           <app-list-files [files]="item.accountingBalanceSheet"  (update)="item.accountingBalanceSheet = $event"></app-list-files>
                                                                        </ng-template>
                                                                    </p-overlayPanel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="grid m-0">
                                                        <div class="col-12 md:col-4 flex align-items-center ">
                                                            <p class="m-0"><i pTooltip="{{ 'share_capital' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-university mr-3"></i>USD {{item.accountingCapitalStockUSD | number:'1.2-2'}} </p>
                                                        </div>
                                                        <div class="col-12 md:col-5 flex align-items-center">
                                                            <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'machinery_equipment' | translate }}" class="cursor-pointer fas fa-cog mr-3"></i>USD {{item.accountingAssetsUSD | number:'1.2-2'}} </p>
                                                        </div>
                                                        <div class="col-12 md:col-3 flex align-items-center">
                                                            <p class="m-0 flex align-items-center"><i pTooltip="{{ 'profit_statement' | translate }}" style="font-size: 18px;"
                                                                    class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAc.toggle($event)" [disabled]="item.accountingProfitLossStatement.length === 0" pButton pRipple type="button"
                                                                    label="{{item.accountingProfitLossStatement.length}} - {{ 'STATE' | translate}}"
                                                                    class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                    <p-overlayPanel #opFilesAc>
                                                                        <ng-template pTemplate>
                                                                            <app-list-files [files]="item.accountingProfitLossStatement"  (update)="item.accountingProfitLossStatement = $event"></app-list-files>
                                                                        </ng-template>
                                                                    </p-overlayPanel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card shadow-5 m-0">
                                    <div class="grid">
                                        <div class="col-12">
                                            <h3 class="m-0" translate>annual_sales</h3>
                                        </div>
                                        <div class="col-12" style="overflow-x: auto;">
                                            <div class="grid m-0" style="width: 550px;">
                                                <div class="col-7 total-top flex align-items-center justify-content-center">
                                                <div class="detail-total">
                                                    <div class="row-range">
                                                        <span class="title-row" translate>sales_range</span>
                                                        <span *ngIf="formAmount > 0"
                                                            class="range-title">{{rangeSales}}</span>
                                                        <span *ngIf="formAmount <= 0" class="range-title"
                                                            translate>NO_SALES_RECORD</span>
                                                    </div>
                                                    <div class="row-range">
                                                        <span class="title-row"
                                                            translate>average_income</span>
                                                        <span *ngIf="formAmount > 0"
                                                            class="promedio-title">USD {{formAmount |
                                                            number:'1.2-2'}}</span>
                                                        <span *ngIf="formAmount <= 0"
                                                            class="promedio-title">USD -</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <div class="grid">
                                                    <div class="col-12 p-0">
                                                        <div class="grid m-0">
                                                            <div class="col-5"><span
                                                                    class="title-row2">{{yearNow}}</span>
                                                            </div>
                                                            <div class="col-7"><span class="title-row2"
                                                                    translate>sales</span></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear1}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount1USD |
                                                                    number:'1.2-2'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear2}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount2USD |
                                                                    number:'1.2-2'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear3}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount3USD |
                                                                    number:'1.2-2'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <h3 class="m-0" translate>infrastructure</h3>
                        </div>
                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                            <ng-container>
                                <div class="list-button-float" *ngIf="!listMap">
                                    <div class="circle-off"></div>
                                    <button style="height:40px; width:40px;  background: black;" pButton
                                        pRipple type="button" icon="fas fa-list-ul"
                                        class="p-button-rounded p-button-secondary "
                                        (click)="listMap = true"></button>
                                </div>
                                <div class="container-list-map animate__animated animate__slideInRight"
                                    *ngIf="listMap">
                                    <ng-container *ngIf="infraestructures.length === 0">
                                        <div class="grid m-0 w-full h-full justify-content-end">
                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                style="width: 50%;">
                                                <span
                                                    style="color: white; font-size: 18px; text-align: center;" translate>not_registered_infrastructures</span>
                                                <button pButton pRipple type="button"
                                                    label="{{'closeU' | translate}}"
                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                    (click)="closeListMap()"></button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="infraestructures.length > 0">
                                        <div class="grid m-0">
                                            <div class="col-12 text-right tex">
                                                <div class="circle"></div>
                                                <button
                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                    pButton pRipple type="button" icon="fas fa-times"
                                                    class="p-button-rounded p-button-danger"
                                                    (click)="closeListMap()">
                                                </button>
                                            </div>
                                            <div class="col-12">
                                                <p-scrollPanel
                                                    [style]="{width: '100%', height: '339px'}">
                                                    <ng-container *ngFor="let item of infraestructures">
                                                        <app-list-properties [type]="'SUMMARY'"
                                                            [item]="item"
                                                            (location)="openLocation(item)">
                                                        </app-list-properties>
                                                    </ng-container>
                                                </p-scrollPanel>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                <map-marker #somemarker="mapMarker"
                                    *ngFor="let item of infraestructures" [position]="item.position"
                                    (mapClick)="openInfo(somemarker, item)">
                                </map-marker>
                                <map-info-window>
                                    <ng-container *ngIf="temporalInfraestructure">
                                        <div style="width: 150px;     flex-direction: column;"
                                            class="flex align-items-center flex-wrap text-center ">
                                            <p class="m-0 text-blue-500 text-center">
                                                <strong
                                                    class="uppercase">{{temporalInfraestructure.name}}</strong>
                                            </p>
                                            <p class="m-0  text-center">
                                                {{temporalInfraestructure.nameType}} -
                                                {{temporalInfraestructure.area}}m2</p>
                                            <p class="m-0 text-center">
                                                <strong>{{temporalInfraestructure.address}}</strong>
                                            </p>
                                        </div>
                                    </ng-container>
                                </map-info-window>
                            </google-map>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>certifications</h3>
                        </div>
                        <div class="col-12">
                            <div class="row service-items">
                                <div class="col col-d-12 col-t-12 col-m-12 border-line-h p-0"
                                    style="display: flex;">
                                    <div *ngIf="certificateISO9001" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'ISO_9001' | translate }}" shape="circle"
                                                image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateGMP" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"
                                                shape="circle"
                                                image="assets/certificacion/2. GMP.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateBCR" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}"
                                                shape="circle"
                                                image="assets/certificacion/3. BRC.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateHACCP" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}"
                                                shape="circle"
                                                image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateBASC" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}"
                                                shape="circle"
                                                image="assets/certificacion/4. BASC.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateISO14001" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'ISO_14001' | translate }}" shape="circle"
                                                image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                        </div>
                                    </div>
                                    <div *ngIf="certificateISO18001" style="padding: 5px;">
                                        <div
                                            style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                            <p-avatar styleClass="cursor-pointer" tooltipPosition="left"
                                                pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}"
                                                shape="circle"
                                                image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>experience_main_clients</h3>
                        </div>
                        <div class="col-12">
                            <div *ngFor="let item of clients" class="card shadow-5 m-0 flex align-items-center mb-3" style="padding: 20px 12px;">
                                <div class="grid m-0 w-full relative">
                                    <img class="mr-3 absolute block md:hidden" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" style="right: -10px;
                                    top: -10px;" > 
                                    <div class="col-12 flex align-items-center">
                                        <h3 class="font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full m-0 text-center md:text-left"
                                            style="color: var(--primary-color);">
                                            <span pTooltip="{{item.businessName}}"
                                                tooltipPosition="top">{{item.businessName}}</span>
                                        </h3>
                                        <div class="hidden md:flex align-items-center">
                                            <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" >  
                                        <button style="float: right;"
                                                [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-paperclip"
                                                label="{{item.files.length}}" class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="opFiless.toggle($event)"></button>
                                            <p-overlayPanel #opFiless>
                                                <ng-template pTemplate>
                                                    <app-list-files [files]="item.files" type="EDIT" (update)="files = $event">
                                                    </app-list-files>
                                                </ng-template>
                                            </p-overlayPanel>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0 w-full">
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0" style="color: #689F38;">{{item.codeTributary}}</p>
                                            </div>
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'average_sales_last_year' | translate }}" class="cursor-pointer fas fa-dollar-sign mr-1 md:mr-3"></i>
                                                    {{getRango(item.averageSalesLastYear)}} </p>
                                            </div>
                                            <div class="col-12 md:col-3 ">
                                                <p class="m-0 md:flex align-items-center text-center"> <button style="width: 125.95px;" icon="fas fa-user-friends" pButton pRipple type="button"
                                                        label="{{ 'CONTACT' | translate }}" (click)="opContact.toggle($event)"
                                                        class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                        [disabled]="item.contacts.length === 0"></button></p>
                                                <p-overlayPanel #opContact>
                                                    <ng-template pTemplate>
                                                        <p-table [value]="item.contacts" [scrollable]="true" styleClass="table-list-contact" [rows]="2" [paginator]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th><span style="width: 100%;"></span> </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-left" style="width: 100%;">
                                                                            <div class="grid align-items-center shadow-5"
                                                                                style="width: 300px; border-radius: 24px;">
                                                                                <div class="col-3">
                                                                                    <p-avatar *ngIf="item.gender === 'M'"
                                                                                        image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                        size="xlarge" shape="circle"></p-avatar>
                                                                                    <p-avatar *ngIf="item.gender === 'F'"
                                                                                        image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                        size="xlarge" shape="circle"></p-avatar>
                                                                                </div>
                                                                                <div class="col-9">
                                                                                    <p>{{item.name}}</p>
                                                                                    <p>{{item.email}}</p>
                                                                                    <p>
                                                                                        <span class="mr-2 inline-block ">
                                                                                            <i class="fas fa-phone mr-2"></i>
                                                                                            <span>{{item.phone}}</span>
                                                                                        </span>
                                                                                        <span>
                                                                                           - #{{item.extensionPhone}}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0">
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center ">
                                                <p class="m-0" style="color: var(--bold-text);">{{item.sectorName}}</p>
                                            </div>
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0"><i style="font-size: 18px;"
                                                    pTooltip="{{ 'year_business_relationship' | translate }}"   class="cursor-pointer fas fa-history mr-1 md:mr-3"></i>{{item.YearCommercialRel}} </p>
                                            </div>
                                            <div class="col-12 md:col-3 md:flex align-items-center text-center">
                                                <p class="m-0 md:flex align-items-center text-center"><button style="width: 125.95px;" icon="pi pi-info-circle" pButton pRipple type="button"
                                                        label="{{ 'DETAIL' | translate }}" (click)="opDetail.toggle($event)"
                                                        class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                        [disabled]="item.detail.length === 0"></button></p>
                                                <p-overlayPanel #opDetail>
                                                    <ng-template pTemplate>
                                                        <div class="grid align-items-center text-center" style="width: 300px;">
                                                            <div class="col-12" style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                <div [innerHTML]="item.detail" >
                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>line_products_services</h3>
                        </div>
                        <div class="col-12">
                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                no_data_available</p>
                                <ng-container *ngIf="catalogues.length > 0">
                                    <div class="grid-system">
                                        <div class="item" *ngFor="let items of catalogues">
                                            <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                        </div>
                                    </div>
                                </ng-container>
                        </div>
                        <div class="col-12">
                            <div class="grid">
                                <div class="col-12">
                                    <h3 class="m-0" translate>credit_report</h3>
                                </div>
                                <div class="col-12">
                                    <ng-container *ngFor="let item of reportsFiles; let i = index">
                                        <div class="card shadow-5 p-3 flex align-items-center flex-wrap m-0  mb-2">
                                            <div class="grid w-full align-items-center m-0">
                                                <div class="w-full md:flex align-items-center justify-content-between">
                                                  <h3 class="w-full ml-2" style="color: var(--primary-color);">{{item.financialEntity}}</h3>  
                                                  <button style="margin-right: 0.35rem;" [disabled]="item.financialReferenceReportFile.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" label="{{item.financialReferenceReportFile.length}}" 
                                                    class="p-button-rounded p-button-secondary p-button-outlined desktop" (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>
                                                </div>
                                                <div class="col-12 md:col-5"><i style="font-size: 18px;" pTooltip="{{ 'credit_level_obtained' | translate }}" class="cursor-pointer fas fa-star-half-alt" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{item.financialReferenceCreditRating | number:'1.2-2'}}</span></div>
                                                <div class="col-12 md:col-5"><i  pTooltip="{{ 'report_date' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{(item.financialReferenceReportDate * 1000) | date:"dd/MM/yyyy"}}</span></div>
                                                
                                                <div class="col-12 md:col-2 text-center md:text-right">
                                                    <button style="margin-right: 0.35rem;" [disabled]="item.financialReferenceReportFile.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" label="{{item.financialReferenceReportFile.length}}" 
                                                    class="p-button-rounded p-button-secondary p-button-outlined mobile " (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="grid">
                                <div class="col-12">
                                    <h3 class="m-0" translate>credit_risk</h3>
                                </div>
                                <div class="col-12">
                                    <div *ngFor="let item of financialReference" class="card shadow-5 m-0 mb-3 ">
                                        <div class="md:flex align-items-center md:text-left text-center relative">
                                            <div class="card-img ">
                                                <p-avatar [image]="item.image" shape="circle" size="large" [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                            </div>
                                            <div class="card-etc ">
                                                <h3 style="color: var(--primary-color);" class="mb-1 font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full" ><span pTooltip="{{item.bank}}" tooltipPosition="top"> {{item.bank}} </span> </h3>
                                                
                                                <p class="mb-0 mt-1" ><b style="color:#8cc785">{{item.name}} </b></p>
                                                <p class="mb-0 mt-1" ><b style="color:#8cc785">{{item.email}}</b></p>
                                                <p class="mb-0 mt-1" style="font-size: 11px;"> <span translate>approvedLine</span>  ({{item.currency}}): {{item.approvedAmount | number:'1.2-2'}} </p>
                            
                                                <div class="mobile" style="width: 100%;">
                                                    <p class="mt-1" style="font-size: 11px; margin-bottom: 0;"> 
                                                        <span class="block mb-1">
                                                            <i class="fas fa-mobile-alt mr-2"></i>
                                                            <span>({{item.prefixCellphone}}) </span>
                                                            <span>{{item.cellphone}}</span>
                                                            
                                                        </span>
                                                        <span class="block">
                                                            <i  class="fas fa-phone mr-2"></i> 
                                                            <span>({{item.prefixPhone}})</span>
                                                            <span>{{item.phone}}</span>
                                                        </span> 
                                                        <span>#{{item.extension}}
                                                        </span> 
                                                    </p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div class="desktop" style="width: 100%;">
                                            <p class="mt-1" style="font-size: 11px;     margin-left: 3.5rem; margin-bottom: 0;"> 
                                                <span class="mr-2 inline-block">
                                                    <i class="fas fa-mobile-alt mr-2"></i>
                                                    <span>({{item.prefixCellphone}}) </span>
                                                    <span>{{item.cellphone}}</span>
                                                    
                                                </span>
                                                <span class="mr-2 inline-block">
                                                    <i  class="fas fa-phone mr-2"></i> 
                                                    <span>({{item.prefixPhone}})</span>
                                                    <span>{{item.phone}}</span>
                                                </span> 
                                                <span>#{{item.extension}}
                                                </span> 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tabDetail">
                        <ng-container *ngIf="profile">
                            <div class="col-12">
                                <h3 class="m-0" translate>company_summary</h3>
                            </div>
                            <div class="col-12">
                                <p class="m-0" *ngIf="profile.summary.length === 0" translate>
                                    no_data_available</p>
                                <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}
                                </p>
                            </div>
                            <div class="col-12">
                                <h3 class="m-0" translate>panelMission</h3>
                            </div>
                            <div class="col-12">
                                <p class="m-0" *ngIf="profile.mission.length === 0" translate>
                                    no_data_available</p>
                                <p class="m-0" *ngIf="profile.mission.length > 0">{{profile.mission}}
                                </p>
                            </div>
                            <div class="col-12">
                                <h3 class="m-0" translate>panelVision</h3>
                            </div>
                            <div class="col-12">
                                <p class="m-0" *ngIf="profile.vision.length === 0" translate>
                                    no_data_available</p>
                                <p class="m-0" *ngIf="profile.vision.length > 0">{{profile.vision}}</p>
                            </div>
                        </ng-container>

                        <div class="col-12">
                            <h3 class="m-0" translate>values</h3>
                        </div>
                        <div class="col-12">
                            <div id="chartdivprofilev1" style="text-align: center;      display: flex;   justify-content: center;
                                flex-flow: wrap;
                                align-content: center;">
                                <ng-container *ngFor="let item of dataGr">
                                    <p-tag styleClass="mr-2 mb-2" value="{{item}}"></p-tag>
                                </ng-container>

                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>business_lines</h3>
                        </div>
                        <div class="col-12">
                            <p-table [value]="categories" [paginator]="true" [scrollable]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width: 50px; width: 50px;"></th>
                                        <th style="min-width: 150px;" translate>segment</th>
                                        <th style="min-width: 150px;" translate>group</th>
                                        <th style="min-width: 150px;" translate>subgroup</th>
                                        <th style="min-width: 150px;" translate>category</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>
                                            <span class="icon-back" [style.background]="data['color']">
                                                <i class="{{data['icon']}}" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td>{{data.segment}}</td>
                                        <td>{{data.group}}</td>
                                        <td>{{data.subGroup}}</td>
                                        <td>{{data.category}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>UNSPSC</h3>
                        </div>
                        <div class="col-12">
                            <p-table [value]="categoriesUnspsc" [paginator]="true" [scrollable]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width: 150px;" translate>segment</th>
                                        <th style="min-width: 150px;" translate>group</th>
                                        <th style="min-width: 150px;" translate>subgroup</th>
                                        <th style="min-width: 150px;" translate>category</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>{{data.segment}}</td>
                                        <td>{{data.family}}</td>
                                        <td>{{data.group}}</td>
                                        <td>{{data.category}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>bank_information_s</h3>
                        </div>
                        <div class="col-12">
                            <div class="grid m-0">
                                <ng-container *ngFor="let item of accountBanks">
                                    <div class="col-12">
                                        <div class="card shadow-5 m-0">
                                            <div class="grid m-0 align-items-center card-primary">
                                                <div class="card-img">
                                                    <p-avatar [image]="item.image" shape="circle" size="large" [style]="{'height':'6rem', 'width': '6rem'}"></p-avatar>
                                                </div>
                                                <div class="flex align-content-start col">
                                                
                                                    <div class="card-etc ">
                                                        <h3 class="m-0 mb-2 font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full md:flex align-items-center justify-content-between" style="color: var(--primary-color);"> 
                                                            <span><span pTooltip="{{item.bank}}" tooltipPosition="top">{{item.bank}} - {{item.countryName}} - {{item.country}}</span></span>
                                                              </h3>
                                                        <span><span  class="md:mr-2 block md:inline-block" style="color: #689F38;"> <b class="block md:inline-block">N°:</b>  {{item.nBankAccount}}</span>  <span style="color: #689F38;" class="block md:inline-block" *ngIf="item.nInterbankAccount.length > 0"> <b class="block md:inline-block">CCI:</b> {{item.nInterbankAccount}}</span></span>
                                                        
                                                        <span class="block mt-2"> <span class="md:mr-2 block md:inline-block"><b style="color: var(--bold-text);" translate>type</b>:  {{item.type}} </span> <span class="md:mr-2 block md:inline-block"><b style="color: var(--bold-text);" translate>coin</b>:  {{item.currencyName}} ({{item.currency}})</span>  <span class="mb-0"><b style="color: var(--bold-text);">Swift/BIC:</b> {{item.SwiftCode}}</span> </span>
                                                        
                                                    </div>
                                                </div>
                                                <div class="button-container flex align-items-center justify-content-center md:flex-column md:align-items-end md:justify-content-end">
                                                <div class="flex align-items-center md:align-items-start">
                                                    <img class="mr-3 hidden md:inline-block" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="">  
                                                    <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" class="p-button-rounded p-button-secondary p-button-outlined mr-2 md:mr-0 md:mb-2" style="float: right;" (click)="files = item.files;opFiles.toggle($event)" label="{{item.files.length}}"></button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>line_products_services</h3>
                        </div>
                        <div class="col-12">
                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                no_data_available</p>
                                <ng-container *ngIf="catalogues.length > 0">
                                    <div class="grid-system">
                                        <div class="item" *ngFor="let items of catalogues">
                                            <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                        </div>
                                    </div>
                                </ng-container>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>commercial_references</h3>
                        </div>
                        <div class="col-12">
                            <div *ngFor="let item of clients" class="card shadow-5 m-0 flex align-items-center mb-3" style="padding: 20px 12px;">
                                <div class="grid m-0 w-full relative">
                                    <img class="mr-3 absolute block md:hidden" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" style="right: -10px;
                                    top: -10px;" > 
                                    <div class="col-12 flex align-items-center">
                                        <h3 class="font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full m-0 text-center md:text-left"
                                            style="color: var(--primary-color);">
                                            <span pTooltip="{{item.businessName}}"
                                                tooltipPosition="top">{{item.businessName}}</span>
                                        </h3>
                                        <div class="hidden md:flex align-items-center">
                                            <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" >  
                                        <button style="float: right;"
                                                [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-paperclip"
                                                label="{{item.files.length}}" class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="opFiless.toggle($event)"></button>
                                            <p-overlayPanel #opFiless>
                                                <ng-template pTemplate>
                                                    <app-list-files [files]="item.files" type="EDIT" (update)="files = $event">
                                                    </app-list-files>
                                                </ng-template>
                                            </p-overlayPanel>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0 w-full">
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0" style="color: #689F38;">{{item.codeTributary}}</p>
                                            </div>
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'average_sales_last_year' | translate }}" class="cursor-pointer fas fa-dollar-sign mr-1 md:mr-3"></i>
                                                    {{getRango(item.averageSalesLastYear)}} </p>
                                            </div>
                                            <div class="col-12 md:col-3 ">
                                                <p class="m-0 md:flex align-items-center text-center"> <button style="width: 125.95px;" icon="fas fa-user-friends" pButton pRipple type="button"
                                                        label="{{ 'CONTACT' | translate }}" (click)="opContact.toggle($event)"
                                                        class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                        [disabled]="item.contacts.length === 0"></button></p>
                                                <p-overlayPanel #opContact>
                                                    <ng-template pTemplate>
                                                        <p-table [value]="item.contacts" styleClass="table-list-contact" [rows]="2" [scrollable]="true" [paginator]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th><span style="width: 100%;"></span> </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-left" style="width: 100%;">
                                                                            <div class="grid align-items-center shadow-5"
                                                                                style="width: 300px; border-radius: 24px;">
                                                                                <div class="col-3">
                                                                                    <p-avatar *ngIf="item.gender === 'M'"
                                                                                        image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                        size="xlarge" shape="circle"></p-avatar>
                                                                                    <p-avatar *ngIf="item.gender === 'F'"
                                                                                        image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                        size="xlarge" shape="circle"></p-avatar>
                                                                                </div>
                                                                                <div class="col-9">
                                                                                    <p>{{item.name}}</p>
                                                                                    <p>{{item.email}}</p>
                                                                                    <p>
                                                                                        <span class="mr-2 inline-block ">
                                                                                            <i class="fas fa-phone mr-2"></i>
                                                                                            <span>{{item.phone}}</span>
                                                                                        </span>
                                                                                        <span>
                                                                                           - #{{item.extensionPhone}}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                            <div class="col-12 md:col-1 text-right  hidden md:block">
                                                <button pButton pRipple type="button"  icon="far fa-trash-alt" (click)="deletedClient(item)"
                                                    [disabled]="loadingDeleted"
                                                    class="p-button-rounded p-button-danger  p-button-outlined"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0">
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center ">
                                                <p class="m-0" style="color: var(--bold-text);">{{item.sectorName}}</p>
                                            </div>
                                            <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                <p class="m-0"><i style="font-size: 18px;"
                                                    pTooltip="{{ 'year_business_relationship' | translate }}"   class="cursor-pointer fas fa-history mr-1 md:mr-3"></i>{{item.YearCommercialRel}} </p>
                                            </div>
                                            <div class="col-12 md:col-3 md:flex align-items-center text-center">
                                                <p class="m-0 md:flex align-items-center text-center"><button style="width: 125.95px;" icon="pi pi-info-circle" pButton pRipple type="button"
                                                        label="{{ 'DETAIL' | translate }}" (click)="opDetail.toggle($event)"
                                                        class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                        [disabled]="item.detail.length === 0"></button></p>
                                                <p-overlayPanel #opDetail>
                                                    <ng-template pTemplate>
                                                        <div class="grid align-items-center text-center" style="width: 300px;">
                                                            <div class="col-12" style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                <div [innerHTML]="item.detail" >
                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                            <div class="col-12 md:col-1 text-right hidden md:block">
                                                <button pButton pRipple type="button" icon="pi pi-pencil" (click)="updateClient(item)"
                                                    class="p-button-rounded p-button-success  p-button-outlined"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>infrastructure</h3>
                        </div>
                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                            <ng-container>
                                <div class="list-button-float" *ngIf="!listMap">
                                    <div class="circle-off"></div>
                                    <button style="height:40px; width:40px;  background: black;" pButton
                                        pRipple type="button" icon="fas fa-list-ul"
                                        class="p-button-rounded p-button-secondary "
                                        (click)="listMap = true"></button>
                                </div>
                                <div class="container-list-map animate__animated animate__slideInRight"
                                    *ngIf="listMap">
                                    <ng-container *ngIf="infraestructures.length === 0">
                                        <div class="grid m-0 w-full h-full justify-content-end">
                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                style="width: 50%;">
                                                <span
                                                    style="color: white; font-size: 18px; text-align: center;"
                                                    translate>not_registered_infrastructures</span>
                                                <button pButton pRipple type="button"
                                                    label="{{ 'closeU' | translate }}"
                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                    (click)="closeListMap()"></button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="infraestructures.length > 0">
                                        <div class="grid m-0">
                                            <div class="col-12 text-right tex">
                                                <div class="circle"></div>
                                                <button
                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                    pButton pRipple type="button" icon="fas fa-times"
                                                    class="p-button-rounded p-button-danger"
                                                    (click)="closeListMap()">
                                                </button>
                                            </div>
                                            <div class="col-12">
                                                <p-scrollPanel
                                                    [style]="{width: '100%', height: '339px'}">
                                                    <ng-container *ngFor="let item of infraestructures">
                                                        <app-list-properties [type]="'SUMMARY'"
                                                            [item]="item"
                                                            (location)="openLocation(item)">
                                                        </app-list-properties>
                                                    </ng-container>
                                                </p-scrollPanel>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                <map-marker #somemarker="mapMarker"
                                    *ngFor="let item of infraestructures" [position]="item.position"
                                    (mapClick)="openInfo(somemarker, item)">
                                </map-marker>
                                <map-info-window>
                                    <ng-container *ngIf="temporalInfraestructure">
                                        <div style="width: 150px;     flex-direction: column;"
                                            class="flex align-items-center flex-wrap text-center ">
                                            <p class="m-0 text-blue-500 text-center">
                                                <strong
                                                    class="uppercase">{{temporalInfraestructure.name}}</strong>
                                            </p>
                                            <p class="m-0  text-center">
                                                {{temporalInfraestructure.nameType}} -
                                                {{temporalInfraestructure.area}}m2</p>
                                            <p class="m-0 text-center">
                                                <strong>{{temporalInfraestructure.address}}</strong>
                                            </p>
                                        </div>
                                    </ng-container>
                                </map-info-window>
                            </google-map>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>registered_vehicles</h3>
                        </div>
                        <div class="col-12">
                            <p-table [value]="transports" [paginator]="true" [scrollable]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width: 50px; width: 50px;"></th>
                                        <th style="min-width: 120px;" translate>type</th>
                                        <th style="min-width: 120px;" translate>vehicle_group</th>
                                        <th style="min-width: 120px;" translate>vehicle_category</th>
                                        <th style="min-width: 120px;" translate>vehicle_owner</th>
                                        <th style="min-width: 120px;" translate>license_plate</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>
                                            <img [src]="data.image" alt="" height="32">
                                        </td>
                                        <td>{{data.type}}</td>
                                        <td>{{data.group}}</td>
                                        <td>{{data.category}}</td>
                                        <td>{{data.typeOwner}}</td>
                                        <td>{{data.plate}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>detail_drivers</h3>
                        </div>
                        <div *ngFor="let item of drivers" class="col-12">
                            <div class="card shadow-5 m-0 flex align-items-center flex-column">
                                <div class="card-img " style="width: 3.5rem;">
                                    <p-avatar
                                        image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                        shape="circle" size="large"
                                        [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                </div>
                                <div class="card-etc text-center">
                                    <h3 style="color: #3bb1ff;"
                                        class="mb-1 font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full">
                                        <span pTooltip="{{item.name}}" tooltipPosition="top">
                                            {{item.name}}</span> </h3>

                                    <p class="mb-0 mt-1"><b style="color: var(--bold-text);"
                                            translate>driver_license </b>{{item.license}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>commercial_terms</h3>
                        </div>
                        <div class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid m-0">
                                    <div class="col-12 text-center"
                                        style="border-bottom: 1px solid var(--surface-d)">
                                        <p class="m-0" translate>way_to_pay</p>
                                    </div>
                                    <div class="col-12 p-0">
                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                            <div *ngFor="let item of payment"
                                                class="col-12 flex align-items-center">
                                                <div class="mr-3">
                                                    <p-avatar [image]="item.img" shape="circle"
                                                        size="large"
                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                </div>
                                                <div>
                                                    {{item.name}}
                                                </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid m-0">
                                    <div class="col-12 text-center"
                                        style="border-bottom: 1px solid var(--surface-d)">
                                        <p class="m-0" translate>warranty</p>
                                    </div>
                                    <div class="col-12 p-0">
                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                            <div *ngFor="let item of warranty"
                                                class="col-12 flex align-items-center">
                                                <div class="mr-3">
                                                    <p-avatar [image]="item.img" shape="circle"
                                                        size="large"
                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                </div>
                                                <div>{{item.name}} </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid m-0">
                                    <div class="col-12 text-center"
                                        style="border-bottom: 1px solid var(--surface-d)">
                                        <p class="m-0" translate>conditionTypeOfReceiptlabel</p>
                                    </div>
                                    <div class="col-12 p-0">
                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                            <div *ngFor="let item of warranty"
                                                class="col-12 flex align-items-center">
                                                <div class="mr-3">
                                                    <p-avatar [image]="item.img" shape="circle"
                                                        size="large"
                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                </div>
                                                <div>{{item.name}} </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid m-0">
                                    <div class="col-12 text-center"
                                        style="border-bottom: 1px solid var(--surface-d)">
                                        <p class="m-0" translate>place_of_delivery</p>
                                    </div>
                                    <div class="col-12 p-0">
                                        <p-scrollPanel [style]="{width: '100%', height: '175px'}">
                                            <div *ngFor="let item of receipt"
                                                class="col-12 flex align-items-center">
                                                <div class="mr-3">
                                                    <p-avatar [image]="item.img" shape="circle"
                                                        size="large"
                                                        [style]="{'height':'2rem', 'width': '2rem'}"></p-avatar>
                                                </div>
                                                <div>{{item.name}} </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <h3 class="m-0" translate>SIDEBAR.QUALITY</h3>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b
                                                    translate>one_quality_management_policies</b> <i
                                                    class="pl-2 fas fa-info-circle"
                                                    pTooltip="{{ 'control_improvement_TT' | translate}}"></i>
                                            </p>


                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.qualityManagementPolicies.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.qualityManagementPolicies.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.qualityManagementPolicies; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>ISO9001_certificate</b> <p-avatar
                                                    tooltipPosition="left"
                                                    pTooltip="{{ 'ISO_9001' | translate }}"
                                                    [style]="{height:'20px' , width: '20px'}" shape="circle"
                                                    image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.ISO9001Certificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.ISO9001Certificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.ISO9001Certificate;opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div class="w-full">
                                            <div class="formgrid grid">
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>registration_number</label>
                                                        <div class="col-12">
                                                            {{qualityManagement.ISO9001RegistrationNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>due_date</label>
                                                        <div class="col-12">
                                                            <ng-container
                                                                *ngIf="qualityManagement.ISO9001RegistrationDueDate">{{(qualityManagement.ISO9001RegistrationDueDate
                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>other_quality_certificates</h3>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>GMP_certified</b> <p-avatar
                                                    tooltipPosition="left" [style]="{height:'20px', width: '20px'}"
                                                    pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"
                                                    shape="circle"
                                                    image="assets/certificacion/2. GMP.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.GMPCertificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.GMPCertificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.GMPCertificate; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>BRC_certificate</b> <p-avatar
                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                    pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}"
                                                    shape="circle"
                                                    image="assets/certificacion/3. BRC.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.BRCCertificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.BRCCertificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.BRCCertificate;opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>HACCP_Certificate</b> <p-avatar
                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                    pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}"
                                                    shape="circle"
                                                    image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.HACCPCertificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.HACCPCertificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.HACCPCertificate;opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>BASC_certificate</b> <p-avatar
                                                    tooltipPosition="left" [style]="{height:'20px' , width: '20px'}"
                                                    pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}"
                                                    shape="circle"
                                                    image="assets/certificacion/4. BASC.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="qualityManagement.BASCCertificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{qualityManagement.BASCCertificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=qualityManagement.BASCCertificate;opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 flex align-items-center justify-content-between">
                                        <div class="w-full">
                                            <div class="formgrid grid">
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>registration_number</label>
                                                        <div class="col-12">
                                                            {{qualityManagement.BASCRegistrationNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>due_date</label>
                                                        <div class="col-12">
                                                            <ng-container
                                                                *ngIf="qualityManagement.BASCDueDate">{{(qualityManagement.BASCDueDate
                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <h3 class="m-0" translate>approvals</h3>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <ng-container *ngFor="let item of qualityManagement.homologation; let i = index">
                                <div class="col-12">
                                    <div class="grid card shadow-5 mt-1 text-center">
                                        <div class="col-12 md:flex align-items-center justify-content-between w-full px-0">
                                            <h3 style="color: var(--primary-color);" class="m-0">
                                                {{item.approvalCompany}}</h3>

                                            <button pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{item.files.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined hidden md:inline-block"
                                                (click)="opHomologationFileTemp.toggle($event)"></button>
                                        </div>
                                        <div class="col-12 md:col-5 p-0">

                                            <p style="color: #689F38;">{{item.requestingCompany}}</p>
                                            <p style="color: var(--bold-text);" class="mb-0">
                                                {{item.codeTributary}}</p>
                                        </div>
                                        <div class="col-12 md:col-5 p-0">
                                            <p class="m-0"> <i style="font-size: 18px;"
                                                    class="fas fa-star-half-alt mr-1 md:mr-3"></i>
                                                {{item.scoreObtained}} </p>
                                            <p> <i style="font-size: 18px;"
                                                    class="fas fa-calendar-check mr-1 md:mr-3"></i>
                                                {{item.expirationDate *
                                                1000 | date:'dd/MM/yyyy'}}</p>

                                            <p-overlayPanel #opHomologationFileTemp>
                                                <ng-template pTemplate>
                                                    
                                                        <app-list-files [files]="item.files"
                                                            (update)="item.files = $event">
                                                        </app-list-files>
                                                    
                                                </ng-template>
                                            </p-overlayPanel>
                                        </div>
                                        <div class="col-12 md:col-2 p-0 text-center md:text-right mt-2 md:mt-0">
                                            <button pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{item.files.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined inline-block md:hidden mr-2"
                                                (click)="opHomologationFileTemp.toggle($event)"></button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>QUALITY_MANAGEMENT_Q1</b>
                                            </p>
                                        </div>
                                        <div style="padding-right: 2.5%;" >
                                            <span>{{qualityManagement.deliveryCertifiedQualityWarranty ?
                                                'SI' : 'NO'}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>QUALITY_MANAGEMENT_Q2</b></p>
                                        </div>
                                        <div style="padding-right: 2.5%;" >
                                            <span>{{qualityManagement.manufacturerDeliverQualityDossier
                                                ? 'SI' : 'NO'}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>QUALITY_MANAGEMENT_Q3</b></p>
                                        </div>
                                        <div style="padding-right: 2.5%;" >
                                            <span>{{qualityManagement.outsourceProcessesActivities ?
                                                'SI' : 'NO'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="qualityManagement" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>outsourced_processes_activities</h3>
                                    </div>
                                    <div class="col-12">
                                        <p-scrollPanel [style]="{width: '100%', height: '162px'}">
                                            <ng-container
                                                *ngFor="let item of qualityManagement.processActivity; let i = index">
                                                <div
                                                    class="mx-1 mb-2 px-3 py-2 card flex align-items-center justify-content-between shadow-5">
                                                    <p>{{item}}</p>
                                                </div>
                                            </ng-container>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="securityHealthWork" class="col-12">
                            <h3 class="m-0" translate>occupational_policies</h3>
                        </div>
                        <div *ngIf="securityHealthWork" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>occupational_health_safety</b></p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="securityHealthWork.occupationalHealthSafetyPolicies.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{securityHealthWork.occupationalHealthSafetyPolicies.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=securityHealthWork.occupationalHealthSafetyPolicies; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>civil_defense_certificate</b></p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="securityHealthWork.civilDefenseCertificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{securityHealthWork.civilDefenseCertificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=securityHealthWork.civilDefenseCertificate; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>occupational_committee</b></p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="securityHealthWork.occupationalHealthSafetyCommittee.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{securityHealthWork.occupationalHealthSafetyCommittee.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=securityHealthWork.occupationalHealthSafetyCommittee; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div class="w-full">
                                            <div class="formgrid grid">
                                                <div class="field col m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>name_supervisor</label>
                                                        <div class="col-12">
                                                            {{securityHealthWork.supervisorName}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>OSHA_certified</b>
                                                <p-avatar [style]="{height:'20px' , width: '20px'}"
                                                    styleClass="cursor-pointer" tooltipPosition="left"
                                                    pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}"
                                                    shape="circle"
                                                    image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="securityHealthWork.OSHA18001Certificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{securityHealthWork.OSHA18001Certificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=securityHealthWork.OSHA18001Certificate; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div class="w-full">
                                            <div class="formgrid grid">
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>registration_number</label>
                                                        <div class="col-12">
                                                            {{securityHealthWork.OSHA18001RegistrationNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>due_date</label>
                                                        <div class="col-12">
                                                            <ng-container
                                                                *ngIf="securityHealthWork.OSHA18001DueDate">{{(securityHealthWork.OSHA18001DueDate
                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="securityHealthWork" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>detailed_security_statistics</h3>
                                    </div>
                                    <div class="col-12 ">
                                        <p-scrollPanel [style]="{width: '100%', height: '303px'}">
                                            <div class="grid">
                                                <div class="col-12 text-center">
                                                    <div class="col-12 flex p-0 pb-2">
                                                        <div class="width-list-s-r">
                                                            <div class="col-12">
                                                                <i class="fas fa-pen-square"
                                                                    style="font-size: 32px;"></i>
                                                            </div>
                                                            <div class="col-12"><span
                                                                    translate>register_SECURITY_AND_HEALTH_AT_WORK_MANAGEMENT</span>
                                                            </div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-calendar-alt"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>year</span></div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-stopwatch-20"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>nacc</span></div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-user-clock"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>IR</span></div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-user-injured"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>SR</span></div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-hospital-user"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>LTCR</span></div>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <div class="col-12"><i
                                                                    class="fas fa-user-graduate"
                                                                    style="font-size: 32px;"></i></div>
                                                            <div class="col-12"><span
                                                                    translate>STR</span></div>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let item of securityHealthWork.securityRecords; let i = index"
                                                        class=" col-12 p-0 pb-2 mb-2 flex">
                                                        <div class="width-list-s-r"><span>{{i+1}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securityYearRegistry}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securityStatisticsHealthRegistry}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securityFrequentIndexIF}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securitySeverityIndexIS}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securityAccidentRateAI}}</span>
                                                        </div>
                                                        <div class="width-list-s-r">
                                                            <span>{{item.securityTrainingIndexIC}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                            <h3 class="m-0" translate>social_responsibility</h3>
                        </div>
                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>responsibility_policies</h3>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>environmental_management_policies</b></p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="responsabilitySocialEnvironmental.environmentalManagementPolicies.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{responsabilitySocialEnvironmental.environmentalManagementPolicies.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=responsabilitySocialEnvironmental.environmentalManagementPolicies; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p><b translate>solid_waste_management_plan</b></p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="responsabilitySocialEnvironmental.solidWasteManagementPlan.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{responsabilitySocialEnvironmental.solidWasteManagementPlan.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=responsabilitySocialEnvironmental.solidWasteManagementPlan; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div>
                                            <p class="m-0 flex align-items-center"><b class="mr-3"
                                                    translate>ISO45001_certificate</b> <p-avatar
                                                    tooltipPosition="left"
                                                    pTooltip="{{ 'ISO_14001' | translate }}"
                                                    shape="circle" [style]="{height:'20px' , width: '20px'}"
                                                    image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                [disabled]="responsabilitySocialEnvironmental.ISO14001Certificate.length === 0"
                                                pButton pRipple type="button" icon="pi pi-paperclip"
                                                label="{{responsabilitySocialEnvironmental.ISO14001Certificate.length}}"
                                                class="p-button-rounded p-button-secondary p-button-outlined"
                                                (click)="files=responsabilitySocialEnvironmental.ISO14001Certificate; opFiles.toggle($event)"></button>
                                        </div>
                                    </div>
                                    <div class="col-12 md:flex align-items-center justify-content-between">
                                        <div class="w-full">
                                            <div class="formgrid grid">
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>registration_number</label>
                                                        <div class="col-12">
                                                            {{responsabilitySocialEnvironmental.ISO14001RegistrationNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field col-12 m-0">
                                                    <div class="field grid m-0">
                                                        <label class="col-12 mb-0"
                                                            translate>due_date</label>
                                                        <div class="col-12">
                                                            <ng-container
                                                                *ngIf="responsabilitySocialEnvironmental.ISO14001DueDate">{{(responsabilitySocialEnvironmental.ISO14001DueDate
                                                                * 1000) | date:'dd/MM/yyyy'}}</ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>ethics_governance</h3>
                                    </div>
                                    <div class="col-12">
                                        <p-scrollPanel [style]="{width: '100%', height: '457px'}">
                                            <div class="grid m-0">
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.washed">
                                                    <p class="m-0 flex align-items-center">
                                                        <i class="pi pi-check-circle" style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q2</span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.washed">
                                                    <p>{{responsabilitySocialEnvironmental.washedText}}</p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.conduct">
                                                    <p class="m-0 flex align-items-center">
                                                        <i  class="pi pi-check-circle" style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q3</span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.conduct">
                                                    <p>{{responsabilitySocialEnvironmental.conductText}}</p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.security">
                                                    <p class="m-0 flex align-items-center">
                                                        <i  class="pi pi-check-circle" style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q4
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.security">
                                                    <p>{{responsabilitySocialEnvironmental.securityText}}</p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.confidentiality">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q5
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.confidentiality">
                                                    <p>{{responsabilitySocialEnvironmental.confidentialityText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.standards">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q6
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.standards">
                                                    <p>{{responsabilitySocialEnvironmental.standardsText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.complaint">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q7
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.complaint">
                                                    <p>{{responsabilitySocialEnvironmental.complaintText}}
                                                    </p>
                                                </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="responsabilitySocialEnvironmental" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>labor_standards</h3>
                                    </div>
                                    <div class="col-12">
                                        <p-scrollPanel [style]="{width: '100%', height: '457px'}">
                                            <div class="grid m-0">
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.personal">
                                                    <p class="m-0 flex align-items-center">
                                                        <i class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2"
                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q8
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.personal">
                                                    <p>{{responsabilitySocialEnvironmental.personalText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.payment">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2"
                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q9
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.payment">
                                                    <p>{{responsabilitySocialEnvironmental.paymentText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.regulation">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q10
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.regulation">
                                                    <p>{{responsabilitySocialEnvironmental.regulationText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.laborUnion">
                                                    <p class="m-0 flex align-items-center">
                                                        <i class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2"
                                                            translate>ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q11
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.laborUnion">
                                                    <p>{{responsabilitySocialEnvironmental.laborUnionText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.judgment">
                                                    <p class="m-0 flex align-items-center">
                                                        <i class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q12
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.judgment">
                                                    <p>{{responsabilitySocialEnvironmental.judgmentText}}
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.minors">
                                                    <p class="m-0 flex align-items-center">
                                                        <i 
                                                            class="pi pi-check-circle"
                                                            style="color: #37e32b;"></i>
                                                        <span class="ml-2" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT_Q13
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-12" *ngIf="responsabilitySocialEnvironmental.minors">
                                                    <p>{{responsabilitySocialEnvironmental.minorsText}}
                                                    </p>
                                                </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="accountingInformation" class="col-12">
                            <h3 class="m-0" translate>accounting_information_details</h3>
                        </div>
                        <div *ngIf="accountingInformation" class="col-12">
                            <div *ngFor="let item of accountingInformation.accountingInformation; let i = index;" class="card shadow-5 m-0 flex align-items-center mb-3">
                                <div class="grid m-0 w-full">
                                    <div class="col-12 md:col-11">
                                        <div class="grid">
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 md:col-4 flex align-items-center ">
                                                        <p class="m-0"><i pTooltip="{{ 'accounting_information_period' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}} </p>
                                                    </div>
                                                    <div class="col-12 md:col-5 flex align-items-center">
                                                        <p class="m-0"><i pTooltip="{{ 'inventories_stocks' | translate }}"  style="font-size: 18px;" class="cursor-pointer fas fa-boxes mr-3"></i>USD {{item.accountingStockInventoryUSD | number:'1.2-2'}}
                                                        </p>
                                                    </div>
                                                    <div class="col-12 md:col-3">
                                                        <p class="m-0 flex align-items-center"><i pTooltip="{{ 'balance_sheet' | translate }}" style="font-size: 18px;"
                                                                class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAb.toggle($event)" [disabled]="item.accountingBalanceSheet.length === 0" pButton pRipple type="button"
                                                                label="{{item.accountingBalanceSheet.length}} - {{ 'BALANCE' | translate}}"
                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                <p-overlayPanel #opFilesAb>
                                                                    <ng-template pTemplate>
                                                                       <app-list-files [files]="item.accountingBalanceSheet"  (update)="item.accountingBalanceSheet = $event"></app-list-files>
                                                                    </ng-template>
                                                                </p-overlayPanel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 md:col-4 flex align-items-center ">
                                                        <p class="m-0"><i pTooltip="{{ 'share_capital' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-university mr-3"></i>USD {{item.accountingCapitalStockUSD | number:'1.2-2'}} </p>
                                                    </div>
                                                    <div class="col-12 md:col-5 flex align-items-center">
                                                        <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'machinery_equipment' | translate }}" class="cursor-pointer fas fa-cog mr-3"></i>USD {{item.accountingAssetsUSD | number:'1.2-2'}} </p>
                                                    </div>
                                                    <div class="col-12 md:col-3 flex align-items-center">
                                                        <p class="m-0 flex align-items-center"><i pTooltip="{{ 'profit_statement' | translate }}" style="font-size: 18px;"
                                                                class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAc.toggle($event)" [disabled]="item.accountingProfitLossStatement.length === 0" pButton pRipple type="button"
                                                                label="{{item.accountingProfitLossStatement.length}} - {{ 'STATE' | translate}}"
                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                <p-overlayPanel #opFilesAc>
                                                                    <ng-template pTemplate>
                                                                        <app-list-files [files]="item.accountingProfitLossStatement"  (update)="item.accountingProfitLossStatement = $event"></app-list-files>
                                                                    </ng-template>
                                                                </p-overlayPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="accountingInformation" class="col-12">
                            <div class="card shadow-5 m-0">
                                <div class="grid">
                                    <div class="col-12">
                                        <h3 class="m-0" translate>annual_sales</h3>
                                    </div>
                                    <div class="col-12" style="overflow: auto;">
                                        <div class="grid" style="width: 650px;">
                                            <div class="col-7 total-top flex align-items-center justify-content-center">
                                                <div class="detail-total">
                                                    <div class="row-range">
                                                        <span class="title-row" translate>sales_range</span>
                                                        <span *ngIf="formAmount > 0"
                                                            class="range-title">{{rangeSales}}</span>
                                                        <span *ngIf="formAmount <= 0" class="range-title"
                                                            translate>NO_SALES_RECORD</span>
                                                    </div>
                                                    <div class="row-range">
                                                        <span class="title-row" translate>average_income</span>
                                                        <span *ngIf="formAmount > 0" class="promedio-title">USD
                                                            {{formAmount | number:'1.2-2'}}</span>
                                                        <span *ngIf="formAmount <= 0" class="promedio-title">USD
                                                            -</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <div class="grid">
                                                    <div class="col-12 p-0">
                                                        <div class="grid m-0">
                                                            <div class="col-5"><span
                                                                    class="title-row2">{{yearNow}}</span></div>
                                                            <div class="col-7"><span class="title-row2"
                                                                    translate>sales</span></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear1}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount1USD | number:'1.2-2'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear2}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount2USD | number:'1.2-2'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="grid">
                                                            <div class="col-5">
                                                                <div class="center-text-flex">
                                                                    {{annualSaleYear3}}</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="center-text-flex">USD
                                                                    {{annualSaleAmount3USD | number:'1.2-2'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="grid">
                                <div class="col-12">
                                    <h3 class="m-0 flex align-items-center" translate>credit_report
                                        <p-avatar tooltipPosition="left"
                                            pTooltip="{{ 'CREDIT_REPORT' | translate }}" shape="circle"
                                            [style]="{height:'20px' , width: '20px'}"
                                            image="assets/certificacion/10. REPORT FIN.svg"></p-avatar>
                                    </h3>
                                </div>
                                <div class="col-12">
                                    <ng-container *ngFor="let item of reportsFiles; let i = index">
                                        <div class="card shadow-5 p-3 flex align-items-center flex-wrap m-0 m-2 mb-0">
                                            <div class="grid w-full align-items-center m-0">
                                                <div class="w-full md:flex align-items-center justify-content-between">
                                                  <h3 class="w-full ml-2" style="color: var(--primary-color);">{{item.financialEntity}}</h3>  
                                                  <button style="margin-right: 0.35rem;" [disabled]="item.financialReferenceReportFile.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" label="{{item.financialReferenceReportFile.length}}" 
                                                    class="p-button-rounded p-button-secondary p-button-outlined desktop" (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>
                                                </div>
                                                <div class="col-12 md:col-5"><i style="font-size: 18px;" pTooltip="{{ 'credit_level_obtained' | translate }}" class="cursor-pointer fas fa-star-half-alt" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{item.financialReferenceCreditRating | number:'1.2-2'}}</span></div>
                                                <div class="col-12 md:col-5"><i  pTooltip="{{ 'report_date' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{(item.financialReferenceReportDate * 1000) | date:"dd/MM/yyyy"}}</span></div>
                                                
                                                <div class="col-12 md:col-2 text-center md:text-right">
                                                    <button style="margin-right: 0.35rem;" [disabled]="item.financialReferenceReportFile.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" label="{{item.financialReferenceReportFile.length}}" 
                                                    class="p-button-rounded p-button-secondary p-button-outlined mobile " (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="grid">
                                <div class="col-12">
                                    <h3 class="m-0" translate>credit_risk </h3>
                                </div>
                                <div class="col-12">
                                    <div *ngFor="let item of financialReference" class="card shadow-5 m-0 mb-3 ">
                                        <div class="md:flex align-items-center md:text-left text-center relative">
                                            <div class="card-img ">
                                                <p-avatar [image]="item.image" shape="circle" size="large" [style]="{'height':'3rem', 'width': '3rem'}"></p-avatar>
                                            </div>
                                            <div class="card-etc ">
                                                <h3 style="color: var(--primary-color);" class="mb-1 font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full" ><span pTooltip="{{item.bank}}" tooltipPosition="top"> {{item.bank}} </span> </h3>
                                                
                                                <p class="mb-0 mt-1" ><b style="color:#8cc785">{{item.name}} </b></p>
                                                <p class="mb-0 mt-1" ><b style="color:#8cc785">{{item.email}}</b></p>
                                                <p class="mb-0 mt-1" style="font-size: 11px;"> <span translate>approvedLine</span>  ({{item.currency}}): {{item.approvedAmount | number:'1.2-2'}} </p>
                            
                                                <div class="mobile" style="width: 100%;">
                                                    <p class="mt-1" style="font-size: 11px; margin-bottom: 0;"> 
                                                        <span class="block mb-1">
                                                            <i class="fas fa-mobile-alt mr-2"></i>
                                                            <span>({{item.prefixCellphone}}) </span>
                                                            <span>{{item.cellphone}}</span>
                                                            
                                                        </span>
                                                        <span class="block">
                                                            <i  class="fas fa-phone mr-2"></i> 
                                                            <span>({{item.prefixPhone}})</span>
                                                            <span>{{item.phone}}</span>
                                                        </span> 
                                                        <span>#{{item.extension}}
                                                        </span> 
                                                    </p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div class="desktop" style="width: 100%;">
                                            <p class="mt-1" style="font-size: 11px;     margin-left: 3.5rem; margin-bottom: 0;"> 
                                                <span class="mr-2 inline-block">
                                                    <i class="fas fa-mobile-alt mr-2"></i>
                                                    <span>({{item.prefixCellphone}}) </span>
                                                    <span>{{item.cellphone}}</span>
                                                    
                                                </span>
                                                <span class="mr-2 inline-block">
                                                    <i  class="fas fa-phone mr-2"></i> 
                                                    <span>({{item.prefixPhone}})</span>
                                                    <span>{{item.phone}}</span>
                                                </span> 
                                                <span>#{{item.extension}}
                                                </span> 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12 flex align-items-center justify-content-between">
                        <h3 class="m-0" translate>executives_available </h3>
                        <button pButton pRipple type="button" icon="fas fa-copy"
                            pTooltip="{{ 'copy_emails' | translate }}"
                            class="p-button-rounded p-button-secondary"
                            [disabled]="selectedContact.length === 0" (click)="copyAll()"></button>
                    </div>
                    <div class="col-12">
                        <p-table [value]="executives" [scrollable]="true" [paginator]="true" [rows]="20" scrollHeight="calc(100vh - 308px)" [(selection)]="selectedContact">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 50px; width: 50px;">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                                    <th style="min-width: 50px; width: 50px;"></th>
                                    <th style="min-width: 120px;" translate>executives</th>
                                    <th style="min-width: 120px;" translate>position_create</th>
                                    <th style="min-width: 120px;" translate>update_date</th>
                                    <th style="min-width: 80px;"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="data"></p-tableCheckbox>
                                    </td>
                                    <td>
                                        <p-avatar
                                            [image]="data.avatar ? data.avatar : 'assets/images/user.svg'"
                                            shape="circle" size="large"
                                            [style]="{'height':'25px', 'width': '25px'}"></p-avatar>
                                    </td>
                                    <td>{{data.name}} {{data.lastName}}</td>
                                    <td translate>{{data.typeExecutive}}</td>
                                    <td>{{(data.updateDate * 1000) | date:'dd/MM/yyyy'}}</td>
                                    <td>
                                        <a *ngIf="data.linkedin.length > 0" [href]="data.linkedin"
                                            style="color: rgb(81, 184, 224);text-decoration: none;"><i
                                                class="fab fa-linkedin mr-1"></i></a>
                                        <span *ngIf="data.email.length > 0" pTooltip="{{data.email}}"
                                            ngxClipboard [cbContent]="data.email"
                                            class="mr-2 cursor-pointer" style="margin-left: 5px;"
                                            (click)="openCopyMail()"><i
                                                class="fas fa-envelope"></i></span>
                                        <span *ngIf="data.cellPhone.length > 0"
                                            pTooltip="{{data.cellPhonePrefix}} {{data.cellPhone}}"
                                            ngxClipboard
                                            cbContent="{{data.cellPhonePrefix}} {{data.cellPhone}}"
                                            (click)="openCopyPhone()" class="cursor-pointer"
                                            style="margin-left: 5px;"><i
                                                class="fas fa-mobile"></i></span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                    <div class="col-12 flex-center-v">
                        <h3 style="margin: 5px 0px;" translate>SHARED_INFORMATION</h3>
                        <div>
                            <span class="flex align-items-center mb-2">
                                <div class="mr-2" style="height: 25px;">
                                    <app-animated-check></app-animated-check>
                                </div>
                                <span translate>FILLED</span>
                            </span>
                            <span class="flex align-items-center mb-2">
                                <i class="fas fa-minus mr-2" style="width: 25px; text-align: center;"></i>
                                <span translate>PARTIAL</span>
                            </span>
                            <span class="flex align-items-center mb-2">
                                <i class="pi pi-times mr-2" style="width: 25px; text-align: center;"></i>
                                <span translate>N_O</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12" style="font-weight: bold;">
                        <div class="grid m-0">
                            <div class="col-12 detail-title">
                                <span class="border-blue" style="width: 100%; padding: 5px 0px;"> <span translate>BASIC_INFORMATION_U</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {{ (10.5 + quantityLineBusiness + quantityExecutive +
                                    quantityAccountBank) | number:'1.2-2'}}/35</span>
                            </div>
                            <div class="col-12 p-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle" translate>
                                        GENERAL_DATA
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>10.5/10.50</span>
                                        <span
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle" translate>
                                        BUSINESS_LINES
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityLineBusiness}}/10.50</span>
                                        <span *ngIf="quantityLineBusiness === 10.5"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityLineBusiness < 10.5"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle" translate>
                                        COMPANY_EXECUTIVES
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityExecutive}}/8.75</span>
                                        <span *ngIf="quantityExecutive === 8.75"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityExecutive < 8.75"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle" translate>
                                        BANK_ACCOUNTS
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityAccountBank}}/5.25</span>
                                        <span *ngIf="quantityAccountBank === 5.25"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityAccountBank < 5.25"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="font-weight: bold;">
                        <div class="grid m-0">
                            <div class="col-12 detail-title">
                                <span class="border-yellow" style="width: 100%; padding: 5px 0px;"> <span translate
                                        class="uppercase">SIDEBAR.COMMERCIAL_SOLIDITY</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {{ (quantityLineProduct + quantityExperienceClients +
                                    quantityInfraestructure +
                                    quantityConditionTerms) | number:'1.2-2'}}/60</span>
                            </div>
                            <div class="col-12 p-0">
                                <div class="grid m-0">
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            PRODUCT_SERVICE_LINES
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span>{{quantityLineProduct}}/21</span>
                                            <span *ngIf="quantityLineProduct === 21"
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                            <span *ngIf="quantityLineProduct < 21"
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle uppercase">
                                            {{ 'SIDEBAR.EXPERIENCE_CLIENTS' | translate}}
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span>{{quantityExperienceClients}}/21</span>
                                            <span *ngIf="quantityExperienceClients === 21"
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                            <span *ngIf="quantityExperienceClients < 21"
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 p-0">
                                <div class="grid m-0">
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            INFRASTRUCTURE
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span>{{quantityInfraestructure}}/15</span>
                                            <span *ngIf="quantityInfraestructure === 15"
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                            <span *ngIf="quantityInfraestructure < 15"
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            COMMERCIAL_TERMS
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span>{{quantityConditionTerms}}/3</span>
                                            <span *ngIf="quantityConditionTerms === 3"
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                            <span *ngIf="quantityConditionTerms < 3"
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-12" style="font-weight: bold;">
                        <div class="grid m-0">
                            <div class="col-12 detail-title">
                                <span class="border-green" style="width: 100%; padding: 5px 0px;"> <span translate
                                        class="uppercase">SIDEBAR.BUSINESS_SOLIDITY</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {{quantityQualityManagement | number:'1.2-2'}}/5</span>
                            </div>
                            <div class="col-12 p-0">
                                <div class="grid m-0">
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            QUALITY_MANAGEMENT
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span>{{quantityQualityManagement}}/5</span>
                                            <span *ngIf="quantityQualityManagement === 5"
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                            <span *ngIf="quantityQualityManagement < 5"
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle uppercase" translate>
                                            SIDEBAR.SAFETY
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span></span>
                                            <span
                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                <i class="fas fa-minus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            FINANCIAL_REFERENCES
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span></span>
                                            <span
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="grid m-0">
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span></span>
                                            <span
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="detail-subtitle" translate>
                                            ACCOUNTING_INFORMATION
                                        </div>
                                        <div class="detail-counter-icon">
                                            <span></span>
                                            <span
                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <h3 style="margin: 5px 0px;" translate>CLASSIFICATION_Kraljic</h3>
                    </div>
                    <div class="col-12">
                        <div class="grid m-0 justify-content-center">
                            <div class="col-12 box-principal">
                                <div class="grid m-0">
                                    <span class="float-span-kraljic-princ"> <span
                                            translate>IMPACT</span> <br> <span
                                            translate>FINANCIAL</span></span>
                                    <span class="float-span-kraljic-botom"
                                        translate>COMPLEXITY_SUPPLY</span>
                                    <div class="col-12" style="padding-bottom: 0;">
                                        <div class="grid m-0">
                                            <span class="float-span-kraljic-top" translate>HIGH</span>
                                            <div class="col-6">
                                                <div class="grid m-0">
                                                    <div class="col-12 box-kraljic" translate
                                                        [ngClass]="{'LEVERAGED': clasification === 'LEVERAGED'}"
                                                        (click)="saveClassification('LEVERAGED')"
                                                        *ngIf="!loadClassification">
                                                        LEVERAGED
                                                    </div>
                                                    <div class="col-12 box-kraljic"
                                                        style="cursor: initial;" translate
                                                        [ngClass]="{'LEVERAGED': clasification === 'LEVERAGED'}"
                                                        *ngIf="loadClassification">
                                                        LEVERAGED
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-6">
                                                <div class="grid m-0">
                                                    <div class="col-12 box-kraljic" translate
                                                        [ngClass]="{'STRATEGIC': clasification === 'STRATEGIC'}"
                                                        (click)="saveClassification('STRATEGIC')"
                                                        *ngIf="!loadClassification">
                                                        STRATEGIC
                                                    </div>
                                                    <div class="col-12 box-kraljic"
                                                        style="cursor: initial;" translate
                                                        [ngClass]="{'STRATEGIC': clasification === 'STRATEGIC'}"
                                                        *ngIf="loadClassification">
                                                        STRATEGIC
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" style="padding-top: 0;">
                                        <div class="grid m-0">
                                            <span class="float-span-kraljic-bottom"
                                                translate>UNDER</span>
                                            <div class="col-6">
                                                <div class="grid m-0">
                                                    <span class="float-span-kraljic-botom-l"
                                                        translate>UNDER</span>
                                                    <div class="col-12 box-kraljic" translate
                                                        [ngClass]="{'UNCRITICAL': clasification === 'UNCRITICAL'}"
                                                        (click)="saveClassification('UNCRITICAL')"
                                                        *ngIf="!loadClassification">
                                                        UNCRITICAL
                                                    </div>
                                                    <div class="col-12 box-kraljic"
                                                        style="cursor: initial;" translate
                                                        [ngClass]="{'UNCRITICAL': clasification === 'UNCRITICAL'}"
                                                        *ngIf="loadClassification">
                                                        UNCRITICAL
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="grid m-0">
                                                    <span class="float-span-kraljic-botom-r"
                                                        translate>HIGH</span>
                                                    <div class="col-12 box-kraljic flex-wrap"
                                                        [ngClass]="{'BOTTLENECK': clasification === 'BOTTLENECK'}"
                                                        (click)="saveClassification('BOTTLENECK')"
                                                        *ngIf="!loadClassification">
                                                        <span translate>NECKS</span> <br> <span
                                                            translate>BOTTLE</span>
                                                    </div>
                                                    <div class="col-12 box-kraljic flex-wrap"
                                                        style="cursor: initial;"
                                                        [ngClass]="{'BOTTLENECK': clasification === 'BOTTLENECK'}"
                                                        *ngIf="loadClassification">
                                                        <span translate>NECKS</span> <br> <span
                                                            translate>BOTTLE</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-top: 30px;">
                        <h3 style="margin: 5px 0px;" translate>STATUS</h3>
                    </div>
                    <div class="col-12 p-0 text-center">
                        <div class="btn-apto" translate
                            (click)="tab = 'SUITABLE'; resetform();"
                            [ngClass]="{'SUITABLE': status === 'SUITABLE' , 'SELECTEDSUITABLE' : tab === 'SUITABLE'}">
                            SUITABLE
                        </div>

                        <div class="btn-observado" translate
                            (click)="tab = 'OBSERVED';resetform();"
                            [ngClass]="{'OBSERVED': status === 'OBSERVED' , 'SELECTEDOBSERVED' : tab === 'OBSERVED'}">
                            OBSERVED
                        </div>

                        <div class="btn-suspendido" translate
                            (click)="tab = 'SUSPENDED';resetform();"
                            [ngClass]="{'SUSPENDED': status === 'SUSPENDED' , 'SELECTEDSUSPENDED' : tab === 'SUSPENDED'}">
                            SUSPENDED
                        </div>

                        <div class="btn-vetado" translate
                            (click)="tab = 'BANNED';resetform();"
                            [ngClass]="{'BANNED': status === 'BANNED' , 'SELECTEDBANNED' : tab === 'BANNED'}">
                            BANNED
                        </div>
                    </div>
                    <ng-container *ngIf="tab === 'SUITABLE' && tab != status">
                        <form [formGroup]="formStatusSuitable" method="GET" class="w-full grid">
                            <div class="col-12">
                                <div class="field">
                                    <label translate>Comentario</label>:
                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                        formControlName="reason"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuitable['reason'].errors }"></textarea>
                                </div>
                                <div class="field">
                                    <label translate>references</label>:
                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                </div>
                                <div class="field">
                                    <label translate>notify</label>:
                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                </div>
                            </div>
                            <div class="col-12 p-0 text-right">
                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                    class="p-button-rounded w-button p-button-danger mr-2"
                                    (click)="resetform(); cancelShow()"></button>
                                <button [loading]="loadForm" pButton pRipple type="button"
                                    label="{{'saveU' | translate}}"
                                    class="p-button-rounded w-button p-button-success"
                                    (click)="saveStatus('SUITABLE' , formStatusSuitable)"></button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="tab === 'OBSERVED' && tab != status">
                        <form [formGroup]="formStatusObserved" method="GET" class="w-full grid">
                            <div class="col-12">
                                <div class="field">
                                    <label translate>motive</label>: <span class="required">*</span>
                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                        formControlName="reason"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlObserved['reason'].errors }"></textarea>
                                </div>
                                <div class="field">
                                    <label translate>references</label>:
                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                </div>
                                <div class="field">
                                    <label translate>notify</label>:
                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                </div>
                            </div>
                            <div class="col-12 p-0 text-right">
                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                    class="p-button-rounded w-button p-button-danger mr-2"
                                    (click)="resetform(); cancelShow()"></button>
                                <button [loading]="loadForm" pButton pRipple type="button"
                                    label="{{'saveU' | translate}}"
                                    class="p-button-rounded w-button p-button-success"
                                    (click)="saveStatus('OBSERVED' , formStatusObserved)"></button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="tab === 'SUSPENDED' && tab != status">
                        <form [formGroup]="formStatusSuspended" method="GET" class="w-full grid">
                            <div class="col-12">
                                <div class="field">
                                    <label translate>motive</label>: <span class="required">*</span>
                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                        formControlName="reason"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuspended['reason'].errors }"></textarea>
                                </div>
                                <div class="field">
                                    <label translate>references</label>:
                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                </div>
                                <div class="field">
                                    <label translate>notify</label>:
                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                </div>
                                <div class="field">
                                    <label translate>suspensio_duration</label> <span
                                        class="required">*</span><br>
                                    <p-calendar appendTo="body" [showIcon]="true" selectionMode="range"
                                        formControlName="date" dateFormat="dd/mm/yy"
                                        placeholder="dd/mm/yyyy"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlSuspended['date'].errors }"></p-calendar>
                                </div>
                            </div>
                            <div class="col-12 p-0 text-right">
                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                    class="p-button-rounded w-button p-button-danger mr-2"
                                    (click)="resetform();cancelShow()"></button>
                                <button [loading]="loadForm" pButton pRipple type="button"
                                    label="{{'saveU' | translate}}"
                                    class="p-button-rounded w-button p-button-success"
                                    (click)="saveStatus('SUSPENDED' , formStatusSuspended)"></button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="tab === 'BANNED' && tab != status">
                        <form [formGroup]="formStatusBaned" method="GET" class="w-full grid">
                            <div class="col-12">
                                <div class="field">
                                    <label translate>motive</label>: <span class="required">*</span>
                                    <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="true"
                                        formControlName="reason"
                                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && controlBaned['reason'].errors }"></textarea>
                                </div>
                                <div class="field">
                                    <label translate>references</label>:
                                    <p-chips styleClass="w-full" formControlName="references" [addOnBlur]="true"></p-chips>
                                </div>
                                <div class="field">
                                    <label translate>notify</label>:
                                    <p-chips styleClass="w-full" formControlName="emails" [addOnBlur]="true"
                                        (onAdd)="validateEmailsList(tab)"></p-chips>
                                </div>
                            </div>

                            <div class="col-12 p-0 text-right">
                                <button pButton pRipple type="button" label="{{'cancelU' | translate}}"
                                    class="p-button-rounded w-button p-button-danger mr-2"
                                    (click)="resetform();cancelShow()"></button>
                                <button [loading]="loadForm" pButton pRipple type="button"
                                    label="{{'saveU' | translate}}"
                                    class="p-button-rounded w-button p-button-success"
                                    (click)="saveStatus('BANNED' , formStatusBaned)"></button>
                            </div>
                        </form>
                    </ng-container>
                    <div class="col-12 mt-3">
                        <h3 style="margin: 5px 0px;" translate>ACTIVITY_HISTORY</h3>
                    </div>
                    <div class="col-12 md:mt-3">
                        <app-log-kraljic [supplier]="supplier"
                            [idCompany]="idCompany"></app-log-kraljic>
                    </div>

                    <div class="col-12">
                        <h3 class="m-0" translate>LIST_OF_AVAILABLE_POLICIES</h3>
                    </div>
                    <div class="col-12">
                        <label class="block mb-2 font-bold" translate>policies-dashboard.GENERAL</label>
                        <p-table [value]="dataPoliciesGeneral" [scrollable]="true" [loading]="loadPolicy">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                       policy
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                        company
                                     </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        file
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                       comercial_activity
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        type
                                    </th>
                                    <th style="min-width: 50px; width: 50px;" translate>
                                        acceptor
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        acceptance_date
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        creation_date
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>
                                        
                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                        
                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                                        
                                    </td>
                                    <td>
                                        {{item.policyName}}
                                    </td>
                                    <td>
                                        {{item.business}}
                                    </td>
                                    <td>
                                        
                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                            <p-overlayPanel #opFiles>
                                                <ng-template pTemplate>
                                                   <app-list-files [files]="item.files"></app-list-files>
                                                </ng-template>
                                            </p-overlayPanel>
                                        
                                    </td>
                                    <td>
                                        
                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                {{item['comercialActivity'][0]['name'+lang]}}
                                            </ng-container>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                -
                                            </ng-container>
                                            <ng-container *ngIf="!item['comercialActivity']">
                                                -
                                            </ng-container>   
                                        
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                            </ng-container>
                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                            </ng-container>
                                        
                                    </td>
                                    <td translate>
                                        {{item.policyType}}
                                    </td>
                                    <td>
                                        @if(item['user']){
                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                        }@else{
                                            -
                                        }
                                        
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.acceptedDate">
                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                            </ng-container>
                                            <ng-container *ngIf="!item.acceptedDate">
                                                -
                                             </ng-container>
                                        
                                    </td>
                                    <td>
                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="col-12">
                        <label class="block mb-2 font-bold" translate>policies-dashboard.GOODS</label>
                        <p-table [value]="dataPoliciesGood" [scrollable]="true" [loading]="loadPolicy">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                       policy
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                        company
                                     </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        file
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                       comercial_activity
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        type
                                    </th>
                                    <th style="min-width: 50px; width: 50px;" translate>
                                        acceptor
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        acceptance_date
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        creation_date
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>
                                        
                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                        
                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                                        
                                    </td>
                                    <td>
                                        {{item.policyName}}
                                    </td>
                                    <td>
                                        {{item.business}}
                                    </td>
                                    <td>
                                        
                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                            <p-overlayPanel #opFiles>
                                                <ng-template pTemplate>
                                                   <app-list-files [files]="item.files"></app-list-files>
                                                </ng-template>
                                            </p-overlayPanel>
                                        
                                    </td>
                                    <td>
                                        
                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                {{item['comercialActivity'][0]['name'+lang]}}
                                            </ng-container>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                -
                                            </ng-container>
                                            <ng-container *ngIf="!item['comercialActivity']">
                                                -
                                            </ng-container>   
                                        
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                            </ng-container>
                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                            </ng-container>
                                        
                                    </td>
                                    <td translate>
                                        {{item.policyType}}
                                    </td>
                                    <td>
                                        @if(item['user']){
                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                        }@else{
                                            -
                                        }
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.acceptedDate">
                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                            </ng-container>
                                            <ng-container *ngIf="!item.acceptedDate">
                                                -
                                             </ng-container>
                                        
                                    </td>
                                    <td>
                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="col-12">
                        <label class="block mb-2 font-bold" translate>policies-dashboard.SERVICES</label>
                        <p-table [value]="dataPoliciesServices" [scrollable]="true" [loading]="loadPolicy">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                       policy
                                    </th>
                                    <th style="min-width: 200px;" translate>
                                        company
                                     </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        file
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                       comercial_activity
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        status
                                    </th>
                                    <th style="min-width: 100px;" translate>
                                        type
                                    </th>
                                    <th style="min-width: 50px; width: 50px;" translate>
                                        acceptor
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        acceptance_date
                                    </th>
                                    <th style="min-width: 70px; width: 70px;" translate>
                                        creation_date
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>
                                        
                                            <span *ngIf="item.status === 'ACCEPTED'" style="background: #59c429;" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                        
                                            <span *ngIf="item.status !== 'ACCEPTED'" class="check-policies cursor-pointer">
                                                <i class="pi pi-check"></i>
                                            </span>
                                        
                                    </td>
                                    <td>
                                        {{item.policyName}}
                                    </td>
                                    <td>
                                        {{item.business}}
                                    </td>
                                    <td>
                                        
                                            <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-download"  (click)="opFiles.toggle($event)"  class="p-button-rounded p-button-secondary p-button-outlined"></button>
                                            <p-overlayPanel #opFiles>
                                                <ng-template pTemplate>
                                                   <app-list-files [files]="item.files"></app-list-files>
                                                </ng-template>
                                            </p-overlayPanel>
                                        
                                    </td>
                                    <td>
                                        
                                            <button *ngIf="item['comercialActivity'] && item['comercialActivity'].length > 1" pButton pRipple
                                                style="font-size: 10.5px;" type="button" label="VARIOS"
                                                class="p-button-rounded p-button-raised p-button-secondary p-button-text"
                                                (click)="tempDataOp = item['comercialActivity']; opInfo.toggle($event)"></button>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 1">
                                                {{item['comercialActivity'][0]['name'+lang]}}
                                            </ng-container>
                                            <ng-container *ngIf="item['comercialActivity'] && item['comercialActivity'].length === 0">
                                                -
                                            </ng-container>
                                            <ng-container *ngIf="!item['comercialActivity']">
                                                -
                                            </ng-container>   
                                        
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.status === 'PENDING'">
                                                <b style="color: var(--pink-500);" translate>{{item.status}}</b>
                                            </ng-container>
                                            <ng-container *ngIf="item.status === 'ACCEPTED'">
                                                <b style="color: var(--green-400);" translate>{{item.status}}</b>
                                            </ng-container>
                                        
                                    </td>
                                    <td translate>
                                        {{item.policyType}}
                                    </td>
                                    <td>
                                        @if(item['user']){
                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="item['user']['avatar'] ? item['user']['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{item['user']['name']}} {{item['user']['lastName']}}">
                                        }@else{
                                            -
                                        }
                                    </td>
                                    <td>
                                        
                                            <ng-container *ngIf="item.acceptedDate">
                                               {{item.acceptedDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}  
                                            </ng-container>
                                            <ng-container *ngIf="!item.acceptedDate">
                                                -
                                             </ng-container>
                                        
                                    </td>
                                    <td>
                                       {{item.createdDate * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="col-12">
                        <label class="block mb-2 font-bold" translate>Formatos requeridos</label>

                        @for (data of listRequestSupplier; track $index) {
                            <div class="card shadow-5 mb-0 mx-0" >
                                <div class="grid">
                                    <div class="col-12 mb-2 py-0 flex align-items-center justify-content-between">
                                        <b style="color: var(--indigo-400);">{{data.nameRequest}}</b>
                                        <div class="flex align-items-center">
                                            
                                            
                                            @if(data.files.length > 1){
                                                <div class="flex align-items-center justify-content-center">
                                                    <app-new-list-files [files]="data.files" [quantity]="0"></app-new-list-files>
                                                </div>
                                            }@else if(data.files.length === 1){
                                                <div class="flex align-items-center justify-content-center">
                                                    <app-new-list-files [files]="data.files" [quantity]="1"></app-new-list-files>
                                                </div>
                                            }@else{
                                                
                                            }
                                        
                                            <!-- } -->
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 py-0">
                                        <b>Documento</b>
                                    </div> -->
                                    <!-- <div class="col-12 py-0 flex align-items-center">
                                        <b>Creado por:</b> 
                                        <div class="ml-2 flex align-items-center">
                                            <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.userCreator['avatar'] ? data.userCreator['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.userCreator['email']}}"/>
                                            <span>{{data.userCreator.name}} {{data.userCreator.lastName}}</span>
                                        </div>
                                        
                                    </div> -->
                                    @if(data.status === 'OBSERVED' && data.observedBy){
                                        <div class="col-12 mb-2 py-0 flex align-items-center">
                                            <b>Observado por:</b> 
                                            <div class="ml-2 flex align-items-center">
                                                <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.observedBy['avatar'] ? data.observedBy['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.observedBy['email']}}"/>
                                                <span>{{data.observedBy.name}} {{data.observedBy.lastName}}</span>
                                            </div>
                                            
                                        </div>
                                    }

                                    @if(data.status === 'APPROVED' && data.approvedBy){
                                        <div class="col-12 mb-2 py-0 flex align-items-center">
                                            <b>Aprobado por:</b> 
                                            <div class="ml-2 flex align-items-center">
                                                <img style="border-radius: 50%; width: 2rem; height: 2rem;" [src]="data.approvedBy['avatar'] ? data.approvedBy['avatar'] : 'assets/images/user.svg' " alt="" srcset="" tooltipPosition="left" pTooltip="{{data.approvedBy['email']}}"/>
                                                <span class="ml-2">{{data.approvedBy.name}} {{data.approvedBy.lastName}}</span>
                                            </div>
                                            
                                        </div>
                                    }
                                    
                                    <div class="col-12 py-0 md:flex align-items-end justify-content-between">
                                        <div>
                                            <div>
                                                {{data.createdDate * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}
                                            </div>
                                           <div>
                                            @if(data.status){
                                                <b class="mr-1">Estado:</b>
                                                @if(data.status === 'SENT'){
                                                    <b style="color: var(--yellow-500);">ENVIADO</b>
                                                }
                                                @if(data.status === 'PENDING'){
                                                    <b style="color: var(--pink-500);">PENDIENTE</b>
                                                }
                                                @if(data.status === 'OBSERVED'){
                                                    <b style="color: var(--bluegray-300);">OBSERVADO</b>
                                                }
                                                @if(data.status === 'APPROVED'){
                                                    <b style="color: var(--green-500);">APROBADO</b>
                                                }
                                            }
                                           </div>
                                        </div>
                                        <div class="mt-2 md:mt-0 md:flex align-items-center md:ml-2">
                                            <!-- <button pButton type="button" [ngClass]="{'p-button-outlined': selectedData}" class="w-button p-button-rounded p-button-success mr-2" label="EMPRESAS" (click)="selectedData = data.companies; sidebarCompanies = true;"></button>  -->
                        
                                            @if((data.observedReason && data.observedReason.length > 0) && data.status === 'OBSERVED'){
                                                <button pButton (click)="op.toggle($event); tempData = data.observedReason" type="button" class="w-button p-button-rounded p-button-secondary p-button-outlined mr-2 mb-2 md:mb-0" label="MOTIVO"></button> 
                                            }
                                            
                                            <button pButton (click)="op.toggle($event); tempData = data.instructionsRequest" type="button" class="w-button p-button-rounded p-button-secondary p-button-outlined mr-2 mb-2 md:mb-0" label="INSTRUCCIONES"></button> 

                                            @if(data.status === 'SENT'){
                                                <button pButton type="button"  class="w-button p-button-rounded p-button-secondary mr-2 mb-2 md:mb-0" label="OBSERVAR" (click)="displayModalObs = true; tempId = data.id;"></button>

                                                <button pButton type="button"  class="w-button p-button-rounded p-button-success mr-2 mb-2 md:mb-0" label="APROBAR" (click)="approvedRequest(data.id)" [loading]="loadRqListApproval"></button>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 px-0 pb-0">
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loadComplete" class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
        </div>
    </div>
</p-sidebar>

<p-overlayPanel #op>
    <div class="grid m-0">
        <div class="col-12 html-content" style="white-space: pre-wrap;">
            {{tempData}}
        </div>
    </div>
</p-overlayPanel>

<p-overlayPanel #opFiles>
    <ng-template pTemplate>

            <app-list-files [files]="files">
        </app-list-files>

    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opInfo>
    <ng-template pTemplate>
        <div class="grid">
            <div class="col-12" style="width:200px;">
                <p-table [value]="tempDataOp" scrollHeight="200px" [scrollable]="true">
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{item['name'+lang] | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-dialog [(visible)]="displayModalObs" [modal]="true" [breakpoints]="{'960px': '100vw'}" [style]="{width: '650px'}" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
        <h2 class="m-0 uppercase" translate>OBSERVAR DOCUMENTO
        </h2>
    </ng-template>
    <div class="formgrid grid m-0">
        <div class="field col-12">
            <label translate class="font-bold">Motivo <span class="required">*</span></label>

            <textarea style="min-height: 106px; height: 106px;" [(ngModel)]="message"
                                                        rows="5"
                                                        cols="30" 
                                                        pInputTextarea 
                                                        [autoResize]="true">
                                                    </textarea>

        </div>
        <div class="field col-12">
            <p class="m-0"><b style="color: var(--pink-500);" translate>important</b> <span translate>Al observar el documento, notificaremos al usuario que envió el
                documento para que subsane las observaciones indicadas.</span>
            </p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="grid">
            <div class="col-12">
                <button class="p-button-rounded p-button-danger w-button"  type="button" pButton label="{{'BACK' | translate}}" (click)="displayModalObs = false; tempId = null; message = ''"></button>
                <button class="p-button-rounded p-button-success w-button" type="button" pButton label="{{'SEND' | translate}}" (click)="saveObserve()" [loading]="loadRqListObs"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>