<div *ngIf="item" class="grid m-0 ">
    <div class="col-12 flex justify-content-end">
        <div class="card shadow-5 m-0 mr-1 card-princ">
            <div class="grid m-0 align-items-center">
                <div class="card-img">
                    <p-avatar *ngIf="item.nameType==='PLANTA'" image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/infrastructureType/1129928731081146.I1btSjZWvqu7a489h9tq_height640.png" shape="circle" size="large" [style]="{'height':'6rem', 'width': '6rem'}"></p-avatar>


                    <p-avatar *ngIf="item.nameType==='ALMACÉN'" image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/infrastructureType/1129928731081142.Y5dKjvkpGYqNulkSD0Kr_height640.png" shape="circle" size="large" [style]="{'height':'6rem', 'width': '6rem'}"></p-avatar>

                    <p-avatar *ngIf="item.nameType==='OFICINA'" image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/infrastructureType/1129928731081144.WXw0FfrlXtk4HoJ17XRz_height640.png" shape="circle" size="large" [style]="{'height':'6rem', 'width': '6rem'}"></p-avatar>
                    
                </div>
                <div class="flex align-content-start col card-text">
                    <div class="card-etc ">
                        <h3 class="uppercase m-0 mb-2 font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full flex align-items-center justify-content-center md:justify-content-between" style="color: var(--primary-color);"> 
                            <span><span pTooltip="{{item.name}}" tooltipPosition="top">{{item.name}}</span></span>
                              </h3>
                        <span><span  class="mr-2" style="color: #689F38;"> {{item.nameType}}</span>  <span style="color: #689F38;" > {{item.area | number}} m2</span></span>
                        
                        <span class="block mt-2"> {{item.addressDetail}}</span> 
                        
                    </div>
                </div>
                <div class="button-container" style="display: flex;
                align-items: flex-end;
                flex-direction: column;">
                    <div class="flex align-items-center w-full justify-content-center md:justify-content-end">
                       <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" style="    margin-bottom: 8px;">  
                       <button [disabled]="item.files.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" class="p-button-rounded p-button-secondary p-button-outlined" style="float: right; margin-bottom: 8px;" (click)="opFiles.toggle($event)" label="{{item.files.length}}"></button>
                    </div>
                    <div class="w-full text-center">
                        <button pButton pRipple type="button" icon="pi pi-map-marker"  class="p-button-rounded p-button-secondary p-button-outlined mr-2" (click)="setLocation()"></button>
                        <button *ngIf="type !== 'SUMMARY'" pButton pRipple type="button" icon="pi pi-pencil"  class="p-button-rounded p-button-info p-button-outlined mr-2" (click)="editItem()"></button>
                        <button *ngIf="type !== 'SUMMARY'"  pButton pRipple type="button" icon="far fa-trash-alt"  class="p-button-rounded p-button-danger p-button-outlined" (click)="confirm($event)"></button>
                    </div>
                    <p-overlayPanel #opFiles>
                        <ng-template pTemplate>
                            <app-list-files [files]="item.files" type="EDIT" (update)="files = $event"></app-list-files>
                        </ng-template>
                    </p-overlayPanel>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmPopup></p-confirmPopup>
