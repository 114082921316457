import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private env: string = environment.env; 
  private endPointIp: string ='https://api.ipify.org/?format=json';
  public lang: string;
  public theme:string;
  public desktop:boolean;
  request = new BehaviorSubject<Object>(undefined);
  request$ = this.request.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private http: HttpClient
  ) {}

  validateDevice(){
    if(screen.width < 768){
     this.desktop = false
    }else{
      this.desktop = true
    }
  }

  switchTheme(theme: string) {
    this.theme = theme;
    let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = theme + '.css';
    }
  }

  changeLanguage(type: string) {
    this.lang = type;
    this.translateService.use(type);
    this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
  }

  sendRequest(data){
    this.request.next(data);
  }

  getThemeShop() {
    return this.http.get<any>(`assets/json/cbnthemes.json`);
  }

  getTypeSession(){
    let pathname = window.location.pathname;
    let list = pathname.split("/");
    if(pathname.includes("/home/company") && list.length >= 4){
      return {
        idCompany: list[3],
        route: pathname
      }
    }else{
      return {
        idCompany: "NOT_COMPANY",
        route: pathname
      }
    }
  }

  /**
   * obtener fecha en formato dd/mm/yyyy hh:mm:ss GMT -5000 (Hora estandar Peru)
   * @param miliseconds 
   */
  getDateTimeZoneString(miliseconds: number){
    let date = new Date(miliseconds);

    let day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
    let month = (date.getMonth() + 1) > 9 ? `${(date.getMonth() + 1)}` : `0${(date.getMonth() + 1)}`;
    let year = date.getFullYear();

    return `${day}/${month}/${year} ${date.toTimeString()}`;
  }

  /**
   * obtener fecha en formato dd/mm/yyyy hh:mm:ss
   * @param miliseconds 
   */
  getDateString(miliseconds: number){
    let date = new Date(miliseconds);

    let day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
    let month = (date.getMonth() + 1) > 9 ? `${(date.getMonth() + 1)}` : `0${(date.getMonth() + 1)}`;
    let year = date.getFullYear();

    let hour = date.getHours() > 9 ? `${date.getHours()}` : `0${date.getHours()}`;
    let minute = date.getMinutes() > 9 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;
    let second = date.getSeconds() > 9 ? `${date.getSeconds()}` : `0${date.getSeconds()}`;
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }

  isArrayofStrings(field) {
    // Verificar que el campo es un array
    if (!Array.isArray(field)) {
        return false;
    }

    // Verificar que cada elemento del array es un string
    for (let item of field) {
        if (typeof item !== 'string') {
            return false;
        }
    }

    return true;
  }

  obtenerFechaFormateada(seconds) {
    var fecha = new Date(seconds * 1000);
    
    // Obteniendo día, mes y año
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1; // Los meses van de 0 a 11, sumamos 1
    var año = fecha.getFullYear();
    
    // Formateando la fecha como "dd/mm/yyyy"
    var fechaFormateada = this.agregarCerosIzquierda(dia) + '/' + this.agregarCerosIzquierda(mes) + '/' + año;
    
    // Obteniendo horas y minutos
    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();
    
    // Determinando si es AM o PM
    var periodo = horas >= 12 ? 'pm' : 'am';
    
    // Convirtiendo horas al formato de 12 horas
    horas = horas % 12;
    horas = horas ? horas : 12; // La medianoche es 12 AM, el mediodía es 12 PM
    
    // Formateando la hora como "hh:mm am/pm"
    var horaFormateada = this.agregarCerosIzquierda(horas) + ':' + this.agregarCerosIzquierda(minutos) + ' ' + periodo;
    
    // Uniendo la fecha y la hora
    var fechaHoraFormateada = fechaFormateada + ' ' + horaFormateada;
    
    return fechaHoraFormateada;
  }

  // Función para agregar ceros a la izquierda si es necesario
  agregarCerosIzquierda(numero: number) {
      return (numero < 10 ? '0' : '') + numero;
  }
}
