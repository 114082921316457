import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-section',
  templateUrl: './maintenance-section.component.html',
  styleUrls: ['./maintenance-section.component.scss']
})
export class MaintenanceSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
