import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electronic-invoice-sunat',
  templateUrl: './electronic-invoice-sunat.component.html',
  styleUrls: ['./electronic-invoice-sunat.component.scss']
})
export class ElectronicInvoiceSunatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
