import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnChanges {

  @Input() endDate: Date = null;
  @Input() initDate: Date = null;
  @Output() closedDate = new EventEmitter<Object>();

  //html
  daysHtml = 0;
  hoursHtml = 0;
  minutesHtml = 0;
  secondsHtml = 0;
  //logic
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  showTimer = false;

  constructor() { }

  ngOnInit(): void {
    this.myTimer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.myTimer();
  }

  myTimer() {
    if(this.endDate){
      this.showTimer = true;

      this.cDateMillisecs = this.initDate ? this.initDate.getTime() : new Date().getTime();
      this.tDateMillisecs = this.endDate.getTime();

      this.difference = this.tDateMillisecs - this.cDateMillisecs;
      this.seconds = Math.floor(this.difference / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.hours = Math.floor(this.minutes / 60);
      this.days = Math.floor(this.hours / 24);

      this.hours %= 24;
      this.minutes %= 60;
      this.seconds %= 60;
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

      
      /* setInterval(() => this.myTimer(), 1000); */
      if(this.difference >= 1000){
        this.daysHtml = this.days;
        this.hoursHtml = this.hours;
        this.minutesHtml = this.minutes;
        this.secondsHtml = this.seconds;
        setTimeout(() => {
          this.myTimer()
        }, 1000);
      }else{
        this.daysHtml = 0;
        this.hoursHtml = 0;
        this.minutesHtml = 0;
        this.secondsHtml = 0;
        this.closedDate.emit({
          status: "FINALIZED"
        })
      }
    }else{
      this.showTimer = false;
    }
  }

}
