<p-dialog [closeOnEscape]="false" header="" [(visible)]="validation" [style]="{width: '650px'}" [closable]="false" [modal]="true"
    [closeOnEscape]="false">
    <div class="grid mx-3">
        <div class="col-12" style="height: 120px;">
            <app-animated-warning [height]="height"></app-animated-warning>
        </div>
        <div class="col-12 text-center">
            <h2 style="font-size: 1.4em;" class="mb-0" tranlate>Se requiere que la informacion basica de su negocio este <br> completa.Por favor,
                complete los siguientes formularios para <br> continuar.</h2>
        </div>
        <div class="col-12">
            <ol style="font-size: 16px;     padding-left: 1.3em;">
                <ng-container *ngFor="let item of list">
                    <li>
                        <div class="grid align-items-center" style="height: 50px;">
                            <div class="col-5">
                                {{item.nameSection}}
                            </div>
                            <div class="col-4 flex align-items-center" [ngClass]="{ 'complete': item.status === false , 'pending': item.status === true }">
                                <span class="inline-block ml-3 mr-2" *ngIf="item.status === false"  style="height: 17px;"><app-animated-error></app-animated-error></span>
                                <span class="inline-block ml-3 mr-2" *ngIf="item.status === true"  style="height: 20px;"><app-animated-check></app-animated-check></span>
                                {{item.status === false ? 'Pendiente' : 'Completado'}}
                            </div>
                            <div class="col-3 text-right">
                                <span *ngIf="!item.status">
                                    <button pButton pRipple type="button" label="ACTUALIZAR" class="p-button-rounded p-button-success" [routerLink]="item.routerLink"></button>
                                </span>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ol>

        </div>
    </div>


    <ng-template pTemplate="footer">
        <p class="text-center"><span style="color: var(--pink-500);" translate>important</span><span translate>validation_business_important</span></p>
    </ng-template>
</p-dialog>