import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  constructor(private http: HttpClient) { }

  get idCompany(){
    let paths = window.location.pathname.split("/");
    return paths.length > 4 && paths[2] === 'hub' ? paths[4] : '';
  }

  get idHub(){
    let paths = window.location.pathname.split("/");
    return paths.length > 3 && paths[2] === 'hub' ? paths[3] : '';
  }

}
