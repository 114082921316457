import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnInit {

  @Output() nextFiles = new EventEmitter<any>();
  @Output() closeDialogVideo = new EventEmitter<any>();
  @Input() type = '';
  @Input() typeValue = '';
  @Input() path = '';

  statusUploading = 'NEW';
  urlVideo = "";
  nameVideo = "";
  sizeVideo = "";
  progress = 0;
  type_crop = 1;
  statusUploadingImg = 'NEW';
  urlPrevImg ="";
  nameImg = "";
  sizeImg = "";
  progressImg = 0;

  showFiles = true;

  displayImagencrop = false;
  constructor(
    private globalService: GlobalService,
    private awsShared: AwsSharedService
  ) { }

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
   onFileDropped($event) {
    if(this.statusUploading != "UPLOADING"){
      this.prepareFilesList($event);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if(this.statusUploading != "UPLOADING"){
      this.prepareFilesList(files);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
   async prepareFilesList(files: Array<File>) {
    if(files.length > 0){
      let file: File = files[0];
      if(file){
        if(file.size <= 104857600){
          let ext = file.name.split(".")[file.name.split(".").length - 1].toLowerCase();
          if(['mp4', 'mov'].includes(ext)){
            this.statusUploading = "UPLOADING";
            this.progress = 0;
            this.sizeVideo = this.formatBytes(file.size, 2);
            let namefile: any = file.name;
            this.nameVideo = namefile.replaceAll(/[^a-zA-Z 0-9.]+/g,'_');
            let preSigned = await this.awsShared.uploadFileS3All({ "type": this.type, "typeValue": this.typeValue,  "path": this.path, "name": this.nameVideo }).toPromise();
            if(preSigned['status']){
              this.awsShared.uploadFileToS3({ url: preSigned['data']['url'], file: file }).subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    this.statusUploading = "UPLOADING";
                    break;
                  case HttpEventType.ResponseHeader:
                    break;
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round(event.loaded / event.total * 100);
                    break;
                  case HttpEventType.Response:
                    let result = event;
                    if(result && result['status'] === 200){
                      this.statusUploading = "FINALIZED";
                      this.urlVideo = result['url'].split("?")[0];
                      this.globalService.sendRequest({severity: 'success',summary: 'Éxito',detail: 'DOCUMENT_UPLOAD_SUCCESS',type: 'TOAST'});
                    }else{
                      this.statusUploading = "NEW";
                      this.progress = 0;
                      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
                    }
                }
              }, error => { 
                this.statusUploading = "NEW";
                this.progress = 0;
                this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
               });
            }else{
              this.statusUploading = "NEW";
              this.progress = 0;
              this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
            }
          }else{
            this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_EXTENSION', type: 'TOAST' });
          }
        }else{
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_SIZE', type: 'TOAST' });
        }
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
   formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  deleteFile(){
    this.statusUploading = "NEW";
    this.progress = 0;
    this.sizeVideo = "";
    this.nameVideo = "";
    this.urlVideo = "";
  }

  //img
  deleteFileImg(){
    this.statusUploadingImg = "NEW";
    this.progressImg = 0;
    this.sizeImg = "";
    this.nameImg = "";
    this.urlPrevImg = "";
  }

  async uploadPrev(event, btnUpload){
    btnUpload.clear();
    if(event.files.length > 0){
      let file: File = event.files[0];
      if(file){
        if(file.size <= 26214400){
          let ext = file.name.split(".")[file.name.split(".").length - 1].toLowerCase();
          if(['jpg', 'jpeg', 'png'].includes(ext)){
            this.statusUploadingImg = "UPLOADING";
            this.progressImg = 0;
            this.sizeImg = this.formatBytes(file.size, 2);
            let namefile: any = file.name;
            this.nameImg = namefile.replaceAll(/[^a-zA-Z 0-9.]+/g,'_');
            let preSigned = await this.awsShared.uploadFileS3All({ "type": this.type, "typeValue": this.typeValue,  "path": this.path, "name": this.nameImg }).toPromise();
            if(preSigned['status']){
              this.awsShared.uploadFileToS3({ url: preSigned['data']['url'], file: file }).subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    this.statusUploadingImg = "UPLOADING";
                    break;
                  case HttpEventType.ResponseHeader:
                    break;
                  case HttpEventType.UploadProgress:
                    this.progressImg = Math.round(event.loaded / event.total * 100);
                    break;
                  case HttpEventType.Response:
                    let result = event;
                    if(result && result['status'] === 200){
                      this.statusUploadingImg = "FINALIZED";
                      this.urlPrevImg = result['url'].split("?")[0];
                      this.globalService.sendRequest({severity: 'success',summary: 'Éxito',detail: 'DOCUMENT_UPLOAD_SUCCESS',type: 'TOAST'});
                    }else{
                      this.statusUploadingImg = "NEW";
                      this.progressImg = 0;
                      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
                    }
                }
              }, error => { 
                this.statusUploadingImg = "NEW";
                this.progressImg = 0;
                this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
               });
            }else{
              this.statusUploadingImg = "NEW";
              this.progressImg = 0;
              this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
            }
          }else{
            this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_EXTENSION', type: 'TOAST' });
          }
        }else{
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_SIZE', type: 'TOAST' });
        }
      }
    }
  } 

  generateFiles(){
    this.nextFiles.emit({
      urlVideo: this.urlVideo,
      urlImg: this.urlVideo.length > 0 ? this.urlPrevImg : ''
    })
  }

  closeUploadVideo(){
    this.closeDialogVideo.emit(false)
  }
}
