<p-sidebar [(visible)]="displayCompare" position="right" [dismissible]="!loading" styleClass="sidebar-compare">
    <div class="grid">
        <div class="col-12 mt-4 block md:hidden">
            <div class="grid flex align-items-center" style="border-bottom: 1px solid var(--surface-d); height: 53px;">
                <div class="col-12 ">
                    <h2 class="m-0" translate>COMPARISON</h2>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4 hidden md:block">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 132px)'}">
                <div class="grid">
                    <div class="col-4" style="padding-right: 0px;">
                        <div class="top-provider ">
                            <ng-container *ngIf="companyOne">
                                <img style="object-fit: contain;" *ngIf="companyOne['logo'].length > 0" [src]="companyOne['logo']">
                                <p-avatar *ngIf="companyOne['logo'].length === 0"
                                    label="{{companyOne['businessName'].charAt(0)}}" styleClass="mr-2"
                                    [style]="{'width': '100px','height': '100px','font-size': '30px','background-color': '#83888f', 'color': '#ffffff'}"
                                    shape="circle"></p-avatar>
                                <h3>{{companyOne['businessName']}}</h3>
                            </ng-container>
                            <ng-container *ngIf="!companyOne">
                                <div class="flex align-items-center justify-content-center flex-column">
                                    <p-skeleton shape="circle" size="7rem"></p-skeleton>
                                    <p-skeleton width="5rem" styleClass="mt-3"></p-skeleton>
                                </div>
                            </ng-container>

                        </div>
                        <div class="indicador-top ">
                            <ng-container *ngIf="companyOne">
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="companyOne.confianzaWidth"
                                            style="background: rgb(255, 143, 0);"></div>
                                    </div>
                                    <div class="number">{{companyOne.trustLevel | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="companyOne.reputationWidth"
                                            style="background: rgb(29, 237, 2);"></div>
                                    </div>
                                    <div class="number">{{companyOne.reputationLevel | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="companyOne.informationWidth"
                                            style="background: rgb(255, 0, 0);"></div>
                                    </div>
                                    <div class="number">{{companyOne.informationLevel | number:'1.2-2'}}</div>
                                </div>
                                <div class="time">
                                    <span *ngIf="companyOne.year <= 0" translate>current_year</span>
                                    <span *ngIf="companyOne.year > 0" >{{companyOne.year}} <span translate>years</span> </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!companyOne">
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="time flex justify-content-end">
                                    <p-skeleton width="2rem"></p-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <div class="indicador-top " style="padding: 40px 5px 8px;">
                            <ng-container *ngIf="companyOne">
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left"
                                            style="width: 100%; background: rgb(204, 204, 204);">
                                        </div>
                                    </div>
                                    <div class="number">10.50</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(10.5, companyOne.CompanyLineBusiness)" [style.background]="setColor(companyOne.CompanyLineBusiness,companyTwo.CompanyLineBusiness)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.CompanyLineBusiness | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(8.75, companyOne.companyExecutives)"  [style.background]="setColor(companyOne.companyExecutives,companyTwo.companyExecutives)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyExecutives | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(5.25, companyOne.companyBankAccount)" [style.background]="setColor(companyOne.companyBankAccount,companyTwo.companyBankAccount)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyBankAccount | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(21, companyOne.companyLineProductAndServiceTwo)" [style.background]="setColor(companyOne.companyLineProductAndServiceTwo,companyTwo.companyLineProductAndServiceTwo)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyLineProductAndServiceTwo | number:'1.2-2'}}
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(21, companyOne.companyClientExperience)" [style.background]="setColor(companyOne.companyClientExperience, companyTwo.companyClientExperience)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyClientExperience | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(15, companyOne.companyInfrastructure)" [style.background]="setColor(companyOne.companyInfrastructure, companyTwo.companyInfrastructure)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyInfrastructure | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(3, companyOne.companyCommercialTerms)" [style.background]="setColor(companyOne.companyCommercialTerms, companyTwo.companyCommercialTerms)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyCommercialTerms | number:'1.2-2'}}</div>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <div class="barra bar-left" [style.width]="percentage(5, companyOne.companyQualityManagement)" [style.background]="setColor(companyOne.companyQualityManagement, companyTwo.companyQualityManagement)">
                                        </div>
                                    </div>
                                    <div class="number">{{companyOne.companyQualityManagement | number:'1.2-2'}}</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!companyOne">
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-end">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <div class="indicador-top ">
                            <div class="indicador" style="justify-content: flex-end;" *ngIf="companyOne">
                                <div class="icon-cer-lef">
                                    <img tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.ISO9001Certificate"
                                        src="assets/certificacion/1. CALIDAD.svg" alt="">
                                    <img  tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"  *ngIf="companyOne.companyQualityManagementCertifications.GMPCertificate"
                                        src="assets/certificacion/2. GMP.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.BRCCertificate"
                                        src="assets/certificacion/3. BRC.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.BASCCertificate"
                                        src="assets/certificacion/4. BASC.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.HACCPCertificate"
                                        src="assets/certificacion/4. HACCP.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyOne">
                                <div class="icon-cer-lef block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" style="justify-content: flex-end;" *ngIf="companyOne">
                                <div class="icon-cer-lef">
                                    <img tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" *ngIf="companyOne.companySecurityHealthWorkCertifications.OSHA18001Certificate"
                                        src="assets/certificacion/6. SEGURIDAD.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyOne">
                                <div class="icon-cer-lef block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" style="justify-content: flex-end;" *ngIf="companyOne">
                                <div class="icon-cer-lef">
                                    <img  tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" *ngIf="companyOne.companyResponsabilitySocialEnvironmentalCertifications.ISO14001Certificate"
                                        src="assets/certificacion/7. AMBIENTE.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyOne">
                                <div class="icon-cer-lef block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" style="justify-content: flex-end;" *ngIf="companyOne">
                                <div class="icon-cer-lef">
                                    <img tooltipPosition="left" pTooltip="{{ 'PROFIT_AND_LOSS_STATEMENTS' | translate }}" *ngIf="companyOne.companyAccountingInformationCertifications.accountingProfitLossStatement"
                                        src="assets/certificacion/8. GYP.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'BALANCE_SHEET' | translate }}" *ngIf="companyOne.companyAccountingInformationCertifications.accountingBalanceSheet"
                                        src="assets/certificacion/9. BALANCE.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyOne">
                                <div class="icon-cer-lef block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" style="justify-content: flex-end;" *ngIf="companyOne">
                                <div class="icon-cer-lef">
                                    <img tooltipPosition="left" pTooltip="{{ 'CREDIT_REPORT' | translate }}" *ngIf="companyOne.companyFinancialReferenceCertifications.referenceReportFile"
                                        src="assets/certificacion/10. REPORT FIN.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyOne">
                                <div class="icon-cer-lef block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 center-block" style="padding-right: 0px; padding-left: 0px;">
                        <div class="title" style="margin-bottom: 0px;" translate>COMPARISON</div>
                        <div class="checks" *ngIf="companyOne">
                            <div class="images flex">
                                <img src="assets/icons/check3.png" tooltipposition="bottom">
                                <img ngi [ngClass]="{'opacity-0': companyOne.companyQualityManagement >= 5}" src="assets/icons/check1.png" tooltipposition="bottom">
                                <img class="opacity-0" src="assets/icons/check2.png" tooltipposition="bottom">
                            </div>
                            <div class="border"></div>
                            <div class="images flex flex-row-reverse">
                                <img src="assets/icons/check3.png" tooltipposition="bottom">
                                <img [ngClass]="{'opacity-0': companyTwo.companyQualityManagement >= 5}" src="assets/icons/check1.png" tooltipposition="bottom">
                                <img class="opacity-0" src="assets/icons/check2.png" tooltipposition="bottom">
                            </div>
                        </div>
                        <div class="checks" *ngIf="!companyOne">
                            <div class="images flex">
                                <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
                                <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
                                <p-skeleton shape="circle" size="2rem"></p-skeleton>
                            </div>
                            <div class="border"></div>
                            <div class="images flex flex-row-reverse">
                                <p-skeleton shape="circle" size="2rem"></p-skeleton>
                                <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
                                <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
                            </div>
                        </div>
                        <div class="indicadores">
                            <h3 translate>MACRO INDICATORS</h3>
                            <p translate>reliability</p>
                            <p translate>reputation</p>
                            <p translate>information</p>
                            <p style="margin-bottom: -0.5px;" translate>operating</p>
                        </div>
                        <div class="indicadores" style="padding: 15px 5px 6.5px 5px;">
                            <h3 translate>INFORMATION_DETAIL</h3>
                            <p translate>SIDEBAR.GENERAL_DATA</p>
                            <p translate>SIDEBAR.LINE_BUSINESS</p>
                            <p translate>SIDEBAR.COMPANY_EXECUTIVES</p>
                            <p translate>SIDEBAR.ACCOUNT_BANK</p>
                            <p translate>SIDEBAR.PRODUCT_LINES</p>
                            <p translate>SIDEBAR.EXPERIENCE_CLIENTS</p>
                            <p translate>SIDEBAR.INFRAESTRUCTURE</p>
                            <p translate>SIDEBAR.TERMS</p>
                            <p style="margin: 1px;" translate>table_info_suppliers_panel.verification</p>
                        </div>
                        <div class="indicadores">
                            <h3 translate>CERTIFICATIONS_AND_REPORTS</h3>
                            <p style="margin: 8.25px 0 5.25px 0;" translate>SIDEBAR.QUALITY</p>
                            <p translate>SIDEBAR.SAFETY</p>
                            <p translate>environmental_management</p>
                            <p translate>SIDEBAR.ACCOUNTING</p>
                            <p translate>SIDEBAR.FINANCE</p>
                        </div>
                    </div>
                    <div class="col-4" style="padding-left: 0px;">
                        <div class="top-provider ">
                            <ng-container *ngIf="companyTwo">
                                <img *ngIf="companyTwo['logo'].length > 0" [src]="companyTwo['logo']">
                                <p-avatar *ngIf="companyTwo['logo'].length === 0"
                                    label="{{companyTwo['businessName'].charAt(0)}}" styleClass="mr-2"
                                    [style]="{'width': '100px','height': '100px','font-size': '30px','background-color': '#83888f', 'color': '#ffffff'}"
                                    shape="circle"></p-avatar>
                                <h3>{{companyTwo['businessName']}}</h3>
                            </ng-container>
                            <ng-container *ngIf="!companyTwo">
                                <div class="flex align-items-center justify-content-center flex-column">
                                    <p-skeleton shape="circle" size="7rem"></p-skeleton>
                                    <p-skeleton width="5rem" styleClass="mt-3"></p-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <div class="indicador-top ">
                            <ng-container *ngIf="companyTwo">
                                <div class="indicador">
                                    <div class="number">{{companyTwo.trustLevel | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="companyTwo.confianzaWidth"
                                            style="background: rgb(255, 0, 0);"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.reputationLevel | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="companyTwo.reputationWidth"
                                            style="background: rgb(29, 237, 2);"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.informationLevel | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="companyTwo.informationWidth"
                                            style="background: rgb(255, 0, 0);"></div>
                                    </div>
                                </div>
                                <div class="time">
                                    <span *ngIf="companyTwo.year <= 0" translate>current_year</span>
                                    <span *ngIf="companyTwo.year > 0" >{{companyTwo.year}} <span translate>years</span> </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!companyTwo">
                                <div class="indicador">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="time">
                                    <p-skeleton width="2rem"></p-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <div class="indicador-top" style="padding: 40px 5px 8px;">
                            <ng-container *ngIf="companyTwo">
                                <div class="indicador">
                                    <div class="number">10.50</div>
                                    <div class="total-barra">
                                        <div class="barra" style="width: 100%; background: rgb(204, 204, 204);"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.CompanyLineBusiness | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(10.5, companyTwo.CompanyLineBusiness)" [style.background]="setColor(companyTwo.CompanyLineBusiness, companyOne.CompanyLineBusiness)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyExecutives | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(8.75, companyTwo.companyExecutives)"   [style.background]="setColor(companyTwo.companyExecutives, companyOne.companyExecutives)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyBankAccount | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(5.25, companyTwo.companyBankAccount)" [style.background]="setColor(companyTwo.companyBankAccount , companyOne.companyBankAccount)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyLineProductAndServiceTwo | number:'1.2-2'}}
                                    </div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(21, companyTwo.companyLineProductAndServiceTwo)" [style.background]="setColor(companyTwo.companyLineProductAndServiceTwo,companyOne.companyLineProductAndServiceTwo)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyClientExperience | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(21, companyTwo.companyClientExperience)" [style.background]="setColor(companyTwo.companyClientExperience,companyOne.companyClientExperience)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyInfrastructure | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(15, companyTwo.companyInfrastructure)" [style.background]="setColor(companyTwo.companyInfrastructure,companyOne.companyInfrastructure)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyCommercialTerms | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(3, companyTwo.companyCommercialTerms)" [style.background]="setColor(companyTwo.companyCommercialTerms,companyOne.companyCommercialTerms)"></div>
                                    </div>
                                </div>
                                <div class="indicador">
                                    <div class="number">{{companyTwo.companyQualityManagement | number:'1.2-2'}}</div>
                                    <div class="total-barra">
                                        <div class="barra" [style.width]="percentage(5, companyTwo.companyQualityManagement)"  [style.background]="setColor(companyTwo.companyQualityManagement, companyOne.companyQualityManagement)"></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!companyTwo">
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                                <div class="indicador justify-content-start">
                                    <div class="total-barra">
                                        <p-skeleton></p-skeleton>
                                    </div>
                                    <p-skeleton></p-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <div class="indicador-top ">
                            <div class="indicador" *ngIf="companyTwo">
                                <div class="icon-cer">
                                    <img tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.ISO9001Certificate"
                                        src="assets/certificacion/1. CALIDAD.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.GMPCertificate"
                                        src="assets/certificacion/2. GMP.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.BRCCertificate"
                                        src="assets/certificacion/3. BRC.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.BASCCertificate"
                                        src="assets/certificacion/4. BASC.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.HACCPCertificate"
                                        src="assets/certificacion/4. HACCP.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyTwo">
                                <div class="icon-cer block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" *ngIf="companyTwo">
                                <div class="icon-cer">
                                    <img tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" *ngIf="companyTwo.companySecurityHealthWorkCertifications.OSHA18001Certificate"
                                        src="assets/certificacion/6. SEGURIDAD.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyTwo">
                                <div class="icon-cer block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" *ngIf="companyTwo">
                                <div class="icon-cer">
                                    <img tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" *ngIf="companyTwo.companyResponsabilitySocialEnvironmentalCertifications.ISO14001Certificate"
                                        src="assets/certificacion/7. AMBIENTE.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyTwo">
                                <div class="icon-cer block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" *ngIf="companyTwo">
                                <div class="icon-cer">
                                    <img tooltipPosition="left" pTooltip="{{ 'PROFIT_AND_LOSS_STATEMENTS' | translate }}" *ngIf="companyTwo.companyAccountingInformationCertifications.accountingProfitLossStatement"
                                        src="assets/certificacion/8. GYP.svg" alt="">
                                    <img tooltipPosition="left" pTooltip="{{ 'BALANCE_SHEET' | translate }}" *ngIf="companyTwo.companyAccountingInformationCertifications.accountingBalanceSheet"
                                        src="assets/certificacion/9. BALANCE.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyTwo">
                                <div class="icon-cer block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                            <div class="indicador" *ngIf="companyTwo">
                                <div class="icon-cer">
                                    <img  tooltipPosition="left" pTooltip="{{ 'CREDIT_REPORT' | translate }}" *ngIf="companyTwo.companyFinancialReferenceCertifications.referenceReportFile"
                                        src="assets/certificacion/10. REPORT FIN.svg" alt="">
                                </div>
                            </div>
                            <div class="indicador block" *ngIf="!companyTwo">
                                <div class="icon-cer block">
                                    <p-skeleton></p-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 p-0 block md:hidden">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 170px)'}">
                <div class="grid m-0">
                    <div class="col-6 container-compare">
                        <div class="grid">
                            <div class="col-12">
                                <ng-container *ngIf="companyOne">
                                    <img style="object-fit: contain;" *ngIf="companyOne['logo'].length > 0" [src]="companyOne['logo']">
                                    <p-avatar *ngIf="companyOne['logo'].length === 0"
                                        label="{{companyOne['businessName'].charAt(0)}}" 
                                        [style]="{'width': '104px','height': '104px','font-size': '30px','background-color': '#83888f', 'color': '#ffffff'}"
                                        shape="circle"></p-avatar>
                                    <h3 class="line-clamp">{{companyOne['businessName']}}</h3>
                                </ng-container>
                            </div>
                            <div class="col-12">
                                <div class="checks" *ngIf="companyOne">
                                    <div class="images flex      justify-content-center">
                                        <img src="assets/icons/check3.png" tooltipposition="bottom">
                                        <img [ngClass]="{'opacity-0': companyOne.companyQualityManagement >= 5}" src="assets/icons/check1.png" tooltipposition="bottom">
                                        <img class="opacity-0" src="assets/icons/check2.png" tooltipposition="bottom">
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        
                    </div>
                    <div class="col-6 container-compare">
                        <div class="grid">
                            <div class="col-12">
                                <ng-container *ngIf="companyTwo">
                                    <img *ngIf="companyTwo['logo'].length > 0" [src]="companyTwo['logo']">
                                    <p-avatar *ngIf="companyTwo['logo'].length === 0"
                                        label="{{companyTwo['businessName'].charAt(0)}}"
                                        [style]="{'width': '104px','height': '104px','font-size': '30px','background-color': '#83888f', 'color': '#ffffff'}"
                                        shape="circle"></p-avatar>
                                    <h3 class="line-clamp">{{companyTwo['businessName']}}</h3>
                                </ng-container>
                               
                            </div>
                            <div class="col-12">
                                <div class="checks" *ngIf="companyTwo">
                                    <div class="images flex flex-row-reverse justify-content-center">
                                        <img src="assets/icons/check3.png" tooltipposition="bottom">
                                        <img [ngClass]="{'opacity-0': companyTwo.companyQualityManagement >= 5}" src="assets/icons/check1.png" tooltipposition="bottom">
                                        <img class="opacity-0" src="assets/icons/check2.png" tooltipposition="bottom">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <h3 class="m-0" translate>MACRO INDICATORS</h3>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>reliability</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="companyOne.confianzaWidth"
                                    style="background: rgb(255, 143, 0);"></div>
                            </div>
                            <div class="number">{{companyOne.trustLevel | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.trustLevel | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="companyTwo.confianzaWidth"
                                    style="background: rgb(255, 0, 0);"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>reputation</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">

                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="companyOne.reputationWidth"
                                    style="background: rgb(29, 237, 2);"></div>
                            </div>
                            <div class="number">{{companyOne.reputationLevel | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.reputationLevel | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="companyTwo.reputationWidth"
                                    style="background: rgb(29, 237, 2);"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>information</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="companyOne.informationWidth"
                                    style="background: rgb(255, 0, 0);"></div>
                            </div>
                            <div class="number">{{companyOne.informationLevel | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.informationLevel | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="companyTwo.informationWidth"
                                    style="background: rgb(255, 0, 0);"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>operating</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="time text-center">
                            <span *ngIf="companyOne.year <= 0" translate>current_year</span>
                            <span *ngIf="companyOne.year > 0" >{{companyOne.year}} <span translate>years</span> </span>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="time text-center">
                            <span *ngIf="companyTwo.year <= 0" translate>current_year</span>
                            <span *ngIf="companyTwo.year > 0" >{{companyTwo.year}} <span translate>years</span> </span>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <h3 class="m-0" translate>INFORMATION_DETAIL</h3>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.GENERAL_DATA</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left"
                                    style="width: 100%; background: rgb(204, 204, 204);">
                                </div>
                            </div>
                            <div class="number">10.50</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">10.50</div>
                            <div class="total-barra">
                                <div class="barra" style="width: 100%; background: rgb(204, 204, 204);"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.LINE_BUSINESS</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(10.5, companyOne.CompanyLineBusiness)" [style.background]="setColor(companyOne.CompanyLineBusiness,companyTwo.CompanyLineBusiness)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.CompanyLineBusiness | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.CompanyLineBusiness | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(10.5, companyTwo.CompanyLineBusiness)" [style.background]="setColor(companyTwo.CompanyLineBusiness, companyOne.CompanyLineBusiness)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.COMPANY_EXECUTIVES</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(8.75, companyOne.companyExecutives)"  [style.background]="setColor(companyOne.companyExecutives,companyTwo.companyExecutives)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyExecutives | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyExecutives | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(8.75, companyTwo.companyExecutives)"   [style.background]="setColor(companyTwo.companyExecutives, companyOne.companyExecutives)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.ACCOUNT_BANK</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(5.25, companyOne.companyBankAccount)" [style.background]="setColor(companyOne.companyBankAccount,companyTwo.companyBankAccount)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyBankAccount | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyBankAccount | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(5.25, companyTwo.companyBankAccount)" [style.background]="setColor(companyTwo.companyBankAccount , companyOne.companyBankAccount)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.PRODUCT_LINES</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(21, companyOne.companyLineProductAndServiceTwo)" [style.background]="setColor(companyOne.companyLineProductAndServiceTwo,companyTwo.companyLineProductAndServiceTwo)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyLineProductAndServiceTwo | number:'1.2-2'}}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyLineProductAndServiceTwo | number:'1.2-2'}}
                            </div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(21, companyTwo.companyLineProductAndServiceTwo)" [style.background]="setColor(companyTwo.companyLineProductAndServiceTwo,companyOne.companyLineProductAndServiceTwo)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.EXPERIENCE_CLIENTS</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(21, companyOne.companyClientExperience)" [style.background]="setColor(companyOne.companyClientExperience, companyTwo.companyClientExperience)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyClientExperience | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyClientExperience | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(21, companyTwo.companyClientExperience)" [style.background]="setColor(companyTwo.companyClientExperience,companyOne.companyClientExperience)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.INFRAESTRUCTURE</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(15, companyOne.companyInfrastructure)" [style.background]="setColor(companyOne.companyInfrastructure, companyTwo.companyInfrastructure)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyInfrastructure | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyInfrastructure | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(15, companyTwo.companyInfrastructure)" [style.background]="setColor(companyTwo.companyInfrastructure,companyOne.companyInfrastructure)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.TERMS</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(3, companyOne.companyCommercialTerms)" [style.background]="setColor(companyOne.companyCommercialTerms, companyTwo.companyCommercialTerms)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyCommercialTerms | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyCommercialTerms | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(3, companyTwo.companyCommercialTerms)" [style.background]="setColor(companyTwo.companyCommercialTerms,companyOne.companyCommercialTerms)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>table_info_suppliers_panel.verification</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador">
                            <div class="total-barra">
                                <div class="barra bar-left" [style.width]="percentage(5, companyOne.companyQualityManagement)" [style.background]="setColor(companyOne.companyQualityManagement, companyTwo.companyQualityManagement)">
                                </div>
                            </div>
                            <div class="number">{{companyOne.companyQualityManagement | number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="number">{{companyTwo.companyQualityManagement | number:'1.2-2'}}</div>
                            <div class="total-barra">
                                <div class="barra" [style.width]="percentage(5, companyTwo.companyQualityManagement)"  [style.background]="setColor(companyTwo.companyQualityManagement, companyOne.companyQualityManagement)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <h3 class="m-0" translate>CERTIFICATIONS_AND_REPORTS</h3>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.QUALITY</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador" style="justify-content: flex-end;" >
                            <div class="icon-cer-lef">
                                <img tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.ISO9001Certificate"
                                    src="assets/certificacion/1. CALIDAD.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img  tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}"  *ngIf="companyOne.companyQualityManagementCertifications.GMPCertificate"
                                    src="assets/certificacion/2. GMP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.BRCCertificate"
                                    src="assets/certificacion/3. BRC.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.BASCCertificate"
                                    src="assets/certificacion/4. BASC.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" *ngIf="companyOne.companyQualityManagementCertifications.HACCPCertificate"
                                    src="assets/certificacion/4. HACCP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="icon-cer">
                                <img tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.ISO9001Certificate"
                                    src="assets/certificacion/1. CALIDAD.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.GMPCertificate"
                                    src="assets/certificacion/2. GMP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.BRCCertificate"
                                    src="assets/certificacion/3. BRC.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.BASCCertificate"
                                    src="assets/certificacion/4. BASC.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" *ngIf="companyTwo.companyQualityManagementCertifications.HACCPCertificate"
                                    src="assets/certificacion/4. HACCP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.SAFETY</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador" style="justify-content: flex-end;">
                            <div class="icon-cer-lef">
                                <img tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" *ngIf="companyOne.companySecurityHealthWorkCertifications.OSHA18001Certificate"
                                    src="assets/certificacion/6. SEGURIDAD.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador" >
                            <div class="icon-cer">
                                <img tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" *ngIf="companyTwo.companySecurityHealthWorkCertifications.OSHA18001Certificate"
                                    src="assets/certificacion/6. SEGURIDAD.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>environmental_management</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador" style="justify-content: flex-end;">
                            <div class="icon-cer-lef">
                                <img  tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" *ngIf="companyOne.companyResponsabilitySocialEnvironmentalCertifications.ISO14001Certificate"
                                    src="assets/certificacion/7. AMBIENTE.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="icon-cer">
                                <img tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" *ngIf="companyTwo.companyResponsabilitySocialEnvironmentalCertifications.ISO14001Certificate"
                                    src="assets/certificacion/7. AMBIENTE.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.ACCOUNTING</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador" style="justify-content: flex-end;">
                            <div class="icon-cer-lef">
                                <img tooltipPosition="left" pTooltip="{{ 'PROFIT_AND_LOSS_STATEMENTS' | translate }}" *ngIf="companyOne.companyAccountingInformationCertifications.accountingProfitLossStatement"
                                    src="assets/certificacion/8. GYP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'BALANCE_SHEET' | translate }}" *ngIf="companyOne.companyAccountingInformationCertifications.accountingBalanceSheet"
                                    src="assets/certificacion/9. BALANCE.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="icon-cer">
                                <img tooltipPosition="left" pTooltip="{{ 'PROFIT_AND_LOSS_STATEMENTS' | translate }}" *ngIf="companyTwo.companyAccountingInformationCertifications.accountingProfitLossStatement"
                                    src="assets/certificacion/8. GYP.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                                <img tooltipPosition="left" pTooltip="{{ 'BALANCE_SHEET' | translate }}" *ngIf="companyTwo.companyAccountingInformationCertifications.accountingBalanceSheet"
                                    src="assets/certificacion/9. BALANCE.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <h3 class="m-0" translate>SIDEBAR.FINANCE</h3>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyOne">
                        <div class="indicador" style="justify-content: flex-end;">
                            <div class="icon-cer-lef">
                                <img tooltipPosition="left" pTooltip="{{ 'CREDIT_REPORT' | translate }}" *ngIf="companyOne.companyFinancialReferenceCertifications.referenceReportFile"
                                    src="assets/certificacion/10. REPORT FIN.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 container-indicator" *ngIf="companyTwo" style="border-left: 1px solid var(--surface-d);">
                        <div class="indicador">
                            <div class="icon-cer">
                                <img  tooltipPosition="left" pTooltip="{{ 'CREDIT_REPORT' | translate }}" *ngIf="companyTwo.companyFinancialReferenceCertifications.referenceReportFile"
                                    src="assets/certificacion/10. REPORT FIN.svg" alt="" tabindex="0" tooltipEvent="focus" style="outline: 0;">
                            </div>
                        </div>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 pb-0">
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loading"
                class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
        </div>
    </div>
</p-sidebar>