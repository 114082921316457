import { Component, EventEmitter, Input, OnInit, Output, ViewChild ,ElementRef, OnDestroy } from '@angular/core';
import { SessionIn } from 'src/app/interfaces/session-in';
import { SessionService } from 'src/app/services/session.service';
import { GlobalService } from 'src/app/services/global.service';
import { firstValueFrom } from 'rxjs';
import { HubService } from 'src/app/services/hub.service';
import { TranslateService } from '@ngx-translate/core';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit, OnDestroy {
  @Input() nameCompany = '';
  @Input() type = '';
  @Input() level = '';
  @Input() itemUpdate = null;
  @ViewChild("input") input: ElementRef;
  emojii18n = null;
  sessionIn: SessionIn;
  displayModalPublish = true;
  textPost: string = '';
  containsPost = [];
  idCompany = '';
  uploadFile = false;
  submitted = false;
  themeSelect = '';
  @Output() close = new EventEmitter<boolean>();
  @Output() create = new EventEmitter<Object>();
  @Output() update = new EventEmitter<Object>();
  publishedForModel = '';
  publishedFor = [];
  idPost = '';
  loading = false;
  subscriber: Subscription;
  constructor(
    private sessionService: SessionService,
    private hubService: HubService,
    private aws: AwsHubHomeService,
    private globalService: GlobalService,
    public trans: TranslateService
  ){ 
      this.themeSelect = this.globalService.theme
      this.sessionIn = this.sessionService.sessionIn;
      this.idCompany = this.hubService.idCompany;
      this.subscriber = this.globalService.request.subscribe((res) => {
        if(res){
          switch (res['type']) {
            case 'UPDATELANG':
              this.translateService();
              break;
            case 'UPDATETHEME':
              this.themeSelect = this.globalService.theme
              break;
              default:
            break;
          }
        }
      });
  }

  ngOnInit(): void {
    if(this.type === 'EDIT'){
      this.idPost = this.itemUpdate['post']['id'];
      this.textPost = this.itemUpdate['post']['formatInformation']['comment'];
      this.containsPost =this.itemUpdate['post']['formatInformation']['images'];
    }
    this.translateService();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  async translateService(){
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;

    let publishedFor = await new Promise((resolve, reject) => {
      this.trans.get('publishedFor').subscribe((res) => {
        resolve(res);
      });
    });

    if(this.level === "MEMBER"){
      this.publishedFor = [
        {
          value: 'PUBLIC',
          name: 'PUBLICO',
          label: publishedFor['PUBLIC']
        },
        {
          value: 'USERS_COMPANY_AND_ASSOCIATED_HUB',
          name: 'SOLO ASOCIADOS DEL HUB',
          label: publishedFor['ONLY_ASSOCIATES']
        }
      ];
    }else{
      this.publishedFor = [
        {
          value: 'PUBLIC',
          name: 'PUBLICO',
          label: publishedFor['PUBLIC']
        },
        {
          value: 'USERS_COMPANY_AND_ASSOCIATED_HUB',
          name: 'SOLO ASOCIADOS DEL HUB',
          label: publishedFor['ONLY_ASSOCIATES']
        },
        {
          value: 'USERS_COMPANY_AND_MEMBER_HUB',
          name: 'SOLO MIEMBROS DEL HUB',
          label: publishedFor['ONLY_MEMBERS']
        },
      ];
    }

  }

  deletePhotoArr(i){
    this.containsPost.splice(i,1)
  }

  cerrar(){
    this.close.emit();
  }
  
  async submitPost(){
    if(this.textPost.trim().length > 0){
      this.loading = true;
      //let message = this.replaceURLWithHTMLLinks(this.textPost)
      let formatInformation = {
        comment: this.textPost.trim(),//message.split("\n").join("<br>"),
        images:this.containsPost
      };
      let body = {
      
          "type":this.hubService.idHub,
          "whoPublished":"COMPANY", 
          "idCompany":this.hubService.idCompany, 
          "whoPublishedGroup":"HUB",
          "publishedFor":this.publishedForModel, 
          "format":"GENERAL", 
          "formatInformation":formatInformation,  
          "showPost":true 
      
      };
      let result = await firstValueFrom(this.aws.createPosts(body));
      this.loading = false;
      if (result['status'] === false) {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: result['message'],
          type: 'TOAST',
        });
      } else if (result['status'] === true) {
        this.globalService.sendRequest({
          severity: 'success',
          summary: 'Éxito',
          detail: result['message'],
          type: 'TOAST',
        });
        this.resetData();
        this.create.emit(result['data']);
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'ERROR_THE_REQUEST',
          type: 'TOAST',
        });
      }

    }else{
      this.submitted = true;
    }
  }

  async submitPostUpdate(){
    if(this.textPost.trim().length > 0){
      this.loading = true;
      //let message = this.replaceURLWithHTMLLinks(this.textPost)
      let formatInformation = {
        comment: this.textPost.trim(),//message.split("\n").join("<br>"),
        images:this.containsPost
      };
      let body = {
          "id":this.idPost,
          "createdDate":this.itemUpdate['post']['createdDate'],
          "type":this.hubService.idHub,
          "whoPublished":"COMPANY", 
          "idCompany":this.hubService.idCompany, 
          "whoPublishedGroup":"HUB",
          "publishedFor":this.publishedForModel, 
          "format":"GENERAL", 
          "formatInformation":formatInformation,  
          "showPost":true 
      };
      let result = await firstValueFrom(this.aws.updatePost(body));
      this.loading = false;
      if (result['status'] === false) {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: result['message'],
          type: 'TOAST',
        });
      } else if (result['status'] === true) {
        this.globalService.sendRequest({
          severity: 'success',
          summary: 'Éxito',
          detail: result['message'],
          type: 'TOAST',
        });
        this.resetData();
        this.update.emit(result['data']);
        
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'ERROR_THE_REQUEST',
          type: 'TOAST',
        });
      }

    }else{
      this.submitted = true;
    }
  }


  nextFiles(data) {
    this.containsPost = this.containsPost.concat(data);
    this.uploadFile = false;
  }

  resetData(){
    this.containsPost = []
    this.publishedForModel = '';
    this.textPost = '';
    this.containsPost = [];
    this.cerrar();
  }

  addEmoji(selected) {
    const emoji: string = (selected.emoji as any).native;
    const input = this.input.nativeElement;
    input.focus();

    if (document.execCommand){ 
      var event = new Event('input');
      document.execCommand('insertText', false, emoji);
      return; 
    }
       // insert emoji on carrot position
    const [start, end] = [input.selectionStart, input.selectionEnd]; 
    input.setRangeText(emoji, start, end, 'end');
  }
}
