import { Component, OnInit , Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-sidebar-feedback',
  templateUrl: './sidebar-feedback.component.html',
  styleUrls: ['./sidebar-feedback.component.scss']
})
export class SidebarFeedbackComponent implements OnInit {
  feedback = true;
  @Output() closeSidebar = new EventEmitter<string>();
  text = "";
  rate = 0;
  constructor() { }

  ngOnInit(): void {
  }

  closeSidebarFeedback(){
    this.closeSidebar.emit();
  }
}
