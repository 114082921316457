<div class="preview-shop" *ngIf="item" (mouseover)="playVideo();" (mouseout)="pauseVideo(); ">
    <div class="logo">
        <img *ngIf="item['intro']['statusLogoAndCompanyName'] === 'PULLED_APART'"  src="{{item['logo']}}" alt="">
    </div>
    <ng-container *ngIf="item['intro']['videoOrBackgroundImage']['statusBackground'] === 'DEFAULT'">
        <video preload="none" [id]="item['id']" loop muted
            poster="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/o-shop/video_tienda_img.jpg">
            <source src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/videos/video_tienda.mp4">
        </video>
    </ng-container>
    <ng-container *ngIf="item['intro']['videoOrBackgroundImage']['statusBackground'] === 'IMAGE'">
        <div class="w-full h-full">
            <ng-container *ngIf="hover">
                <swiper [navigation]="false" class="mySwiper" [autoplay]="{ delay: 2500, disableOnInteraction: false }">
                    <ng-template *ngFor="let image of item['intro']['videoOrBackgroundImage']['image']" swiperSlide>
                        <img [src]="image.url" alt="" srcset="">
                    </ng-template>
                </swiper>
            </ng-container>
            <ng-container *ngIf="!hover">
                <img class="w-full h-full" style="object-fit: cover; border-radius: 24px;" [src]="item['intro']['videoOrBackgroundImage']['image'][0]['url']" alt="" srcset="">
            </ng-container>   
        </div>
    </ng-container>
    <ng-container *ngIf="item['intro']['videoOrBackgroundImage']['statusBackground'] === 'VIDEO'">
        <video [id]="item['id']" loop muted [poster]="item['intro']['videoOrBackgroundImage']['Imagevideo']">
            <source [src]="item['intro']['videoOrBackgroundImage']['video']">
        </video>
    </ng-container>
    <!--  -->
    
    <div class="content-all" [ngClass]="{'justify-content-end': item['intro']['orientation'] === 'RIGHT', 'justify-content-center': item['intro']['orientation'] === 'CENTER'}">
        <div style="    display: block;
        width: 100%;
        max-width: 70%;">
            <div *ngIf="item['intro']['statusLogoAndCompanyName'] === 'UNIFIED'" class="logo"
                [ngClass]="{'text-right': item['intro']['orientation'] === 'RIGHT', 'text-center': item['intro']['orientation'] === 'CENTER'}">
                <img src="{{item['logo']}}" alt="">
        </div>
        <div class="block-intro"
            [ngClass]="{'text-right': item['intro']['orientation'] === 'RIGHT', 'text-center': item['intro']['orientation'] === 'CENTER'}">
            
            <div *ngIf="item['intro']['statusEffect'] === 'GLOW'"
                class="business-name animation" [style.color]="item['intro']['statusColorCompanyNameEffects']"
                [style.text-shadow]="item['styleGlow']">
                {{item['businessName']}}
            </div>
            <div *ngIf="item['intro']['statusEffect'] !== 'GLOW'" class="business-name "
                [style.color]="item['intro']['statusColorCompanyNameEffects']">
                {{item['businessName']}}
            </div>
        </div>
        </div>
    </div>
</div>