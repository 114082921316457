<div *ngIf="data" class="grid" >
    <div class="col-12">
        <div class="card m-1 shadow-5 pb-0">
            <div class="grid card-list-business">
                <div class="avatar-logo-responsive col-12 align-items-center justify-content-center md:col-3">
                    <p-avatar  *ngIf="data.logo" [image]="data.logo" [style]="{'width':'6rem', 'height': '6rem'}"></p-avatar>
                    <ng-container *ngIf="data.status === 'REJECTED'">
                        <button pButton pRipple type="button" label="{{'see_motif' | translate }}" class="p-button-outlined p-button-rounded p-button-secondary" 
                        (click)="opSecond.toggle($event)" tooltipPosition="left"></button>
                    </ng-container>
                </div>
                <div class="col-12 md:col">
                    <div class="grid text-center md:text-left">

                        <div class="col-12 name-business">
                            <h2 class="m-0">{{data.businessName}}</h2>
                        </div>
                        <div class="col-12 pb-0 code-tributary-business">
                            <span class="text-sm font-bold">{{data.codeTributary}}</span>
                        </div>
                        <div class="col-12 pt-1 status-business">
                            <span *ngIf="data.status === 'PENDING'" class="text-sm font-bold text-yellow-500" translate>VERIFICATION_PENDING</span>
                            <span *ngIf="data.status === 'REJECTED'" class="text-sm font-bold text-pink-500" translate>REJECTED</span>
                            <span *ngIf="data.status === 'APPROVED'" class="text-sm font-bold text-green-500" translate>VERIFIED</span>
                        </div>
                    </div>
                </div>
                <div class="avatar-logo col-3 align-items-center justify-content-center">
                    <p-avatar  *ngIf="data.logo" [image]="data.logo" [style]="{'width':'6rem', 'height': '6rem'}"></p-avatar>
                    <ng-container *ngIf="data.status === 'REJECTED'">
                        <button pButton pRipple type="button" label="{{'see_motif' | translate }}" class="p-button-outlined p-button-rounded p-button-secondary" 
                         tooltipPosition="left" (click)="opSecond.toggle($event)"></button>
                    </ng-container>
                </div>
                <div class="col-12 icons-card-business pb-0">
                    <div class="grid">
                        <div class="col flex align-items-center justify-content-center cursor-pointer">
                            <i class="fas fa-user-friends pr-2" pTooltip="{{'USERS' | translate }}"></i>
                            <span>{{data.users}}</span>
                        </div>
                        <div class="col flex align-items-center justify-content-center tower">
                            <fieldset [disabled]="listHubs.length === 0" style="margin: 0;
                            padding: 0;
                            border: 0px;" (click)="op.toggle($event)">
                                <i *ngIf="!loading" class="fa-project-diagram fas pr-2 cursor-pointer"  pTooltip="{{'RED_U' | translate }}" ></i>
                            </fieldset>
                            
                            <i *ngIf="loading" class="pi pi-spin pi-spinner" style="font-size: 20px"></i>
                            <span *ngIf="!loading">{{listHubs.length}}</span>
                        </div>
                        <div class="col flex align-items-center justify-content-center badge cursor-pointer">
                            <i class="far fa-id-badge pr-2"  pTooltip="{{'COMPANY_PROFILE' | translate }}"></i>
                            <span class="font-bold" [style.color]="getColorScale(data.information)">{{data.information}}%</span>
                        </div>
                        <div class="col flex align-items-center justify-content-center" >
                            <ng-container *ngIf="data.status === 'APPROVED'">
                                <i class="fas fa-arrow-right cursor-pointer" (click)="openCompany(data['id'])"></i>
                            </ng-container>
                            <ng-container *ngIf="data.status === 'REJECTED'">
                                <button pButton icon="fas fa-sync" pTooltip="{{'to_update' | translate }}" class="p-button-info p-button-rounded" (click)="update(data)"></button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-overlayPanel #op [style]="{width: '250px'}">
        <ng-template pTemplate>
           <div class="grid">
               <div *ngFor="let item of listHubs" class="col-12 p-0">
                <div class="card m-1 shadow-5 p-2 grid cursor-pointer" (click)="openHub(item)">
                    <div class="col-3 text-center">
                        <p-avatar [image]="item.signatureLogo" shape="circle"></p-avatar>
                    </div>
                    <div class="col-9 flex align-items-center">
                        <span><strong>{{item.nameEs}}</strong></span>
                    </div>
                </div>
               </div>
           </div>
        </ng-template>
    </p-overlayPanel>
    <p-overlayPanel #opSecond>
        <ng-template pTemplate>
            {{data.commentRechazed}}
        </ng-template>
    </p-overlayPanel>
    <p-confirmPopup></p-confirmPopup>
</div>



