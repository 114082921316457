<div class="grid m-0">
    <div *ngIf="item && comment" class="col-12 p-0" style="display: flex;">
        <div class="image-profil">
            <p-avatar [image]="item['user']['avatar']" shape="circle"></p-avatar>
       </div>
       <div class="card shadow-1 comment-container" style="width: 100%;
       margin: 0px 0px 0px 5px;">
          <div class="name-b w-full">
             <span class="name"><strong>{{item['user']['name']}} {{item['user']['lastName']}}</strong>
              <ng-container *ngIf="comment['comment']['whoComment'] === 'COMPANY'">
                  <i class="ml-4 fas fa-building" pTooltip="{{item['company']['businessName']}}"></i>
              </ng-container>
              <i class="fas fa-ellipsis-h" *ngIf="item.edit && !statusEdit" (click)="showOptions(op, $event, item['comment'], 'ANSWER')"></i>
              <i class="fas fa-ellipsis-h" *ngIf="item.edit && statusEdit" (click)="showOptions(op, $event, item['comment'], 'ANSWER')"></i>
          </span>
            <ng-container *ngIf="!statusEdit">
                <p class="m-0 mt-2" [innerHtml]="item['comment']['comment']"></p>
            </ng-container>
            <ng-container *ngIf="statusEdit">
                <div class="flex">
                    <div class="text-area-comment">
                        <textarea #input [rows]="1" [cols]="30" [(ngModel)]="item['comment']['comment']" pInputTextarea autoResize="autoResize" class="text-area"
                            placeholder="{{ 'comment_here' | translate}}"></textarea>
                    </div>
                    <div class="button-comment" style="width: 100px;">
                        <button pButton pRipple type="button" icon="fas fa-grin" class="mr-2 p-button-rounded p-button-text p-button-plain hidden md:inline-flex"  (click)="opEmoji.toggle($event)"></button>
                        <button pButton pRipple type="button" icon="fas fa-sync"
                        [loading]="loadingCommentUpdate" (click)="editComment(item['comment'])" class="p-button-rounded p-button-secondary" [disabled]="item['comment']['comment'].trim().length === 0"></button>
                    </div>
                </div>
                
            </ng-container>
             
         </div>
       </div>
    </div>
</div>

 <div class="btn-answer flex align-items-center justify-content-end px-3"  *ngIf="item && comment">   
    <span class="bussiness" style="font-size: 11px;">
        <ng-container>{{item['comment']['createdDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a' }}</ng-container>
    </span>
  </div>

  <p-menu #op [popup]="true" [model]="items" appendTo="body"></p-menu>

  <p-menu #opCancel [popup]="true" [model]="itemsCancel" appendTo="body"></p-menu>

  <p-overlayPanel #opEmoji [showCloseIcon]="true" styleClass="overlay-emoji">
    <ng-template pTemplate>
      <emoji-mart  [i18n]="emojii18n" [enableFrequentEmojiSort]="false" [enableSearch]="false" [showPreview]="false" (emojiSelect)="addEmoji($event)"  [darkMode]="themeSelect === 'light' ? false : true"></emoji-mart> 
    </ng-template>
</p-overlayPanel>