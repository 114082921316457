import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physical-invoices',
  templateUrl: './physical-invoices.component.html',
  styleUrls: ['./physical-invoices.component.scss']
})
export class PhysicalInvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
