<div class="formgrid grid">
    <div class="field col-12 m-0">
        <label class="font-bold" translate>register_document</label>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>serial_correlative</label><span class="required">*</span>
        <input type="text" class="w-full" pInputText />
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>correlative_invoice</label><span class="required">*</span>
        <input type="text" class="w-full" pInputText />
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>date_of_issue</label><span class="required">*</span>
        <p-calendar [showIcon]="true" inputId="icon" appendTo="body"></p-calendar>
    </div>
    <div class="field col-12 m-0">
        <label class="font-bold" translate>enter_document_amount</label>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>subtotal_amount</label><span class="required">*</span>
        <input type="text" class="w-full" pInputText />
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>taxes</label><span class="required">*</span>
        <div>
            <input type="text" class="w-3 mr-2" pInputText /> % <span class="font-bold ml-3">USD 103.50</span>
        </div>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>total_value</label>
        <div class="flex align-items-center" style="height: 37px;">
            <span class="font-bold ml-3">USD 103.50</span>
        </div>
    </div>
    <div class="field col-12 m-0">
        <label class="font-bold" translate>additional_concepts</label>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>code_subject_to_detraction</label><span class="required">*</span>
        <p-dropdown appendTo="body" placeholder="Seleccionar" [style]="{width: '100%'}">
        </p-dropdown>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>detraction</label><span class="required">*</span>
        <div>
            <p-dropdown appendTo="body" placeholder="Seleccionar" [style]="{width: '41.6667%'}">
            </p-dropdown><span class="font-bold ml-3">USD 103.50</span>
        </div>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>retention</label><span class="required">*</span>
        <div>
            <p-dropdown appendTo="body" placeholder="Seleccionar" [style]="{width: '41.6667%'}">
            </p-dropdown><span class="font-bold ml-3">USD 103.50</span>
        </div>
    </div>
    <div class="field col-12 m-0">
        <label class="font-bold" translate>attach_documents</label>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>issuance_certificate_of_physical_invoices <span class="required">*</span></label>

    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>certificate_previously_attached</label>

    </div>
    <div class="field col-4 ">
        <div class="text-right">
            <button pButton pRipple type="button" icon="pi pi-paperclip" label="0"
                class="p-button-rounded p-button-secondary p-button-outlined mr-2">
            </button>
            <button pButton pRipple type="button" label="{{ 'attach' | translate }}"
                class="p-button-rounded p-button-secondary"></button>
        </div>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>invoice_(PDF)</label><span class="required">*</span>
    </div>
    <div class="field col-4 "></div>
    <div class="field col-4 ">
        <div class="text-right">
            <button pButton pRipple type="button" icon="pi pi-paperclip" label="0"
                class="p-button-rounded p-button-secondary p-button-outlined mr-2">
            </button>
            <button pButton pRipple type="button" label="{{ 'attach' | translate }}"
                class="p-button-rounded p-button-secondary"></button>
        </div>
    </div>
    <div class="field col-12 m-0">
        <label class="font-bold" translate>additional_data</label>
    </div>
    <div class="field col-12">
        <label class="font-bold" translate>comments_or_observations</label>
        <p-editor [style]="{'height':'220px'}">
            <ng-template pTemplate="header">
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
            </ng-template>
        </p-editor>
    </div>
    <div class="field col-4 ">
        <label class="font-bold" translate>other_files</label>
    </div>
    <div class="field col-4 "></div>
    <div class="field col-4 ">
        <div class="text-right">
            <button pButton pRipple type="button" icon="pi pi-paperclip" label="0"
                class="p-button-rounded p-button-secondary p-button-outlined mr-2">
            </button>
            <button pButton pRipple type="button" label="{{ 'attach' | translate }}"
                class="p-button-rounded p-button-secondary"></button>
        </div>
    </div>
</div>
