import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-shop',
  templateUrl: './company-shop.component.html',
  styleUrls: ['./company-shop.component.scss'],
})
export class CompanyShopComponent implements OnInit {
  @Input() item = null;
  hover = false;

  constructor() {}

  ngOnInit(): void {}

  playVideo() {
    let video: HTMLVideoElement = document.getElementById(this.item['id']) as HTMLVideoElement;
    if (video) {
      video.muted = true;
      var playPromise = video.play();
      if (playPromise === undefined) {
        playPromise.then(_ => {
          video.play();
        })
        .catch(error => {
          console.log(error)
        });
      }
    }
    this.hover = true;
  }

  pauseVideo() {
    let video: HTMLVideoElement = document.getElementById(this.item['id']) as HTMLVideoElement;
    if (video) {
      video.muted = true;
      var playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          video.pause();
        })
        .catch(error => {
          console.log(error)
        });
      }
    }
    this.hover = false;
  }
}
