<ng-container *ngIf="showTimer">
    <div class="containe-countdown">
        <div class="column-time">
            <div class="title-time" translate>primeng.days</div>
            <div class="number-time">{{daysHtml}}</div>
        </div>
        <div class="column-time border-left-1">
            <div class="title-time" translate>primeng.hours</div>
            <div class="number-time">{{hoursHtml}}</div>
        </div>
        <div class="column-time border-left-1">
            <div class="title-time" translate>primeng.minutes</div>
            <div class="number-time">{{minutesHtml}}</div>
        </div>
        <div class="column-time border-left-1">
            <div class="title-time" translate>primeng.seconds</div>
            <div class="number-time">{{secondsHtml}}</div>
        </div>
    </div>
</ng-container>
