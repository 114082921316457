import { Component, Input, OnInit ,ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { AwsSearchService } from 'src/app/services/main/home/search/aws-search.service';
import { TranslateService } from '@ngx-translate/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
@Component({
  selector: 'app-info-supplier-search',
  templateUrl: './info-supplier-search.component.html',
  styleUrls: ['./info-supplier-search.component.scss']
})
export class InfoSupplierSearchComponent implements OnInit {
  @Input() idCompany = "";

  displayInfoSupplier = true;
  loadComplete = true;
  companyTypes = [];
  //card left
  profile = null;
  typeCompany = "";
  years = "";
  files = [];

  informationColor = "";
  informationNum = 0;
  informationScore = 0;
  reputationColor = "";
  reputationNum = 0;
  reputationScore = 0;
  trustColor = "";
  trustNum = 0;
  trustScore = 0;

  //card resumen
  tabSidebar_info = 'resumen';
  tabDetail = false;

  categories = [];
  categoriesUnspsc = [];
  accountBanks = [];
  catalogues = [];
  clients = [];
  infraestructures = [];
  transports = [];
  drivers = [];
  delivery = [];
  payment = [];
  warranty = [];
  receipt = [];
  qualityManagement = null;
  securityHealthWork = null;
  responsabilitySocialEnvironmental = null;
  accountingInformation = null;
  reportsFiles = [];
  financialReference = [];
  chart: any;

  yearNow = new Date().getFullYear();
  annualSaleYear1 = null;
  annualSaleAmount1USD = null;
  annualSaleYear2 = null;
  annualSaleAmount2USD = null;
  annualSaleYear3 = null;
  annualSaleAmount3USD = null;
  
  certificateISO9001 = false;
  certificateGMP = false;
  certificateBCR = false;
  certificateHACCP = false;
  certificateBASC = false;
  certificateISO14001 = false;
  certificateISO18001 = false;

  //card contactos
  executives = [];
  // card estado
  clasification = "";
  status = "";

  quantityLineBusiness = 0;
  quantityExecutive = 0;
  quantityAccountBank = 0;

  quantityLineProduct = 0;
  quantityExperienceClients = 0;
  quantityInfraestructure = 0;
  quantityConditionTerms = 0;
  quantityQualityManagement = 0;
  quantityEnviromental = 0;
  quantitySecurity = 0;
  quantityAccountingInformation = 0;
  quantityFinancialReference = 0;

  list_activity_proccess = [];

  centerMap: google.maps.LatLngLiteral;
  zoomMap = 12;
  positionMarker: google.maps.LatLngLiteral;
  options = {
    draggable: true
  };
  listMap = false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  //MAP INFRAESTRUCTURA
  center: google.maps.LatLngLiteral;
  zoom = 12;
  temporalInfraestructure = null;

  listTaxConditions = [];
  taxConditions = [];

  comercialActivityList = [];

  lang = null;
  countryName = "";
  commercialSectionList = "";
  @Input() typeShow = "USER"
  @Input() plan = "USER"
  @Input() client_id = "";

  statusInvite = null;
  loadInvitation = false;
  infoInvitation = null;

  dataGr = []
  constructor(
    private globalService: GlobalService,
    private aws: AwsSearchService,
    public trans: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.getCompanyData();
    this.companyTypes = [
      {
        value: 'BOTH',
        label: `${this.globalService.lang === 'es' ? 'SERVICIOS, BIENES Y EQUIPOS' : 'SERVICES, GOODS AND EQUIPMENT'}`,
      },
      {
        value: 'GOOD',
        label: `${
          this.globalService.lang === 'es' ? 'BIENES Y EQUIPOS' : 'GOODS AND EQUIPMENT' }`,
      },
      {
        value: 'SERVICE',
        label: `${this.globalService.lang === 'es' ? 'SERVICIOS' : 'SERVICES'}`,
      },
    ];
    //centrar mapa
    navigator.geolocation.getCurrentPosition((position) => {
      this.centerMap = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    this.lang = this.globalService.lang;
    if(this.client_id.length > 0 && (this.idCompany != this.client_id)){
       this.getInvitation();
    }
   
  }

  async getInvitation(){
    let result = await firstValueFrom(this.aws.detailInviteSupplier({ idCompany: this.client_id , idSupplier: this.idCompany }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      if(result['data'] ){
        this.infoInvitation = result['data']
        this.statusInvite = this.infoInvitation['invitationStatus']
      }
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  async sendInvitation(){
    this.loadInvitation = true;
    try {
      let body = { 
        idCompany: this.client_id , 
        idSupplier: this.idCompany , 
        language: this.globalService.lang
      }
      let result = await firstValueFrom(this.aws.inviteSupplier(body));
      if(!result['status']){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      }else{
        this.globalService.sendRequest({ severity: 'success', summary: 'Success', detail: result['message'], type: 'TOAST', });
        this.getInvitation()
      }
    } catch (error) {
      if (error.status === 401) {
        this.globalService.sendRequest({severity: 'error', summary: 'Error', detail: error.error['message'], type: 'TOAST'});
      }else if (error.status === 400){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'Error de sistema.', type: 'TOAST' });
      }
    }
    this.loadInvitation = false;
  }

  async getCompanyData(){
    this.loadComplete = true;
    let result = await firstValueFrom(this.aws.detailCompanySearch({ idCompany: this.idCompany, language: this.globalService.lang }));
    this.loadComplete = false;
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.profile = result['data'];
      this.years = `${new Date().getFullYear() - new Date(this.profile['startCommercialActivity'] * 1000).getFullYear()} años(s)` ;
      this.typeCompany = this.companyTypes.find(c => c['value'] === this.profile['typeCompany']) ? this.companyTypes.find(c => c['value'] === this.profile['typeCompany'])['label'] : '';
      this.changeValues(this.profile);
      setTimeout(() => {
        this.createGraphic();
      }, 500);

      this.catalogues = result['data'] ? result['data']['catalogs'] : [];
      this.infraestructures = result['data'] ? result['data']['infrastructures'].map(c => {
        c['nameType'] = this.lang === 'en' ? c['nameTypeEn'] : c['nameTypeEs'];
        return c;
      }) : [];
      this.comercialActivityList = this.lang === 'es' ? result['data']['comercialActivityListEs'] : result['data']['comercialActivityListEn'];
      this.countryName = this.lang === 'es' ? result['data']['countryEs'] : result['data']['countryEn'];
      this.certificateISO9001 = result['data']['certificateISO9001'];
      this.certificateGMP = result['data']['certificateGMP'];
      this.certificateBCR = result['data']['certificateBCR'];
      this.certificateHACCP = result['data']['certificateHACCP'];
      this.certificateBASC = result['data']['certificateBASC'];
      this.certificateISO14001 = result['data']['certificateISO14001'];
      this.certificateISO18001 = result['data']['certificateISO18001'];
      this.commercialSectionList = this.lang === 'es' ? result['data']['commercialSectionEs'] : result['data']['commercialSectionEn'];
      this.categories = result['data'] ? result['data']['categories'] : [];
      this.categoriesUnspsc = result['data'] ? result['data']['categoriesUnspsc'] : [];
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }


  closeModal() {
    this.globalService.sendRequest({ type: 'CLOSEMODALSUPPLIERINFOSEARCH' });
  }

  changeValues(data) {
    this.informationScore = data['informationLevel'];
    this.informationNum = parseInt(data['informationLevel']);
    this.informationColor = this.getColorScale(this.informationNum);

    this.reputationScore = data['reputationLevel'];
    this.reputationNum = parseInt(data['reputationLevel']);
    this.reputationColor = this.getColorScale(this.reputationNum);

    this.trustScore = data['trustLevel'];
    this.trustNum = parseInt(data['trustLevel']);
    this.trustColor = this.getColorScale(this.trustNum);
  }

  getColorScale(number) {
    if (number >= 0 && number <= 45) {
      return 'red';
    } else if (number > 45 && number <= 55) {
      return 'orange';
    } else if (number > 55 && number <= 65) {
      return 'yellow';
    } else if (number > 65 && number <= 85) {
      return 'lemon';
    } else if (number > 85 && number <= 100) {
      return 'green';
    } else {
      return 'red';
    }
  }

  get formAmount() {
    let amount1 = this.annualSaleAmount1USD ? this.annualSaleAmount1USD : 0;
    let amount2 = this.annualSaleAmount2USD ? this.annualSaleAmount2USD : 0;
    let amount3 = this.annualSaleAmount3USD ? this.annualSaleAmount3USD : 0;
    let contador = 0;
    if (amount1 > 0) contador = contador + 1;
    if (amount2 > 0) contador = contador + 1;
    if (amount3 > 0) contador = contador + 1;
    let suma = amount1 + amount2 + amount3;
    if (suma === 0) {
      return 0;
    } else {
      let promedio = suma / contador;
      return promedio;
    }
  }

  get rangeSales() {
    if (this.formAmount >= 1 && this.formAmount <= 200000) {
      return `Menos de $200,000`;
    } else if (this.formAmount > 200000 && this.formAmount <= 500000) {
      return 'USD 200,001 - 500,000';
    } else if (this.formAmount > 500000 && this.formAmount <= 1000000) {
      return 'USD 500,001 - 1,000,000';
    } else if (this.formAmount > 1000000 && this.formAmount <= 3000000) {
      return 'USD 1,000,001 - 3,000,000';
    } else if (this.formAmount > 3000000 && this.formAmount <= 5000000) {
      return 'USD 3,000,001 - 5,000,000';
    } else if (this.formAmount > 5000000 && this.formAmount <= 10000000) {
      return 'USD 5,000,001 - 10,000,000';
    } else if (this.formAmount > 10000000 && this.formAmount <= 25000000) {
      return 'USD 10,000,001 - 25,000,000';
    } else if (this.formAmount > 25000000 && this.formAmount <= 50000000) {
      return 'USD 25,000,001 - 50,000,000';
    } else if (this.formAmount > 50000000 && this.formAmount <= 75000000) {
      return 'USD 50,000,001 - 75,000,000';
    } else if (this.formAmount > 75000000 && this.formAmount <= 100000000) {
      return 'USD 75,000,001 - 100,000,000';
    } else if (this.formAmount > 100000000) {
      return `Más de $100,000,001`;
    } else {
      return '-';
    }
  }

  createGraphic(){
    this.dataGr = this.lang === 'es' ? this.profile.corporateValuesListEs : this.profile.corporateValuesListEn;
  }
  
  getRango(promedio) {
    if (promedio >= 1 && promedio <= 1000) {
      return `HASTA US$ 1,000`;
    } else if (promedio > 1000 && promedio <= 5000) {
      return 'US$ 1,001 - US$ 5,000';
    } else if (promedio > 5000 && promedio <= 10000) {
      return 'US$ 5,001 - US$ 10,000';
    } else if (promedio > 10000 && promedio <= 20000) {
      return 'US$ 10,001 - US$ 20,000';
    } else if (promedio > 20000 && promedio <= 50000) {
      return 'US$ 20,001 - US$ 50,000';
    } else if (promedio > 50000 && promedio <= 100000) {
      return 'US$ 50,001 - US$ 100,000';
    } else if (promedio > 100000 && promedio <= 250000) {
      return 'US$ 100,001 - US$ 250,000';
    } else if (promedio > 250000 && promedio <= 500000) {
      return 'US$ 250,001 - US$ 500,000';
    } else if (promedio > 500000 && promedio <= 1000000) {
      return 'US$ 500,001 - US$ 1M';
    } else if (promedio > 1000000 && promedio <= 2500000) {
      return 'US$ 1M - US$ 2.5M';
    } else if (promedio > 2500000 && promedio <= 5000000) {
      return 'US$ 2.5M - US$ 5M';
    } else if (promedio > 5000000 && promedio <= 10000000) {
      return 'US$ 5M - US$ 10M';
    } else if (promedio > 10000000 && promedio <= 20000000) {
      return 'US$ 10M - US$ 20M';
    } else if (promedio > 20000000 && promedio <= 50000000) {
      return 'US$ 20M - US$ 50M';
    } else if (promedio > 50000000) {
      return `MÁS DE US$ 50M`;
    }else{
      return '-'
    }
  }

  closeListMap(){
    if (this.listMap = true) {
       document.querySelector('.container-list-map').classList.remove('animate__animated' , 'animate__slideInRight');
       document.querySelector('.container-list-map').classList.add('animate__animated', 'animate__slideOutRight');
       setTimeout(() => {
          this.listMap = false;
       }, 1000);
      
    }
  }

  openLocation(item){
      this.centerMap = {
        lat: item.lat,
        lng: item.lng,
      };
      this.center = {
        lat: item.lat,
        lng: item.lng,
      };
      this.closeListMap();
  }

  openInfo(marker: MapMarker, content) {
    this.temporalInfraestructure = content;
    this.infoWindow.open(marker)
  }

}
