<ng-container *ngIf="message">
    <div class="message-chat" [ngClass]="{ 'me': message.me }" >
        <div class="avatarContent">
            <span class="border-avatar" [style.border-color]="colorAvatar">
                <p-avatar [image]="avatar" styleClass="avatarChat" shape="circle"></p-avatar>
            </span>
        </div>
        <div class="messageContent">
            <div class="container-message">
                <div class="container-name">
                    <div [style.color]="colorName" class="name-participant " > 
                        <ng-container *ngIf="name === 'NOT_USER_NAME'">
                           <b translate>{{name}}</b>  - {{correlativeParticipant}}
                        </ng-container> 
                        <ng-container *ngIf="name !== 'NOT_USER_NAME'">
                            {{name}}
                        </ng-container>
                    </div> 
                    <div class="name-participant" *ngIf="company" style="font-size: 10px;">
                        {{company}}
                    </div>
                </div>
               <div class="container-time"><i class="far fa-clock"></i> {{ (time * 1000) | date: 'h:mm a'  }} </div> 
            </div>
            <div class="container-message-responsive align-items-center">
                <span style="display: inline-block;" class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    <span [style.color]="colorName" class="name-participant block font-bold" > 
                        <ng-container *ngIf="name === 'NOT_USER_NAME'">
                           <b translate>{{name}}</b>  - {{correlativeParticipant}}
                        </ng-container> 
                        <ng-container *ngIf="name !== 'NOT_USER_NAME'">
                            {{name}}
                        </ng-container>
                    </span> 
                    <span class="name-participant block font-bold" *ngIf="company">
                        {{company}}
                    </span>
                </span>
            </div>
            <div class="content-html-message" [innerHtml]="messageHtml"></div>
            <ng-container *ngIf="files.length > 0">
                <div class="">
                    <div *ngFor="let file of files;let i = index;" class="col-12 flex justify-content-between border-files">
                        <div class="w-2rem flex align-items-center justify-content-center mr-2">
                            <i class="far {{getIcon(file.name)}} text-2xl" [style.color]="getColor(file.name)"></i>
                        </div>
                        <div class="flex align-items-center overflow-hidden" style="width: 100%;">
                            <span style="width: calc(100% - 30px)!important;" class=" overflow-hidden text-left text-overflow-ellipsis white-space-nowrap block">
                                <a target="_blank" class="text-900 no-underline">{{file.name}}</a>
                            </span>
                        </div>

                        <div class="downloadBtn">
                            <button pButton (click)="download(file.url)" pRipple style="padding: 8px;padding: 8px;height: 25px;width: 25px;" type="button" icon="fas fa-download" class="p-button-rounded p-button-success p-button-outlined"></button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="generalFiles.length > 0">
                    <div class="border-images cursor-pointer my-2">
                        <ng-container *ngIf="generalFiles.length === 1">
                            <div class="image-grid-1 w-full h-full">
                                <ng-container *ngIf="generalFiles[0].type === 'PHOTO'">
                                    <img src="{{generalFiles[0].url}}" class="image-grid-col-2 image-grid-row-2" (click)="openPreviewIndex(0)">    
                                </ng-container>
                                <ng-container *ngIf="generalFiles[0].type === 'VIDEO'">
                                    <video id="background-video" muted playsinline (click)="openPreviewIndex(1)">
                                        <source [src]="generalFiles[0].url">
                                    </video>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="generalFiles.length === 2">
                            <div class="image-grid-2">
                                <ng-container *ngIf="generalFiles[0].type === 'PHOTO'">
                                    <img src="{{generalFiles[0].url}}" (click)="openPreviewIndex(0)">
                                </ng-container>
                                <ng-container *ngIf="generalFiles[1].type === 'PHOTO'">
                                    <img src="{{generalFiles[1].url}}" (click)="openPreviewIndex(1)">
                                </ng-container>
                                <ng-container *ngIf="generalFiles[0].type === 'VIDEO'">
                                    <video id="background-video" muted playsinline (click)="openPreviewIndex(0)">
                                        <source [src]="generalFiles[0].url">
                                    </video>
                                </ng-container>
                                <ng-container *ngIf="generalFiles[1].type === 'VIDEO'">
                                    <video id="background-video" muted playsinline (click)="openPreviewIndex(1)">
                                        <source [src]="generalFiles[1].url">
                                    </video>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="generalFiles.length >= 3">
                            <div class="image-grid-3">
                                <ng-container *ngIf="generalFiles[0].type === 'PHOTO'">
                                    <img class="image-grid-col-2 image-grid-row-2" src="{{generalFiles[0].url}}" (click)="openPreviewIndex(0)">  
                                </ng-container>
                                <ng-container *ngIf="generalFiles[1].type === 'PHOTO'">
                                    <img src="{{generalFiles[1].url}}" alt="architecture" (click)="openPreviewIndex(1)">
                                </ng-container>
                                <ng-container *ngIf="generalFiles[2].type === 'PHOTO'">
                                    <span class="relative">
                                        <ng-container *ngIf="generalFiles.length > 3">
                                            <span class="absolute w-full h-full flex align-items-center justify-content-center" style="background: rgba(0, 0, 0, 0.5);">
                                                <span class="text-2xl" style="color: white;">+ {{generalFiles.length - 3}}</span>
                                            </span>
                                        </ng-container>
                                        <img src="{{generalFiles[2].url}}" alt="architecture" (click)="openPreviewIndex(2)">
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="generalFiles[0].type === 'VIDEO'">
                                    <video  class="image-grid-col-2 image-grid-row-2" id="background-video" muted playsinline (click)="openPreviewIndex(0)">
                                        <source [src]="generalFiles[0].url">
                                    </video>
                                </ng-container>
                                <ng-container *ngIf="generalFiles[1].type === 'VIDEO'" (click)="openPreviewIndex(1)">
                                    <video id="background-video" muted playsinline>
                                        <source [src]="generalFiles[1].url">
                                    </video>
                                </ng-container>
                                <ng-container *ngIf="generalFiles[2].type === 'VIDEO'" (click)="openPreviewIndex(2)">
                                    <span class="relative">
                                        <ng-container *ngIf="generalFiles.length > 3">
                                            <span class="absolute w-full h-full flex align-items-center justify-content-center" style="background: rgba(0, 0, 0, 0.5);">
                                                <span class="text-2xl" style="color: white;">+ {{generalFiles.length - 3}}</span>
                                            </span>
                                        </ng-container>
                                        <video id="background-video" muted playsinline>
                                                <source [src]="generalFiles[2].url">
                                        </video>
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
            </ng-container>
            <div *ngIf="message.quantityAnswer > 0 && message.typeMessage === 'PRINCIPAL' && thread === 'NO'" class="container-response" (click)="openResponse()">
                <span class="ml-2 font-bold" style="color: var(--primary-color);">{{message.quantityAnswer}} Respuestas</span> <i class="mx-2 fas fa-chevron-right"></i>
            </div>
            <div class="options-message">
                <ng-container *ngIf="thread === 'NO'">
                    <i class="fas fa-chevron-down" (click)="menuChat.toggle($event)"></i>
                </ng-container>
            </div>
            <div class="container-message-responsive align-items-center">
                <span class="time block text-right"><i class="mr-2 far fa-clock"></i>{{ (time * 1000) | date:'h:mm:ss a' }} </span> 
            </div>
        </div>
    </div>
    <app-preview-photo-video [listPreview]="generalFiles" (closeComponent)="previewSidebarImage = false"
      *ngIf="previewSidebarImage" [section]="'CHAT'" [dataUser]="dataUser" [index]="previewIndex"></app-preview-photo-video>
</ng-container>

<!-- options chat -->
<p-menu #menuChat [popup]="true" [model]="itemsChat" appendTo="body"></p-menu>

<!-- PREVIEW PHOTO VIDEO -->

<p-sidebar [(visible)]="sidebarNotify" position="right" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" styleClass="sidebar-notify" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center" style="border-bottom: 1px solid var(--surface-d); height: 48.96px;">
                <div class="col-12 flex align-items-center justify-content-between">
                    <h2 class="m-0" translate>NOTIFY</h2>
                    <button pButton pRipple type="button" label="{{'NOTIFY_ALL' | translate}}" class="p-button-rounded w-button p-button-secondary md:hidden" [loading]="loadNotifyAll" [disabled]="loadNotifyAll || loadNotify" (click)="sendNotifyAll()"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 165px)'}">
                <div class="grid m-0">
                    <div class="col-12" *ngIf="chatType === 'USERS'">
                        <p-table [value]="participants" styleClass="table-personality-rq" [scrollable]="true" [loading]= "loadTableNotify" [(selection)]="selectedParticipants">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 50px; width: 50px;">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th style="min-width: 50px; width: 50px;">
                                       
                                    </th>
                                    <th style="min-width: 150px;" translate>
                                        name
                                    </th>
                                    <th style="min-width: 150px;" translate>
                                        email
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                    </td>
                                    <td>
                                        <p-avatar styleClass="avatar-chat" [image]="item.avatar && item.avatar.length > 0 ? item.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{item.email}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="col-12" *ngIf="chatType === 'COMPANY'">
                        <p-accordion styleClass="accordion-request-quotation">
                            <ng-container *ngFor="let company of participants">
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="grid m-0 w-full">
                                            <div class="col-12 p-0 flex align-items-center justify-content-between">
                                                <h4 class="m-0">{{company.businessName}}</h4>

                                                <p-avatar styleClass="avatar-chat" [image]="company.logo && company.logo.length > 0 ? company.logo : 'assets/images/user.svg'" shape="circle"></p-avatar>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="col-12">
                                            <p-table [value]="company.participants" styleClass="table-personality-rq" [scrollable]="true" [loading]= "loadTableNotify" [(selection)]="selectedParticipants">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 50px; width: 50px;">
                                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                                        </th>
                                                        <th style="min-width: 50px; width: 50px;">
                                                            
                                                        </th>
                                                        <th style="min-width: 150px;" translate>
                                                            name
                                                        </th>
                                                        <th style="min-width: 150px;" translate>
                                                            email
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-item>
                                                    <tr>
                                                        <td>
                                                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                                        </td>
                                                        <td>
                                                            <p-avatar styleClass="avatar-chat" [image]="item.avatar && item.avatar.length > 0 ? item.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
                                                        </td>
                                                        <td>
                                                            {{item.name}}
                                                        </td>
                                                        <td>
                                                            {{item.email}}
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </ng-template>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 px-0 pb-0">
            <button pButton pRipple type="button" label="{{'NOTIFY' | translate}}" class="p-button-rounded w-button p-button-success mr-2" [disabled]="selectedParticipants.length === 0 || loadNotifyAll || loadNotify" [loading]="loadNotify" (click)="sendNotify()"></button>
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" class="p-button-rounded w-button p-button-danger mr-2" (click)="closeSidebarNotify()"></button>
           <button pButton pRipple type="button" label="{{'NOTIFY_ALL' | translate}}" class="p-button-rounded w-button p-button-secondary hidden md:inline-flex" [loading]="loadNotifyAll" [disabled]="loadNotifyAll || loadNotify" (click)="sendNotifyAll()"></button>
        </div>
    </div>
</p-sidebar>