<div class="feedback">
    <label class="angry" pTooltip="{{ 'DEFICIENT' | translate }}">
        <input type="radio" value="1" name="feedback" [(ngModel)]="valueRating" />
        <div>
            <svg class="eye left">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="eye right">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="mouth">
                <use href="#mouth" xlink:href="#mouth"/>
            </svg>
        </div>
    </label>
    <label class="sad" pTooltip="{{ 'REGULAR' | translate }}">
        <input type="radio" value="2" name="feedback" [(ngModel)]="valueRating" />
        <div>
            <svg class="eye left">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="eye right">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="mouth">
                <use href="#mouth" xlink:href="#mouth"/>
            </svg>
        </div>
    </label>
    <label class="ok" pTooltip="{{ 'GOOD' | translate }}">
        <input type="radio" value="3" name="feedback" [(ngModel)]="valueRating" />
        <div></div>
    </label>
    <label class="good" pTooltip="{{ 'VERY_GOOD' | translate }}">
        <input type="radio" value="4" name="feedback" [(ngModel)]="valueRating" />
        <div>
            <svg class="eye left">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="eye right">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="mouth">
                <use href="#mouth" xlink:href="#mouth"/>
            </svg>
        </div>
    </label>
    <label class="happy"  pTooltip="{{ 'EXCELLENT' | translate }}">
        <input type="radio" value="5" name="feedback" [(ngModel)]="valueRating" />
        <div>
            <svg class="eye left">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
            <svg class="eye right">
                <use href="#eye" xlink:href="#eye"/>
            </svg>
        </div>
    </label>
</div>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
        <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1">
        </path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
        <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
    </symbol>
</svg>