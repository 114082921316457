<div class="grid mt-3">
    <div class="col-12" style="margin-top: 7px;">
        <div class="grid">
            <div class="col-12">
                <div class="timeline ui-fluid">
                    <div class="grid">
                        <ng-container *ngFor="let log of list">
                            <ng-container *ngIf="log.action === 'CREATE' || log.action === 'UPDATESOLPED'">
                                <div class="col-4">
                                    <span class="event-time"> <i class="fas fa-clock"></i>
                                        {{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <ng-container *ngIf="log.content.proccess_name">
                                            <span><b>Nombre del envío:</b> {{log.content.proccess_name}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.solped">
                                            <span><b>Solped:</b> {{log.content.solped}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.solped_pos">
                                            <span><b>Solped - Pos:</b> {{log.content.solped_pos}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.location">
                                            <span><b>Ubicación:</b> {{getSplit(log.content.location)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.area">
                                            <span><b>Área:</b> {{getArea(log.content.area)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.managment">
                                            <span><b>Gerencia:</b> {{getManagment(log.content.managment)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.premises">
                                            <span><b>Sede:</b> {{getSplitDataPremises(log.content.premises)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.company">
                                            <span><b>Empresa:</b>
                                                {{getSplitDataCompanies(log.content.company)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.level_priority">
                                            <span><b>Nivel de Urgencia:</b>
                                                {{getPriority(log.content.level_priority)}}</span><br>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'SENDSOLPED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <ng-container *ngFor="let prov of log.content.providers; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.businessName}}</span><br>
                                        </ng-container>
                                        <span><b>Duración del proceso:</b> {{convert(log.content.duration_init)}} -
                                            {{convert(log.content.duration_finish)}}</span><br>
                                        <span>{{log.content.inactivity_reminder.length}} recordatorios de
                                            inactividad</span><br>
                                        <span>{{log.content.closing_reminder.length}} recordatorios de cierre</span><br>
                                        <span><b>Plazo de tiempo para consulta:</b> {{log.content.deadline_for_inquiries
                                            ? convert(log.content.deadline_for_inquiries) : ''}}</span><br>
                                        <span><b>Absolución de dudas:</b> {{log.content.deadline_to_answer_inquiries ?
                                            convert(log.content.deadline_to_answer_inquiries) : ''}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'CURRENTSOLPED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span><b>Duración del proceso:</b> {{convert(log.content.duration_init)}} -
                                            {{convert(log.content.duration_finish)}}</span><br>
                                        <span>{{log.content.inactivity_reminder.length}} recordatorios de
                                            inactividad</span><br>
                                        <span>{{log.content.closing_reminder.length}} recordatorios de cierre</span><br>
                                        <span><b>Plazo de tiempo para consulta:</b> {{log.content.deadline_for_inquiries
                                            ? convert(log.content.deadline_for_inquiries) : ''}}</span><br>
                                        <span><b>Absolución de dudas:</b> {{log.content.deadline_to_answer_inquiries ?
                                            convert(log.content.deadline_to_answer_inquiries) : ''}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'ACCEPTEDPOL'">
                                <div class="col-4">
                                    <span class="event-time"> <i class="fas fa-clock"></i>
                                        {{convert(log.date_created)}}</span>

                                    <i class="fa {{log.icon}}" style="color:#adeda8"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text">
                                        {{log.content.label}}
                                    </span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'STATUSQUOTEYELLOW'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:yellow"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>{{log.content.time}}</span><br>
                                    </div>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="log.action === 'NOTPARTICIPIED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:black"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:black">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span><b>Motivo:</b> </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                        <span>{{log.content.time}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'LINKEXTERNALPROCESS'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:black"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:black">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span><b>Contactos invitados:</b> </span><br>
                                        <ng-container *ngFor="let prov of log.content.users; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.name}} {{prov.lastname}}
                                                ({{prov.email}})</span><br>
                                        </ng-container>
                                        <span><b>Motivo:</b> </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'STATUSQUOTEGREEN'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:green"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>{{log.content.time}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'RECOTE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}: </b></span>
                                    <div class="event-content">
                                        <span>{{log.content.nameProvider}}</span><br>
                                        <span>{{log.content.mails}}</span><br>
                                        <ng-container *ngIf="log.content.date_finish">
                                            <span><b>Cierre del proceso:</b>
                                                {{convert(log.content.date_finish)}}</span><br>
                                        </ng-container>
                                        <ng-container *ngIf="log.content.type">
                                            <span><b>Tipo de recotización:</b>
                                                <ng-container *ngIf="log.content.type === 'GENERAL' ">GENERAL
                                                </ng-container>
                                                <ng-container *ngIf="log.content.type === 'ECONOMIC'">ECONOMICA
                                                </ng-container>
                                                <ng-container *ngIf="log.content.type === 'TECHNICAL'">TECNICA
                                                </ng-container>
                                            </span><br>
                                        </ng-container>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'DETAIL'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'CANCELQUOTE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:red"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>Sustento: </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'RECOVERQUOTE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#59c429"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:#59c429">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'MODIFIEDSIMPLE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'ADDPROVIDER'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}} </b></span>
                                    <div class="event-content">
                                        <ng-container *ngFor="let prov of log.content.providers; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.businessName}}</span><br>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'REMINDER'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#59c429"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:#59c429">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span><b>Duración del proceso:</b> {{convert(log.content.duration_init)}} -
                                            {{convert(log.content.duration_finish)}}</span><br>
                                        <span>{{log.content.inactivity_reminder.length}} recordatorios de
                                            inactividad</span><br>
                                        <span>{{log.content.closing_reminder.length}} recordatorios de cierre</span><br>
                                        <span><b>Plazo de tiempo para consulta:</b>
                                            {{convert(log.content.deadline_for_inquiries)}}</span><br>
                                        <span><b>Absolución de dudas:</b>
                                            {{convert(log.content.deadline_to_answer_inquiries)}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'STATUSPROCESS'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'COMPARATIVE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'NOTWINNERPROVIDER'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:red"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'FINALIZED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:green"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'REOPEN'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:green"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>Sustento: </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'WINNERPROVIDER'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <ng-container *ngFor="let prov of log.content.providers; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.businessName}} ({{prov.currency}}
                                                {{prov.amount_assigned | number:'1.2-2'}}) -
                                                {{prov.reason ? getReason(prov.reason) : prov.other_reason}}</span><br>
                                        </ng-container>
                                        <span><b>Sustento:</b> </span><br>
                                        <div class="content-html" [innerHtml]="log.content.sustenance"></div><br>
                                        <span *ngIf="log.content.files.length > 0">Adjuntos: </span><br>
                                        <span *ngFor="let file of log.content.files">- <a
                                                href="{{file.fileLocation}}">{{file.fileName}}</a></span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'SOL-ACCESS-SOLPED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}: </b></span>
                                    <div class="event-content">
                                        <span>{{log.content.nameProvider}}</span><br>
                                        <span><b>Sustento:</b> </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'SUMMARYQUOTE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'SUMMARYQUOTEFILE'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'PURCHASEORDER'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#27a9a9"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:#27a9a9">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>{{log.content.provider}}</span><br>
                                        <span><b translate>purchase_order</b> {{log.content.oc}}</span><br>
                                        <span><b>Fecha de entrega:</b> {{convert(log.content.date)}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'FORWARD'">
                                <div class="col-4">
                                    <span class="event-time">{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner" style="color:#009688">{{log.user.name}} -
                                        {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}: </b></span>
                                    <div class="event-content">
                                        <span>{{log.content.nameProvider}}</span><br>
                                        <span>{{log.content.mails}}</span><br>
                                        <ng-container *ngIf="log.content.date_finish">
                                            <span><b>Cierre del proceso:</b> {{convert(log.content.date_finish)}}</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'EVALUATIONTECHNICAL'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color:#009688"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}} :</b></span>
                                    <div class="event-content">
                                        <ng-container *ngFor="let prov of log.content.providers; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.businessName}}</span><br>
                                        </ng-container>
                                        <span><b>Usuarios:</b> </span><br>
                                        <ng-container *ngFor="let prov of log.content.partners; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.name}} ({{prov.email}})</span><br>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'EVALUATIONTECHNICALSEND'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color: #00ccff"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <ng-container *ngFor="let prov of log.content.providers; let i = index;">
                                            <span><b>{{i+1}}.-</b> {{prov.businessName}}</span><br>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'EVALUATIONTECHNICALOBSERVED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color: #ffc107"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>{{log.content.user}}</span><br>
                                        <span><b>Motivo:</b> </span><br>
                                        <div class="content-html" [innerHtml]="log.content.reason"></div><br>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="log.action === 'EVALUATIONTECHNICALRETIRED'">
                                <div class="col-4">
                                    <span class="event-time"><i
                                            class="fas fa-clock"></i>{{convert(log.date_created)}}</span>
                                    <i class="fa {{log.icon}}" style="color: #00cc00"></i>
                                </div>
                                <div class="col-8">
                                    <span class="event-owner">{{log.user.name}} - {{log.business_name}}</span>
                                    <span class="event-text"><b>{{log.content.label}}</b></span>
                                    <div class="event-content">
                                        <span>{{log.content.user}}</span><br>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- <ng-container *ngIf="log.action === 'MODIFIEDSERVICE'">
                                    <div class="col-4">
                                        <span class="event-time">{{convert(log.time)}}</span>
                                        <i class="fa {{log.icon}}" style="color:#009688"></i>
                                    </div>
                                    <div class="col-8">
                                        <span class="event-owner" style="color:#009688">{{log.nameUser}}</span>
                                        <span class="event-text"><b>{{log.content.label}}</b></span>
                                        <div class="event-content">
                                            <span *ngFor="let tab of log.content.tabs">- {{tab}}</span><br>
                                        </div>
                                    </div>
                                </ng-container> -->

                            <!-- <ng-container *ngIf="log.action === 'SUMMARYQUOTEMAIL'">
                                    <div class="col-4">
                                        <span class="event-time">{{convert(log.time)}}</span>
                                        <i class="fa {{log.icon}}" style="color:#009688"></i>
                                    </div>
                                    <div class="col-8">
                                        <span class="event-owner" style="color:#009688">{{log.nameUser}}</span>
                                        <span class="event-text"><b>{{log.content.label}}</b></span>
                                        <div class="event-content">
                                            <span>Correos: </span><br>
                                            <span *ngFor="let mail of log.content.mails">- {{mail}}</span><br>
                                        </div>
                                    </div>
                                </ng-container> -->
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>