<p-sidebar [(visible)]="feedback" position="right" 
    [style]="{width: '35vw'}" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" styleClass="sidebar-zindex" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid" style="border-bottom: 1px solid var(--surface-d); height: 52.6px; align-items: center;">
                <h2 class="m-0" style="padding: 0.5rem;" translate>RETROALIMENTACIÓN</h2>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 177px)'}">
                <div class="col-12 text-center">
                    <span class="section-subsection" style="border-color: rgb(255, 80, 80);"><span class="font-bold" style="font-size: 16px;">&nbsp;&nbsp;CBN NETWORK - RED</span></span>
                </div>
                <div class="col-12 text-center">
                    <p  class="font-bold">¡Gracias por tomarte unos minutos! <br> Tu opinión es muy valiosa para nosotros. <br> Cuéntanos que es lo que más te gustó y que no de nuestro servicio. </p>
                </div>
                <div class="col-12 text-center rating-feedback flex justify-content-center">
                    <!-- <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome-o'"></bar-rating> -->
                </div>
                <div class="col-12 text-center">
                    <p  class="font-bold">Descríbenos tu experiencia</p>
                </div>
                <div class="col-12">
                    <p-editor [(ngModel)]="text" [style]="{'height':'220px'}">
                        <ng-template pTemplate="header">
                            <span class="ql-formats">
                                <button type="button" class="ql-bold" aria-label="Bold"></button>
                                <button type="button" class="ql-italic" aria-label="Italic"></button>
                                <button type="button" class="ql-underline" aria-label="Underline"></button>
                            </span>
                        </ng-template>
                    </p-editor>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 pb-0">
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" class=" p-button-rounded w-button p-button-danger mr-2"
                (click)="closeSidebarFeedback()"></button>

                <button pButton pRipple type="button" label="{{'SEND' | translate}}" class=" p-button-rounded w-button p-button-success"
                (click)="closeSidebarFeedback()"></button>
        </div>
    </div>
</p-sidebar>
