<p-sidebar [(visible)]="notifications" position="right" [baseZIndex]="10000" styleClass="sidebar-notification-responsive" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center" style="height: 48.96px; border-bottom: 1px solid var(--surface-d);">
                <div class="col-12">
                    <h2 class="m-0" translate>NOTIFICATIONS</h2>
                </div>
            </div>
        </div>

        <div class="col-12 p-0">
                    <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 165px)'}">
                        <div class="w-full h-full"  *ngIf="loading">
                            <p-skeleton width="100%" height="100px"  borderRadius="24px"></p-skeleton> 
                        </div>
                        <ng-container *ngIf="statusLoad === 'FINISH' && notificationList.length > 0">
                            <ng-container *ngFor="let item of notificationList">
                                <app-list-notification [data]="item" (close)="closeModal()"></app-list-notification>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="statusLoad === 'FINISH' && notificationList.length === 0">
                            <div class="grid h-full flex justify-content-center flex-column">
                                <div class="col-12 text-center">
                                    <i class="far fa-bell" style="font-size: 150px;"></i>
                                </div>
                                <div class="col-12 text-center">
                                    <h2 class="m-0" translate>SUBTITTLE_NOTIFICATIONS</h2>
                                </div>
                                <div class="col-12 text-center">
                                    <h3 class="m-0" translate>DESCRIPTION_NOTIFICATIONS</h3>
                                </div>
                            </div>
                        </ng-container>
                        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up"></p-scrollTop>
                    </p-scrollPanel>
            
        </div>
        <div class="col-12 px-0 pb-0">
            <button pButton pRipple type="button" label="{{'SEE_MORE' | translate}}" class="p-button-rounded w-button p-button-secondary mr-2" routerLink="/home/all-notifications" (click)="closeModal()"></button>
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" class="p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
        </div>
    </div>
</p-sidebar>
