import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
@Injectable({
  providedIn: 'root'
})
export class AwsSharedService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      'Auth': this.sessionService.token
    }),
  };

  download_file(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' })
  }

  getCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/company`, body,  this.httpOptions);
  }

  getNotificationsNotShow(){
    return this.http.post(`${environment.baseUrlAPI}/user/notificationsNotShow`, {}, this.httpOptions);
  }

  updateNotificationsToTrue(){
    return this.http.post(`${environment.baseUrlAPI}/user/updateNotificationsToTrue`, {}, this.httpOptions);
  }

  listChatParticipantCompanyUnread(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listChatParticipantCompanyUnread`, body, this.httpOptions);
  }

  listChatParticipantUnreadAll(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listChatParticipantUnreadAll`, body, this.httpOptions);
  }

  quantityMessageUnreadChatCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/quantityMessageUnreadChatCompany`, body, this.httpOptions);
  }

  quantityMessageUnreadChatAll(body){
    return this.http.post(`${environment.baseUrlAPI}/user/quantityMessageUnreadChatAll`, body, this.httpOptions);
  }

  updatePreference(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/updateUserPreferencesAttributes`,body, this.httpOptions);
  }

  logout() {
    const httpOptionsLogout = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': 'apliclientesksks',
        'client-hash': 'apliclientesksks',
        Auth: this.sessionService.revokeToken,
      }),
    };
    return this.http.post(`${environment.baseUrlAPI}/logout`,{}, httpOptionsLogout);
  }

  getPlanUserCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getPlanUserCompany`, body,  this.httpOptions);
  }

  getCompaniesVerified() {
    return this.http.post(`${environment.baseUrlAPI}/user/getCompanyVerifyByUser`,{}, this.httpOptions);
  }

  dependencyCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/hub/dependencyCompanyHub`, body, this.httpOptions);
  }

  uploadFileS3All(body){
    return this.http.post(`${environment.baseUrlAPI}/user/uploadFile`, body, this.httpOptions);
  }

  updateUserAttributes(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/updateUserAttributes`,body, this.httpOptions);
  }

  getUpdatefollowSetps(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/UpdatefollowSetps`,body,this.httpOptions);
  }

  getCountries() {
    return this.http.post(`${environment.baseUrlAPI}/listCountries`,{},this.httpOptions);
  }

  updateAvatar(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/updateAvatar`,body,this.httpOptions);
  }

  uploadFileToS3(body){
    let httpFormaData = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }); 
    return this.http.put(body['url'], body['file'], { headers: httpFormaData, reportProgress: true, observe: 'events' });
  }

  getListCommercialSection(body){
    return this.http.post(`${environment.baseUrlAPI}/listCommercialSection`, body, this.httpOptions);
  }

  /**
   * Registrar empresa
   */

  getListNotifications(){
    return this.http.post(`${environment.baseUrlAPI}/user/notifications`, {}, this.httpOptions);
  }

  acceptinviteBusinessUser(body){
    return this.http.post(`${environment.baseUrlAPI}/user/toAcceptinviteBusinessUser`, body, this.httpOptions);
  }

  rejectedinviteBusinessUser(body){
    return this.http.post(`${environment.baseUrlAPI}/user/toRefuseinviteBusinessUser`, body, this.httpOptions);
  }

  updateNotificationReadToTrue(body){
    return this.http.post(`${environment.baseUrlAPI}/user/updateNotificationReadToTrue`, body, this.httpOptions);
  }

  getStatusCompany(idCompany: string) {
    return this.http.post(`${environment.baseUrlAPI}/user/statusCompany`,{ id: idCompany }, this.httpOptions);
  }

  uploadFileS3AllExternal(body){
    return this.http.post(`${environment.baseUrlAPI}/company/contract/uploadFile`, body, this.httpOptions);
  }
}
