import { Component, OnInit , Output, EventEmitter, OnDestroy } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AwsRedHomeService } from 'src/app/services/main/home/red-home/aws-red-home.service';
import { GlobalService } from 'src/app/services/global.service';
import SwiperCore, { Pagination } from "swiper";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-sidebar-create-business',
  templateUrl: './sidebar-create-business.component.html',
  styleUrls: ['./sidebar-create-business.component.scss']
})
export class SidebarCreateBusinessComponent implements OnInit, OnDestroy {
  business = true;

  businessAll = [];
  loading = false;

  @Output() closeSidebar = new EventEmitter<boolean>();

  subscriber: Subscription;
  constructor( 
    private globalService: GlobalService,
    private aws: AwsRedHomeService,
    private router: Router
  ) {
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'STATUSCOMPLETEDBUSINESS':
            this.getCompanies();
            break;
          default:
            break;
        }
      }
    });
   }

  ngOnInit(): void {
    this.getCompanies();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  closeSidebarCreateBusiness(){
    this.closeSidebar.emit();
  }

  async getCompanies(){
    this.loading = true
    let resultVerified = await firstValueFrom(this.aws.getCompaniesVerified());
    let result = await firstValueFrom(this.aws.getCompaniesRequest());
    
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      let tempVerified = resultVerified['status'] ? resultVerified['data'] : [];
      let tempRequest = result['status'] ? result['data'] : [];
      let listTemporal = [];
      tempVerified.forEach(company => {
        listTemporal.push({
          businessName: company['businessName'],
          codeTributary: company['codeTributary'],
          status: 'APPROVED',
          logo: company['logo'],
          id: company['id'],
          users: company['usersQuantity'],
          information: company['informationLevel']
        })
      });
      tempRequest.forEach(company => {
        let objTemp = JSON.parse(JSON.stringify(company));
        objTemp['logo'] = "";
        objTemp['users'] = 1;
        objTemp['information'] = 10.5;
        objTemp['logo'] = "";
        listTemporal.push(objTemp)
      });
      this.businessAll = listTemporal;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
    this.loading = false;
  }

  updateCompany(data){
    this.router.navigate([`/home/create-business`]);
    this.globalService.sendRequest({ type: 'UPDATEBUSINESS'});
    localStorage.setItem('create-bussiness-update', JSON.stringify(data));
    this.closeSidebarCreateBusiness();
  }
}
