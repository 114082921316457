import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-upload-single-file-per-type',
  templateUrl: './upload-single-file-per-type.component.html',
  styleUrl: './upload-single-file-per-type.component.scss'
})
export class UploadSingleFilePerTypeComponent {
  showFiles = true;
  files = [];
  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];
  statusUploading = false;
  
  //extension en caso sea archivo unico
  @Input() extension = '';

  //en caso sea multiple
  @Input() extensions = '';
  @Input() type = '';
  @Input() typeValue = '';
  @Input() path = '';
  @Output() nextFiles = new EventEmitter<any>();
  @Output() closeDialogFiles = new EventEmitter<any>();
  initColor = "";
  @Input() idApproval = '';
  @Input() query = '';
  constructor(
    private globalService: GlobalService,
    private awsShared: AwsSharedService
  ) { 
    
  }

  ngOnInit(): void {
    this.geColorInit();
  }

  geColorInit(){
    let attr = this.extensionsAttr.find(c => c['ext'].includes(this.extension));
    this.initColor = attr ? attr['color'] : '#9e9e9e';
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<File>) {
      if (files.length === 1) {
        if(this.extension.length === 0){
     
            if(files[0].size <= 5242880){
              let ext = files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase()
                let attr = this.extensionsAttr.find(c => c['ext'].includes(ext))
                let namefile: any = files[0].name;
                let objPendingUpload = {
                  id: uuid(),
                  status: 'PENDING',
                  progress: 0,
                  size: this.formatBytes(files[0].size, 2),
                  name: namefile.replaceAll(/[^a-zA-Z 0-9.]+/g,'_'),
                  icon: attr ? attr['icon'] : 'fa-file',
                  color: attr ? attr['color'] : '#9e9e9e',
                  file: files[0]
                };
                this.files = [objPendingUpload];
              }else{
                if(files[0].size > 5242880){
                  this.globalService.sendRequest({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'El archivo es demasiado grande',
                    type: 'TOAST',
                  });
                }
              }
            
          
          if(!this.statusUploading){
            this.statusUploading = true;
            let index = 0;
            for (let i = 0; i < this.files.length; i++) {
              if(this.files[i].status === 'PENDING'){
                index = i;
                break;
              }
            }
            this.uploadFile(index);
          }
        }else{
          const file = files[0];
          if (file.size <= 5242880 && file.name.endsWith(`.${this.extension}`)) {
            let attr = this.extensionsAttr.find(c => c['ext'].includes(this.extension))
            const namefile: any = file.name;
            const objPendingUpload = {
              id: uuid(),
              status: 'PENDING',
              progress: 0,
              size: this.formatBytes(file.size, 2),
              name: namefile.replaceAll(/[^a-zA-Z 0-9.]+/g, '_'),
              icon: attr ? attr['icon'] : 'fa-file',
              color: attr ? attr['color'] : '#9e9e9e',
              file: file
            };
    
            this.files = [objPendingUpload];
    
            if (!this.statusUploading) {
              this.statusUploading = true;
              this.uploadFile(0);
            }
          } else {
            if(file.size > 5242880){
              this.globalService.sendRequest({
                severity: 'error',
                summary: 'Error',
                detail: 'El archivo es demasiado grande',
                type: 'TOAST',
              });
            }
            if(!file.name.endsWith(this.extension)){
              this.globalService.sendRequest({
                severity: 'error',
                summary: 'Error',
                detail: `El formato del archivo no es el correcto solo se admiten archivos ${this.extension}`,
                type: 'TOAST',
              });
            }
          }
        }
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'FILE_ERROR_MULTIPLE',
          type: 'TOAST',
        });
      }
  }

  async uploadFile(index) {
    const file = this.files[index];
    let preSigned = null;
      if(this.query === 'EXTERNAL_CONTRACT'){
        preSigned = await this.awsShared.uploadFileS3AllExternal({ "idApproval" : this.idApproval, "path": this.path, "name": file.name }).toPromise();
      }else{
        preSigned = await this.awsShared.uploadFileS3All({ "type": this.type, "typeValue": this.typeValue,  "path": this.path, "name": file.name }).toPromise();
      }

    if (preSigned['status']) {
      this.awsShared.uploadFileToS3({ url: preSigned['data']['url'], file: file.file })
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              file.status = 'PROGRESS';
              break;
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              const result = event;
              if (result && result['status'] === 200) {
                file.status = 'FINALIZED';
                file.url = result['url'].split('?')[0];
                this.uploadNextFile(index + 1);
                this.globalService.sendRequest({
                  severity: 'success',
                  summary: 'Éxito',
                  detail: 'DOCUMENT_UPLOAD_SUCCESS',
                  type: 'TOAST'
                });
              } else {
                this.files = [];
                this.statusUploading = false;
                this.globalService.sendRequest({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'FILE_ERROR_UPLOAD',
                  type: 'TOAST'
                });
              }
              break;
          }
        }, error => {
          this.files = [];
          this.statusUploading = false;
          this.globalService.sendRequest({
            severity: 'error',
            summary: 'Error',
            detail: 'FILE_ERROR_UPLOAD',
            type: 'TOAST'
          });
        });
    } else {
      this.files = [];
      this.statusUploading = false;
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'FILE_ERROR_UPLOAD',
        type: 'TOAST'
      });
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadNextFile(index) {
    if (index < this.files.length) {
      this.uploadFile(index);
    } else {
      this.statusUploading = false;
    }
  }

  deleteFile() {
    this.files = [];
    this.statusUploading = false;
  }

  generateFiles() {
    if (this.files.length > 0) {
      this.nextFiles.emit(this.files.map(c => {
        return {
          url: c.url,
          name: c.name
        };
      }));
    } else {
      this.nextFiles.emit([]);
    }
  }

  closeDialog(){
    this.closeDialogFiles.emit(false)
  }
}
