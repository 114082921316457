import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AwsSearchService {

  constructor(
    private http: HttpClient, 
    private sessionService: SessionService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks',
      'Auth': this.sessionService.token
    })
  };

  companiesAndSessionUser(body) {
    return this.http.post(`${environment.baseUrlAPI}/company/companiesAndSessionUser`,body, this.httpOptions);
  }

  searchCompany(body) {
    return this.http.post(`${environment.baseUrlAPI}/company/searchCompany`,body,this.httpOptions);
  }

  detailInviteSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/detailInviteSupplier`, body , this.httpOptions);
  }

  inviteSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/invite`, body , this.httpOptions);
  }

  detailCompanySearch(body) {
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': 'apliclientesksks',
        'client-hash': 'apliclientesksks',
        Auth: this.sessionService.token,
      }),
    };
    return this.http.post(
      `${environment.baseUrlAPI}/company/detailCompanySearch`,
      body,
      httpOptionsPreference
    );
  }

  getComparisonCompany(body) {
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': 'apliclientesksks',
        'client-hash': 'apliclientesksks',
        Auth: this.sessionService.token,
      }),
    };
    return this.http.post( `${environment.baseUrlAPI}/company/getComparisonCompany`, body, httpOptionsPreference );
  }
}
