import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { firstValueFrom } from 'rxjs';
import { HubService } from 'src/app/services/hub.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-permission-to-post',
  templateUrl: './permission-to-post.component.html',
  styleUrls: ['./permission-to-post.component.scss'],
})
export class PermissionToPostComponent implements OnInit {
  sidebarPermisionToPost = true;

  @Input() colorHub = '';
  @Input() permission = [];

  @Output() close = new EventEmitter<boolean>();
  @Output() solicitude = new EventEmitter<boolean>();

  listFormularyPermissions = [];
  listFormularyRules = [];
  acceptHubRules = false;
  loadingFormPermission = false;

  constructor(
    private aws: AwsHubHomeService,
    private hubService: HubService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.listFormularyPermissions = []
    this.listFormularyRules = [];
    this.permission.forEach(c => {
      if(c['typeQuestion'] === 'RULE'){
        this.listFormularyRules.push({
          id: c['id'],
          typeQuestion: c['typeQuestion'],
          question: c['question'],
          summary: c['summary'],
          value: "YES"
        })
      }else{
        this.listFormularyPermissions.push({
          id: c['id'],
          typeQuestion: c['typeQuestion'],
          question: c['question'],
          optionsQuestion: c['optionsQuestion'],
          value: ""
        })
      }

    });
  }

  cerrar() {
    this.close.emit();
  }

  solicitud() {
    this.solicitude.emit();
  }
  get validateFor() {
    return this.listFormularyPermissions.filter(c => c.value.trim().length === 0).length > 0 ? false : true;
  }

  async request() {
    if (this.validateFor && this.acceptHubRules) {
      this.loadingFormPermission = true;
      let permissionsPost = [];
      this.listFormularyPermissions.forEach(c => {
        permissionsPost.push({
          id: c['id'],
          value: c.value
        });
      });
      this.listFormularyRules.forEach(c => {
        permissionsPost.push({
          id: c['id'],
          value: c.value
        });
      });
      let body = {
        idhub: this.hubService.idHub,
        idCompany: this.hubService.idCompany,
        fields: permissionsPost,
      };
      let result = await firstValueFrom( this.aws.createPermissionsPostHubForUserAndCompany(body) );
      if (result['status'] === false) {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: result['message'],
          type: 'TOAST',
        });
      } else if (result['status'] === true) {
        this.globalService.sendRequest({
          severity: 'success',
          summary: 'Éxito',
          detail: result['message'],
          type: 'TOAST',
        });
        this.solicitud();
        this.cerrar();
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'ERROR_THE_REQUEST',
          type: 'TOAST',
        });
      }
      this.loadingFormPermission = false;
    } else {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'Llene el formulario completamente',
        type: 'TOAST',
      });
    }
  }
}
