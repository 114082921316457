<div class="relative w-full">
    <i *ngIf="show" class="fas fa-circle icon-show text-blue-600 cursor-pointer z-1" (click)="updateReadShowNotified()"  pTooltip="Marcar como leído"></i>
    <ng-container *ngIf="url.length > 0">
        <a [routerLink]="url" class="no-underline" (click)="closesidebar()">
            <div class="card m-2 p-2 flex shadow-5 align-items-center flex-wrap relative">
                <div class="flex align-items-center justify-content-center w-5rem">
                    <ng-container *ngIf="image.length > 0" >
                        <p-avatar [image]="image" [style]="{'width':'5rem', 'height': '5rem','background-color':'#ffffff', 'color': '#ffffff'}" shape="circle"></p-avatar>
                    </ng-container>
                    <p-avatar *ngIf="image.length === 0" label="{{avatar}}" [style]="{'width':'5rem', 'height': '5rem'}" shape="circle"></p-avatar>
                    <ng-container *ngIf="logoSecond.length > 0 || nameSecond.length > 0" >
                        <div class="w-5rem h-5rem absolute">
                            <p-avatar *ngIf="logoSecond.length > 0" [image]="logoSecond" [pTooltip]="nameSecond"
                                [style]="{'width':'2rem', 'height': '2rem','background-color':'#ffffff', 'color': '#ffffff', 'position':'absolute', 'bottom': '0', 'right':'0px'}" shape="circle">
                            </p-avatar>
                            <p-avatar *ngIf="logoSecond.length === 0" label="{{nameSecondAvatar}}" [style]="{'width':'2rem', 'height': '2rem', 'position':'absolute', 'bottom': '0', 'right':'0px'}" shape="circle">
                            </p-avatar>
                        </div>
                    </ng-container>
                </div>
                <div class="flex align-items-center pl-2" style="width: calc(100% - 5rem);">
                    <div class="w-full">
                        <p tooltipPosition="left" pTooltip="{{title}}" class="my-1 white-space-nowrap text-base overflow-hidden text-overflow-ellipsis"><strong>{{title}}</strong></p>
                        <p class="my-1 desc-notify" [innerHtml]="message"></p>
                    </div>
                </div>
                <ng-container *ngIf="data['type'] === 'CONFIRM_USER'">
                    <div class="flex align-items-center w-full justify-content-end">
                        <ng-container *ngIf="data['data']['status']">
                            <p-tag *ngIf="data['data']['status'] === 'ACCEPTED'" icon="pi pi-check" severity="success" value="Aceptada"></p-tag>
                            <p-tag *ngIf="data['data']['status'] === 'REJECTED'" icon="pi pi-times" severity="danger" value="Rechazada"></p-tag>
                        </ng-container>
                        <ng-container *ngIf="!data['data']['status']">
                            <button pButton label="RECHAZAR" class="p-button-danger p-button-rounded w-button mr-2" [disabled]="loading" (click)="rejectedUser()"></button>
                            <button pButton label="ACEPTAR" class="p-button-success p-button-rounded w-button" [disabled]="loading" (click)="acceptUser()"></button>
                        </ng-container>
                    </div>
                </ng-container>
                <span class="absolute text-green-400" style="right: 20px; top: 5px;">{{timeRange}}</span>
            </div>
        </a>
    </ng-container>
    <ng-container *ngIf="url.length === 0">
        <div class="card m-2 p-2 flex shadow-5 align-items-center flex-wrap relative">
            <div class="flex align-items-center justify-content-center w-5rem">
                <ng-container *ngIf="image.length > 0" >
                    <p-avatar [image]="image" [style]="{'width':'5rem', 'height': '5rem','background-color':'#ffffff', 'color': '#ffffff'}" shape="circle"></p-avatar>
                </ng-container>
                <p-avatar *ngIf="image.length === 0" label="{{avatar}}" [style]="{'width':'5rem', 'height': '5rem'}" shape="circle"></p-avatar>
                <ng-container *ngIf="logoSecond.length > 0 || nameSecond.length > 0" >
                    <div class="w-5rem h-5rem absolute">
                        <p-avatar *ngIf="logoSecond.length > 0" [image]="logoSecond" 
                            [style]="{'width':'2rem', 'height': '2rem','background-color':'#ffffff', 'color': '#ffffff', 'position':'absolute', 'bottom': '0', 'right':'0px'}" shape="circle">
                        </p-avatar>
                        <p-avatar *ngIf="logoSecond.length === 0" label="{{nameSecondAvatar}}" [style]="{'width':'2rem', 'height': '2rem', 'position':'absolute', 'bottom': '0', 'right':'0px'}" 
                            shape="circle">
                        </p-avatar>
                    </div>
                </ng-container>
            </div>
            <div class="flex align-items-center pl-2" style="width: calc(100% - 5rem);">
                <div class="w-full">
                    <p tooltipPosition="left" pTooltip="{{title}}" class="my-1 white-space-nowrap text-base overflow-hidden text-overflow-ellipsis"><strong>{{title}}</strong></p>
                    <p class="my-1 desc-notify" [innerHtml]="message"></p>
                </div>
            </div>
            <ng-container *ngIf="data['type'] === 'CONFIRM_USER'">
                <div class="flex align-items-center w-full justify-content-end">
                    <ng-container *ngIf="data['data']['status']">
                        <button *ngIf="data['data']['status'] === 'ACCEPTED'" pButton label="{{ 'accepted' | translate }}" icon="pi pi-check" class="p-button-success p-button-text w-button" ></button>
                        <button *ngIf="data['data']['status'] === 'REJECTED'" pButton label="{{ 'rejected' | translate }}" icon="pi pi-times" class="p-button-danger p-button-text w-button" ></button>
                    </ng-container>
                    <ng-container *ngIf="!data['data']['status']">
                        <button pButton label="{{ 'REJECT' | translate }}" class="p-button-danger p-button-rounded w-button mr-2" [disabled]="loading" (click)="rejectedUser()"></button>
                        <button pButton label="{{ 'AGREE' | translate }}" class="p-button-success p-button-rounded w-button" [disabled]="loading" (click)="acceptUser()"></button>
                    </ng-container>
                </div>
            </ng-container>
            <span class="absolute text-green-400" style="right: 20px; top: 5px;">{{timeRange}}</span>
        </div>
    </ng-container>
    
</div>