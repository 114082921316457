<div class="grid m-0">
    <div class="col-12">
        <div class="card m-0 flex align-items-center" style="height: calc(100vh - 93px);">
            <div class="grid text-center">
                <div class="col-12">
                    <img src="assets/images/image-section.png" alt="" srcset="">
                </div>
                <div class="col-12">
                    <h1 translate>text_section_disabled</h1>
                </div>
                <div class="col-12">
                    <h3 translate>text_section_disabled_op1</h3>
                    <h3 translate>text_section_disabled_op2</h3>
                    <h4><span translate>text_section_disabled_email</span><a style="color: var(--primary-color);" href="mailto:soporte@cbntec.com" >soporte&#64;cbntec.com</a><span translate>text_section_disabled_phone</span> (+51) 963-753-524 <span translate>text_section_disabled_or</span> (+51) 963-753-506.</h4>
                </div>
            </div>
        </div>
    </div>
</div>
