import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-preview-image-opportunities',
  templateUrl: './preview-image-opportunities.component.html',
  styleUrls: ['./preview-image-opportunities.component.scss']
})
export class PreviewImageOpportunitiesComponent implements OnInit, OnChanges {
  imageTemp = '';
  previewSidebarImage = false;
  @Input() images = [];
  index = 0;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.imageTemp = this.images.length > 0 ? this.images[0]['url'] : "";
  }

  selectedImage(url, index){
    this.imageTemp = url;
    this.index = index;
  }

  openMore(index){
    this.previewSidebarImage = true;
    this.index = index;
  }

}
