import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { SessionIn } from 'src/app/interfaces/session-in';
import { firstValueFrom } from 'rxjs';
import { HubService } from 'src/app/services/hub.service';
import { AwsEvaluationsHubService } from 'src/app/services/main/hub/evaluations-hub/aws-evaluations-hub.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-list-result-search',
  templateUrl: './list-result-search.component.html',
  styleUrls: ['./list-result-search.component.scss'],
})
export class ListResultSearchComponent implements OnInit, OnDestroy {
  informationScore = '';
  informationNum = 0;
  informationColor = '';
  resultDATE = null;
  showInfo = false;
  @Input() result = null;
  @Input() client_id = "";
  @Input() companiesCompare: string[] = [];
  @Output() updateCompare = new EventEmitter<Object>();
  @Input() typeView = 'USER';
  @Input() plan_id = 'FREE';
  @Input() typeCompany = null;
  urlCompany = "";
/////
  displayContac = false;

  sessionIn: SessionIn;

  representatives = [];
  chiefSalesOfficer = [];
  salesManager = [];
  salesRepresentative = [];
  tempContact = [];
  messageMail = "";
  files = [];
  loadEmail = false;
  uploadFileGeneralData = false;
  idCompany = "";
  sidebarAdd = false;

  // EVALUACION
  reason = "";
  showAutor = true;
  typeEv = "GENERAL";
  themeListFormulary = [];
  commentary = "";
  filesEv = [];
  uploadFiles = false;
  references = [];
  score = 0;
  scoreCriterion = 0;
  submitted = false;
  loadCreate = false;
  subscriber: Subscription;
  constructor( private globalService: GlobalService,private sessionService: SessionService, private hubService: HubService,private aws: AwsEvaluationsHubService) {
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'CLOSEMODALSUPPLIERINFOSEARCH':
            this.showInfo = false;
            break;
          default:
            break
        }
      }
    });
  }

  setId(){
    this.showInfo = true;
  }

  ngOnInit(): void {
    this.idCompany = this.result['id'];
    if(this.sessionService.statusSession){
      this.sessionIn = this.sessionService.sessionIn;
    }

    let data = {
      informationLevel: this.result['information'],
    };
    this.changeValues(data);

    var currentTime = new Date();
    var year = currentTime.getFullYear();
    this.resultDATE = year - this.result['start_commercial_activity'];
    if(this.result && this.result['store_status'] === "YES"){
      this.urlCompany = `https://${environment.baseUrlStore}/${this.result['url_company']}`;
    }
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  get clientStatus(){
    if(this.result && this.result.clients_id && this.client_id.length > 0){
      return this.result['clients_id'].includes(this.client_id) ? true : false;
    }else{
      return false;
    }
  }

  changeValues(data) {
    this.informationScore = data['informationLevel'];
    this.informationNum = parseInt(data['informationLevel']);
    this.informationColor = this.getColorScale(this.informationNum);
  }

  getColorScale(number) {
    if (number >= 0 && number <= 45) {
      return 'red';
    } else if (number > 45 && number <= 55) {
      return 'orange';
    } else if (number > 55 && number <= 65) {
      return 'yellow';
    } else if (number > 65 && number <= 85) {
      return 'lemon';
    } else if (number > 85 && number <= 100) {
      return 'green';
    } else {
      return 'red';
    }
  }

  get validateCompare(){
    return this.companiesCompare.includes(this.result ? this.result['id'] : '');
  }

  changeCompare(type){
    if(type === 'ADD'){
      this.companiesCompare.push(this.result['id'])
    }else{
      this.companiesCompare = this.companiesCompare.filter(c => c != this.result['id'])
    }
    this.updateCompare.emit(this.companiesCompare);
  }

  async sendMail(){

  }

  nextFilesGeneralData(data) {
    this.files = this.files.concat(data);
    this.uploadFileGeneralData = false;
  }

  reset(){
    this.reason = "";
    this.showAutor = true;
    this.typeEv = "GENERAL";
    this.commentary = "";
    this.filesEv = [];
    this.references = [];
    this.score = 0;
    this.resetThemes();
  }

  resetThemes(){
    this.scoreCriterion = 0;
    this.themeListFormulary = [
      {
        criterion: "ADMINISTRATIVE",
        score: 0,
        evaluate: 0,
        themes: [
          { theme: "COMMERCIAL_REFERENCES_AND_EXPERIENCE", evaluate: false, score: 0 },
          { theme: "OPERATIONAL_CAPACITY_AND_INFRASTRUCTURE", evaluate: false, score: 0 },
          { theme: "FINANCIAL_REFERENCES", evaluate: false, score: 0 },
          { theme: "QUALITY_ASSURANCE", evaluate: false, score: 0 },
          { theme: "SOCIAL_RESPONSABILITY", evaluate: false, score: 0 }
        ]
      },
      {
        criterion: "COMMERCIAL",
        score: 0,
        evaluate: 0,
        themes: [
          { theme: "CLAIMS_SUPPORT", evaluate: false, score: 0 },
          { theme: "RESPONSE_TIME", evaluate: false, score: 0 },
          { theme: "COMMERCIAL_TERMS", evaluate: false, score: 0 },
          { theme: "PRICE_COMPETITIVENESS", evaluate: false, score: 0 }
        ]
      },
      {
        criterion: "TECHNICAL",
        score: 0,
        evaluate: 0,
        themes: [
          { theme: "QUALITY_OF_GOOD_OR_SERVICE", evaluate: false, score: 0 },
          { theme: "DELIVERY_TIME", evaluate: false, score: 0 },
          { theme: "QUALITY_CONTROL", evaluate: false, score: 0 },
          { theme: "OCCUPATIONAL_HEALTH_AND_SAFETY", evaluate: false, score: 0 },
          { theme: "ENVIRONMENTAL_MANAGEMENT", evaluate: false, score: 0 }
        ]
      }
    ];
  }

  get validateEvaluate(){
    if(this.typeEv === "GENERAL"){
      return this.reason.trim().length > 0 && this.score >= 0 ? true : false;
    }else{
      let criterions = this.themeListFormulary.filter( c => c.evaluate > 0 );
      return this.reason.trim().length > 0 && criterions.length > 0 ? true : false;
    }
  }

  getColorScaleEv(number) {
    if (number >= 0 && number <= 2) {
      return 'red';
    } else if (number > 2 && number <= 4) {
      return 'orange';
    } else if (number > 4 && number <= 6) {
      return 'yellow';
    } else if (number > 6 && number <= 8) {
      return '#74e73b';
    } else if (number > 8 && number <= 10) {
      return 'green';
    } else {
      return 'red';
    }
  }

  fixNum(value: number) {
    return parseFloat((Math.round(value * 100) / 100).toFixed(4));
  }

  fixNumData(value: number) {
    return parseFloat((Math.round(value * 10) / 10).toFixed(1));
  }

  calculateThemeScore(i){
    this.themeListFormulary[i].evaluate = this.themeListFormulary[i].themes.filter( c => c.evaluate ).length;
    if(this.themeListFormulary[i].evaluate > 0){
      let sum = 0 ;
      for (const item of this.themeListFormulary[i].themes.filter( c => c.evaluate )) {
        sum += item['score'];
      }
      this.themeListFormulary[i].score = sum > 0 ? this.fixNum(sum / this.themeListFormulary[i].evaluate) : 0;
    }else{
      this.themeListFormulary[i].score = 0;
    }
    this.calculateGlobalScore();
  }

  calculateGlobalScore(){
    let sum = 0 ;
    for (const item of this.themeListFormulary.filter( c => c.evaluate > 0 )) {
      sum += item['score'];
     
    }
    this.scoreCriterion = sum > 0 ? this.fixNum(sum / this.themeListFormulary.filter( c => c.evaluate > 0 ).length) : 0;
  }

  nextFilesEv(data){
    this.filesEv = this.filesEv.concat(data);
    this.uploadFiles = false;
  }

  async evaluateCompany(){
    if(this.validateEvaluate){
      this.submitted = false;
      let criterion = [];
      if(this.typeEv === "CRITERION"){
        let criterios = this.themeListFormulary.filter( c => c.evaluate > 0 );
        for (const crit of criterios) {
          let themes = crit.themes.filter(c => c.evaluate);
          if(themes.length > 0){
            criterion.push({
              themes: themes,
              criterion: crit.criterion
            });
          }
        }
      }
      criterion.forEach((c,i) => {
        c.themes.map(c => {
          c['score'] = (c['score'] / 10)
        })
      })
      let objData = {
        idCompany: this.hubService.idCompany,
        idHub: this.hubService.idHub,
        idSupplier: this.idCompany,
        reason: this.reason.trim().toUpperCase(),
        type: this.typeEv,
        criterion: this.typeEv === "CRITERION" ? criterion : [],
        references: this.references.length > 0 ? this.references.join(" || ") : "",
        commentary: this.commentary && this.commentary.length > 0 ? this.commentary : "",
        files: this.filesEv,
        score: this.typeEv === "CRITERION" ? 0 : this.fixNumData(this.score / 10),
        showUserName: this.showAutor ? "YES" : "NO"
      };
      this.loadCreate = true;
      let result = await firstValueFrom(this.aws.evaluateCompanyHub(objData));
      this.loadCreate = false;
      if (result['status'] === false) {
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      } else if (result['status'] === true) {
          this.reset();
          this.sidebarAdd = false;
          this.globalService.sendRequest({ severity: 'success', summary: 'Exito', detail: result['message'], type: 'TOAST', });
      } else {
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
      }
    }else{
      this.submitted = true;
    }
  }

  
}
