import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { forkJoin } from 'rxjs';
import JSZip from 'jszip';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';

@Component({
  selector: 'app-download-all',
  templateUrl: './download-all.component.html',
  styleUrls: ['./download-all.component.scss']
})
export class DownloadAllComponent implements OnInit {
  @Input() files = [];
  loadDownloadFiles = false;
  getRequests = [];
  constructor(private globalService: GlobalService, private aws: AwsSharedService) { }

  ngOnInit(): void {
  }

  downloadAll(files){
    
    if(files.length > 0){
      this.loadDownloadFiles = true;
      this.getRequests = [];
      this.createGetRequets(files);

      forkJoin(...this.getRequests).subscribe(
        (res) => {
          let files_blob = res as any[];

          Promise.all(files_blob).then(
            (result) => {
              const zip = JSZip();
              result.forEach((f, i) => {
                zip.file(f.name, f.data_blob);
              });
              zip.generateAsync({ type: 'blob' }).then((blob) => {
                const a: any = document.createElement('a');
                document.body.appendChild(a);

                a.style = 'display: none';
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `FILES.zip`;
                a.click();
                window.URL.revokeObjectURL(url);
              });
            },
            (error) => {
              this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
            }
          );
        },
        (error) => {
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
        }
      );
      this.loadDownloadFiles = false;
    }else{
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'NO HAY ADJUNTOS POR DESCARGAR', type: 'TOAST', });
    }
  }

  private createGetRequets(data: string[]) {
    let list = [];
    data.map((file) => {
      let names = list.filter(c => c === file['name']);
      let name = (names.length > 0) ? `(${names.length + 1})${file['name']}` : file['name'];

      list.push(file['name']);
      this.getRequests.push(
        new Promise((resolve, reject) => {
          this.aws.download_file(file['url']).subscribe((blob) => {
              resolve({ data_blob: blob, name: name });
          }, 
          (error) => {
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
        });
        })
      );
    });
  }
}
