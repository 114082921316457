import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-list-activities-proccess',
  templateUrl: './list-activities-proccess.component.html',
  styleUrls: ['./list-activities-proccess.component.scss']
})
export class ListActivitiesProccessComponent implements OnInit {

  @Input() list = [];

  @Input() companies = [];
  @Input() managments = [];
  @Input() areas = [];
  @Input() premises = [];

  levels = [
    { label: 'NORMAL', value: 'NOR' },
    { label: 'URGENTE', value: 'URG' },
    { label: 'EMERGENCIA', value: 'EME' },
  ];

  reasons = [
    { label: "MEJOR PRECIO", value: "BESTPRICE" },
    { label: "MEJOR CALIDAD", value: "BESTQUALITY" },
    { label: "MEJOR TIEMPO DE ENTREGA", value: "BESTDELIVERYTIME" },
    { label: "PROVEEDOR ÚNICO", value: "ONLYPROVIDER" },
    { label: "MEJOR SERVICIO", value: "BESTSERVICE" },
    { label: "MEJOR FORMA DE PAGO", value: "BESTPAYMENTMETHOD" },
    { label: "ADJUDICACION DIRECTA", value: "DIRECTAWARD" },
    { label: "REGULARIZACION", value: "REGULARIZATION" },
    { label: "CONTRATO INTERNO", value: "INTERNALCONTRACT" },
    { label: "OTRA RAZÓN", value: "OTHERREASON" }
  ];

  constructor() { }

  ngOnInit(): void {
   
  }
  
  getPriority(code){
    return this.levels.find(c => c.value === code) ? this.levels.find(c => c.value === code)['label'] : code;
  }
  getCompanies(code){
    return this.companies.find(c => c.value === code) ? this.companies.find(c => c.value === code)['label'] : code;
  }
  getManagment(code){
    return this.managments.find(c => c.value === code) ? this.managments.find(c => c.value === code)['label'] : code;
  }
  getPremise(code){
    return this.premises.find(c => c.value === code) ? this.premises.find(c => c.value === code)['label'] : code;
  }
  getArea(code){
    return this.areas.find(c => c.value === code) ? this.areas.find(c => c.value === code)['label'] : code;
  }

  getReason(code){
    return this.reasons.find(c => c.value === code) ? this.reasons.find(c => c.value === code)['label'] : code;
  }

  convert(date){
    return new Date(date * 1000).toLocaleString();
  }

  getSplit(data){
   return data.split(" // ").filter(a => a.length > 0)
  }

  getSplitDataPremises(data){
    // CONVIERTE LA DATA DE STRING A ARRAY
    let dataSplit = data.split(" // ").filter(a => a.length > 0 )
    // COMPARA LOS VALORES DEL ARRAY Y ELIMINA LOS DUPLICADOS
    let uniqsDataSplit = dataSplit.filter(function(item, index, array) {
      return array.indexOf(item) === index;
    })
    let dataSplitArr = []
    // RECORRE Y ALMACENA LOS DATOS PARA LUEGO RETORNARLOS 
    for (let i = 0; i < uniqsDataSplit.length; i++) {
      
        dataSplitArr.push(this.premises.find(c => c.value === uniqsDataSplit[i]) ? this.premises.find(c => c.value === uniqsDataSplit[i])['label'] : uniqsDataSplit[i])
      
    }
    return dataSplitArr.join(", ");
   }

   getSplitDataCompanies(data){
    let dataSplit = data.split(" // ").filter(a => a.length > 0 )
    let uniqsDataSplit = dataSplit.filter(function(item, index, array) {
      return array.indexOf(item) === index;
    })
    let dataSplitArr = []
    for (let i = 0; i < uniqsDataSplit.length; i++) {
        dataSplitArr.push(this.companies.find(c => c.value === uniqsDataSplit[i]) ? this.companies.find(c => c.value === uniqsDataSplit[i])['label'] : uniqsDataSplit[i])
    }
    return dataSplitArr.join(", ");
   }

}
