import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-business-information',
  templateUrl: './validation-business-information.component.html',
  styleUrls: ['./validation-business-information.component.scss']
})
export class ValidationBusinessInformationComponent implements OnInit {
  validation = true;
  height  = 'height: 120px;'
  @Input () list = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
