
    <div class="grid m-0">
        <div class="col-12 py-0 hidden md:block">
            <div class="grid m-0">
                <div class="col-3 p-0">
                    <div class="grid m-0">
                        <div class="col-12 p-0">
                            <span class="font-bold" translate>date_and_time</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 p-0">
                    <div class="grid m-0">
                        <div class="col-12 p-0">
                            <span class="font-bold" translate>activities</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="loadingData">
            <div class="grid">
                <div class="col-3">
                    <div class="grid">
                        <div class="col-12 flex aling-items-center" style="padding-top: 13px;">
                            <p-skeleton shape="circle" size="1rem" styleClass="mr-2"></p-skeleton>
                            <p-skeleton width="5rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="grid list-activity">
                        <div class="col-12 flex aling-items-center">
                            <p-skeleton shape="circle" size="1rem" styleClass="mr-2"></p-skeleton><p-skeleton shape="circle" size="1rem" styleClass="mr-2"></p-skeleton><p-skeleton width="20rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <ng-container *ngFor="let item of activities">
            <div class="col-12 mb-2 class-border {{item.status}}-BORDER">
            
                <div class="grid m-0">
                    <div class="col-12 md:col-4 p-0">
                        <span class="font-bold"><i class="fas fa-clock mr-1"></i> {{(item.date * 1000) | date : 'dd/MM/yyyy, h:mm:ss a'}}</span>
                    </div>
                    <div class="col-12 md:col-8 p-0">
                        <div class="grid list-activity">
                            <div class="col-12">
                                <span class="font-bold text-base flex align-items-center" style="color: var(--bold-text);">
                                    <span class="bg-circle-log {{item.status}}" ></span>
                                    <img class="mr-2" style="border-radius: 50%; width: 1.5rem;
                                            height: 1.5rem;" [src]="item.userAvatar" alt="" srcset=""
                                                    tooltipPosition="left">
                                    {{item.userName}}
                                </span>
                            </div>
                            <div class="col-12 pt-0 mt-0">
                                <div class="grid m-0" style="margin-left: 30px!important;">
                                    <ng-container *ngIf="item.status === 'SUITABLE'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_SUITABLE</p>
                                        </div>
                                        <ng-container *ngIf="item['references'] && item['references'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>references</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['references']">
                                                        <li>{{items}}</li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item['emails'] && item['emails'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>notified_to</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['emails']">
                                                        <li> <a style="color: var(--primary-color);" href="mailto:{{items}}">{{items}}</a></li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        @if(item.reason){
                                            <div class="col-12">
                                                <span ><span class="font-bold" translate>Comentario</span>:</span>
                                            </div>
                                            <div class="col-12" [innerHTML]="item.reason">
                                            </div>
                                        }
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'OBSERVED'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_OBSERVED</p>
                                        </div>
                                        <ng-container *ngIf="item['references'] && item['references'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>references</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['references']">
                                                        <li>{{items}}</li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item['emails'] && item['emails'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>notified_to</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['emails']">
                                                        <li> <a style="color: var(--primary-color);" href="mailto:{{items}}">{{items}}</a></li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        @if(item.reason){
                                            <div class="col-12">
                                                <span ><span class="font-bold" translate>motive</span>:</span>
                                            </div>
                                            <div class="col-12" [innerHTML]="item.reason">
                                            </div>
                                        }
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'SUSPENDED'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_SUSPENDED</p>
                                        </div>
                                        <div class="col-12">
                                            <span ><span class="font-bold" translate>suspension_duration</span> <i  class="far fa-clock ml-1"></i> {{item['date'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}} <i  class="ml-1 fas fa-clock mr-1"></i>{{item['endDate'] * 1000 | date : 'dd/MM/yyyy, h:mm:ss a'}}</span>
                                        </div>
                                        <ng-container *ngIf="item['references'] && item['references'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>references</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['references']">
                                                        <li>{{items}}</li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item['emails'] && item['emails'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>notified_to</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['emails']">
                                                        <li> <a style="color: var(--primary-color);" href="mailto:{{items}}">{{items}}</a></li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        @if(item.reason){
                                            <div class="col-12">
                                                <span ><span class="font-bold" translate>motive</span>:</span>
                                            </div>
                                            <div class="col-12" [innerHTML]="item.reason">
                                            </div>
                                        }
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'BANNED'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_BANNED</p>
                                        </div>
                                        <ng-container *ngIf="item['references'] && item['references'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>references</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['references']">
                                                        <li>{{items}}</li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item['emails'] && item['emails'].length > 0">
                                            <div class="col-12">
                                                <span class="m-0 font-bold" translate>notified_to</span>:
                                            </div>
                                            <div class="col-12">
                                                <ul class="m-0">
                                                    <ng-container *ngFor="let items of item['emails']">
                                                        <li> <a style="color: var(--primary-color);" href="mailto:{{items}}">{{items}}</a></li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        @if(item.reason){
                                            <div class="col-12">
                                                <span ><span class="font-bold" translate>motive</span>:</span>
                                            </div>
                                            <div class="col-12" [innerHTML]="item.reason">
                                            </div>
                                        }
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'STRATEGIC'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_STRATEGIC</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'LEVERAGED'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_LEVERAGED</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'UNCRITICAL'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_UNCRITICAL</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item.status === 'BOTTLENECK'">
                                        <div class="col-12">
                                            <p class="m-0" translate>DESIGNATED_THE_COMPANY_AS_BOTTLENECK</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

