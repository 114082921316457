import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AwsRedHomeService {

  constructor(
    private http: HttpClient, 
    private sessionService: SessionService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks',
      'Auth': this.sessionService.token
    })
  };

  countAllCompanies(body){
    return this.http.post(`${environment.baseUrlAPI}/user/countAllCompanies`, body , this.httpOptions);
  }

  getHubsCompanyByIdPublics(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getHubsCompanyByIdPublics`, body, this.httpOptions);
  }

  listStoreCompanies(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listStoreCompanies`, body , this.httpOptions);
  }

  getCompaniesVerified() {
    return this.http.post(`${environment.baseUrlAPI}/user/getCompanyVerifyByUser`,{},this.httpOptions);
  }

  getCompaniesRequest() {
    return this.http.post( `${environment.baseUrlAPI}/user/getCompanysByUser`,{},this.httpOptions);
  }

  getHubsCompany(body) {
    return this.http.post(`${environment.baseUrlAPI}/user/getHubsCompany`,body,this.httpOptions);
  }

  getListfollowSetps() {
    return this.http.post(`${environment.baseUrlAPI}/user/ListfollowSetps`,{},this.httpOptions);
  }

  getPosts(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getPosts`, body,  this.httpOptions);
  }
}

