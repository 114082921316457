import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AwsRedHomeService } from 'src/app/services/main/home/red-home/aws-red-home.service';
import { GlobalService } from 'src/app/services/global.service';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
  selector: 'app-list-business',
  templateUrl: './list-business.component.html',
  styleUrls: ['./list-business.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ListBusinessComponent implements OnInit {

  @Input() data = null;
  @Output() close = new EventEmitter<boolean>();
  @Output() updateCompany = new EventEmitter<object>();

  listHubs = [];
  loading = false;
  desktop = true;
  constructor(
    private router: Router,
    private aws: AwsRedHomeService,
    private globalService: GlobalService,
    ) { 
      this.desktop = this.globalService.desktop;
    }

  ngOnInit(): void {
    this.searchHubs();
  }

  openCompany(id){
    this.close.emit(true);
    if(this.desktop){
      window.open('/home/company/'+id+'/list-hub', '_blank');
    }else{
      this.router.navigate([`/home/company/${id}/list-hub`]);
    }
  }

  openHub(item){
    this.close.emit(true);
    if(this.desktop){
      window.open('/home/hub/'+item.id+'/'+this.data['id'], '_blank');
    }else{
      this.router.navigate([`/home/hub/${item.id}/${this.data['id']}`]);
    }
  }

  getColorScale(number) {
    if (number >= 0 && number <= 45) {
      return 'red';
    } else if (number > 45 && number <= 55) {
      return 'orange';
    } else if (number > 55 && number <= 65) {
      return 'yellow';
    } else if (number > 65 && number <= 85) {
      return '#74e73b';
    } else if (number > 85 && number <= 100) {
      return 'green';
    } else {
      return 'red'
    }
  }

  update(data){
    this.updateCompany.emit(data)
  }

  async searchHubs(){
    this.loading = true;
    let result = await firstValueFrom(this.aws.getHubsCompany({ idCompany: this.data['id'] }));
    this.listHubs = result['status'] ? result['data'] : [];
    this.loading = false;
  }
}
