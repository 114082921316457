import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AwsSearchService } from 'src/app/services/main/home/search/aws-search.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-compare-provider',
  templateUrl: './compare-provider.component.html',
  styleUrls: ['./compare-provider.component.scss'],
})
export class CompareProviderComponent implements OnInit {
  @Input() companiesCompare = [];
  @Output() closeCompare = new EventEmitter<Boolean>();

  displayCompare = true;
  loading = false;

  companyOne = null;
  companyTwo = null;
  constructor(
    private globalService: GlobalService,
    private aws: AwsSearchService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.loading = true;
    let result = await firstValueFrom(
      this.aws.getComparisonCompany({
        id: this.companiesCompare[0],
        idCompnaySecound: this.companiesCompare[1],
      })
    );
    this.loading = false;
    if (result['status'] === false) {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: result['message'],
        type: 'TOAST',
      });
    } else if (result['status'] === true) {
      this.companyOne = result['data']['companyOne'];
      this.companyOne['confianzaWidth'] = this.companyOne['trustLevel'] + '%';
      this.companyOne['reputationWidth'] =
        this.companyOne['reputationLevel'] + '%';
      this.companyOne['informationWidth'] =
        this.companyOne['informationLevel'] + '%';
      this.companyOne['year'] =
        new Date().getFullYear() -
        new Date(
          this.companyOne['startCommercialActivity'] * 1000
        ).getFullYear();

      this.companyTwo = result['data']['companyTwo'];
      this.companyTwo['confianzaWidth'] = this.companyTwo['trustLevel'] + '%';
      this.companyTwo['reputationWidth'] =
        this.companyTwo['reputationLevel'] + '%';
      this.companyTwo['informationWidth'] =
        this.companyTwo['informationLevel'] + '%';
      this.companyTwo['year'] =
        new Date().getFullYear() -
        new Date(
          this.companyTwo['startCommercialActivity'] * 1000
        ).getFullYear();
    } else {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'ERROR_THE_REQUEST',
        type: 'TOAST',
      });
    }
  }

  closeModal() {
    this.displayCompare = false;
    setTimeout(() => {
      this.closeCompare.emit(false);
    }, 500);
  }

  percentage(percent, num) {
    return `${(num * 100) / percent}`+`%`;
  }

  setColor(num1, num2){
    if(num1 > num2){
      return 'rgb(29, 237, 2)';
    }else if(num1 < num2){
      return 'rgb(156, 156, 156)';
    }else if(num1 === num2){
      return 'rgb(204, 204, 204)';
    }else{
      return '#fffff'
    }

  }
}
