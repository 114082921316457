import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Notification } from 'src/app/interfaces/notification';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-list-notification',
  templateUrl: './list-notification.component.html',
  styleUrls: ['./list-notification.component.scss']
})
export class ListNotificationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data:Notification = null;
  @Output() close = new EventEmitter<Boolean>();

  image = "";
  avatar = "";
  title = "";
  message = "";
  date = null;
  show = false;
  typeImage = "";
  logoSecond = "";
  nameSecondAvatar = "";
  nameSecond = "";
  loading = false;
  url = "";
  justDay = "";
  justHour = "";
  justMinut = "";
  now = "";
  subscriber: Subscription;
  constructor(
    private globalService: GlobalService,
    private awsShared: AwsSharedService,
    public trans: TranslateService,
  ) { 
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateService();
            break;
            default:
            break;
        }
      }
    });

  }
  async translateService() {
    let textLang:any = await new Promise((resolve, reject) => {
      this.trans.get('timeLang').subscribe((res) => {
        resolve(res);
      });
    });
    this.justDay = textLang['justDay'];
    this.justHour = textLang['justHour'];
    this.justMinut = textLang['justMinut'];
    this.now = textLang['now'];
  }

  ngOnInit(): void {
    this.setValue();
    this.translateService();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setValue();
  }

  setValue(){
    this.image = this.data['image'];
    this.avatar = this.data['title'].charAt(0).toUpperCase();
    this.title = this.data['title'];
    this.message = this.data['message'+`${this.globalService.lang.charAt(0).toUpperCase()}` +  `${this.globalService.lang.slice(1)}`];
    this.date = this.data['createdDate'] * 1000;
    this.url = this.data['url'];
    this.show = !this.data['show'] || !this.data['read'] ? true : false;
    if(this.data['whoNotified'] && this.data['whoNotified'] === "USER_COMPANY"){
      this.logoSecond = this.data['whoNotifiedData']['logo'];
      this.nameSecond = this.data['whoNotifiedData']['businessName'];
      this.nameSecondAvatar = this.data['whoNotifiedData']['businessName'].charAt(0).toUpperCase();
    }
    if(this.data['whoNotified']){
      this.typeImage = this.data['whoNotified'];
    }else{
      this.typeImage = "USER";
    }
  }

  get timeRange(){
    if(this.date){
      let fechaActual = new Date().getTime();
      let miliseconds = Math.round(fechaActual - this.date);
      if(miliseconds >= 60000){
        let minutes = Math.round(miliseconds / 60000);
        if(minutes >= 60){
          let horas = Math.round(minutes / 60);
          if(horas >= 24){
            let dias = Math.round(horas / 24);
            return dias === 1 ?  `${this.justDay}`  : this.globalService.lang === 'es' ? 'Hace '+dias+' días' : dias+' day ago' ;
          }else{
            return horas === 1 ? `${this.justHour}` : this.globalService.lang === 'es' ? 'Hace '+horas+' horas' : horas+' hours ago' ;
          }
        }else{
          return minutes === 1 ? `${this.justMinut}` : this.globalService.lang ==='es' ? 'Hace '+minutes+' minutos' : minutes+' minutes ago' ;
        }
      }else{
        return `${this.now}`;
      }
    }else{
      return '';
    }
  }

  async acceptUser(){
    this.loading = true;
    let result = await firstValueFrom(this.awsShared.acceptinviteBusinessUser({ 
      idNotification: this.data['id'], idCompany: this.data['data']['idCompany'] 
    }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.globalService.sendRequest({ severity: 'success', summary: 'Éxito', detail: result['message'], type: 'TOAST' });
      if(result['message'] === "ACCEPT_INVITATION_SUCCESS"){
        this.data['data'] = {
          idCompany: this.data['data']['idCompany'] ,
          status: "ACCEPTED"
        };
        this.show = false;
        this.globalService.sendRequest({ type: 'STATUSCOMPLETEDBUSINESS' });
      }
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
    this.loading = false;
  }

  async rejectedUser(){
    this.loading = true;
    let result = await firstValueFrom(this.awsShared.rejectedinviteBusinessUser({ 
      idNotification: this.data['id'], idCompany: this.data['data']['idCompany'] 
    }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.globalService.sendRequest({ severity: 'success', summary: 'Éxito', detail: result['message'], type: 'TOAST' });
      if(result['message'] === "SEND_TO_REFOUSE_INVITATION_SUCCESS"){
        this.data['data'] = {
          idCompany: this.data['data']['idCompany'] ,
          status: "REJECTED"
        };
        this.show = false;
      }
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
    this.loading = false;
  }

  closesidebar(){
    this.close.emit();
    this.updateReadShowNotified();
  }

  async updateReadShowNotified(){
    if(this.show){
      this.show = false;
      let result = await firstValueFrom(this.awsShared.updateNotificationReadToTrue({ id: this.data['id'], createdDate: this.data['createdDate'] }) );
      if(result['status'] === false){
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      }else if(result['status'] === true){
      }else{
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
      }
    }
  }
}
