import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import SwiperCore, { Autoplay } from 'swiper';
import { GlobalService } from 'src/app/services/global.service';
SwiperCore.use([Autoplay]);
import { MenuItem} from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit, OnDestroy {
  @Input() type = '';
  @Input() optionCatalogue = "VISIBLE";
  previewSidebarImage = false;
  items = [
    {
      icon: 'fas fa-pen',
      command: () => {
        this.globalService.sendRequest({type : 'OPENSIDEBRPRODUCTSERVICES' , data :this.item});
      },
    },
    {
      icon: 'far fa-trash-alt',
      command: () => {
        this.globalService.sendRequest({type : 'DELETERPRODUCTSERVICES' , data :this.item});
      },
    },
    {
      icon: 'fas fa-photo-video',
      command: () => {
        this.previewSidebarImage = true
      },
    }
  ];
  itemsSummary = [
    {
      icon: 'fas fa-photo-video',
      command: () => {
        this.previewSidebarImage = true
      },
    }
  ]
  arrPhoto = [];
  arrVideo = [];
  @Input() item = null;
  menu: MenuItem[] | undefined;
  subscriber: Subscription;
  constructor( private globalService: GlobalService, public trans: TranslateService) {
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateService();
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    /* console.log(this.list) */
    if (this.item) {
      this.arrPhoto = this.item['files'].filter(c => c['type'] === "PHOTO");
      this.arrVideo = this.item['files'].filter(c => c['type'] === "VIDEO");
    }
    this.translateService();
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }

  async translateService(){
    let translate:any = await new Promise((resolve, reject) => {
      this.trans.get('shoppingPlan').subscribe((res) => {
        resolve(res);
      });
    });
    let items = [];
    if(this.type === 'SUMMARY'){
      items = [
        {
          label: translate['GALLERY'],
          icon: 'fas fa-photo-video',
          command: () => {
            this.previewSidebarImage = true
          }
      }]
    }else{
      items = [
        {
          label: translate['UPDATE'],
          icon: 'pi pi-refresh',
          command: () => {
            this.globalService.sendRequest({type : 'OPENSIDEBRPRODUCTSERVICES' , data :this.item});
          }
      },
      {
          label: translate['DELETE'],
          icon: 'far fa-trash-alt',
          command: () => {
            this.globalService.sendRequest({type : 'DELETERPRODUCTSERVICES' , data :this.item});
          }
      }]
    }
    
    this.menu = [
      {
          label: translate['options'],
          items: items
      }];
  }
}
