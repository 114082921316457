<div class="grid">
    <div class="col-12">
        <div class="flex align-items-center mr-4 icon-star justify-content-center">
            <i class="fas fa-star text-lg mr-2"></i>
            <span>{{listUserReaction.length}}</span>
        </div>
        <p-divider></p-divider>
        <div class="col-12">
            <p-scrollPanel [style]="{width: '100%', height: '250px'}" styleClass="custom">
                <div class="grid">
                    <div class="col-12">
                        <div *ngFor="let item of listUserReaction" class="mb-2">
                            <div class="feed-reactions">
                                <img class="img-profile" [src]="item.user.avatar ? item.user.avatar : 'assets/images/user.svg' " alt="">
                                <div class="name-b">
                                    <span class="name">{{item.user.name}}</span>
                                    <ng-container *ngIf="item.whoReaction === 'COMPANY'">
                                        <br>
                                        <span class="text-xs">{{item.company.businessName}}</span>
                                    </ng-container>
                                </div>
                                <div class="options-feed" style="width: 30px;">
                                    <div class="flex align-items-center icon-star">
                                        <i class="fas fa-star text-lg"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
    </div>
</div>
