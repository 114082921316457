import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { forkJoin } from 'rxjs';
import JSZip from 'jszip';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-new-list-files',
  templateUrl: './new-list-files.component.html',
  styleUrls: ['./new-list-files.component.scss'],
  providers: [ConfirmationService],
})
export class NewListFilesComponent implements OnInit {

  @Input() files = [];
  @Input() type = "";
  @Input() quantity = 3;
  @Output() update = new EventEmitter<any>();
  tempFiles = [];
  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];

  getRequests = [];
  loadDownloadFiles = false;
  constructor(private globalService: GlobalService, private aws: AwsSharedService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
  }

  updateFiles(){
    this.update.emit(this.files);
  }

  confirm(event: Event, file, i) {
    this.confirmationService.confirm({
        target: event.target,
        message: this.globalService.lang === 'es' ? '¿Está seguro que desea eliminar el archivo?' : "Are you sure you want to delete the file?",
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteFile(file, i);
        },
        reject: () => {
            //reject action
        }
    });
  }

  deleteFile(file, i){
    this.files.splice(i, 1);
    this.globalService.sendRequest({severity: 'success',summary: 'Éxito',detail: 'DOCUMENT_DELETE_SUCCESS',type: 'TOAST'});
    this.updateFiles();
  }

  getColor(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['color'] : '#9e9e9e';
  }
  
  getIcon(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['icon'] : 'fa-file';
  }

  private createGetRequets(data: string[]) {
    let list = [];
    data.map((file) => {
      let names = list.filter(c => c === file['name']);
      let name = (names.length > 0) ? `(${names.length + 1})${file['name']}` : file['name'];

      list.push(file['name']);
      this.getRequests.push(
        new Promise((resolve, reject) => {
          this.aws.download_file(file['url']).subscribe((blob) => {
              resolve({ data_blob: blob, name: name });
          }, 
          (error) => {
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
        });
        })
      );
    });
  }

  downloadAll(files){
    
    if(files.length > 0){
      this.loadDownloadFiles = true;
      this.getRequests = [];
      this.createGetRequets(files);

      forkJoin(...this.getRequests).subscribe(
        (res) => {
          let files_blob = res as any[];

          Promise.all(files_blob).then(
            (result) => {
              const zip = JSZip();
              result.forEach((f, i) => {
                zip.file(f.name, f.data_blob);
              });
              zip.generateAsync({ type: 'blob' }).then((blob) => {
                const a: any = document.createElement('a');
                document.body.appendChild(a);

                a.style = 'display: none';
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `FILES.zip`;
                a.click();
                window.URL.revokeObjectURL(url);
              });
            },
            (error) => {
              this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
            }
          );
        },
        (error) => {
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', });
        }
      );
      this.loadDownloadFiles = false;
    }else{
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'NO HAY ADJUNTOS POR DESCARGAR', type: 'TOAST', });
    }
  }

  getFiles(files){
    return files.slice(0, this.quantity);
  }

  getFilesSlice(files){
    this.tempFiles = files.slice(this.quantity);
  }

  download(url){
    window.open(url, '_blank')
  }
}
