import { Component, OnInit , EventEmitter, Input , Output, OnChanges } from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-list-properties',
  templateUrl: './list-properties.component.html',
  styleUrls: ['./list-properties.component.scss'],
  providers: [ConfirmationService]
})
export class ListPropertiesComponent implements OnInit, OnChanges {
  @Input() item = null;
  @Input() type = '';
  @Output() deleted = new EventEmitter<Object>();
  @Output() edit = new EventEmitter<Object>();
  @Output() location = new EventEmitter<Object>();
  constructor(private globalService: GlobalService,private confirmationService: ConfirmationService) { 
    
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.item){
      if(!this.item['addressDetail']){
        this.item['addressDetail'] = this.item['address'];
      }
    }
  }

  confirm(event: Event){
    this.confirmationService.confirm({
        target: event.target,
        message: this.globalService.lang === 'es' ? '¿Está seguro que desea eliminar la ubicación?' : "Are you sure you want to delete the location?",
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deletedItem()
        },
        reject: () => {
            //reject action
        }
    });
  }

  deletedItem(){
    this.deleted.emit(this.item)
  }
  editItem(){
    this.edit.emit(this.item)
  }
  setLocation(){
    this.location.emit(this.item)
  }
} 
