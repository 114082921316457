<p-sidebar [(visible)]="business" position="right" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" styleClass="sidebar-create-business-responsive" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center" style="border-bottom: 1px solid var(--surface-d); height: 53px;">
                <div class="col-7 ">
                    <h2 class="m-0" translate>REGISTERED_COMPANIES</h2>
                </div>
                <div class="col-5 text-right">
                    <button pButton pRipple type="button" label="{{'CREATE' | translate }}" class=" p-button-rounded w-button"  routerLink="/home/create-business" (click)="closeSidebarCreateBusiness()" [disabled]="loading"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 169px)'}">
                <div class="w-full h-full p-2" *ngIf="loading">
                    <p-skeleton width="100%" height="167px"  borderRadius="24px"></p-skeleton> 
                </div>
                <ng-container *ngIf="!loading">
                    <ng-container *ngFor="let item of businessAll">
                        <app-list-business [data]="item" (close)="closeSidebarCreateBusiness()" (updateCompany)="updateCompany($event)"></app-list-business>
                    </ng-container>
                </ng-container>
            </p-scrollPanel>
        </div>
        <div class="col-12 px-0 pb-0">
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" class=" p-button-rounded w-button p-button-danger"
                (click)="closeSidebarCreateBusiness()"></button>
        </div>
    </div>
</p-sidebar>
