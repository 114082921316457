<div class="grid m-0">
    <div class="col-12 container-image" (click)="previewSidebarImage = true">
        <img src="{{imageTemp}}" alt="" srcset="" class="image-container">
        <img src="{{imageTemp}}" alt="" srcset="" class="image-product">
        <div class="preview-photo">
           <i class="fas fa-eye"></i> 
        </div>
    </div>
    <div class="col-12 px-0 mb-2" style="height: 74px;">
        <div class="container-tumb scrollStyle">
            <ng-container *ngIf="images.length <= 4">
                <ng-container *ngFor="let item of images">
                        <img src="{{item.url}}" alt="" srcset="" (click)="selectedImage(item.url)">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="images.length > 4">
                <img src="{{images[0].url}}" alt="" srcset="" (click)="selectedImage(images[0].url , 0)">
                <img src="{{images[1].url}}" alt="" srcset="" (click)="selectedImage(images[1].url , 1)">
                <img src="{{images[2].url}}" alt="" srcset="" (click)="selectedImage(images[2].url , 2)">
                <img src="{{images[3].url}}" alt="" srcset="" (click)="selectedImage(images[3].url , 3)">
                <div class="more" (click)="openMore(4)">
                   + {{images.length - 4}}
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-preview-photo-video [listPreview]="images" (closeComponent)="previewSidebarImage = false" *ngIf="previewSidebarImage" [index]="index"></app-preview-photo-video>
